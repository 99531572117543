import styled from "@emotion/styled/macro";
import { useEffect, useRef, useState } from "react";
import { Button } from "@sunrun/experience-ui-components";
import { type AxiosError } from "axios";
import { type JsonObject } from "type-fest";
import { rollbar } from "providers/rollbar";

const helpText =
  "If you require assistance with this error, please include this information with your incident report.";

const isAxiosError = (error: unknown): error is AxiosError => {
  return !!error && typeof error === "object" && "response" in error;
};

type axiosErrorMetadata = {
  message?: string;
  requestId?: string;
  errorType?: string;
  statusText?: string;
  responseData?: any;
  method?: string;
  url?: string;
};
const getAxiosErrorMetadata = (
  error: Error | AxiosError | undefined
): axiosErrorMetadata => {
  if (!error) {
    return {};
  }
  if (isAxiosError(error) || isAxiosError(error?.cause)) {
    const axiosError = (
      isAxiosError(error) ? error : error.cause
    ) as AxiosError;
    const message = axiosError?.message;
    const requestId = axiosError?.response?.headers?.["x-amzn-requestid"];
    const errorType = axiosError?.response?.headers?.["x-amzn-errortype"];
    const statusText = axiosError?.response?.statusText;
    const responseData = axiosError?.response?.data;
    const method = axiosError?.config?.method;
    const url = axiosError?.config?.url;
    return {
      message,
      requestId,
      errorType,
      statusText,
      responseData,
      method,
      url,
    };
  }
  return {};
};

const sensitiveFields = [
  "password",
  "encryptedpassword",
  "newpassword",
  "repassword",
  "oldpassword",
  "passcode",
  "passphrase",
  "authorization",
  "authentication",
  "x-api-key",
  "apikey",
  "client_id",
  "client_secret",
  "accesstoken",
  "idtoken",
  "refreshtoken",
  "payment_token",
  "req_payment_token",
];

const checkSafeProperties = (key: string, value: string) => {
  if (sensitiveFields.includes(key.toLowerCase())) {
    return undefined;
  }
  return value;
};

const isUserSafeMessage = (message: string | undefined) => {
  if (!message || message.length <= 0) {
    return false;
  }
  if (message.includes("<html>")) {
    return false;
  }
  return true;
};

const pricingLambdaRegex = /Error invoking .*:\s*({.*})"/;
const getDeepErrorMessage = (error: Error) => {
  const realError: any = error.cause ?? error;
  let lightmileDeveloperMessage =
    realError?.lightmileResponse?.developerMessage;
  if (lightmileDeveloperMessage) {
    if (typeof lightmileDeveloperMessage === "string") {
      lightmileDeveloperMessage = JSON.parse(lightmileDeveloperMessage);
    }
    if (isUserSafeMessage(lightmileDeveloperMessage?.message)) {
      return lightmileDeveloperMessage.message;
    }
  }
  if (realError?.name === "ApolloError") {
    const message = realError?.message;
    if (pricingLambdaRegex.test(message)) {
      const matches = pricingLambdaRegex.exec(message);
      try {
        const errorObj = JSON.parse(matches?.[1].replace(/\\"/g, '"') ?? "");
        return `${errorObj.errorType}: ${errorObj.errorMessage}`;
      } catch (e) {} // NOTE: If JSON parsing fails, do not error
    }
    return realError?.message;
  }
  return realError?.message;
};

type ErrorProps = {
  /**
   * @summary Information about where the error originated.
   * @example
   * ```ts
   * "Pricing Page"
   * ```
   */
  context: string;
  /**
   * @summary Information about where the error originated.
   * Important to keep the original error object around because it has very
   * useful metadata associated with it.
   * @example
   * ```ts
   * // NOTE: Errors we are creating in our code.
   * new Error("Description of the invalid state being identified.");
   * ```
   * @example
   * ```ts
   * // NOTE: Changing an Error's message
   * new Error("Friendlier error message", { cause: error });
   * ```
   * @example
   * ```ts
   * // NOTE: Errors from libraries or runtime errors
   * try {
   *   axios.get();
   * } catch (error) {
   *   setError(error); // Note: Use the error from the catch
   * }
   * ```
   */
  error: Error | AxiosError | undefined;
  /**
   * @summary Steps the user can take to try to alleviate the error.
   * @example "Try refreshing the page."
   */
  instructions?: string;
  /**
   * @summary Information you want sent to the error reporting service to help
   * with triaging and debugging.
   * @example
   * ```ts
   * {
   *   prospectId: "123123",
   *   offerId: "abcabc",
   * }
   * ```
   */
  metadata?: JsonObject;
};
const ErrorComponent: React.FunctionComponent<ErrorProps> = ({
  context,
  error,
  instructions,
  metadata,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [timestamp] = useState<Date>(new Date());
  const reported = useRef<boolean>(false);
  useEffect(() => {
    if (reported.current) return;
    let axiosErrorMetadata = getAxiosErrorMetadata(error);
    console.error(`[${context}] ${error?.message}`, error);
    rollbar.critical(`[${context}] ${error?.message}`, error, {
      ...axiosErrorMetadata,
      ...metadata,
    });
    reported.current = true;
  }, [context, error, metadata, reported]);
  if (!error) {
    return (
      <ErrorSegment>
        <ParagraphWrap>
          <em>An unknown error occurred.</em>
        </ParagraphWrap>
        <hr />
        <ParagraphWrap>{helpText}</ParagraphWrap>
      </ErrorSegment>
    );
  }
  if (isAxiosError(error) || isAxiosError(error?.cause)) {
    let axiosErrorMetadata = getAxiosErrorMetadata(error);
    const axiosError = (
      isAxiosError(error) ? error : error.cause
    ) as AxiosError;
    return (
      <ErrorSegment>
        {axiosErrorMetadata.message && (
          <ParagraphWrap>
            <em>{axiosErrorMetadata.message}</em>
          </ParagraphWrap>
        )}
        {instructions && <p>{instructions}</p>}
        {axiosErrorMetadata.statusText && (
          <ParagraphWrap>
            <em>{axiosErrorMetadata.statusText}</em>
          </ParagraphWrap>
        )}
        {axiosErrorMetadata.responseData && (
          <ParagraphWrap>
            <code>
              {axiosErrorMetadata.responseData.messages?.length > 0 && (
                <ol>
                  {axiosErrorMetadata.responseData.messages.map(
                    (message: string, index: number) => (
                      <li key={`axiosErrorMetadata${index}`}>{message}</li>
                    )
                  )}
                </ol>
              )}
              {!axiosErrorMetadata.responseData.messages &&
                JSON.stringify(
                  axiosErrorMetadata.responseData,
                  checkSafeProperties
                )}
            </code>
          </ParagraphWrap>
        )}
        {axiosErrorMetadata.method && axiosErrorMetadata.url && (
          <ParagraphWrap>
            <em>{`${axiosErrorMetadata.method.toUpperCase()} ${
              axiosErrorMetadata.url
            }`}</em>
          </ParagraphWrap>
        )}
        {axiosErrorMetadata.requestId && (
          <ParagraphWrap>
            <code>requestId: {axiosErrorMetadata.requestId}</code>
          </ParagraphWrap>
        )}
        {axiosErrorMetadata.errorType && (
          <ParagraphWrap>
            <code>errorType: {axiosErrorMetadata.errorType}</code>
          </ParagraphWrap>
        )}
        <ErrorDetails>{"Timestamp: " + timestamp}</ErrorDetails>
        <ErrorDetailButtonContainer>
          <Button
            data-testid="show-details-button"
            onClick={() => setShowDetails(!showDetails)}
            size="sm"
          >
            {showDetails ? "Hide" : "Show"} Details
          </Button>
        </ErrorDetailButtonContainer>
        {showDetails && (
          <ErrorDetails>
            {JSON.stringify(axiosError, checkSafeProperties)}
          </ErrorDetails>
        )}
        <hr />
        <ParagraphWrap>{helpText}</ParagraphWrap>
      </ErrorSegment>
    );
  }
  const deepErrorMessage = getDeepErrorMessage(error);
  return (
    <ErrorSegment>
      {error?.message && !deepErrorMessage && (
        <ParagraphWrap>
          <em>{error.message}</em>
        </ParagraphWrap>
      )}
      {deepErrorMessage && (
        <ParagraphWrap>
          <em>{deepErrorMessage}</em>
        </ParagraphWrap>
      )}
      {instructions && <p>{instructions}</p>}
      <ErrorDetails>{"Timestamp: " + timestamp}</ErrorDetails>
      <ErrorDetailButtonContainer>
        <Button onClick={() => setShowDetails(!showDetails)} size="sm">
          {showDetails ? "Hide" : "Show"} Details
        </Button>
      </ErrorDetailButtonContainer>
      {showDetails && (
        <ErrorDetails>
          {JSON.stringify(error?.cause ?? error, checkSafeProperties)}
        </ErrorDetails>
      )}
      <hr />
      <ParagraphWrap>{helpText}</ParagraphWrap>
    </ErrorSegment>
  );
};

const ParagraphWrap = styled.p`
  overflow-wrap: break-word;
`;

const ErrorSegment = styled.div``;
const ErrorDetailButtonContainer = styled.div`
  margin: 24px 0px;
  max-width: fit-content;
`;
const ErrorDetails = styled.div`
  width: 100%;
  font-size: 0.75em;
  max-height: 30em;
  overflow-y: auto;
  overflow-wrap: anywhere;
`;

export { ErrorComponent };
