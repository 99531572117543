import { Modal, Typography, tokens } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { LinkHelpPhone, LinkHelpPhoneText } from "../atoms/ContactLinks";

type UnknownOdiModalProps = {
  odiNames: string[];
  isConfirmUtility?: boolean;
  onClose: () => void;
};

const UnknownOdiModal = ({
  odiNames,
  isConfirmUtility = false,
  onClose,
}: UnknownOdiModalProps) => {
  return (
    <Modal
      title={`Additional requirement${odiNames.length > 1 ? "s" : ""} found`}
      type="warning"
      primaryButton={{
        text: "Close",
        onClick: onClose,
      }}
      onClose={onClose}
    >
      <UnknownOdiModalContent>
        <Typography
          variant="p"
          color={tokens.TINTS_OFF_WHITE_20}
          size={tokens.FONT_SIZE_3}
        >{`You have ${
          odiNames.length > 1
            ? "additional requirements"
            : "an additional requirement"
        } of ${odiNames.join(", ")} for this proposal. ${
          isConfirmUtility
            ? "Please make sure to upload this to the “Other Documents” section."
            : ""
        }`}</Typography>
        <Typography
          variant="p"
          color={tokens.TINTS_OFF_WHITE_20}
          size={tokens.FONT_SIZE_3}
        >
          {`If you have any questions, please contact Sales Support Desk: Call at `}
          <LinkHelpPhone />
          {` or text at `}
          <LinkHelpPhoneText />
          {`.`}
        </Typography>
      </UnknownOdiModalContent>
    </Modal>
  );
};

const UnknownOdiModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 800px;
`;

export { UnknownOdiModal };
export type { UnknownOdiModalProps };
