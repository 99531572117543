import { Typography } from "@sunrun/experience-ui-components";
import React from "react";

const ErrorPage: React.FC = () => {
  return (
    <div>
      <Typography variant="h2">
        Whoops... something went wrong. Try going back.
      </Typography>
    </div>
  );
};

export { ErrorPage };
