import useSWR from "swr";
import { useAuthToken } from "./useAuthToken";
import { getDesign } from "services/lightmileProject";

const useDesign = (lightmileProjectId: string | undefined) => {
  const ihdToken = useAuthToken("IHD");
  const proposalToken = useAuthToken("PROPOSAL");
  return useSWR(
    ["design", ihdToken ?? proposalToken, lightmileProjectId],
    ([, authToken, lightmileProject]) => {
      if (!authToken) return;
      if (!lightmileProject) return;
      return getDesign(authToken, lightmileProject);
    }
  );
};

export { useDesign };
