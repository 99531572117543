import {
  SvgIcon,
  SvgNames,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";

type Props = {
  success: boolean;
  isLoading?: boolean;
  size?: number;
  testid?: string;
};

const StatusCheckMark = ({ success, isLoading = false, size = 18 }: Props) => {
  if (isLoading) {
    return (
      <LoadingIndicator
        width={size}
        height={size}
        data-testid="loading-indicator"
        showLoadingMessage={false}
      />
    );
  }

  if (success) {
    return (
      <SvgIcon
        width={size}
        name={SvgNames.SuccessCircle}
        data-testid="success-icon"
      />
    );
  }

  return (
    <SvgIcon
      width={size}
      name={SvgNames.ErrorCircle}
      data-testid="error-icon"
    />
  );
};

export { StatusCheckMark };
