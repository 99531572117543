import styled from "@emotion/styled/macro";
import infoIcon from "../assets/images/info-icon.svg";

const InfoToolTip = () => {
  return (
    <InfoToolTipContainer>
      <img alt="info icon tooltip" src={infoIcon}></img>
    </InfoToolTipContainer>
  );
};

const InfoToolTipContainer = styled.div`
  display: inline-block;

  > img {
    padding-left: 5px;
    width: 14px;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export { InfoToolTip };
