import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";
import { PackageHeader } from "../atoms/PackageHeader";
import { ReactComponent as Yes } from "../../assets/images/included.svg";
import { ReactComponent as No } from "../../assets/images/notIncluded.svg";
import { EditOfferBundle } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";

type PackageCompareTableProps = React.ComponentPropsWithoutRef<"table"> & {
  className?: string;
  bundles: EditOfferBundle[];
  uniqueProdIds: string[];
  productTitles: string[];
};

const PackageCompareTable = ({
  className = "",
  bundles,
  uniqueProdIds,
  productTitles,
  ...otherProps
}: PackageCompareTableProps) => {
  return (
    <StyledTable
      className={className}
      cellSpacing="0"
      cellPadding="0"
      {...otherProps}
    >
      <thead>
        <tr>
          <td>&nbsp;</td>

          {bundles.map((bundle, i) => (
            <StyledTh key={`tableCol${i}`} scope="col">
              <PackageHeader>{bundle.title}</PackageHeader>
            </StyledTh>
          ))}
        </tr>
      </thead>

      <tbody>
        {uniqueProdIds.map((prodId: string, j: number) => (
          <StyledTr key={`tableRow${j}`}>
            <StyledThItem scope="row">{productTitles[j]}</StyledThItem>

            {bundles.map((bundle, i) => (
              <StyledTd key={`tableCell${j}-${i}`}>
                {bundle.productIds.includes(prodId) ? <Yes /> : <No />}
              </StyledTd>
            ))}
          </StyledTr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 94%;
  margin: 0 2.5%;
  table-layout: fixed;
`;

const StyledTr = styled.tr`
  td:last-child {
    border-right: 0;
  }

  &:first-of-type th,
  &:first-of-type td {
    border-top: 1px solid rgba(208, 204, 195, 0.5);
  }
`;

const StyledTh = styled.th`
  text-align: left;
  background-color: ${tokens.BRAND_CHARGEBLUE};
`;

const StyledThItem = styled.th`
  padding-right: 12px;
  color: ${tokens.BRAND_HERO_BLUE};
  text-align: right;
  font-size: 12px;
  font-family: ${tokens.FONT_FAMILIES_ROOBERT};
  font-weight: 500;
  line-height: 18px;
  border-right: 1px solid rgba(208, 204, 195, 0.5);
  border-bottom: 1px solid rgba(208, 204, 195, 0.5);
`;

const StyledTd = styled.td`
  padding: 16px 20px;
  text-align: center;
  border-right: 1px solid rgba(208, 204, 195, 0.5);
  border-bottom: 1px solid rgba(208, 204, 195, 0.5);
`;

export { PackageCompareTable };
