import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, tokens } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { TaskItem } from "../molecules/TaskItem";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";

type TaskListProps = {
  title: string;
  installationPartner?: string;
};

const TaskList: React.FC<TaskListProps> = ({ title, installationPartner }) => {
  const { tasks, navigateToTask } = useCheckoutContext();
  const { pathname } = useLocation();

  return (
    <nav>
      <Header>
        <Typography variant="h5" color={tokens.BLACK}>
          {title}
        </Typography>
        {installationPartner && (
          <Typography size="small" color={tokens.TINTS_OFF_WHITE_10}>
            {installationPartner}
          </Typography>
        )}
      </Header>
      {tasks.map((task) => (
        <TaskItem
          {...task}
          key={task.id}
          onClick={() => navigateToTask(task.id)}
          isSelected={!!(task.urlSlug && pathname.includes(task.urlSlug))}
        />
      ))}
    </nav>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 8px;
  border-radius: 8px;
  background-color: ${tokens.TINTS_HERO_BLUE_90};
  margin-bottom: 16px;
`;

export { TaskList };
export type { TaskListProps };
