import React from "react";
import {
  Button,
  Skeleton,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { Card as SrCard, CardContent, CardHeader } from "../molecules/Card";
import freedomPortalImg from "assets/images/freedom-portal.png";
import tabletImg from "assets/images/tablet.png";
import { CardSectionDivider } from "components/atoms/Card";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { useProspect } from "checkout/hooks/useCheckoutSwr";
import { SwrSuspense } from "utils/SwrSuspense";

const Card = styled(SrCard)`
  max-width: 1024px;
`;

const SplitCard = styled(SrCard)`
  max-width: 1024px;
  display: grid;
  grid-template-columns: 1fr 340px;
  overflow: hidden;
`;

const Right = styled.div`
  --top-img-offset: 30px;

  background: ${tokens.GRADIENT_NIGHT_BLACK_MEDBLUE};
  border-radius: 0 16px 16px 0;
  position: relative;

  > .tablet,
  > .screenshot {
    position: absolute;
    right: 0;
  }

  > .tablet {
    top: var(--top-img-offset);
  }
  > .screenshot {
    top: calc(23px + var(--top-img-offset));
  }
`;

const TopParagraph = styled(Typography)`
  // NOTE: Pointless specificity needed to override styles more gently than !important
  :is(&) {
    margin-bottom: 1em;
  }
`;

const Freedom: React.FC = () => {
  const openPortal = () => {
    window.open("https://portal.freedomforever.com/", "_blank");
  };
  return (
    <>
      <TopParagraph color={tokens.OFFWHITE_10}>
        Please coordinate with the installation partner by completing the
        following steps in the Freedom Portal.
      </TopParagraph>
      <SplitCard>
        <div>
          <CardHeader>
            <Typography fontWeight="medium" size="large">
              Freedom Portal
            </Typography>
          </CardHeader>
          <CardContent>
            <div>
              <Typography color={tokens.OFFWHITE_20}>
                Completing the following will mark this task as done.
              </Typography>
              <Typography color={tokens.BRAND_HERO_BLUE} variant="span">
                <ol>
                  <li>Create Project and Enter Information</li>
                  <li>Upload Docs & Complete NEM</li>
                  <li>Submit Project</li>
                  <li>Confirm Site Audit</li>
                </ol>
              </Typography>
            </div>
            <div>
              <Button
                onClick={openPortal}
                color="secondary"
                size="sm"
                data-testid="freedom-portal-link"
              >
                Login to Freedom Portal
              </Button>
            </div>
          </CardContent>
        </div>
        <Right>
          <img alt="tablet" className="tablet" src={tabletImg} />
          <img
            alt="portal screenshot"
            className="screenshot"
            src={freedomPortalImg}
          />
        </Right>
      </SplitCard>
    </>
  );
};

const Generic: React.FC<{ installPartner?: string }> = ({ installPartner }) => {
  return (
    <Card>
      <CardHeader>
        <Typography fontWeight="medium" size="large">
          Fulfillment Partner
        </Typography>
      </CardHeader>
      <CardSectionDivider />
      <CardContent>
        <Typography>
          This project will be supported by{" "}
          {installPartner ? `"${installPartner}"` : "a trusted partner"}, one of
          Sunrun's premier fulfilment partners. Please consult your training to
          ensure all actions are completed to ensure a smooth and speedy
          project.
        </Typography>
        <Typography fontWeight="medium">
          {installPartner ? `"${installPartner}"` : "A trusted partner"} will be
          contacting you to coordinate next steps.
        </Typography>
      </CardContent>
    </Card>
  );
};

const FlexItemSkeleton = styled(Skeleton)`
  flex-grow: 1;
`;

const Loading: React.FC = () => {
  return (
    <Card>
      <CardHeader>
        <FlexItemSkeleton />
      </CardHeader>
      <CardSectionDivider />
      <CardContent>
        <Skeleton />
      </CardContent>
    </Card>
  );
};

const FinalizeWithPartnerTaskView: React.FC = () => {
  const { prospectId } = useCheckoutContext();
  const {
    data: prospect,
    isLoading: prospectLoading,
    error: prospectError,
  } = useProspect(prospectId);
  let INSTALL_PARTNER_NAME = "GENERIC";
  switch (prospect?.installPartnerName) {
    case "Freedom Forever, LLC":
      INSTALL_PARTNER_NAME = prospect?.installPartnerName;
      break;
  }
  return (
    <>
      <SwrSuspense
        data={prospect}
        isLoading={prospectLoading}
        error={prospectError}
      >
        <SwrSuspense.Loading>
          <Loading />
        </SwrSuspense.Loading>
        <SwrSuspense.Data>
          {INSTALL_PARTNER_NAME === "Freedom Forever, LLC" && <Freedom />}
          {INSTALL_PARTNER_NAME === "GENERIC" && (
            <Generic installPartner={prospect?.installPartnerName!} />
          )}
        </SwrSuspense.Data>
        <SwrSuspense.Error>
          <Generic />
        </SwrSuspense.Error>
      </SwrSuspense>
    </>
  );
};

export { FinalizeWithPartnerTaskView };
