import React from "react";
import {
  Button,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { Card, CardContent, CardHeader } from "../molecules/Card";
import { useDesignPreferencesUrl } from "../../../hooks/useDesignPreferencesUrl";
import { ErrorModal } from "../../../components/molecules/ErrorModal";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import included from "assets/images/included.svg";

const ConfirmPreferencesTaskView: React.FC = () => {
  const { currentTask, prospectId, refreshTasks, navigateToNextTask } =
    useCheckoutContext();

  const { data, error, mutate } = useDesignPreferencesUrl(prospectId);

  let sfdcWindow: Window | null = null;
  const updatePreferences = () => {
    if (data && (!sfdcWindow || sfdcWindow?.closed)) {
      sfdcWindow = window.open(data.url, "SFDC-des-pref");
    } else {
      sfdcWindow?.focus();
    }

    const timer = setInterval(async () => {
      if (sfdcWindow && sfdcWindow.closed) {
        clearInterval(timer);
        await refreshTasks();
      }
    }, 1000);
  };

  if (error) {
    return (
      <ErrorModal
        action="Fetching Design Preferences url"
        onRetry={() => mutate(data)}
        context="ConfirmPreferencesCheckout"
        error={error}
        onClose={refreshTasks}
      />
    );
  } else {
    return (
      <>
        <Card>
          <CardHeader>
            <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HEROBLUE}>
              Preferences
            </Typography>
          </CardHeader>
          <CardContent>
            <p data-testid="confirm-preferences-description">
              Customer preferences helps us make the best decision for your
              home.
            </p>
            {currentTask?.isComplete && (
              <CompletedContainer>
                <Icon src={included} />
                <span>Customer Preferences Completed</span>
              </CompletedContainer>
            )}
            <ButtonWrapper>
              <Button onClick={updatePreferences} size="sm" color="secondary">
                {data?.url ? (
                  "Update Preferences"
                ) : (
                  <LoadingIndicator
                    color={"black"}
                    showLoadingMessage={false}
                  />
                )}
              </Button>
            </ButtonWrapper>
          </CardContent>
        </Card>
        <StyledFooter>
          <Button
            disabled={!currentTask?.isComplete}
            onClick={navigateToNextTask}
            style={{ width: 120 }}
          >
            Confirm
          </Button>
        </StyledFooter>
      </>
    );
  }
};

const ButtonWrapper = styled.span`
  & Button {
    min-width: 185px;
  }
`;

const StyledFooter = styled.footer`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const CompletedContainer = styled.div`
  width: 378px;
  height: auto;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 12px;
  background: rgba(31, 38, 71, 0.05);
  border-radius: 8px;
  flex-direction: row;
  justify-content: left;
  gap: 4px;
  display: inline-flex;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
`;

export { ConfirmPreferencesTaskView };
