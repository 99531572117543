/* eslint-disable import/no-restricted-paths */
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Banner,
  BannerTypes,
  Chart,
  Typography,
  tokens,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { EnergyUsageForm } from "components/molecules/EnergyUsageForm";
import { Card, CardHeaderDivider } from "components/atoms/Card";
import { formatNumber } from "utils/formatHelpers";
import {
  UsageCadence,
  monthsAndDays,
  formatDataForMonthly,
} from "utils/usageUtils";
import { UsageFormContext } from "providers/UsageFormContext";

type UsageInfoProps = {
  monthlyUsageData: (number | undefined)[] | null;
  setMonthlyUsageData: (arr: (number | undefined)[]) => void;
  usageCadence: UsageCadence;
  setUsageCadence: (cadence: UsageCadence) => void;
  isReadOnly?: boolean;
};

const UsageInfo = ({
  monthlyUsageData,
  setMonthlyUsageData,
  usageCadence,
  setUsageCadence,
  isReadOnly = false,
}: UsageInfoProps) => {
  const [calculatedUsageData, setCalculatedUsageData] = useState<
    (number | undefined)[]
  >(monthlyUsageData ?? new Array(12).fill(undefined));
  const [totalUsage, setTotalUsage] = useState<null | number>(null);
  const [activeData, setActiveData] = useState(false);
  const { setIsDirty } = useContext(UsageFormContext);

  const determineIfChartIsEmpty = useCallback((monthlyData: number[]) => {
    const usageDataWithValues = monthlyData.filter((data) => data !== 0);
    const isEmpty = usageDataWithValues.length === 0;
    setActiveData(!isEmpty);
  }, []);

  const calculateUsage = useCallback(
    (data: (number | undefined)[], cadence: UsageCadence) => {
      const calcData = (data ?? []).map((datum) => datum ?? 0);
      setCalculatedUsageData(calcData);

      const total = calcData.reduce((accumulator, currentValue, index) => {
        const multiplier =
          cadence === "monthly" ? 1 : monthsAndDays[index].days;
        return accumulator + currentValue * multiplier;
      }, 0);

      determineIfChartIsEmpty(calcData);
      setTotalUsage(total);
    },
    [determineIfChartIsEmpty]
  );

  useEffect(() => {
    calculateUsage(
      monthlyUsageData ?? new Array(12).fill(undefined),
      usageCadence
    );
  }, [usageCadence, monthlyUsageData, calculateUsage]);

  return (
    <Card>
      <header className="usage-info-header">
        <Typography size={tokens.FONT_SIZE_3}>Usage Info</Typography>
      </header>
      <CardHeaderDivider />
      <StyledBanner
        bannerType={BannerTypes.WarningLight}
        show={true}
        showClose={false}
      >
        Please enter all 12 months of kWh data to optimize system design and
        customer experience.
      </StyledBanner>
      <div className="usage-info-body">
        <StyledFlexContainer>
          <EnergyUsageForm
            usageData={monthlyUsageData}
            setUsageData={(data) => {
              setMonthlyUsageData(data);
              calculateUsage(data, usageCadence);
              setIsDirty(true);
            }}
            usageCadence={usageCadence}
            setUsageCadence={setUsageCadence}
            isReadOnly={isReadOnly}
          />
          <StyledFlex4Div>
            <header data-testid="annual-usage">
              <Typography size={tokens.FONT_SIZE_3}>
                Total Usage Entered:{" "}
                <StyledAnnualUsageSpan>
                  {totalUsage ? `${formatNumber(totalUsage)} kWh` : ""}
                </StyledAnnualUsageSpan>
              </Typography>
            </header>
            <StyledChartContainer>
              {!isReadOnly && (
                <StyledChartHeader>
                  <Typography variant="p" color={tokens.TINTS_OFF_WHITE_20}>
                    Enter usage information to the left
                  </Typography>
                </StyledChartHeader>
              )}
              <StyledChartHeight>
                <Chart
                  xLabel="month"
                  labelAngle={45}
                  barData={
                    activeData
                      ? formatDataForMonthly(
                          calculatedUsageData,
                          monthsAndDays,
                          usageCadence
                        )
                      : []
                  }
                  defaultXTickValues={monthsAndDays.map(
                    (monthObj) => monthObj.month
                  )}
                  // TODO: Fix chart animations bug.  When values are not updating when animation duration has a non-zero value
                  //   when switching between daily and monthly cadence
                  // https://sunrun.jira.com/browse/SPLAT1-4747
                  animationDuration={0}
                />
              </StyledChartHeight>
            </StyledChartContainer>
          </StyledFlex4Div>
        </StyledFlexContainer>
      </div>
    </Card>
  );
};

const StyledAnnualUsageSpan = styled.span`
  color: ${tokens.TINTS_OFF_WHITE_20};
`;

const StyledFlexContainer = styled.div`
  display: flex;
  margin-top: 32px;
  @media (max-width: 900px) {
    display: block;
    section {
      margin-bottom: 20px;
    }
  }
`;

const StyledBanner = styled(Banner)`
  position: relative;
  margin: 24px 0px 24px 0px;
  border-width: 1.5px;
  font-weight: 500;
`;

const StyledFlex4Div = styled.div`
  flex: 4;
`;

const StyledChartContainer = styled.div`
  margin-top: 24px;
  border: 1px solid ${tokens.OFFWHITE_40};
  border-radius: 8px;
`;

const StyledChartHeight = styled.div`
  height: 350px;
  width: 100%;
  max-width: 100%;
`;

const StyledChartHeader = styled.header`
  padding: 18px;
`;

export { UsageInfo };
