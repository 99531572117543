import { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";

const MOBILE_BREAKPOINT = 768;

const useResponsive = () => {
  const [state, setState] = useState({
    isMobile: false,
    isDesktop: false,
  });

  const onResizeHandler = () => {
    const isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
    const isDesktop = window.innerWidth > MOBILE_BREAKPOINT;

    setState({ isMobile, isDesktop });
  };

  const debouncedCall = useDebounce(onResizeHandler, 300);

  useEffect(() => {
    onResizeHandler();
    window.addEventListener("resize", debouncedCall, false);

    return () => {
      window.removeEventListener("resize", debouncedCall, false);
    };
  }, [debouncedCall]);

  return state;
};

export { useResponsive, MOBILE_BREAKPOINT };
