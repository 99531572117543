import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import {
  ContactStatusNotification,
  StatusString,
} from "./ContactStatusNotification";

type CreditStatusNotificationProps = {
  contact: SplatContact;
};

const CreditStatusNotification = ({
  contact,
}: CreditStatusNotificationProps) => {
  const { creditStatus } = contact;
  const { isFailed, isPassed, isInProgress } = creditStatus;

  let message = "Credit Not Started";
  let status: StatusString = "Error";

  if (isFailed) {
    message = "Credit Not Approved";
  }

  if (isPassed) {
    message = "Credit Approved";
    status = "Success";
  }

  if (isInProgress) {
    message = "Credit Check In Progress";
    status = "Warning";
  }

  return <ContactStatusNotification status={status} message={message} />;
};

export { CreditStatusNotification };
