import systemSizeImg from "../components/assets/images/carousel/system-size-img.png";
import consumptionImg from "../components/assets/images/carousel/consumption-img.png";
import productionImg from "../components/assets/images/carousel/production-img.png";
import { CardCarouselData } from "providers/Types";
import { isBigTipText } from "utils/textUtils";

const productionTipText =
  "This is an estimate and does not guarantee actual system production. The system design may change based on a detailed engineering site survey. Actual system production will vary based on the final system size, design, and configuration.";

const systemSizeCardData = {
  headerText: "System Size",
  infoToolTipText: "",
  footerTextSuffix: "kW",
  iconMedia: systemSizeImg,
  extendedDescription: "",
} as CardCarouselData;
const productionCardData = {
  headerText: "Production",
  infoToolTipText: productionTipText,
  footerTextSuffix: "kWh",
  iconMedia: productionImg,
  extendedDescription: "",
  isBigTip: isBigTipText(productionTipText),
} as CardCarouselData;
const consumptionCardData = {
  headerText: "Consumption",
  infoToolTipText: "",
  footerTextSuffix: "kWh",
  iconMedia: consumptionImg,
  extendedDescription: "",
} as CardCarouselData;

export { systemSizeCardData, productionCardData, consumptionCardData };
