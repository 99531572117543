import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

const PageCenter = styled.div`
  background-color: ${tokens.BRAND_OFF_WHITE};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 1rem;
`;
export { PageCenter };
