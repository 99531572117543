import { useEffect } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router";
import { useMixpanelTracker } from "./providers/mixpanel";
import { router } from "router";
import { AuthPage } from "components/pages/AuthPage";
import { publish, setBaseUrl, useSubscribe } from "utils/messages";
import { store } from "store";
import { isInIframe } from "utils/iframeUtils";
import "./App.css";
import { FlagsmithEnvProvider } from "providers/flagsmith";

function App() {
  useEffect(() => {
    if (!isInIframe()) return;
    publish.eventReady();
  }, []);
  useSubscribe((message) => {
    switch (message.type) {
      case "EVENT:BOOTSTRAP": {
        setBaseUrl(message.payload.baseUrl);
        break;
      }
    }
  });
  useMixpanelTracker();
  return (
    <Provider store={store}>
      <FlagsmithEnvProvider>
        <AuthPage>
          <RouterProvider router={router} />
        </AuthPage>
      </FlagsmithEnvProvider>
    </Provider>
  );
}

export { App };
