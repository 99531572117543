import React from "react";
import styled from "@emotion/styled/macro";
import ExpandMapBtn from "../../assets/images/expand-map-btn.svg";
import sitePlaceholder from "components/assets/images/site-model-placeholder.png";
import { LeafletHelper } from "components/molecules/LeafletHelper";
import { maxWidth } from "components/atoms/GlobalStyles";
import { DesignRequest, SiteIhdResponse } from "providers/Types";

type SiteModelImageProps = {
  designRequest: DesignRequest | undefined | null;
  site: SiteIhdResponse | undefined;
  isMapModalOpen: boolean;
  setIsMapModalOpen: (arg0: boolean) => void;
};
const SiteModelImage: React.FC<SiteModelImageProps> = ({
  designRequest,
  site,
  isMapModalOpen,
  setIsMapModalOpen,
}) => {
  let image: React.ReactNode;
  if (designRequest?.status === "Complete" && site) {
    image = (
      <LeafletHelper
        showDisabledRoofs
        showEnabledRoofs
        showFlushObstructions
        showParcel
        showTrees
        showVerticalObstructions
        site={site}
        zoomToFeatures={["ENABLED_ROOFS"]}
      />
    );
  } else {
    image = (
      <SiteModelPlaceholderGraphic
        src={sitePlaceholder}
        data-testid="placeholder-image"
      />
    );
  }
  return (
    <SiteImageContainer
      isPlaceHolderImg={designRequest?.status !== "Complete" || !site}
      isMapModalOpen={isMapModalOpen}
    >
      {!isMapModalOpen && designRequest?.status === "Complete" && site && (
        <ExpandMapButton onClick={() => setIsMapModalOpen(true)}>
          <img src={ExpandMapBtn} alt="Expand Site Model Map Button" />
        </ExpandMapButton>
      )}
      {image}
    </SiteImageContainer>
  );
};

const SiteImageContainer = styled.div<{
  isPlaceHolderImg: boolean;
  isMapModalOpen: boolean;
}>`
  position: relative;
  flex: 1 1 30%;
  padding: 8px;
  min-height: ${({ isPlaceHolderImg, isMapModalOpen }) =>
    isPlaceHolderImg ? "auto" : isMapModalOpen ? "32rem" : "24rem"};
  margin: ${({ isPlaceHolderImg }) => (isPlaceHolderImg ? "auto" : "initial")};
  margin-bottom: ${({ isPlaceHolderImg }) =>
    isPlaceHolderImg ? "0px" : "24px"};
`;

const ExpandMapButton = styled.button`
  z-index: 50;
  position: absolute;
  right: 20px;
  top: 20px;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  &:hover {
    opacity: 0.7;
  }
`;

const SiteModelPlaceholderGraphic = styled.img`
  max-width: 100%;
  min-width: 140px;
  min-height: 140px;
  max-height: 450px;
  border-radius: 16px;
  @media (max-width: ${maxWidth}px) {
    object-fit: cover;
    aspect-ratio: 2/1;
  }
`;

export { SiteModelImage };
export type { SiteModelImageProps };
