import styled from "@emotion/styled/macro";
import { Typography } from "@sunrun/experience-ui-components";
import { tokens } from "@sunrun/experience-ui-components";

type BundleRadioButtonProps = {
  label: string;
  value: string;
  imageUrl: string;
  disabled?: boolean;
  description: string;
  bundleId?: string;
  handleBundleChange: (value: string) => void;
};

const BundleRadioButton = ({
  label,
  value,
  imageUrl,
  disabled = false,
  description,
  bundleId,
  handleBundleChange,
}: BundleRadioButtonProps) => {
  return (
    <BundleRadioButtonContainer $isDisabled={disabled}>
      <StyledBundleRadioButton
        disabled={disabled && !(bundleId === value)}
        type="radio"
        name="bundleOptions"
        checked={bundleId === value}
        value={value}
        onChange={(event: any) => handleBundleChange(event.target.value)}
        data-testid={"standard-radio"}
      />
      <RadioInfo>
        <RadioImage imageUrl={imageUrl} />

        <Typography variant="h6" color={tokens.BRAND_HERO_BLUE}>
          {label}
        </Typography>
        <TextContainer>
          <Typography color={tokens.TINTS_OFF_WHITE_20}>
            {description}
          </Typography>
        </TextContainer>
      </RadioInfo>
    </BundleRadioButtonContainer>
  );
};

const BundleRadioButtonContainer = styled.div<{ $isDisabled: boolean }>`
  cursor: ${(props) => (props.$isDisabled ? "progress" : "pointer")};
  height: 100%;
`;

const StyledBundleRadioButton = styled.input`
  opacity: 0;
  position: absolute;

  &:not(:disabled) {
    &:checked + div,
    &:hover + div {
      border: 1.5px solid ${tokens.BRAND_HERO_BLUE};
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.12);
      border-radius: 6.144px;
    }
  }
`;

const RadioImage = styled.div<{ imageUrl: string }>`
  aspect-ratio: 21 / 9;
  background: ${(props) =>
    `${tokens.WHITE} url(${props.imageUrl}) no-repeat center`};
  background-size: cover;
  border-radius: 6.144px 6.144px 0 0;
`;

const RadioInfo = styled.div`
  border-radius: 6.144px;
  display: flex;
  flex-direction: column;
  border: 1.5px solid rgba(208, 204, 195, 0.6);
  background: ${tokens.TINTS_OFF_WHITE_70};
  height: inherit;
  width: 100%;
  > h6 {
    padding: 20px 17px 10px;
    margin: 0;
  }
  > div:nth-of-type(2) {
    padding: 0px 17px 17px;
    margin: 0;
  }
`;

const TextContainer = styled.div`
  padding: 20px 17px 10px;
`;

export { BundleRadioButton };
