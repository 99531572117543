import { FLEX_MONTHLY, MONTHLY } from "constants/financialProducts";
import { FinancialProduct } from "providers/pricing/PricePoints";

const formatNumber = (num: number): string => {
  return new Intl.NumberFormat("en-US").format(num);
};

const formatPercent = (number: number, maxDecimal: number = 2): string => {
  return `${(number * 100).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimal,
  })}%`;
};

const formatCurrency = (value?: number | null, decimals = 0) => {
  if (value === null || value === undefined) {
    return "N/A";
  }
  const currencyValue = Number(value).toFixed(decimals);
  const negative = currencyValue.startsWith("-") ? "-" : "";
  return `${negative}$${currencyValue
    .replace(/^(?:-)/, "")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

const formatProductCost = (value: number, paymentType: FinancialProduct) => {
  let productCost = "";
  if (value > 0) {
    productCost = formatCurrency(value);
    if (paymentType === MONTHLY || paymentType === FLEX_MONTHLY) {
      productCost += "/mo";
    }
  }
  return productCost;
};

const formatProductMinMaxCost = (
  minValue: number,
  maxValue: number,
  paymentType: FinancialProduct
) => {
  if (!minValue || minValue === maxValue) {
    return formatProductCost(maxValue, paymentType);
  }
  let productCost = `${formatCurrency(minValue) || "N/A"} - ${
    formatCurrency(maxValue) || "N/A"
  }`;
  if (paymentType === MONTHLY || paymentType === FLEX_MONTHLY) {
    productCost += "/mo";
  }
  return productCost;
};

export {
  formatCurrency,
  formatNumber,
  formatPercent,
  formatProductCost,
  formatProductMinMaxCost,
};
