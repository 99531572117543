import { useMemo } from "react";
import {
  SplatCheckoutDocumentType,
  SplatODI,
} from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { Role } from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { useCheckoutStepsData } from "checkout/api/checkoutSteps";
import { useAppSelector } from "store";
import { useContactsData } from "utils/swrHooks";
import { ROLES } from "utils/usageUtils";

type ReadSignDocsProps = {
  prospectId: string;
  proposalId: string;
};

type ReadSignDocsStep = SplatODI & {
  docName: string;
  isUnlocked: boolean;
  description: string;
  allowSecondaryContact: boolean;
  primarySignerRole: Role;
  documentType: SplatCheckoutDocumentType;
  showEmail?: boolean;
  disableEmail?: boolean;
  showRead?: boolean;
  showSign?: boolean;
  isOptional?: boolean;
  isUnknown?: boolean;
};

const mapODIToDocs = (odi: SplatODI, allOdis: SplatODI[]) => {
  let isUnlocked = true;
  let docName = "";
  let description = "";
  let allowSecondaryContact = false;
  let primarySignerRole: Role;
  let documentType: SplatCheckoutDocumentType | undefined;
  let [showSign, showEmail, disableEmail, showRead] = Array(4).fill(false);
  let isOptional = false;
  let isUnknown = false;
  const loanOdi = allOdis.find(
    (odi) => odi.odiName === "loanApplicationApproval"
  );
  const sunrunAgreementOdi = allOdis.find(
    (odi) => odi.odiName === "customerAgreement"
  );
  const cpucOdi = allOdis.find((odi) => odi.odiName === "cpuc");

  const ipaOdi = allOdis.find((odi) => odi.odiName === "ipaRebate");
  const creditCheckOdi = allOdis.find((odi) => odi.odiName === "creditCheck");

  switch (odi.odiName) {
    case "ipaRebate":
      docName = "Illinois Power Authority";
      primarySignerRole = ROLES.HOMEOWNER;
      description =
        "This document needs to be signed prior to signing the Sunrun Agreement. Please email the IPA document to the customer for signature.";
      documentType = "Illinois Power Authority";
      isUnlocked = true;
      showRead = false;
      showEmail = true;
      isOptional = false;
      break;
    case "hicLight":
      docName = "EV Charger Agreement";
      primarySignerRole = ROLES.HOMEOWNER;
      description =
        "Please review the terms of your EV Charger agreement with Sunrun.";
      documentType = "HIC Light";
      isUnlocked = !!sunrunAgreementOdi && sunrunAgreementOdi.completed;
      showRead = true;
      showEmail = true;
      isOptional = !odi.isRequired;
      break;

    case "cpuc":
      docName = "California Solar Consumer Protection Guide";
      primarySignerRole = ROLES.HOMEOWNER;
      description =
        "The state of California created a guide to outline the solar process, your rights, and address common questions.";
      documentType = "CPUC e-sign";
      showRead = true;
      showEmail = true;
      isOptional = !odi.isRequired;
      break;

    case "nemAgreement":
      docName = "Net Metering Agreement";
      primarySignerRole = ROLES.UTILITYBILLCONTACT;
      description =
        "This agreement allows us to monitor your production and sell back the energy you have produced.";
      isUnlocked = !!sunrunAgreementOdi?.completed;

      documentType = "NEM Agreement";
      showRead = true;
      showEmail = true;
      disableEmail = !sunrunAgreementOdi?.completed;
      isOptional = !odi.isRequired;
      break;

    case "loanDocumentSignature":
      docName = "Loan";
      primarySignerRole = ROLES.HOMEOWNER;
      description =
        "Please review the terms of your Loan agreement with the bank.";
      documentType = "Bank Approval Document";
      showEmail = true;

      isUnlocked = !(
        (!!loanOdi && !loanOdi.completed) ||
        (!!sunrunAgreementOdi && !sunrunAgreementOdi?.completed)
      );

      break;

    case "customerAgreement":
      docName = "Sunrun Agreement";
      description =
        "Please review the terms of your solar agreement with Sunrun.";
      allowSecondaryContact = true;
      primarySignerRole = ROLES.HOMEOWNER;
      isUnlocked = !(
        (!!ipaOdi && !ipaOdi.completed) ||
        (!!cpucOdi && !cpucOdi.completed) ||
        (!!creditCheckOdi && !creditCheckOdi.completed) ||
        (!!loanOdi && !loanOdi.completed)
      );

      documentType = "Customer Agreement";
      showRead = true;
      showEmail = true;
      isOptional = !odi.isRequired;
      break;
    default:
      console.error(`Unknown ODI: ${odi.odiName}`);
      docName = odi.odiName;
      isUnknown = true;
      //these props don't really need to be here, but they can't be undefined
      documentType = "Home Owner Insurance";
      primarySignerRole = "Other";
  }

  return {
    ...odi,
    docName,
    description,
    isUnlocked,
    allowSecondaryContact,
    primarySignerRole,
    documentType,
    showEmail,
    disableEmail,
    showRead,
    showSign,
    isOptional,
    isUnknown,
  };
};

const useReadSignDocs = ({ prospectId, proposalId }: ReadSignDocsProps) => {
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { steps, stepsError, isStepsLoading, refetch } = useCheckoutStepsData(
    prospectId,
    proposalId,
    authKey
  );
  const { isContactsLoading } = useContactsData(prospectId, authKey);

  const allOdIs = steps?.reduce(
    (acc, step) => [...acc, ...step.odis],
    [] as SplatODI[]
  );

  const readSignDocsSteps = useMemo(
    () =>
      steps
        ?.filter((step) =>
          ["readAndSignDocs", "nemAgreement"].includes(step.name)
        )
        .reduce((acc, step) => {
          return [
            ...acc,
            ...step.odis.map((odi) => mapODIToDocs(odi, allOdIs!)),
          ];
        }, [] as ReadSignDocsStep[]),
    [allOdIs, steps]
  );

  return {
    readSignDocsSteps,
    stepsError,
    isStepsLoading: isStepsLoading || isContactsLoading,
    refetch,
  };
};

export { useReadSignDocs, mapODIToDocs };
export type { ReadSignDocsStep };
