import styled from "@emotion/styled/macro";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import { useMemo } from "react";
import { TaskCheckMark } from "../atoms/TaskCheckMark";
import { CheckoutTask } from "checkout/api/checkoutSteps";

type TaskItemProps = CheckoutTask & {
  isSelected?: boolean;
  onClick?: () => void;
};

const TaskItem = ({
  label,
  isUnlocked,
  isComplete,
  isInProgress,
  isSelected = false,
  onClick,
  urlSlug,
}: TaskItemProps) => {
  const locked = !isUnlocked;

  const ariaLabelStatus = useMemo(() => {
    if (isSelected) return "selected";
    if (isComplete) return "completed";
    if (isInProgress) return "in progress";
    if (locked) return "locked";
    return "incomplete";
  }, [isComplete, isInProgress, isSelected, locked]);

  return (
    <Button
      $selected={isSelected}
      disabled={locked}
      onClick={onClick}
      data-testid={`checkout-task-list-${urlSlug}`}
      aria-label={`${label} is ${ariaLabelStatus}`}
    >
      <IconWrapper>
        <TaskCheckMark
          checked={isComplete}
          locked={locked}
          halfChecked={isInProgress}
        />
      </IconWrapper>
      <Typography
        variant="p"
        color={locked ? tokens.TINTS_OFF_WHITE_30 : tokens.BLACK}
      >
        {label}
      </Typography>
    </Button>
  );
};

const Button = styled.button<{ $selected: boolean }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  background-color: transparent;
  border-radius: 8px;
  border: none;

  ${(props) =>
    props.$selected &&
    `
    background-color: ${tokens.TINTS_HERO_BLUE_90};
    `}
  &:hover {
    background-color: ${tokens.INFO_90};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

export { TaskItem };
export type { TaskItemProps };
