import styled from "@emotion/styled/macro";
import { PageHeaderOrganism } from "./PageHeaderOrganism";
import {
  contentPadding,
  contentPaddingMobile,
  maxWidth,
} from "components/atoms/GlobalStyles";

export const PositionedHeader = styled(PageHeaderOrganism)`
  grid-column: main;
  grid-row: page-top;
  margin: 0;
  padding-left: ${contentPadding}px;
  &.smallPadding {
    padding-left: ${contentPaddingMobile}px;
  }

  @media (max-width: ${maxWidth}px) {
    padding-left: ${contentPaddingMobile}px;
  }
`;
