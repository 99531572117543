import { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import plusIcon from "../../assets/images/plus.svg";
import minusIcon from "../../assets/images/minus.svg";
import { EditOfferProduct } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { ProductVariantOptions } from "./ProductVariantOptions";
import { ProductTitleWithInfoModal } from "components/atoms/ProductTitleWithInfoModal";
import { AddPill } from "components/atoms/AddPill";
import { useDebounce } from "hooks/useDebounce";
import { ProductImage } from "components/atoms/ProductImage";

type ProductAccessoryProps = {
  productId: string;
  title: string;
  imageUrl: string;
  added: boolean;
  disabled: boolean;
  quantity?: number;
  onAccessoryChange: (
    id: string,
    quantity: number,
    type: "delete" | "add" | "update"
  ) => void;
  selectedVariantId?: string;
  variants?: EditOfferProduct["variants"];
  onVariantChange: (productId: string, variantId: string) => void;
};

const ProductAccessory = ({
  productId,
  title,
  imageUrl,
  added,
  quantity = 0,
  disabled,
  onAccessoryChange,
  selectedVariantId,
  variants = [],
  onVariantChange,
}: ProductAccessoryProps) => {
  const [itemAdded, setItemAdded] = useState(added);
  const [itemQuantity, setItemQuantity] = useState(quantity);

  // Wait for 1s for the user to stop making actions to update "debouncedValues"
  const debouncedValues = useDebounce(`${itemAdded}${itemQuantity}`, 1000);

  useEffect(() => {
    // Only want to update the parent component when the user has stopped making actions
    // and if result is different from the initial values
    if (itemAdded !== added || itemQuantity !== quantity) {
      const type = itemAdded
        ? itemAdded === added
          ? "update"
          : "add"
        : "delete";
      onAccessoryChange(productId, itemQuantity, type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added, debouncedValues, productId, onAccessoryChange, quantity]);

  const toggleAdded = () => {
    setItemAdded((prev) => {
      const newValue = !prev;
      setItemQuantity(Number(newValue));
      return newValue;
    });
  };

  const handleMinus = () => {
    if (itemQuantity - 1 >= 1) {
      setItemQuantity((prev) => prev - 1);
    }
  };

  const handlePlus = () => {
    setItemQuantity((prev) => prev + 1);
  };

  return (
    <ProductAccessoryContainer>
      <ProductImage imageUrl={imageUrl}>
        <AddPill
          itemAdded={itemAdded}
          toggleAdded={toggleAdded}
          disabled={disabled}
        />
      </ProductImage>
      <div>
        <AccessoryInfoRow>
          <ProductTitleWithInfoModal title={title} />
          {itemAdded && (
            <QuantitySection $disabled={disabled}>
              <MinusIcon
                onClick={disabled ? undefined : handleMinus}
                data-testid="minus"
              />
              <QuantityCounter data-testid="quantity">
                {itemQuantity}
              </QuantityCounter>
              <PlusIcon
                onClick={disabled ? undefined : handlePlus}
                data-testid="plus"
              />
            </QuantitySection>
          )}
        </AccessoryInfoRow>
        <ProductVariantOptions
          productId={productId}
          disabled={!itemAdded || disabled}
          selectedVariantId={selectedVariantId}
          variants={variants}
          onVariantChange={onVariantChange}
        ></ProductVariantOptions>
      </div>
    </ProductAccessoryContainer>
  );
};

const ProductAccessoryContainer = styled.div`
  width: 233px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 536px) {
    width: 100%;
    padding-bottom: 40px;
  }
`;

const AccessoryInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const QuantitySection = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 8px;
  ${(props) =>
    props.$disabled &&
    `
    cursor: progress;
    & > div {
      cursor: progress;
    }  
  `}
`;
const QuantityCounter = styled.div`
  min-width: 18px;
  text-align: center;
  user-select: none;
`;
const MinusIcon = styled.div`
  cursor: pointer;
  height: 10px;
  width: 10px;
  background: rgba(0, 0, 0, 0) url(${minusIcon}) no-repeat center;
`;
const PlusIcon = styled.div`
  cursor: pointer;
  height: 10px;
  width: 10px;
  background: rgba(0, 0, 0, 0) url(${plusIcon}) no-repeat center;
`;

export type { ProductAccessoryProps };
export { ProductAccessory };
