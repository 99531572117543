import React from "react";
import {
  Button,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { DesignRequest } from "providers/Types";

type SiteModelStatusTextProps = {
  designRequest: DesignRequest | undefined | null;
  overrideComplete?: boolean;
  useDesignRequest?: boolean;
  onClick: () => void;
  onViewDetails: () => void;
};
/**
 * Component which is responsible for displaying the 'Status' of the
 * site model request including the call to action for updating or
 * requesting a new site model.
 */
const SiteModelStatus: React.FC<SiteModelStatusTextProps> = ({
  designRequest,
  overrideComplete = false,
  useDesignRequest = true,
  onClick,
  onViewDetails,
}) => {
  const requestToDisplay = overrideComplete
    ? ({ status: "Complete" } as DesignRequest)
    : designRequest;
  return (
    <SiteModelStatusContainer>
      <StyledIconDescriptionText>
        <StyledIcon>{getIcon(requestToDisplay)}</StyledIcon>
        <div>
          <StyledStatusText>
            <Typography
              color={tokens.TINTS_OFF_WHITE_10}
              variant="h6"
              testId="confirm-info--site-model--status"
            >
              {getStatusText(requestToDisplay)}
            </Typography>
          </StyledStatusText>
          {useDesignRequest && (
            <StyledSubText variant="span" color={tokens.TINTS_OFF_WHITE_10}>
              {getSubText(requestToDisplay)}
            </StyledSubText>
          )}
        </div>
      </StyledIconDescriptionText>
      {useDesignRequest && (
        <StyledButton>
          {getCallToAction(requestToDisplay, onClick, onViewDetails)}
        </StyledButton>
      )}
    </SiteModelStatusContainer>
  );
};

const getIcon = (request: DesignRequest | null | undefined) => {
  let icon: React.ReactNode;
  if (request === undefined) {
    icon = undefined;
  } else if (request === null || request?.status === "Failure") {
    icon = <SvgIcon name={SvgNames.ErrorCircle} width="20px" />;
  } else if (request?.status === "Open") {
    icon = <SvgIcon name={SvgNames.StopWatchIconSvg} width="20px" />;
  } else if (request?.status === "Complete") {
    icon = <SvgIcon name={SvgNames.SuccessCircle} width="20px" />;
  }
  return icon;
};

const getStatusText = (request: DesignRequest | null | undefined) => {
  let statusText: React.ReactNode;

  if (request === undefined) {
    statusText = "Site model requests are loading...";
  } else if (request === null) {
    statusText = "Site model missing.";
  } else if (request.status === "Open") {
    statusText = "Your site model is pending.";
  } else if (request.status === "In Progress") {
    statusText = "Your site model is being worked on.";
  } else if (request.status === "Complete") {
    statusText = "Your site model is ready.";
  } else if (request.status === "Failure") {
    statusText = "Sorry, we have a issue with your Site Model";
  }
  return statusText;
};

const getSubText = (request: DesignRequest | null | undefined) => {
  let subText: React.ReactNode;

  if (
    request === undefined ||
    request?.status === "Complete" ||
    request?.status === "Failure"
  ) {
    subText = undefined;
  } else if (request === null) {
    subText = "Please request one before proceeding.";
  } else if (request.status === "Open") {
    subText = `${request.queuePosition} in queue. Model will display once ready.`;
  }
  return subText;
};

const getCallToAction = (
  request: DesignRequest | null | undefined,
  onClick: () => void,
  onViewDetails: () => void
) => {
  let callToAction: React.ReactNode;

  if (request === undefined || request?.status === "Open") {
    callToAction = undefined;
  } else if (request === null || request?.status === "Complete") {
    callToAction = (
      <Button
        size="sm"
        color="secondary"
        onClick={onClick}
        data-testid="confirm-info--site-model--request-update"
      >
        {request === null ? "Request Site Model" : "Request Update"}
      </Button>
    );
  } else if (request?.status === "Failure") {
    callToAction = (
      <Button
        color="tertiary"
        size="sm"
        onClick={onViewDetails}
        data-testid="failure-details-button"
      >
        View Details
      </Button>
    );
  }
  return callToAction;
};

const StyledIconDescriptionText = styled.span`
  display: flex;
`;

const SiteModelStatusContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledStatusText = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledButton = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding-top: 4px;
`;

const StyledSubText = styled(Typography)`
  font-size: 16px;
`;

const StyledIcon = styled.div`
  align-self: baseline;
  padding-right: 8px;
`;

export { SiteModelStatus };
