import styled from "@emotion/styled/macro";
import { useDesignImages } from "hooks/useDesignImages";

type CheckoutOfferImageProps = {
  lightmileProjectId: string;
};

const CheckoutOfferImage = ({
  lightmileProjectId,
}: CheckoutOfferImageProps) => {
  const designImages = useDesignImages(lightmileProjectId);
  const bgImageUrl = designImages.data?.smallImageUrl;
  return (
    <OfferDesignImage
      data-testid="checkout-offer-design-image"
      bgImageUrl={bgImageUrl!}
    />
  );
};
const OfferDesignImage = styled.div<{ bgImageUrl: string }>`
  width: 100%;
  aspect-ratio: 4/3;
  ${({ bgImageUrl }) =>
    bgImageUrl ? `background: url(${bgImageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  margin-top: 20px;
`;

export { CheckoutOfferImage };
