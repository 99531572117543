const MONTHLY = "Monthly";
const PREPAID = "Prepaid";
const FLEX_MONTHLY = "FlexMonthly";
const CASH = "Cash";
const LOAN = "Loan";

type FinancialProduct =
  | typeof MONTHLY
  | typeof PREPAID
  | typeof CASH
  | typeof LOAN
  | typeof FLEX_MONTHLY;

const financialProductDisplayText: Record<FinancialProduct, string> = {
  Monthly: "Monthly",
  Prepaid: "Prepaid",
  FlexMonthly: "Sunrun Flex",
  Cash: "Purchase",
  Loan: "Loan",
};

export {
  MONTHLY,
  CASH,
  LOAN,
  PREPAID,
  FLEX_MONTHLY,
  financialProductDisplayText,
};
export type { FinancialProduct };
