import styled from "@emotion/styled/macro";
import {
  Typography,
  tokens,
  SvgIcon,
  SvgNames,
} from "@sunrun/experience-ui-components";

type StatusChipProps = {
  status: "signed" | "readyToSign" | "locked";
};

const StatusChip = ({ status }: StatusChipProps) => {
  const statusLabel = {
    signed: "Signed",
    readyToSign: "Ready to Sign",
    locked: "Locked",
  }[status];

  return (
    <Chip $status={status}>
      <IconWrapper>
        {
          {
            signed: (
              <SvgIcon
                name={SvgNames.CheckEnclosed}
                width="16"
                height="16"
                color={tokens.TINTS_SUCCESS_30}
                data-testid="signed"
              />
            ),
            readyToSign: (
              <SvgIcon
                name={SvgNames.PenToolSvg}
                width="16"
                height="16"
                color={tokens.TINTS_MEDIUM_BLUE_50}
                data-testid="readyToSign"
              />
            ),
            locked: (
              <SvgIcon
                name={SvgNames.LockFilled}
                width="16"
                height="16"
                color={tokens.CHARGERED_20}
                data-testid="locked"
              />
            ),
          }[status]
        }
      </IconWrapper>
      <Typography size="small">{statusLabel}</Typography>
    </Chip>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
`;

const Chip = styled.div<{ $status: StatusChipProps["status"] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
  gap: 4px;
  border-radius: 6px;

  ${({ $status }) => {
    switch ($status) {
      case "signed":
        return `
            border: 1px solid ${tokens.SUCCESS_70};
            background: ${tokens.SUCCESS_80};

            & > svg {
                & > path {
                    fill: ${tokens.SUCCESS_30} !important;
                }
            }
            
            & > div {
                fill: ${tokens.SUCCESS_30} !important;
                color: ${tokens.SUCCESS_30};
            }
        `;
      case "readyToSign":
        return `
            border: 1px solid ${tokens.TINTS_CHARGE_BLUE_40};
            background: ${tokens.TINTS_CHARGE_BLUE_70};
            fill: ${tokens.TINTS_MEDIUM_BLUE_50};
            & > div {
                color: ${tokens.TINTS_MEDIUM_BLUE_50};
            }
        `;
      case "locked":
        return `
            border: 1px solid ${tokens.TINTS_CHARGE_RED_60};
            background: ${tokens.TINTS_CHARGE_RED_80};
            fill: ${tokens.TINTS_CHARGE_RED_20};
            & > div {
                color: ${tokens.TINTS_CHARGE_RED_20};
            }
        `;
    }
  }}
`;

export { StatusChip };
export type { StatusChipProps };
