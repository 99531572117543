import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { Modal, Typography, tokens } from "@sunrun/experience-ui-components";
import { SiteModelImage } from "components/organisms/SiteModelImage";
import { SiteModelStatus } from "components/molecules/SiteModelStatus";
import { CardHeaderDivider } from "components/atoms/Card";
import { useDesignRequest } from "hooks/useDesignRequest";

type SiteModelProgressProps = {
  prospectId: string;
  onClick: () => void;
};
const SiteModelProgress: React.FC<SiteModelProgressProps> = ({
  prospectId,
  onClick,
}) => {
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [inQueue, setInQueue] = useState(false);
  const { data: { designRequest, site, isDesignRequestRequired = true } = {} } =
    useDesignRequest(prospectId, {
      revalidateOnFocus: inQueue,
      refreshInterval: inQueue ? 8000 : undefined,
    });
  const { failureReason, failureDescription, notesToSales } =
    designRequest || {};

  const defaultFailureReason = "Unable to create your site model";
  const defaultFailureDescription =
    "Contact the site modeling team for more information.";

  /**
   * Performs any updates necessary whenever the state of design request
   * changes. (For eg, if we are waiting in the queue)
   */
  useEffect(() => {
    const missingDesignRequest = designRequest === null;
    if (missingDesignRequest || designRequest?.status === "Open") {
      setInQueue(true);
    } else {
      setInQueue(false);
    }
  }, [designRequest]);
  return (
    <div>
      <div>
        <Typography variant="h4" color={tokens.BRAND_HERO_BLUE}>
          Site Model Progress
        </Typography>
        <CardHeaderDivider />
      </div>
      <StyledDataContainer>
        <SiteModelImage
          designRequest={designRequest}
          site={site}
          isMapModalOpen={isMapModalOpen}
          setIsMapModalOpen={setIsMapModalOpen}
        />
        <SiteModelStatus
          designRequest={designRequest}
          overrideComplete={!isDesignRequestRequired && !!site}
          useDesignRequest={isDesignRequestRequired}
          onClick={onClick}
          onViewDetails={() => setIsFailureModalOpen(true)}
        />
      </StyledDataContainer>
      {isMapModalOpen && (
        <StyledModal onClose={() => setIsMapModalOpen(false)}>
          <StyledDataContainer>
            <SiteModelImage
              designRequest={designRequest}
              site={site}
              isMapModalOpen={isMapModalOpen}
              setIsMapModalOpen={setIsMapModalOpen}
            />
            <SiteModelStatus
              designRequest={designRequest}
              overrideComplete={!isDesignRequestRequired && !!site}
              useDesignRequest={isDesignRequestRequired}
              onClick={onClick}
              onViewDetails={() => setIsFailureModalOpen(true)}
            />
          </StyledDataContainer>
        </StyledModal>
      )}
      {isFailureModalOpen && (
        <StyledModal
          title="The Site Modeling Team has Identified an Issue on your model"
          onClose={() => setIsFailureModalOpen(false)}
          primaryButton={{
            text: "New Design Request",
            onClick: () => {
              onClick();
              setIsFailureModalOpen(false);
            },
          }}
          type="error"
          secondaryButton={{
            text: "Go Back",
            onClick: () => {
              setIsFailureModalOpen(false);
            },
          }}
        >
          <FailureModalDescription>
            <FailureDescriptionHead>
              Site modeling team failure reason
            </FailureDescriptionHead>
            <FailurDescriptionBody>
              {failureReason || defaultFailureReason}
            </FailurDescriptionBody>
          </FailureModalDescription>
          {(failureDescription || !notesToSales) && (
            <FailureModalDescription>
              <FailureDescriptionHead>
                Site modeling team failure description
              </FailureDescriptionHead>
              <FailurDescriptionBody>
                {failureDescription || defaultFailureDescription}
              </FailurDescriptionBody>
            </FailureModalDescription>
          )}
          {notesToSales && (
            <FailureModalDescription>
              <FailureDescriptionHead>
                Notes to Sales Team from Designer
              </FailureDescriptionHead>
              <FailurDescriptionBody>{notesToSales}</FailurDescriptionBody>
            </FailureModalDescription>
          )}
        </StyledModal>
      )}
    </div>
  );
};

const StyledModal = styled(Modal)`
  .modal-container {
    width: 820px;
    max-width: 90%;
    margin: auto;
  }
  .sr-button-secondary {
    width: 268px;
  }
`;

const StyledDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FailureModalDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 20px;
`;

const FailureDescriptionHead = styled.div`
  color: ${tokens.TINTS_OFF_WHITE_20};
  font-size: 14px;
`;

const FailurDescriptionBody = styled.div`
  color: ${tokens.BRAND_HERO_BLUE};
`;

export { SiteModelProgress };
export type { SiteModelProgressProps };
