import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

type props = {
  children: React.ReactNode[];
};

const TitledSection = ({ children }: props) => {
  return (
    <Container>
      <Title>{children[0]}</Title>
      <Content>{children[1]}</Content>
    </Container>
  );
};

const Container = styled.div`
  background: ${tokens.TINTS_OFF_WHITE_80};
  border-width: 1px;
  border-style: solid;
  border-color: #d0ccc3;
  border-radius: 12px;
`;

const Title = styled.div`
  padding: 20px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #d0ccc3;
`;

const Content = styled.div`
  padding: 10px;
`;

export { TitledSection };
