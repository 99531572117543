import { ComponentPropsWithoutRef, ReactElement } from "react";
import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";
import { Card } from "components/atoms/Card";

interface RadioCardProps extends ComponentPropsWithoutRef<"input"> {
  label?: string;
  labelElement?: ReactElement;
  checked: boolean;
  handleChange: () => void;
}

const RadioCard = ({
  label,
  labelElement,
  checked,
  handleChange,
  ...rest
}: RadioCardProps) => {
  return (
    <label>
      <StyledCard checked={checked}>
        <span>
          <StyledRadio
            type="radio"
            checked={checked}
            onChange={handleChange}
            {...rest}
          />
        </span>
        {label && <RadioText>{label}</RadioText>}
        {!label && !!labelElement && labelElement}
      </StyledCard>
    </label>
  );
};

const StyledCard = styled(Card)<{ checked: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 20px;

  ${({ checked }) => {
    return checked ? `border-color: ${tokens.BRAND_HEROBLUE}` : ``;
  }}
`;

const StyledRadio = styled.input`
  width: 20px;
  height: 20px;
  accent-color: black;
  cursor: pointer;
  transition: opacity 0.3s ease;
`;

const RadioText = styled.span`
  font-family: Roboto-Medium, Tahoma, Helvetica, Arial, Verdana;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  padding-left: 14px;
  padding-top: 10px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  transition: opacity 0.3s ease;
`;

export type { RadioCardProps };
export { RadioCard };
