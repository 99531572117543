import camelCase from "lodash/camelCase";
import { CamelCase } from "type-fest";
import {
  FinancialProduct,
  PricePoint,
  UnifiedPricingData,
} from "../providers/pricing/PricePoints";

const isBaseRate = (pricePoint?: PricePoint) => {
  return (
    pricePoint?.pricingModelType === "Flex" &&
    pricePoint?.displayDetails?.pricePointStep === 0
  );
};

const findBaseRate = (
  pricingData: UnifiedPricingData | undefined,
  financialProduct: FinancialProduct
) => {
  if (!pricingData) {
    return undefined;
  }
  const productToSearchFor = camelCase(
    financialProduct
  ) as CamelCase<FinancialProduct>;
  const pricePoints = pricingData[productToSearchFor];
  return pricePoints.find((pp) => isBaseRate(pp));
};

export { isBaseRate, findBaseRate };
