const CHANNEL_PARTNER = "Channel Partner";
const DFS = "DFS";
const DIRECT_TO_HOME = "D2H";
const ECOMMERCE = "E-Commerce";
const FIELD_SALES = "Field Sales";
const FUSION = "Fusion";
const INSIDE_SALES = "Inside Sales";
const NIS = "NIS";
const SALES_DEALER = "Sales Dealer";
const SALES_PARTNER = "Sales Partner";

type SalesDivision =
  | typeof CHANNEL_PARTNER
  | typeof DFS
  | typeof DIRECT_TO_HOME
  | typeof ECOMMERCE
  | typeof FIELD_SALES
  | typeof FUSION
  | typeof INSIDE_SALES
  | typeof NIS
  | typeof SALES_DEALER
  | typeof SALES_PARTNER;

export type { SalesDivision };
export {
  CHANNEL_PARTNER,
  DFS,
  DIRECT_TO_HOME,
  ECOMMERCE,
  FIELD_SALES,
  FUSION,
  INSIDE_SALES,
  NIS,
  SALES_DEALER,
  SALES_PARTNER,
};
