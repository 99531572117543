import axios from "axios";
import { getAuthToken } from "utils/authUtils";
import { getEnv } from "utils/env";

type HQSEligibility = {
  isEligible: boolean;
};

type getHQSEligibilityInputs = {
  proposalId?: string;
  opportunityId?: string;
};

const toolboxApiUrl =
  getEnv() === "production"
    ? "https://designtools.sunrun.com/api"
    : "https://devdesigntools.sunrun.com/api";

const getHQSEligibility = async (
  variables: getHQSEligibilityInputs
): Promise<boolean> => {
  const token = await getAuthToken();
  const response = await axios.post<HQSEligibility>(
    `${toolboxApiUrl}/v1/rules/hqs-eligibility`,
    {
      opportunityId: variables.opportunityId,
      proposalName: variables.proposalId,
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "application/json",
      },
    }
  );

  return response.data?.isEligible;
};

export { getHQSEligibility };
export type { HQSEligibility };
