import { API } from "aws-amplify";
import {
  GetUploadURLResponse,
  UpdateDesignResponse,
} from "../../amplify/backend/function/OfferExpSiteModel/ts/public/types";

const performRequest = async <Response>(
  method: "get" | "post" | "del" | "patch",
  jwt: string,
  path: string,
  body?: any,
  headers?: { [key: string]: string }
): Promise<Response> => {
  const response = await API[method]("OfferExpApi", path, {
    headers: {
      ...headers,
      Authorization: `Bearer ${jwt}`,
    },
    body,
  });
  return response as Response;
};

const siteModelUpdateDesign = async (
  jwt: string,
  prospectId: string,
  notesToDesigner: string,
  priority: string,
  queue: string,
  status: string
): Promise<UpdateDesignResponse> => {
  return await performRequest<UpdateDesignResponse>(
    "post",
    jwt,
    `/prospect/${prospectId}/designRequest/update`,
    {
      notesToDesigner,
      priority,
      queue,
      status,
    }
  );
};

const siteModelUploadImage = async (
  jwt: string,
  prospectId: string,
  image: Blob,
  contentType: string
): Promise<GetUploadURLResponse> => {
  const formData = new FormData();
  formData.append("documentBlob", image);
  formData.append("contentType", contentType);
  return await performRequest<GetUploadURLResponse>(
    "post",
    jwt,
    `/prospect/${prospectId}/designRequest/image`,
    formData,
    { "Content-Type": `multipart/form-data` }
  );
};

const siteModelSendEmail = async (
  jwt: string,
  prospectId: string,
  designRequestId: string,
  imageKey?: string
): Promise<""> => {
  return await performRequest(
    "post",
    jwt,
    `/prospect/${prospectId}/designRequest/sendEmail`,
    {
      designRequestId,
      imageKey,
    }
  );
};
export { siteModelUpdateDesign, siteModelUploadImage, siteModelSendEmail };
