import { useCallback, useEffect, useRef } from "react";
import { IhdMessage, logMessage } from "../../utils/IhdMessage";
import { InHomeDesign } from "components/molecules/InHomeDesignMolecule";
import { useAppSelector } from "store";
import { type Message, publish as publishToHost } from "utils/messages";

type DesignOrganismProps = {
  goToPricing: () => void;
  goToProductSelection: () => void;
  offerId: string;
  prospectId: string;
  copyDesignOrProposalId?: string;
  setIhdRef: (ref: React.RefObject<HTMLIFrameElement> | undefined) => void;
};
const DesignOrganism = ({
  goToPricing,
  goToProductSelection,
  offerId,
  prospectId,
  copyDesignOrProposalId,
  setIhdRef,
}: DesignOrganismProps) => {
  const cognitoToken = useAppSelector(
    (state) => state?.auth?.cognitoToken?.token
  );
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    setIhdRef(iFrameRef);
  }, [setIhdRef, iFrameRef]);
  const publishToIhd = useCallback((message: IhdMessage) => {
    iFrameRef.current?.contentWindow?.postMessage(message, "*");
  }, []);
  useEffect(() => {
    const handler = (event: MessageEvent) => {
      const message = event?.data as Message;
      if (!message) return;
      if (message.source !== "Legacy Splat") {
        return;
      }
      switch (message.type) {
        case "EVENT:GENERATE_DESIGNS": {
          publishToIhd(message as IhdMessage);
          break;
        }
      }
    };
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [goToProductSelection, goToPricing, cognitoToken, publishToIhd]);
  useEffect(() => {
    const handler = (event: MessageEvent) => {
      const message = event?.data as IhdMessage;
      if (!message) return;
      if (
        message.type !== "DESIGN_EDIT_COMPLETE" &&
        message.type !== "DESIGN_EDIT_CANCEL" &&
        message.source !== "IHD"
      )
        return;
      logMessage(message);
      switch (message.type) {
        case "COMMAND:GENERATE_DESIGNS": {
          publishToHost.message(message);
          break;
        }
        case "DESIGN_EDIT_CANCEL": {
          goToProductSelection();
          break;
        }
        case "DESIGN_EDIT_COMPLETE": {
          goToPricing();
          break;
        }
        case "QUERY:AUTH_TOKEN": {
          if (message.payload.type !== "IHD") return;
          const authMessage = {
            source: "Offer Experience",
            type: "EVENT:AUTH_TOKEN",
            payload: {
              type: "IHD",
              token: cognitoToken,
            },
          } as IhdMessage;
          logMessage(authMessage);
          publishToIhd(authMessage);
          break;
        }
      }
    };
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [goToProductSelection, goToPricing, cognitoToken, publishToIhd]);
  return (
    <section>
      <InHomeDesign
        ref={iFrameRef}
        offerId={offerId}
        prospectId={prospectId}
        copyDesignOrProposalId={copyDesignOrProposalId}
      />
    </section>
  );
};

export { DesignOrganism };
