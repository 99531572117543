import styled from "@emotion/styled/macro";
import { useMemo, useState } from "react";
import { Tooltip, Typography } from "@sunrun/experience-ui-components";
import infoIcon from "../assets/images/info-icon.svg";
import { InfoToolTip } from "components/atoms/InfoToolTip";
import { CardCarouselData, TitleSection } from "providers/Types";
import { Grid, maxWidth, ModalButton } from "components/atoms/GlobalStyles";
import { formatNumber } from "utils/formatHelpers";
import { CenteredModal } from "components/atoms/CenteredModal";

type ImageInfoProps = {
  cardData: CardCarouselData;
  id: number;
  isActiveCardNumber: number;
  isLargeSize: boolean | undefined;
  setIsActiveCardNumber: React.Dispatch<React.SetStateAction<number>>;
};

const ImageInfoComponent = ({
  cardData,
  id,
  isActiveCardNumber,
  isLargeSize,
  setIsActiveCardNumber,
}: ImageInfoProps) => {
  const isActiveCard = isActiveCardNumber === id;
  const {
    extendedDescription,
    value,
    footerTextSuffix,
    headerText,
    iconMedia,
    infoToolTipText,
    isBigTip,
  } = cardData;

  const [showImageInfoModal, setShowImageInfoModal] = useState(false);

  const formattedValue = useMemo(() => {
    let val;

    if (value) {
      if (footerTextSuffix === "%") {
        val = Math.round(Number(value));
      } else if (headerText === "Consumption") {
        val = formatNumber(Math.round(Number(value)));
      } else {
        val = formatNumber(value);
      }
    }

    return val;
  }, [value, footerTextSuffix, headerText]);

  return (
    <ImageInfoContainer
      className={isActiveCardNumber === id ? "active" : ""}
      iconMedia={iconMedia}
      isActiveCard={isActiveCard}
      isLargeSize={isLargeSize}
      onClick={() => {
        setIsActiveCardNumber(id);
      }}
    >
      <ImageHeader>
        <StyledHeaderText>{headerText}</StyledHeaderText>
        {infoToolTipText && isBigTip && (
          <>
            <CenteredModal
              isOpen={showImageInfoModal}
              onClose={() => setShowImageInfoModal(false)}
              focusOnExit={false}
            >
              <Grid>
                <Typography
                  component="h5"
                  variant="h4"
                  style={{ marginBottom: 20 }}
                >
                  {headerText}
                </Typography>
                <Typography>{infoToolTipText}</Typography>
              </Grid>
            </CenteredModal>
            <StyledModalButton onClick={() => setShowImageInfoModal(true)}>
              <StyledIcon alt="info icon tooltip" src={infoIcon}></StyledIcon>
            </StyledModalButton>
          </>
        )}
        {infoToolTipText && !isBigTip && (
          <StyledTooltip content={infoToolTipText} direction="bottom-left">
            {infoToolTipText && <InfoToolTip />}
          </StyledTooltip>
        )}
      </ImageHeader>
      <div>
        {formattedValue && (
          <ImageFooterContainer>
            <ImageFooter>{formattedValue}</ImageFooter>
            <ImageFooterSuffix>{footerTextSuffix}</ImageFooterSuffix>
          </ImageFooterContainer>
        )}
        {isActiveCard && (
          <ImageExtendedDescription>
            {extendedDescription}
          </ImageExtendedDescription>
        )}
      </div>
    </ImageInfoContainer>
  );
};

type ImageInfoCarouselProps = {
  cardCarouselData: Array<CardCarouselData>;
  isLargeSize?: boolean;
  titleInfo?: TitleSection;
};

const ImageInfoCarousel = ({
  cardCarouselData,
  isLargeSize,
  titleInfo,
}: ImageInfoCarouselProps) => {
  const [isActiveCardNumber, setIsActiveCardNumber] = useState<number>(0);
  const hasTitle = titleInfo?.title;

  return (
    <ImageInfoCarouselContainer>
      {hasTitle && (
        <TitleContainer>
          <CarouselTitle>{titleInfo.title}</CarouselTitle>
          <CarouselSubTitle>{titleInfo.subTitle}</CarouselSubTitle>
        </TitleContainer>
      )}
      <CarouselContainer>
        {cardCarouselData.map((cardData: CardCarouselData, index: number) => {
          return (
            <ImageInfoComponent
              cardData={cardData}
              key={index}
              id={index}
              isActiveCardNumber={isActiveCardNumber}
              isLargeSize={isLargeSize}
              setIsActiveCardNumber={setIsActiveCardNumber}
            />
          );
        })}
      </CarouselContainer>
    </ImageInfoCarouselContainer>
  );
};

const ImageInfoCarouselContainer = styled.div`
  padding: 80px 0px 0px 0px;
`;

const CarouselContainer = styled.div`
  display: flex;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  align-items: center;
  width: 94%;
  margin-left: auto;
`;

const ImageInfoContainer = styled.div<{
  isActiveCard: boolean | undefined;
  iconMedia: any;
  isLargeSize: boolean | undefined;
}>`
  transition: 1.5s;
  display: flex;
  flex-direction: column;
  flex: ${(props) =>
    props.isLargeSize
      ? props.isActiveCard
        ? "0 0 765px"
        : "0 0 239px"
      : props.isActiveCard
      ? "0 0 482px"
      : "0 0 239px"};
  height: ${(props) => (props.isLargeSize ? "358px" : "278px")};
  margin-right: 16px;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.iconMedia})`};
  border-radius: 8px;
  display: flex;

  font-size: 12px;
  line-height: 20px;
  padding: 24px;
  color: #fff;

  @media (max-width: ${maxWidth}px) {
    flex: ${(props) => (props.isActiveCard ? "0 0 300px" : "0 0 136px")};
    height: 180px;
  }
`;

const ImageHeader = styled.div`
  display: flex;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500px;

  @media (max-width: ${maxWidth}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledHeaderText = styled.div`
  display: inline-block;
`;

const StyledTooltip = styled(Tooltip)`
  .sr-tooltip-content {
    padding: 16px;
    font-size: 12px;
    line-height: 1.4;

    @media (min-width: 1024px) {
      padding: 32px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.big .sr-tooltip-content {
    min-width: 300px;

    @media (min-width: 768px) {
      min-width: 480px;
    }

    @media (min-width: 1024px) {
      min-width: 530px;
    }
  }
`;

const StyledModalButton = styled(ModalButton)`
  padding-left: 6px;
`;

const StyledIcon = styled.img`
  width: 15px;
  padding-top: 2px;
`;

const TitleContainer = styled.div`
  width: 94%;
  margin-left: auto;
  padding-bottom: 80px;
  color: #1f2647;
`;

const CarouselTitle = styled.div`
  font-size: 44px;
  font-weight: 400;
  line-height: 44px;
  padding-bottom: 40px;
`;

const CarouselSubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

const ImageFooterContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const ImageFooter = styled.div`
  font-size: 64px;
  line-height: 72px;
  padding-bottom: 10px;

  @media (max-width: ${maxWidth}px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

const ImageFooterSuffix = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: ${maxWidth}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ImageExtendedDescription = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: ${maxWidth}px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export { ImageInfoCarousel };
