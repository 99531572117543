import useSwr, { SWRResponse } from "swr";
import { useFlag } from "./useFlag";
import { getEnv } from "utils/env";
import { getHQSEligibility } from "services/homeQualificationEligibility";
import { FeatureFlags } from "fixtures/features";

interface HqsResponse extends SWRResponse<boolean> {
  loading: boolean;
}

const useHqsEligibilityByOpportunityId = (
  opportunityId?: string
): HqsResponse => {
  const shouldFetch = Boolean(opportunityId);
  const result = useSwr(
    shouldFetch ? [opportunityId] : null,
    async ([opportunityId]) => {
      return getHQSEligibility({ opportunityId });
    }
  );
  return { ...result, loading: result.isLoading || result.isValidating };
};

const useHqsEligibilityByProposalId = (proposalId?: string): HqsResponse => {
  const shouldFetch = Boolean(proposalId);
  const result = useSwr(
    shouldFetch ? [proposalId] : null,
    async ([proposalId]) => {
      return getHQSEligibility({ proposalId });
    },
    {
      shouldRetryOnError: false,
    }
  );
  return { ...result, loading: result.isLoading || result.isValidating };
};

const useHqsUrl = ({ prospectId }: { prospectId: string | undefined }) => {
  const enableHqsLink = useFlag(FeatureFlags.USE_HQS_LINK);
  if (!prospectId) {
    return "";
  }
  const env = getEnv();
  if (enableHqsLink) {
    const hqsPrdUrl = `https://www.home-qualification-survey.designtools.sunrun.com/prospect/${prospectId}`;
    const hqsStagingUrl = `https://www.home-qualification-survey.devdesigntools.sunrun.com/prospect/${prospectId}`;
    const hqsUrl = env === "production" ? hqsPrdUrl : hqsStagingUrl;
    return hqsUrl;
  } else {
    const jotFormUrl = "https://form.jotform.com/230227091225143";
    return jotFormUrl;
  }
};

export {
  useHqsEligibilityByOpportunityId,
  useHqsEligibilityByProposalId,
  useHqsUrl,
};
