import { store } from "store";

type AuthType = "IHD" | "HYBRID" | "PROPOSAL";
type AuthToken = {
  token: string;
  type: AuthType;
};

const getAuthToken = (): Promise<string> =>
  new Promise((success) => {
    let tokenData = store.getState()?.auth?.cognitoToken;
    if (tokenData && tokenData.token) {
      success(tokenData.token);
    } else {
      const unsubscribe = store.subscribe(() => {
        tokenData = store.getState()?.auth?.cognitoToken;
        if (tokenData && tokenData.token) {
          success(tokenData.token);
          unsubscribe();
        }
      });
    }
  });

export { getAuthToken, type AuthType, type AuthToken };
