import { API } from "aws-amplify";

/**
 * This function is meant to be used with SWR as a `fetcher`.
 * It accepts a URL and an Auth key and will wait for the auth key be provided
 * before invoking the URL using AWS Amplify api. The data will be cached by SWR and
 * then returned to the component. Errors are handled by SWR.
 *
 * @param url The URL to GET.
 * @param authkey Auth key retrieved from one-container
 * @see https://swr.vercel.app/docs/data-fetching
 * @see https://swr.vercel.app/docs/error-handling
 */
async function getWithAuth<R>([url, authKey]: [
  url: string | undefined,
  authKey: string | undefined
]) {
  if (!authKey || authKey === "") {
    return undefined;
  }
  if (!url) {
    return undefined;
  }
  const resp: R = await API.get("OfferExpApi", url, {
    headers: {
      Authorization: `Bearer ${authKey}`,
    },
  });
  return resp;
}

export { getWithAuth };
