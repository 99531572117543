import styled from "@emotion/styled/macro";
import { minBy, maxBy, debounce } from "lodash";
import { Typography } from "@sunrun/experience-ui-components";
import { useCallback } from "react";
import type { PricePoint } from "providers/pricing/PricePoints";
import {
  MinMaxPayload,
  DropdownPayload,
  FilterType,
  Filters,
  ActiveFilters,
} from "providers/pricing/fields/Filters";
import { MinMaxSlider } from "components/atoms/MinMaxSlider";
import { Dropdown, Option } from "components/atoms/Dropdown";

type MinMaxFilterProps = {
  pricePoints: PricePoint[];
  filter: Filters[0];
  onApplyFilter: (filter: Filters[0], payload: MinMaxPayload) => void;
};

const MinMaxFilter = ({
  filter,
  pricePoints,
  onApplyFilter,
}: MinMaxFilterProps) => {
  const maxPricePoint = filter.value(
    maxBy(pricePoints, (pricePoint) => filter.value(pricePoint)) as any
  ) as number;

  const minPricePoint = filter.value(
    minBy(pricePoints, (pricePoint) => filter.value(pricePoint)) as any
  ) as number;

  const max = Math.ceil(maxPricePoint);
  const min = Math.floor(minPricePoint);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    debounce((min: number, max: number) => {
      onApplyFilter(filter, { min, max });
    }, 50),
    [onApplyFilter, filter]
  );

  return (
    <>
      {filter.type === FilterType.MinMaxSlider && (
        <Filter>
          {filter.title}
          <MinMaxSlider
            max={max}
            min={min}
            twoDots
            displayFn={filter.displayFilter}
            onChange={onChange}
            key={max + min}
            step={filter.step}
          ></MinMaxSlider>
        </Filter>
      )}
    </>
  );
};

type DropdownFilterProps = {
  pricePoints: PricePoint[];
  filter: Filters[0];
  value?: DropdownPayload;
  onApplyFilter: (filter: Filters[0], payload: DropdownPayload) => void;
};

const DropdownFilter = ({
  filter,
  pricePoints,
  value,
  onApplyFilter,
}: DropdownFilterProps) => {
  const onChange = useCallback(
    (option: Option) => {
      onApplyFilter(filter, option);
    },
    [onApplyFilter, filter]
  );

  const payloads =
    filter.type === FilterType.Dropdown
      ? filter.payloads(pricePoints, filter.value as any, filter.displayFilter)
      : [];

  const defaultPayload =
    filter.type === FilterType.Dropdown && payloads
      ? filter.defaultPayload(payloads)
      : undefined;

  const defaultPayloadIndex =
    (value
      ? payloads.findIndex((payload) => payload.value === value?.value)
      : payloads.findIndex(
          (payload) => payload.value === defaultPayload?.value
        )) || 0;
  if (Array.isArray(payloads) && payloads.length > 0) {
    return (
      <>
        {(payloads[0].value !== undefined || payloads[0].value !== null) && (
          <Filter>
            <Typography>{filter.title}</Typography>
            <Dropdown
              selectedIndex={defaultPayloadIndex}
              options={payloads}
              onChange={onChange}
            />
          </Filter>
        )}
      </>
    );
  }
  return <></>;
};

type props = {
  pricePoints: PricePoint[];
  filters: Filters;
  activeFilters: ActiveFilters;
  onApplyFilter: (filter: Filters[0], payload: any) => void;
  resetCounter: number;
};

const PricingFilters = ({
  pricePoints,
  filters,
  activeFilters,
  onApplyFilter,
  resetCounter,
}: props) => {
  return (
    <Container>
      {filters &&
        filters.map((filter) => {
          const activeFilter = activeFilters.find(
            (e) => e.filter.title === filter.title
          );
          if (filter.type === FilterType.MinMaxSlider)
            return (
              <MinMaxFilter
                key={
                  filter.resetsOtherFilters
                    ? filter.title + filter.type
                    : filter.title + filter.type + resetCounter
                }
                filter={filter}
                pricePoints={pricePoints}
                onApplyFilter={onApplyFilter}
              />
            );
          if (filter.type === FilterType.Dropdown)
            return (
              <DropdownFilter
                key={
                  filter.resetsOtherFilters
                    ? filter.title + filter.type
                    : filter.title + filter.type + resetCounter
                }
                filter={filter}
                value={activeFilter?.payload}
                pricePoints={pricePoints}
                onApplyFilter={onApplyFilter}
              />
            );
          return <></>;
        })}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 10px;
  display: grid;
  grid-gap: 20px 50px;
  grid-template-columns: 1fr;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const Filter = styled.span`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;
`;

export {
  PricingFilters,
  // Exported for testing
  DropdownFilter,
};
export type {
  DropdownPayload,
  MinMaxPayload,
  // Exported for testing
  DropdownFilterProps,
};
