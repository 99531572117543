import { createContext, useState } from "react";

interface UsageFormContextType {
  setIsDirty: (setDirty: boolean) => void;
  isDirty: boolean;
  showUtilityBillWarningBanner: boolean;
  setShowUtilityBillWarningBanner: (show: boolean) => void;
}

const UsageFormContext = createContext<UsageFormContextType>({
  isDirty: false,
  setIsDirty: () => {},
  showUtilityBillWarningBanner: false,
  setShowUtilityBillWarningBanner: () => {},
});

const UsageFormContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [showUtilityBillWarningBanner, setShowUtilityBillWarningBanner] =
    useState(false);

  const usageFormContextValue = {
    setIsDirty,
    isDirty,
    showUtilityBillWarningBanner,
    setShowUtilityBillWarningBanner,
  };

  return (
    <UsageFormContext.Provider value={usageFormContextValue}>
      {children}
    </UsageFormContext.Provider>
  );
};

export { UsageFormContextProvider, UsageFormContext };
export type { UsageFormContextType };
