import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

type SupportLinkProps = {
  linkColor?: string;
};

const LinkHelpEmail = ({
  linkColor = tokens.BRAND_HERO_BLUE,
}: SupportLinkProps) => {
  return (
    <Link
      linkColor={linkColor}
      key="linkHelpEmail"
      href="mailto:sunrunhelp@sunrun.com"
    >
      sunrunhelp@sunrun.com
    </Link>
  );
};

const LinkHelpPhone = ({
  linkColor = tokens.BRAND_HERO_BLUE,
}: SupportLinkProps) => {
  return (
    <Link linkColor={linkColor} key="linkHelpPhone" href="tel:+18888407180">
      888-840-7180
    </Link>
  );
};

const LinkHelpPhoneText = ({
  linkColor = tokens.BRAND_HERO_BLUE,
}: SupportLinkProps) => {
  return (
    <Link linkColor={linkColor} key="linkHelpPhone" href="tel:+13853170193">
      385-317-0193
    </Link>
  );
};

const Link = styled.a<{
  linkColor: string;
}>`
  color: ${({ linkColor }) => linkColor};
`;

export { LinkHelpEmail, LinkHelpPhone, LinkHelpPhoneText };
