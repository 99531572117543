import styled from "@emotion/styled/macro";
import { tokens, Typography } from "@sunrun/experience-ui-components";

type CheckoutStepProps = {
  step: {
    img: string;
    description: string;
    stepTitle: string;
  };
};

const CheckoutStep = ({ step }: CheckoutStepProps) => {
  return (
    <CheckoutStepContainer>
      <CheckoutStepImage
        data-testid="checkout-step-image"
        stepImage={step.img}
      />
      <CheckoutStepText>
        <Typography
          variant="p"
          size={tokens.LABEL_MD_FONT_SIZE}
          color={tokens.BRAND_MEDIUMBLUE}
        >
          {step.stepTitle}
        </Typography>
        <NoWrapTypography
          variant="p"
          size={tokens.FONT_SIZE_1}
          color={tokens.BRAND_MEDIUMBLUE}
        >
          {step.description}
        </NoWrapTypography>
      </CheckoutStepText>
    </CheckoutStepContainer>
  );
};

const NoWrapTypography = styled(Typography)`
  white-space: nowrap;
`;
const CheckoutStepContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const CheckoutStepImage = styled.div<{ stepImage: string }>`
  background: url(${({ stepImage }) => stepImage}) no-repeat center;
  height: 60px;
  width: 60px;
`;
const CheckoutStepText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export { CheckoutStep };
