import qs from "qs";

// NOTE: react-router has a tendency to clear out the search.
//       This caches the value on start before it is cleared.
const cachedParams = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
});

const getQueryStringParams = () => {
  return cachedParams;
};

export { getQueryStringParams };
