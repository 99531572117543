import Rollbar from "rollbar";

const rollbar = new Rollbar({
  accessToken: "946695f4471c42a1a4cc71dc41b2b81a",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled:
    process.env.REACT_APP_ENV_NAME === "production" ||
    process.env.REACT_APP_ENV_NAME === "staging",
  payload: {
    environment: process.env.REACT_APP_ENV_NAME,
    context: "UI",
    client: {
      javascript: {
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
  },
});

export { rollbar };
