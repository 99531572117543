import styled from "@emotion/styled/macro";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";

type SlimContactCardProps = {
  contact: SplatContact;
  secondaryContact?: SplatContact;
  label?: string;
  onClick?: () => void;
  buttonLabel?: string;
};

const SlimContactCard = ({
  contact,
  secondaryContact,
  onClick,
  label,
  buttonLabel,
  children,
}: React.PropsWithChildren<SlimContactCardProps>) => (
  <Wrapper>
    <Text>
      {label && (
        <Typography
          color={tokens.BRAND_HERO_BLUE}
          size={tokens.FONT_SIZE_3}
          testId="slim-contact-card-label"
        >
          {label}
        </Typography>
      )}
      <Typography
        color={tokens.TINTS_OFF_WHITE_20}
        size={tokens.FONT_SIZE_3}
        testId="slim-contact-card-contact-names"
      >
        {secondaryContact
          ? `${contact.customerFirstName} ${contact.customerLastName} & ${secondaryContact.customerFirstName} ${secondaryContact.customerLastName}`
          : `${contact.customerFirstName} ${contact.customerLastName}`}
      </Typography>
    </Text>
    {children ? (
      children
    ) : (
      <HyperlinkButton onClick={onClick}>{buttonLabel}</HyperlinkButton>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 24px;
  border-radius: 8px;
  border: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  background: ${tokens.TINTS_OFF_WHITE_70};
`;

const Text = styled.div`
  display: flex;
  gap: 8px;
`;

export { SlimContactCard };
export type { SlimContactCardProps };
