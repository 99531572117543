import styled from "@emotion/styled/macro";

import {
  Typography,
  SvgIcon,
  SvgNames,
  tokens,
} from "@sunrun/experience-ui-components";
import { useResponsive } from "hooks/useResponsive";

// eslint-disable-next-line import/no-restricted-paths
import { maxWidth } from "components/atoms/GlobalStyles";

type BatteryRow = {
  title: string;
  subtitle?: string;
  essential: boolean;
  extended: boolean;
  ultimate: boolean;
};

const tableData = {
  columns: [
    {
      title: "Essential",
      key: "essential",
    },
    {
      title: "Extended",
      key: "extended",
    },
    {
      title: "Ultimate",
      key: "ultimate",
    },
  ],
  rows: [
    { title: "Lights", essential: true, extended: true, ultimate: true },
    { title: "Refrigerator", essential: true, extended: true, ultimate: true },
    { title: "Wifi", essential: true, extended: true, ultimate: true },
    { title: "Freezer", essential: true, extended: true, ultimate: true },
    {
      title: "Outlets (120v)",
      subtitle: "i.e. smartphone & computer charging",
      essential: true,
      extended: true,
      ultimate: true,
    },
    { title: "Cooking Oven", essential: false, extended: true, ultimate: true },
    { title: "Water Heater", essential: false, extended: true, ultimate: true },
    {
      title: "Small Pumps (sump, etc.)",
      essential: false,
      extended: true,
      ultimate: true,
    },
    { title: "Window A/C", essential: false, extended: true, ultimate: true },
    {
      title: "Large Pump (pool, etc)",
      essential: false,
      extended: true,
      ultimate: true,
    },
    {
      title: "Central Heating or A/C",
      essential: false,
      extended: true,
      ultimate: true,
    },
    {
      title: "Hot Tub or Spa",
      essential: false,
      extended: false,
      ultimate: true,
    },
    {
      title: "Multiple A/C or heating system",
      essential: false,
      extended: false,
      ultimate: true,
    },
    { title: "EV Charger", essential: false, extended: false, ultimate: true },
  ] as BatteryRow[],
};

type ObjectType = {
  [key: string]: any;
};

const TableCellWithIcon = ({
  booleanCellValue,
}: {
  booleanCellValue: boolean;
}) => {
  if (!booleanCellValue) {
    return (
      <TableCell aria-label={String(booleanCellValue)}>
        <SvgIcon width={18} name={SvgNames.ErrorCircle} />
      </TableCell>
    );
  }

  return (
    <TableCell aria-label={String(booleanCellValue)}>
      <SvgIcon width={18} name={SvgNames.SuccessCircle} />
    </TableCell>
  );
};

const BatteryTableDesktop = () => {
  const batteryMobileTableStyle = {
    borderColor: tokens.BORDER_LIGHT,
    width: "100%",
  };

  return (
    <table style={batteryMobileTableStyle} rules="all">
      <thead>
        <tr>
          <TableHeader />
          {tableData.columns.map(({ title }) => (
            <TableHeader key={title}>
              <TableTypography fontWeight="bold">{title}</TableTypography>
            </TableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.rows.map((row, index) => (
          <tr aria-rowindex={index} key={row.title + index}>
            <TableCellTitle>
              <FlexCol>
                <TableTypography>{row.title}</TableTypography>
                {row.subtitle && (
                  <TableTypography size="extra_small">
                    {row.subtitle}
                  </TableTypography>
                )}
              </FlexCol>
            </TableCellTitle>
            {tableData.columns.map(({ key }) => (
              <TableCellWithIcon
                key={row.title + key}
                booleanCellValue={(row as ObjectType)?.[key] ?? ""}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const BatteryTableMobile = () => {
  const batteryMobileTableStyle = {
    borderColor: "rgba(208, 204, 195, 0.50)",
    width: "100%",
    padding: 40,
  };

  return (
    <>
      {tableData.columns.map(({ title, key }) => (
        <table style={batteryMobileTableStyle} rules="all" key={title}>
          <thead>
            <tr>
              <TableHeader colSpan={2}>
                <TableTypography fontWeight="bold">{title}</TableTypography>
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((row, index) => (
              <tr aria-rowindex={index} key={row.title + title}>
                <TableCellTitle style={{ width: "50%" }}>
                  <FlexCol>
                    <TableTypography>{row.title}</TableTypography>
                    {row.subtitle && (
                      <TableTypography size="small">
                        {row.subtitle}
                      </TableTypography>
                    )}
                  </FlexCol>
                </TableCellTitle>

                <TableCellWithIcon
                  booleanCellValue={(row as ObjectType)?.[key] ?? ""}
                />
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </>
  );
};

const BatteryTable = () => {
  const { isDesktop } = useResponsive();
  return isDesktop ? <BatteryTableDesktop /> : <BatteryTableMobile />;
};

const TableHeader = styled.th`
  padding: 20px 16px;

  border-top: unset;
  border-left: unset;

  &:last-child {
    border-right: unset;
  }

  text-align: center;

  @media (max-width: ${maxWidth}px) {
    text-align: left;
  }
`;

const TableCell = styled.td`
  padding: 20px 16px;
  text-align: center;

  @media (max-width: ${maxWidth}px) {
    padding: 10px 8px;
  }
`;

const TableCellTitle = styled(TableCell)`
  text-align: right;
  @media (max-width: ${maxWidth}px) {
    text-align: left;
  }
`;

const TableTypography = styled(Typography)`
  color: ${tokens.TINTS_HERO_BLUE_50};
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export { BatteryTable, tableData };
