import { useContext, useState } from "react";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import { SplatCheckoutDocument } from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { Card, CardHeaderDivider } from "components/atoms/Card";
import {
  ConfirmationModalProps,
  FileUploadError,
  UploadAttachments,
} from "components/molecules/UploadAttachments";
import { Attachment } from "utils/usageUtils";
import { MaxSizeErrorModal } from "components/molecules/MaxSizeErrorModal";
import { UsageFormContext } from "providers/UsageFormContext";

type DocumentUploadProps = {
  uploadedFiles: Attachment[] | SplatCheckoutDocument[];
  uploadedNewFiles: Attachment[];
  setUploadedNewFiles: (files: Attachment[]) => void;
  documentType: string;
  description: string;
  title: string;
  confirmationModal?: ConfirmationModalProps;
  documentLoading?: boolean;
};

const MAX_FILE_SIZE_MB = 4;

const DocumentUpload = ({
  uploadedFiles,
  uploadedNewFiles,
  setUploadedNewFiles,
  documentType,
  description,
  title,
  confirmationModal,
  documentLoading,
}: DocumentUploadProps) => {
  const [errors, setErrors] = useState<FileUploadError[]>([]);
  const { setIsDirty } = useContext(UsageFormContext);
  return (
    <Card>
      <header data-testid="document-upload-header">
        <Typography size={tokens.FONT_SIZE_3}>{title}</Typography>
      </header>
      <CardHeaderDivider />
      <div className="document-upload-body">
        <UploadAttachments
          setIsDirty={setIsDirty}
          uploadedFiles={uploadedFiles}
          documentType={documentType}
          buttonSize="md"
          uploadedNewFiles={uploadedNewFiles}
          onUploadedNewFiles={setUploadedNewFiles}
          maxAllowedMBFileSize={MAX_FILE_SIZE_MB}
          onError={(errors) => setErrors(errors)}
          description={description}
          allowDownload
          allowPreview
          confirmationModal={confirmationModal}
          documentLoading={documentLoading}
        />
      </div>
      <MaxSizeErrorModal
        errors={errors}
        maxSizeMB={MAX_FILE_SIZE_MB}
        onClose={() => setErrors([])}
      />
    </Card>
  );
};

export { DocumentUpload };
