const openPDFInNewTab = async (path: string) => {
  try {
    const attachmentResp = await fetch(path);
    if (attachmentResp.status !== 200) {
      throw new Error("Failure downloading PDF data");
    }
    const attachmentData = await attachmentResp.blob();
    const contentType = attachmentResp.headers.get("content-type");
    let pdfData = attachmentData;
    if (contentType !== "application/pdf") {
      // create a new Blob with correct MIME type.
      pdfData = new Blob([attachmentData], { type: "application/pdf" });
    }
    const _url = window.URL.createObjectURL(pdfData);
    window.open(_url, "_blank");
  } catch (e) {
    throw new Error("Error opening PDF", { cause: e });
  }
};

export { openPDFInNewTab };
