import jwt_decode from "jwt-decode";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { Button } from "@sunrun/experience-ui-components";
import { Mixpanel } from "../../providers/mixpanel";
import { useAppDispatch, useAppSelector } from "store";
import { publish, useSubscribe } from "utils/messages";
import {
  authSetCognitoToken,
  authSetHybridToken,
  authSetIdentity,
  authSetProposalToken,
  CognitoToken,
} from "slices/authSlice";
import { rollbar } from "providers/rollbar";
import {
  getCognitoToken,
  getUserInfo,
  refreshCognitoToken,
  setCognitoToken,
} from "services/cognitoService";
import { identify } from "services/fullstory";
import { getQueryStringParams } from "utils/queryString";
import { isInIframe } from "utils/iframeUtils";

const AuthPage = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const cognitoTokenData = useAppSelector((state) => state.auth.cognitoToken);
  const hybridToken = useAppSelector((state) => state.auth.hybridToken);
  const proposalToken = useAppSelector((state) => state.auth.proposalToken);

  const host = getQueryStringParams()?.host;
  const expectHybridToken = host !== "LIGHTMILE" && isInIframe();
  const cacheAuthToken = host === "LIGHTMILE" || !isInIframe();

  const [pollingInterval, setPollingInterval] = useState<number>();
  const [showLogin, setShowLogin] = useState(false);

  const isLoginPage = window.location.href.startsWith(window.origin + "/login");

  const doLoginClick = useCallback(() => {
    const newWindow = window.open(window.origin + "/login");
    if (!newWindow) {
      return;
    }
    const queryNewWindow = () => {
      newWindow.postMessage(
        {
          source: "Offer Experience",
          type: "QUERY:AUTH_TOKEN",
          payload: {
            type: "IHD",
          },
        },
        "*"
      );
    };
    // Poll new window
    const interval = window.setInterval(queryNewWindow, 100);
    setPollingInterval(interval);
    // After 30 seconds, start polling every 5 seconds indefinitely.
    window.setTimeout(() => {
      window.clearInterval(interval);
      window.setInterval(queryNewWindow, 5000);
    }, 30000);
  }, [setPollingInterval]);

  const processCognitoToken = useCallback(
    (token: CognitoToken) => {
      dispatch(authSetCognitoToken(token));
      if (!expectHybridToken) {
        dispatch(authSetIdentity("LIGHTMILE"));
      }
      const userInfo = getUserInfo(token);
      if (userInfo?.email) {
        identify(userInfo.email);
      }
      if (cacheAuthToken) {
        setCognitoToken(token);
      }
    },
    [cacheAuthToken, dispatch, expectHybridToken]
  );

  useEffect(() => {
    if (!cognitoTokenData || !cognitoTokenData.token) {
      if (cacheAuthToken) {
        const cachedToken = getCognitoToken();
        if (cachedToken) {
          processCognitoToken(cachedToken);
          return;
        }
        refreshCognitoToken().then((result) => {
          if (result) {
            processCognitoToken(result);
          }
        });
      }
      publish.queryCognitoToken();
    } else {
      var decoded = jwt_decode(cognitoTokenData.token) as any;
      if (!decoded?.email) {
        rollbar.error("Unexpected JWT shape", decoded as any);
        return;
      }
      rollbar.configure({
        payload: {
          person: {
            id: decoded.email,
            email: decoded.email,
          },
          profile: decoded,
        },
      });
      // Only identify by email if we are not expecting a more specific identify
      if (!expectHybridToken) {
        authSetIdentity(decoded.email);
      }
      window.setTimeout(() => {
        refreshCognitoToken().then((result) => {
          if (!result) {
            setShowLogin(true);
          } else {
            dispatch(authSetCognitoToken(result));
          }
        });
      }, cognitoTokenData.expiration - Date.now() - 30000);
      setShowLogin(false);
    }
  }, [
    cacheAuthToken,
    cognitoTokenData,
    dispatch,
    expectHybridToken,
    processCognitoToken,
  ]);
  useEffect(() => {
    if (!hybridToken || hybridToken.length === 0) {
      publish.queryHybridToken();
    }
  }, [hybridToken]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!cognitoTokenData && !proposalToken) {
        setShowLogin(true);
      }
    }, 2000);
    return () => clearTimeout(timer);
    // Intentionally excluding deps - this should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoTokenData, proposalToken]);
  useSubscribe((message) => {
    switch (message.type) {
      case "EVENT:AUTH_TOKEN": {
        if (message.payload.type === "IHD") {
          if (pollingInterval) {
            window.clearInterval(pollingInterval);
          }
          processCognitoToken(message.payload.token);
        } else if (message.payload.type === "HybridAuth") {
          dispatch(authSetHybridToken(message.payload.token));
          dispatch(authSetIdentity(message.payload.identity));
          Mixpanel.identify(message.payload.identity);
        } else if (message.payload.type === "Proposal") {
          dispatch(authSetProposalToken(message.payload.token));
        }
      }
    }
  });
  if (!isLoginPage && showLogin) {
    return (
      <LoginButtonArea>
        <Button onClick={doLoginClick} style={{ width: 150 }}>
          Login
        </Button>
      </LoginButtonArea>
    );
  }
  return <>{children}</>;
};

const LoginButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem;
`;

export { AuthPage };
