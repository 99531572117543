import styled from "@emotion/styled/macro";
import copyIcon from "../assets/images/copy-icon.svg";

const CopyIcon = styled.button`
  padding: 25px;
  border: none;
  background: url(${copyIcon}) no-repeat center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

export { CopyIcon };
