import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled/macro";
import { Modal, Typography } from "@sunrun/experience-ui-components";
import { useParams } from "react-router-dom";
import { SplatProspectResponse } from "../../../../amplify/backend/function/OfferExpUsage/ts/public/types";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { experiencePaymentsUIUrl } from "checkout/util/constants";
import {
  EventData,
  Inbound_InitializeAutopay_MessagePayload,
  Inbound_InitializePayment_MessagePayload,
  MessageType,
  PayMethod,
  UserInfo,
} from "checkout/types/payments-ui-form";
import { findContact } from "utils/contacts";
import { ROLES } from "utils/usageUtils";
import { useAppSelector } from "store";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
// NOTE: we muse use the local Card component due to incompatibility with component library Card
import { Card } from "components/atoms/Card";

type PaymentIFrameProps = {
  autopay: boolean;
  paymentMethod: PayMethod;
  paymentAmount?: number;
  prospect: SplatProspectResponse;
  contacts: SplatContact[];
  cancelPayment: () => void;
};

const PaymentIFrame: React.FC<PaymentIFrameProps> = ({
  autopay,
  paymentMethod,
  paymentAmount,
  prospect,
  contacts,
  cancelPayment,
}) => {
  const iFrameRef = useRef<HTMLIFrameElement>();
  const [iFrameHeight, setIFrameHeight] = useState(800);
  const [showExpiredErrorModal, setShowExpiredErrorModal] = useState(false);
  const { offer } = useCheckoutContext();
  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);

  const messageHandler = useCallback(
    (message: MessageEvent) => {
      if (message?.data.type === MessageType.OUTBOUND_WINDOW_RESIZE) {
        const payload = message.data.payload;
        setIFrameHeight(payload.height);
      }
      if (message?.data.type === MessageType.OUTBOUND_CANCEL_PAYMENT) {
        cancelPayment();
      }
      if (message?.data.type === MessageType.OUTBOUND_SESSION_EXPIRED) {
        setShowExpiredErrorModal(true);
      }
    },
    [cancelPayment]
  );

  useEffect(() => {
    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [messageHandler]);

  const sendDataToEmbeddedView = useCallback(
    (data: EventData) => {
      if (iFrameRef?.current && iFrameRef.current.contentWindow) {
        iFrameRef.current.contentWindow.postMessage(
          data,
          experiencePaymentsUIUrl
        );
      }
    },
    [iFrameRef]
  );

  const initializeHtmlForm = useCallback(() => {
    if (!prospect || !contacts) {
      return;
    }

    const primaryContact = findContact(contacts, ROLES.HOMEOWNER);
    const { customerZipCode, customerStreet, customerState, customerCity } =
      prospect;

    const userInfo: UserInfo = {
      address: customerStreet || "",
      billingType: "payment",
      firstName: primaryContact?.customerFirstName || "",
      lastName: primaryContact?.customerLastName || "",
      phoneNumber: primaryContact?.customerPrimaryPhone || "",
      email: primaryContact?.customerEmail || "",
      city: customerCity || "",
      state: customerState || "",
      zip: customerZipCode || "",
      prospectId: prospectId as string,
      contactId: primaryContact?.contactId,
      proposalId: offer?.proposalId,
    };

    const payload:
      | Inbound_InitializePayment_MessagePayload
      | Inbound_InitializeAutopay_MessagePayload = {
      authToken: authKey ?? "",
      userInfo,
      payMethod: paymentMethod as PayMethod,
      isIframe: true,
      showCancelPaymentButton: true,
      // Conditionally add properties if not autopay
      ...(!autopay && {
        autopayEnrollmentEnabled: false,
        amount: paymentAmount,
        isDeposit: true,
      }),
    };

    sendDataToEmbeddedView({
      type:
        autopay === true
          ? MessageType.INBOUND_INITIALIZE_AUTOPAY
          : MessageType.INBOUND_INITIALIZE_PAYMENT,
      payload,
    });
  }, [
    sendDataToEmbeddedView,
    paymentAmount,
    paymentMethod,
    prospectId,
    authKey,
    prospect,
    contacts,
    autopay,
    offer?.proposalId,
  ]);

  return (
    <StyledCard>
      {showExpiredErrorModal && (
        <Modal
          type="error"
          title="Session expired"
          onClose={() => {
            cancelPayment();
            setShowExpiredErrorModal(false);
          }}
          secondaryButton={{
            text: "Cancel",
            onClick: () => {
              cancelPayment();
              setShowExpiredErrorModal(false);
            },
          }}
          primaryButton={{
            text: "Refresh",
            onClick: () => {
              cancelPayment();
              setShowExpiredErrorModal(false);
            },
          }}
        >
          <Typography>
            Your session expired due to a 15 minute time limit. Please reload to
            try again.
          </Typography>
        </Modal>
      )}
      <IFrameContainer
        ref={(ref: HTMLIFrameElement) => (iFrameRef.current = ref)}
        title={autopay ? "autopay-info-form" : "payment-info-form"}
        src={
          autopay
            ? `${experiencePaymentsUIUrl}/autopay`
            : `${experiencePaymentsUIUrl}/payment`
        }
        onLoad={initializeHtmlForm}
        data-testid={autopay ? "autopay-form-iframe" : "payment-form-iframe"}
        height={`${iFrameHeight + 20}px`}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: 0;
`;

const IFrameContainer = styled.iframe<{ height: string }>`
  display: block;
  width: 100%;
  min-height: 200px;
  height: ${({ height }) => height};
  overflow-y: visible;
  border: 0;
`;

export { PaymentIFrame };
