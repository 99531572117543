import styled from "@emotion/styled/macro";

const ProductImage = styled.div<{ imageUrl: string }>`
  aspect-ratio: 16 / 9;
  background: ${(props) => `url(${props.imageUrl}) no-repeat center`};
  background-size: contain;
  border-radius: 8px;
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: 536px) {
    aspect-ratio: 4 / 3;
  }
`;

export { ProductImage };
