async function retry({
  fn,
  test,
  retryCount = 10,
  retryDelay = 4000,
}: {
  fn: () => Promise<unknown>;
  test: (value: unknown) => boolean;
  retryCount?: number;
  retryDelay?: number;
}): Promise<unknown> {
  await wait(retryDelay);
  const result = await fn();

  return (
    test(result) ||
    (await retry({ fn, test, retryCount: --retryCount, retryDelay }))
  );
}

async function wait(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export { retry, wait };
