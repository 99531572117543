import React, { ChangeEvent, useCallback, useMemo } from "react";
import styled from "@emotion/styled/macro";
import {
  Select,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { useParams } from "react-router-dom";
import { CheckoutHeader } from "../molecules/CheckoutHeader";
import {
  CheckoutFullWidthContentGrid,
  CheckoutGrid,
  CheckoutHeaderGrid,
} from "../templates/CheckoutGrid";
import { CheckoutFooter } from "../molecules/CheckoutFooter";
import { CheckoutStep } from "../molecules/CheckoutStep";
import { CheckoutOfferImage } from "../molecules/CheckoutOfferImage";
import { ContactStatusNotification } from "../atoms/ContactStatusNotification";
import { Mixpanel } from "../../../providers/mixpanel";
import { OPTY_PROGRESS_STEPS } from "checkout/util/constants";
import { useAppSelector } from "store";
import {
  useNavigateToCheckout,
  useNavigateToNoOffer,
} from "utils/useNavigateHelper";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { maxWidth } from "components/atoms/GlobalStyles";
import { getOfferToShow } from "utils/offerListHelpers";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { useOfferList } from "checkout/hooks/useCheckoutSwr";

const CheckoutConfirmOfferPage: React.FC = () => {
  const navigateToCheckout = useNavigateToCheckout();
  const navigateToNoOffer = useNavigateToNoOffer();
  const { prospectId, offerId } = useParams();
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const { offer, isOfferLoading } = useCheckoutContext();

  if (!prospectId) {
    throw Error("Must have a prospect id");
  }

  const { offerList, isOfferListLoading, offerListError } = useOfferList(
    prospectId,
    cognitoToken
  );

  if (!offerId && offerList) {
    if (offerList?.length) {
      const offerToShow = getOfferToShow(offerList);
      navigateToCheckout("confirm-offer", offerToShow.id);
    } else {
      navigateToNoOffer();
    }
  }

  const offerOptions = useMemo(() => {
    if (!offerList) {
      return [];
    }
    return offerList.map((e) => {
      return { label: e.label, value: e.id };
    });
  }, [offerList]);

  const onOfferSelect = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      navigateToCheckout("confirm-offer", event.target.value);
    },
    [navigateToCheckout]
  );

  const navigateToConfirmNames = useCallback(() => {
    if (!offer) {
      throw Error("Cannot start checkout if offer does not exist.");
    }
    Mixpanel.track("Checkout V2 - Checkout landing page Continue cta", {
      prospectId,
    });
    navigateToCheckout("confirm-names", offer.id);
  }, [navigateToCheckout, offer, prospectId]);

  if (isOfferLoading || isOfferListLoading || !offer) {
    return (
      <LoadingIndicator color="black" fullScreen={true}></LoadingIndicator>
    );
  }

  return (
    <CheckoutGrid>
      <CheckoutHeaderGrid>
        <CheckoutHeader headerTitle="Confirm Your Offer" />
      </CheckoutHeaderGrid>
      <CheckoutFullWidthContentGrid>
        {offer.isExpired && (
          <ContactStatusNotification
            status="Error"
            message="This offer is expired. You won't be able to checkout with this offer."
          />
        )}
        <ConfirmOfferPageContentContainer>
          {offerListError && (
            <ErrorComponent context="ReviewPage" error={offerListError} />
          )}
          {!offerListError && (
            <>
              <ConfirmOfferPageSection>
                <OfferSelect>
                  <Typography
                    variant="p"
                    size={tokens.FONT_SIZE_3}
                    fontWeight="light"
                    lineHeight={tokens.LINE_HEIGHTS_12}
                    style={{ marginBottom: 10 }}
                  >
                    You've selected:
                  </Typography>
                  {!isOfferLoading &&
                    !isOfferListLoading &&
                    offerOptions &&
                    offerOptions.length > 0 && (
                      <>
                        <Select
                          value={offer!.id}
                          options={offerOptions}
                          onChange={onOfferSelect}
                          role="select"
                        />
                        <CheckoutOfferImage
                          lightmileProjectId={offer?.lightmileProjectId}
                        />
                      </>
                    )}
                </OfferSelect>
              </ConfirmOfferPageSection>
              <ConfirmOfferPageSection>
                <StepsTypographyMover>
                  <Typography
                    variant="p"
                    size={tokens.FONT_SIZE_4}
                    fontWeight="medium"
                  >
                    After getting started today, here are your steps to clean
                    energy:
                  </Typography>
                </StepsTypographyMover>
                <ConfirmOfferPageCheckoutStepsColumnLayout>
                  {OPTY_PROGRESS_STEPS.map((step) => (
                    <CheckoutStep key={step.description} step={step} />
                  ))}
                </ConfirmOfferPageCheckoutStepsColumnLayout>
                <CheckoutFooter
                  buttonText="Continue"
                  onClick={navigateToConfirmNames}
                  isDisabled={offer.isExpired}
                />
              </ConfirmOfferPageSection>
            </>
          )}
        </ConfirmOfferPageContentContainer>
      </CheckoutFullWidthContentGrid>
    </CheckoutGrid>
  );
};

const ConfirmOfferPageContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  margin-top: 40px;
  @media (max-width: ${maxWidth}px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ConfirmOfferPageSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${maxWidth}px) {
    width: 100%;
  }
`;
const OfferSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
const StepsTypographyMover = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
const ConfirmOfferPageCheckoutStepsColumnLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  column-gap: 25px;
  row-gap: 20px;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  @media (max-width: 1024px) {
    grid-template-rows: repeat(8, 1fr);
  }
`;

export { CheckoutConfirmOfferPage };
