import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { Controller, UseFormReturn } from "react-hook-form";
import { Select } from "@sunrun/experience-ui-components";
import { RateScheduleResponse, SplatUtilityResponse } from "providers/Types";
import { UtilityInfoState } from "utils/usageUtils";

type UtilityCompanyDropdownProps = {
  utilitiesOptions?: SplatUtilityResponse[];
  formUtils: UseFormReturn<UtilityInfoState>;
  disableComponent?: boolean;
};

const UtilityCompanyDropdown = ({
  utilitiesOptions = [],
  formUtils,
  disableComponent = false,
}: UtilityCompanyDropdownProps) => {
  const [rateScheduleOptions, setRateScheduleOptions] = useState<
    RateScheduleResponse[]
  >([]);

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = formUtils;

  const { utilityCompany, rateSchedule } = getValues();

  const getRateSchedulesFromUtilityOptions = useCallback(
    (utilityName: string | undefined) => {
      if (!utilityName) {
        return;
      }

      const utilityObj = utilitiesOptions.find((utility) => {
        return utility.utilityName === utilityName;
      });

      if (utilityObj) {
        setRateScheduleOptions(utilityObj.rateSchedules);
      }
    },
    [utilitiesOptions]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "utilityCompany" && type === "change") {
        getRateSchedulesFromUtilityOptions(value.utilityCompany ?? "");
        setValue("rateSchedule", "");
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, getRateSchedulesFromUtilityOptions]);

  useEffect(() => {
    getRateSchedulesFromUtilityOptions(utilityCompany);
  }, [getRateSchedulesFromUtilityOptions, utilityCompany]);

  const rsOptions = [{ label: "", value: "" }, ...rateScheduleOptions];
  const rateScheduleDisabled = rsOptions.length < 2 || disableComponent;

  return (
    <>
      <div>
        <StyledSelectLabel id="utility" htmlFor="utility-company-select">
          Utility Company
        </StyledSelectLabel>
        <Controller
          name="utilityCompany"
          defaultValue={utilityCompany}
          control={control}
          render={({ field: { ref, ...rest } }) => {
            return (
              <Select
                disabled={
                  !utilityCompany || utilityCompany === "" || disableComponent
                }
                aria-labelledby="utilityCompany"
                testId="utility-company-select"
                options={[
                  { label: "", value: "" },
                  ...utilitiesOptions.map((company: SplatUtilityResponse) => ({
                    label: company.utilityName,
                    value: company.utilityName,
                  })),
                ]}
                {...rest}
              />
            );
          }}
        />
        {!!errors?.utilityCompany && (
          <span className="sr-text-helper-text">
            {errors?.utilityCompany?.message?.toString()}
          </span>
        )}
      </div>
      <div>
        <StyledSelectLabel id="rateSchedule" htmlFor="rate-schedule-select">
          Rate Schedule
        </StyledSelectLabel>
        <Controller
          name="rateSchedule"
          defaultValue={rateSchedule}
          control={control}
          render={({ field: { ref, ...rest } }) => {
            return (
              <Select
                disabled={rateScheduleDisabled}
                aria-labelledby="rateSchedule"
                testId="rate-schedule-select"
                options={rsOptions}
                {...rest}
              />
            );
          }}
        />
        {!!errors?.rateSchedule && (
          <span className="sr-text-helper-text">
            {errors?.rateSchedule?.message?.toString()}
          </span>
        )}
      </div>
    </>
  );
};

const StyledSelectLabel = styled.label`
  margin-bottom: 8px;
  display: block;
`;

export { UtilityCompanyDropdown };
