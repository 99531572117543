import { FC, useCallback, useEffect, useState } from "react";
import { Snackbar } from "@sunrun/experience-ui-components";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { SplatProspectResponse } from "../../../../amplify/backend/function/OfferExpUsage/ts/public/types";
import { SuccessfulPayment } from "../molecules/SuccessfulPayment";
import { formatCurrency } from "../../../utils/formatHelpers";
import { PaymentIFrame } from "./PaymentIframeContainer";
import {
  MessageType,
  Outbound_PaymentSuccess_MessagePayload,
  PayMethod,
} from "checkout/types/payments-ui-form";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";

type DepositPaymentIFrameProps = {
  paymentMethod: PayMethod;
  paymentAmount?: number;
  prospect: SplatProspectResponse;
  contacts: SplatContact[];
  cancelPayment: () => void;
  paymentResults?: Outbound_PaymentSuccess_MessagePayload;
  setPaymentResults: (payload: Outbound_PaymentSuccess_MessagePayload) => void;
};

const DepositPaymentIFrame: FC<DepositPaymentIFrameProps> = ({
  paymentAmount = 500,
  paymentMethod,
  prospect,
  contacts,
  cancelPayment,
  paymentResults,
  setPaymentResults,
}) => {
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const { refreshTasks } = useCheckoutContext();

  const messageHandler = useCallback(
    (message: MessageEvent) => {
      const { payload, type } = message?.data;
      if (type === MessageType.OUTBOUND_PAYMENT_SUCCESS) {
        setPaymentResults(payload);
        setShowSuccessSnackbar(true);
        refreshTasks();
      }
    },
    [refreshTasks, setPaymentResults]
  );

  useEffect(() => {
    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [messageHandler]);

  return (
    <>
      {paymentResults && prospect && (
        <SuccessfulPayment
          autoPay={false}
          paymentAmount={formatCurrency(paymentAmount)}
          customerEmail={prospect?.customerEmail ?? ""}
          lastFour={paymentResults.lastFour ?? ""}
          paymentType={paymentMethod}
        />
      )}
      {!paymentResults && prospect && contacts.length > 0 && (
        <PaymentIFrame
          autopay={false}
          paymentMethod={paymentMethod}
          paymentAmount={paymentAmount}
          prospect={prospect}
          contacts={contacts}
          cancelPayment={cancelPayment}
        />
      )}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        message={`Your payment has been successfully processed`}
        onClose={() => setShowSuccessSnackbar(false)}
        type="success"
      />
    </>
  );
};

export { DepositPaymentIFrame };
