import styled from "@emotion/styled/macro";
import {
  Button,
  Checkbox,
  LoadingOverlay,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import type {
  Contact,
  Role,
} from "@sunrun/experience-ui-components/lib/components/ContactCard";
import { CardContent, CardHeader } from "../molecules/Card";
import { CheckoutContacts } from "./CheckoutContacts";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { Card, CardHeaderDivider } from "components/atoms/Card";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { ErrorModal } from "components/molecules/ErrorModal";
import { confirmContacts } from "services/contacts";
import { useAppSelector } from "store";
import { findContact, getPrimaryContact } from "utils/contacts";
import { isEmptyValue, ROLES } from "utils/usageUtils";
import { useContactManagement } from "hooks/useContactManagement";
import { usePrevious } from "hooks/usePrevious";
import { useUsageData } from "utils/swrHooks";

const ConfirmNamesTaskView: React.FC = () => {
  const { prospectId, offerId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const {
    proposalId,
    navigateToNextTask,
    currentTask,
    refreshAndNavigateNext,
  } = useCheckoutContext();
  const {
    prospect,
    error: prospectError,
    isLoading: isProspectLoading,
  } = useUsageData(prospectId);

  const {
    contactList: contacts,
    isContactsLoading,
    contactsError,
    onEditContact,
    onEditContactRole,
    refetch,
    error: contactError,
    setError: setContactError,
    loading: updateContactLoading,
  } = useContactManagement();

  const selectedPrimaryContact = getPrimaryContact(contacts);
  const selectedUtilityContact = findContact(
    contacts,
    ROLES.UTILITYBILLCONTACT
  );
  const selectedSecondaryContact = findContact(
    contacts,
    ROLES.CONTRACTCOSIGNER
  );

  const previousPrimaryContact = usePrevious(selectedPrimaryContact);
  const [originalPrimaryContact, setOriginalPrimaryContact] =
    useState<Contact>();
  const previousUtilityContact = usePrevious(selectedUtilityContact);
  const [originalUtilityContact, setOriginalUtilityContact] =
    useState<Contact>();
  const previousSecondaryContact = usePrevious(selectedSecondaryContact);
  const [originalSecondaryContact, setOriginalSecondaryContact] =
    useState<Contact>();

  useEffect(() => {
    if (!previousPrimaryContact && selectedPrimaryContact) {
      setOriginalPrimaryContact(selectedPrimaryContact);
    }
  }, [previousPrimaryContact, selectedPrimaryContact]);

  useEffect(() => {
    if (!previousUtilityContact && selectedUtilityContact) {
      setOriginalUtilityContact(selectedUtilityContact);
    }
  }, [previousUtilityContact, selectedUtilityContact]);

  useEffect(() => {
    if (!previousSecondaryContact && selectedSecondaryContact) {
      setOriginalSecondaryContact(selectedSecondaryContact);
    }
  }, [previousSecondaryContact, selectedSecondaryContact]);

  const [error, setError] = useState<{ action?: string; error?: Error }>();
  const [isSaving, setIsSaving] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const contactsHaveInvalidContactInfo = () => {
    const requiredContactsHaveValidContactInfo =
      isEmptyValue(selectedPrimaryContact?.customerEmail) ||
      isEmptyValue(selectedPrimaryContact?.customerPrimaryPhone) ||
      isEmptyValue(selectedUtilityContact?.customerEmail) ||
      isEmptyValue(selectedUtilityContact?.customerPrimaryPhone);
    const secondaryInvalid =
      selectedSecondaryContact?.contactId &&
      (isEmptyValue(selectedSecondaryContact?.customerEmail) ||
        isEmptyValue(selectedSecondaryContact?.customerPrimaryPhone));

    return requiredContactsHaveValidContactInfo || secondaryInvalid;
  };

  const contactsHaveChanged = useMemo(() => {
    const requiredContactsHaveChanged =
      originalPrimaryContact?.contactId !== selectedPrimaryContact?.contactId ||
      originalUtilityContact?.contactId !== selectedUtilityContact?.contactId;

    const secondaryChanged =
      originalSecondaryContact?.contactId !==
      selectedSecondaryContact?.contactId;

    return requiredContactsHaveChanged || secondaryChanged;
  }, [
    originalPrimaryContact?.contactId,
    originalSecondaryContact?.contactId,
    originalUtilityContact?.contactId,
    selectedPrimaryContact?.contactId,
    selectedSecondaryContact?.contactId,
    selectedUtilityContact?.contactId,
  ]);

  const handleConfirm = () => {
    if (!selectedPrimaryContact || !selectedUtilityContact) {
      setError({
        action: "Confirm Names Task View",
        error: new Error(
          "You must select a Primary signer and a Utility contact."
        ),
      });
      return;
    }
    if (contactsHaveInvalidContactInfo()) {
      setError({
        action: "Confirm Names Task View",
        error: new Error(
          "All selected contacts must both have an email and phone number."
        ),
      });
      return;
    }

    if (!prospectId || !offerId) {
      throw new Error("prospectId or offerId are undefined");
    }

    // If contacts haven't changed, AND ODI isComplete THEN skip confirmContacts
    if (!contactsHaveChanged && currentTask?.isComplete) {
      navigateToNextTask();
      return;
    }

    const contact1 = {
      contactId: selectedPrimaryContact?.contactId,
      role: ROLES.HOMEOWNER,
    };
    const contact2 = {
      contactId: selectedUtilityContact?.contactId,
      role: ROLES.UTILITYBILLCONTACT,
    };

    let contactsArr = [contact1, contact2];
    if (selectedSecondaryContact?.contactId) {
      if (
        isEmptyValue(selectedSecondaryContact?.customerEmail) ||
        isEmptyValue(selectedSecondaryContact?.customerPrimaryPhone)
      ) {
        setError({
          action: "Confirm Names Task View",
          error: new Error("You must select a valid secondary signer."),
        });
        return;
      }

      contactsArr.push({
        contactId: selectedSecondaryContact?.contactId,
        role: ROLES.CONTRACTCOSIGNER,
      });
    }

    setIsSaving(true);

    confirmContacts(authKey, prospectId, proposalId, contactsArr)
      .then(() => {
        refetch();
        refreshAndNavigateNext();
      })
      .catch((err) => {
        setError({ action: "Confirm Names Task View", error: err });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleContactUpdate = useCallback(
    async (newContact: Contact, role: Role, currentContact?: Contact) => {
      if (newContact?.contactId !== currentContact?.contactId) {
        setIsNew(!newContact?.contactId);
        // Update ROLE of the contact;
        await onEditContact({
          ...newContact,
          role,
          // Set contact as primary if changing role to Homeowner
          ...(role === ROLES.HOMEOWNER && { primary: true }),
        });
        setConfirmed(false);
      }
    },
    [onEditContact]
  );

  const handleContactRoleUpdate = useCallback(
    async (newContact: Contact, roleToUpdate: Role, newRole: Role) => {
      try {
        // Update ROLE of the contact
        await onEditContactRole({ ...newContact, roleToUpdate, newRole });
        setConfirmed(false);
      } catch (error) {
        setError({ action: "Updating Contact", error: error as Error });
      }
    },
    [onEditContactRole]
  );

  if (prospectError || contactsError) {
    return (
      <ErrorComponent
        context="ConfirmNamesTaskView"
        error={prospectError ?? contactsError}
      />
    );
  }

  if (isProspectLoading || isContactsLoading || isSaving) {
    return <LoadingIndicator fullScreen />;
  }

  const showCheckbox = !currentTask?.isComplete || contactsHaveChanged;
  const checkboxDisabled = Boolean(
    selectedPrimaryContact === undefined ||
      selectedUtilityContact === undefined ||
      contactsHaveInvalidContactInfo()
  );
  const confirmButtonDisabled =
    checkboxDisabled || (showCheckbox && !confirmed);

  return (
    <>
      {error && (
        <ErrorModal
          context="ConfirmNamesTaskView"
          error={error.error}
          action={error.action}
          onClose={() => setError(undefined)}
        />
      )}
      {contactError && (
        <ErrorModal
          title={contactError.title}
          context="ConfirmNamesTaskView"
          error={contactError.error}
          action={contactError.action}
          onClose={() => setContactError(undefined)}
          onRetry={contactError.retry}
        />
      )}
      <GridLayoutDiv>
        <Typography variant="p">
          Please confirm that the primary signer's name matches the name of a
          person on the title. If the primary signer and name on title DO NOT
          match, change the primary signer.
        </Typography>
        <Card>
          <CardHeader>
            <Typography>Names on Title</Typography>
          </CardHeader>
          <CardHeaderDivider />
          <CardContent>
            <Typography variant="p">
              {prospect?.primaryTitle ?? ""}
              {prospect?.primaryTitle && prospect.secondaryTitle && ", "}
              {prospect?.secondaryTitle ?? ""}
              {!prospect?.primaryTitle &&
                !prospect?.secondaryTitle &&
                "Name on title data not available, please confirm customer is on title."}
            </Typography>
          </CardContent>
        </Card>
        <CheckoutContacts
          selectedPrimaryContact={selectedPrimaryContact}
          setSelectedPrimaryContact={async (contact) =>
            await handleContactUpdate(
              contact,
              ROLES.HOMEOWNER,
              selectedPrimaryContact
            )
          }
          selectedUtilityContact={selectedUtilityContact}
          setSelectedUtilityContact={async (contact) =>
            await handleContactUpdate(
              contact,
              ROLES.UTILITYBILLCONTACT,
              selectedUtilityContact
            )
          }
          selectedSecondaryContact={selectedSecondaryContact}
          setSelectedSecondaryContact={async (contact, remove) => {
            remove
              ? // If remove = true, selectedSecondaryContact is defined
                await handleContactRoleUpdate(
                  selectedSecondaryContact!,
                  ROLES.CONTRACTCOSIGNER,
                  ROLES.OTHER
                )
              : await handleContactUpdate(
                  contact,
                  ROLES.CONTRACTCOSIGNER,
                  selectedSecondaryContact
                );
          }}
        />
        <StyledFooter>
          <Button
            disabled={confirmButtonDisabled}
            onClick={() => {
              handleConfirm();
            }}
            style={{ width: 120 }}
          >
            Confirm
          </Button>

          {showCheckbox && (
            <StyledCheckbox
              checked={confirmed}
              disabled={checkboxDisabled}
              label="I verify that the above information is correct"
              onChange={() => {
                setConfirmed((confirmed) => !confirmed);
              }}
            />
          )}
        </StyledFooter>
      </GridLayoutDiv>
      {updateContactLoading && (
        <LoadingOverlay
          message={`${isNew ? "Creating" : "Updating"} contact...`}
        />
      )}
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  span {
    padding-left: 5px;
    font-size: 16px;
  }
`;

const StyledFooter = styled.footer`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const GridLayoutDiv = styled.div`
  display: grid;
  margin-top: 32px;
  grid-row-gap: 32px;
  margin-bottom: 32px;
`;

export { ConfirmNamesTaskView };
