import { Modal } from "@sunrun/experience-ui-components";
import { ReactNode } from "react";
import styled from "@emotion/styled/macro";

type ConfirmModalProps = {
  title: string;
  children: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel: string;
  loading?: boolean;
  modalType?: "info" | "error" | "warning";
};

const ConfirmModal = ({
  title,
  children,
  onClose,
  onConfirm,
  confirmLabel,
  loading = false,
  modalType = "info",
}: ConfirmModalProps) => {
  return (
    <Modal
      title={title}
      type={modalType}
      primaryButton={{
        text: loading ? "Loading" : confirmLabel,
        onClick: onConfirm,
        disabled: loading,
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: onClose,
        disabled: loading,
      }}
      onClose={onClose}
    >
      {children}
      {loading && <LoadingOverlay data-testid="loading-overlay" />}
    </Modal>
  );
};

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: progress;
`;

export { ConfirmModal };
export type { ConfirmModalProps };
