import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { LoadingIndicator } from "@sunrun/experience-ui-components";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { PageCenter } from "components/templates/PageCenter";
import { Message } from "utils/messages";
import { CognitoToken } from "slices/authSlice";

const CognitoLoginPage = () => {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState<Error>();
  const [showWaitingMessage, setShowWaitingMessage] = useState(false);
  const [tokenData, setTokenData] = useState<CognitoToken>();

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        const tokenData = {
          token: data.getIdToken().getJwtToken(),
          refreshToken: data.getRefreshToken().getToken(),
          expiration: data.getIdToken().getExpiration() * 1000, // Converting Unix timestamp to JS milliseconds
        };
        setTokenData(tokenData);
      })
      .catch((error) => {
        setError(error);
        setShowError(true);
      });
  }, []);
  useEffect(() => {
    const handler = (event: MessageEvent) => {
      const message = event?.data as Message;
      if (!message || !event.source) {
        return;
      }
      if (message.type === "QUERY:AUTH_TOKEN" && tokenData) {
        event.source.postMessage(
          {
            type: "EVENT:AUTH_TOKEN",
            source: "Offer Experience Standalone",
            payload: {
              token: tokenData,
              type: "IHD",
            },
          },
          { targetOrigin: "*" }
        );
        window.close();
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [tokenData]);
  // Fallback to prevent "infinite loading"
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWaitingMessage(true);
    }, 5000);
    return () => clearTimeout(timer);
    // Intentionally excluding deps - this should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showError) {
    return (
      <PageCenter>
        <ErrorComponent
          context="CognitoLoginPage"
          error={error}
        ></ErrorComponent>
      </PageCenter>
    );
  }

  return (
    <PageCenter>
      {showWaitingMessage && (
        <div>
          This is taking longer than expected. Something may have gone wrong...
        </div>
      )}
      <LoadingIndicator color="black" />
    </PageCenter>
  );
};

export { CognitoLoginPage };
