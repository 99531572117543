import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { getBaseUrl, publish } from "./messages";
import { isInIframe } from "./iframeUtils";

type UseNavigationOptions = Pick<NavigateOptions, "replace"> & {
  hash?: string;
};

const useNavigateHelper = (options?: UseNavigationOptions) => {
  const navigate = useNavigate();
  const mergedOptions = Object.assign(
    { replace: false } satisfies UseNavigationOptions,
    options ?? {}
  );

  return useCallback(
    (path: string) => {
      if (isInIframe()) {
        publish.changeUrl(path, mergedOptions.replace, mergedOptions.hash);
      } else {
        navigate(`${getBaseUrl()}${path}`, { replace: mergedOptions.replace });
      }
    },
    [navigate, mergedOptions.replace, mergedOptions.hash]
  );
};

const useNavigateToList = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(() => {
    navigate(`/list`);
  }, [navigate]);
};

const useNavigateToConfirm = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(() => {
    navigate(`/confirm`);
  }, [navigate]);
};

const useNavigateToBuild = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(
    (offerId?: string, copyDesignOrProposalId?: string) => {
      if (offerId) {
        if (copyDesignOrProposalId) {
          navigate(`/build/${offerId}/${copyDesignOrProposalId}`);
        } else {
          navigate(`/build/${offerId}`);
        }
      } else {
        navigate(`/build`);
      }
    },
    [navigate]
  );
};

const useNavigateToChangeOrder = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(
    (offerId?: string) => {
      if (offerId) {
        navigate(`/change-order/${offerId}`);
      } else {
        navigate(`/change-order`);
      }
    },
    [navigate]
  );
};

const useNavigateToReview = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(
    (offerId?: string) => {
      navigate(`/${offerId ? offerId : ""}`);
    },
    [navigate]
  );
};

const useNavigateToUsage = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(() => {
    navigate(`/usage`);
  }, [navigate]);
};

const useNavigateToCheckout = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(
    (step: string, offerId: string) => {
      navigate(`/${offerId}/checkout/${step}`);
    },
    [navigate]
  );
};

const useNavigateToNoOffer = (options?: UseNavigationOptions) => {
  const navigate = useNavigateHelper(options);
  return useCallback(() => {
    navigate(`/checkout/no-offer`);
  }, [navigate]);
};

const reloadMFE = () => {
  if (isInIframe()) {
    publish.reloadMFE();
  } else {
    window.location.reload();
  }
};

export {
  useNavigateToConfirm,
  useNavigateToBuild,
  useNavigateToList,
  useNavigateToReview,
  useNavigateToUsage,
  useNavigateHelper,
  useNavigateToCheckout,
  useNavigateToNoOffer,
  useNavigateToChangeOrder,
  reloadMFE,
};
