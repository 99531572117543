import { API } from "aws-amplify";
import { type SplatProspectUpdate } from "../../amplify/backend/function/OfferExpUsage/ts/public/types";
import {
  CancelSiteSurveyAppointmentRequestData,
  SiteSurveySlotRequestData,
  SplatCheckoutDocumentType,
} from "../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { listOffers } from "./storeFront";
import { subscribeToAsyncRequest } from "utils/subscribeToAsyncRequest";
import { Attachment } from "utils/usageUtils";
import { retry } from "utils/async";

const performRequest = async <Response>(
  method: "get" | "post" | "del" | "patch",
  jwt: string,
  path: string,
  body?: any,
  headers?: Record<string, unknown>
): Promise<Response> => {
  const response = await API[method]("OfferExpApi", path, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      ...headers,
    },
    body: body,
  });
  return response as Response;
};

const patchUsage = async (
  jwt: string,
  prospectId: string,
  prospectData: Partial<SplatProspectUpdate>
): Promise<{
  asyncRequestId: string;
}> => {
  return await performRequest(
    "patch",
    jwt,
    `/prospect/${prospectId}/usage`,
    prospectData
  );
};

const postAttachment = async (
  jwt: string,
  prospectId: string,
  attachment: Attachment
): Promise<any> => {
  const formData = new FormData();
  formData.append("documentBlob", attachment.documentBlob);
  formData.append("prospectId", prospectId);
  formData.append("contentType", attachment.contentType);
  formData.append("documentName", attachment.documentName);
  formData.append("documentType", attachment.documentType);

  return performRequest(
    "post",
    jwt,
    `/prospect/${prospectId}/usage/attachments`,
    formData,
    { "Content-Type": `multipart/form-data` }
  ).catch((err) => {
    const status = err?.response?.status || "";
    if (status === 408) {
      console.log(
        "initial attempt timed out, it will probably still go through anyways"
      );
      return;
    }
    throw err;
  });
};

const postCheckoutAttachment = async (
  authKey: string,
  prospectId: string,
  proposalId: string,
  attachment: Attachment
): Promise<any> => {
  const formData = new FormData();
  formData.append("documentBlob", attachment.documentBlob);
  formData.append("prospectId", prospectId);
  formData.append("proposalId", proposalId);
  formData.append("contentType", attachment.contentType);
  formData.append("documentName", attachment.documentName);
  formData.append("documentType", attachment.documentType);
  const attachmentUrlsAsyncResponse = await performRequest<{
    asyncRequestId: string;
  }>(
    "post",
    authKey,
    `/prospect/${prospectId}/proposals/${proposalId}/get-attachment-urls`,
    formData,
    { "Content-Type": `multipart/form-data` }
  );
  const attachmentUrls = await subscribeToAsyncRequest(
    authKey,
    attachmentUrlsAsyncResponse.asyncRequestId
  );

  formData.append("uploadUrls", JSON.stringify(attachmentUrls));

  const uploadUrl: string = await performRequest(
    "post",
    authKey,
    `/prospect/${prospectId}/proposals/${proposalId}/upload-attachment-blob`,
    formData,
    { "Content-Type": `multipart/form-data` }
  );

  formData.append("uploadUrl", uploadUrl);

  const checkoutAttachmentAsyncResponse = await performRequest<{
    asyncRequestId: string;
  }>(
    "post",
    authKey,
    `/prospect/${prospectId}/proposals/${proposalId}/upload-attachment`,
    formData,
    { "Content-Type": `multipart/form-data` }
  );

  return await subscribeToAsyncRequest(
    authKey,
    checkoutAttachmentAsyncResponse.asyncRequestId
  );
};

const postCreditCheck = (
  jwt: string,
  prospectId: string,
  contactId: string,
  action: string
): Promise<{
  url?: string;
  success?: boolean;
}> => {
  return performRequest("post", jwt, `/prospect/${prospectId}/creditCheck`, {
    contactId,
    action: action,
  });
};

const postLoanApplicationEmail = (
  jwt: string,
  prospectId: string,
  proposalId: string,
  coBorrowerId?: string | undefined
): Promise<any> => {
  let url = `/prospect/${prospectId}/proposal/${proposalId}/loan/application/send-email`;
  if (coBorrowerId) {
    url += `?coborrower=${coBorrowerId}`;
  }
  return performRequest("post", jwt, url);
};

const createSiteSurveyAppointment = async (
  authKey: string,
  prospectId: string,
  requestData: SiteSurveySlotRequestData
) => {
  const createSSApptAsyncResponse = await performRequest<{
    asyncRequestId: string;
  }>(
    "post",
    authKey,
    `/prospect/${prospectId}/site-survey/appointment`,
    requestData
  );

  return await subscribeToAsyncRequest(
    authKey,
    createSSApptAsyncResponse.asyncRequestId
  );
};

const cancelSiteSurveyAppointment = async (
  authKey: string,
  prospectId: string,
  appointmentId: string,
  requestData: CancelSiteSurveyAppointmentRequestData
) => {
  const cancelSSApptAsyncResponse = await performRequest<{
    asyncRequestId: string;
  }>(
    "patch",
    authKey,
    `/prospect/${prospectId}/site-survey/appointment/${appointmentId}/cancel-site-survey`,
    requestData
  );

  return await subscribeToAsyncRequest(
    authKey,
    cancelSSApptAsyncResponse.asyncRequestId
  );
};

const sendIpaEmail = async (
  jwt: string,
  prospectId: string,
  proposalId: string
) => {
  return await performRequest<{
    status: string;
  }>(
    "get",
    jwt,
    `/prospect/${prospectId}/proposals/${proposalId}/sendIlIpaEmail`
  );
};

const sendLoanEmail = async (
  jwt: string,
  prospectId: string,
  proposalId: string,
  secondaryContactId?: string
) => {
  const sendLoanEmailResponse = await performRequest<{
    status: string;
  }>(
    "post",
    jwt,
    `/prospect/${prospectId}/proposals/${proposalId}/send-loan-email`,
    {
      secondaryContactId,
    }
  );
  if (sendLoanEmailResponse.status === "FAILED") {
    throw new Error("Error sending loan document email.");
  }
  return sendLoanEmailResponse;
};

const signDocument = async (
  props: {
    authKey: string;
    prospectId: string;
    proposalId: string;
    documentType: SplatCheckoutDocumentType;
    eSignatureSource: string;
    eSignatureType: string;
    primaryContactId: string;
  },
  secondaryContactId?: string,
  action?: string
) => {
  const {
    authKey,
    prospectId,
    proposalId,
    documentType,
    eSignatureSource,
    eSignatureType,
    primaryContactId,
  } = props;
  const signDocumentAsyncResponse = await performRequest<{
    asyncRequestId: string;
  }>(
    "post",
    authKey,
    `/prospect/${prospectId}/proposals/${proposalId}/sign-document`,
    {
      action,
      documentType,
      eSignatureSource,
      eSignatureType,
      primaryContactId,
      secondaryContactId,
    }
  );

  return await subscribeToAsyncRequest(
    authKey,
    signDocumentAsyncResponse.asyncRequestId
  );
};

const voidProposal = async ({
  authKey,
  prospectId,
  proposalId,
  cognitoToken,
  identity,
}: {
  authKey: string;
  prospectId: string;
  proposalId: string;
  identity: string;
  cognitoToken: string;
}): Promise<any> => {
  await performRequest(
    "del",
    authKey,
    `/void-proposals/prospect/${prospectId}/proposals/${proposalId}`
  );

  return retry({
    fn: async () => listOffers(cognitoToken, prospectId, identity),
    test: (value: unknown) => {
      return (
        Array.isArray(value) && !value.find((offer) => offer.id === proposalId)
      );
    },
  });
};

const voidAllProposals = async ({
  authKey,
  prospectId,
  identity,
  cognitoToken,
}: {
  authKey: string;
  prospectId: string;
  identity: string;
  cognitoToken: string;
}): Promise<any> => {
  await performRequest(
    "del",
    authKey,
    `/void-proposals/prospect/${prospectId}`
  );
  return retry({
    fn: async () => listOffers(cognitoToken, prospectId, identity),
    test: (offers: unknown) => {
      return Array.isArray(offers) && offers?.length === 0;
    },
  });
};

const voidDocument = async (
  props: {
    authKey: string;
    prospectId: string;
    proposalId: string;
    documentType: SplatCheckoutDocumentType;
    eSignatureSource: string;
    eSignatureType: string;
    primaryContactId: string;
  },
  action?: string
) => {
  const {
    authKey,
    prospectId,
    proposalId,
    documentType,
    eSignatureSource,
    eSignatureType,
    primaryContactId,
  } = props;
  const voidDocumentAsyncResponse = await performRequest<{
    asyncRequestId: string;
  }>(
    "post",
    authKey,
    `/prospect/${prospectId}/proposals/${proposalId}/void-document`,
    {
      action,
      documentType,
      eSignatureSource,
      eSignatureType,
      primaryContactId,
    }
  );
  return await subscribeToAsyncRequest(
    authKey,
    voidDocumentAsyncResponse.asyncRequestId
  );
};

const getDocumentStatus = async (props: {
  authKey: string;
  prospectId: string;
  proposalId: string;
  documentType: SplatCheckoutDocumentType;
}): Promise<any> => {
  const { authKey, prospectId, proposalId, documentType } = props;
  return await performRequest(
    "get",
    authKey,
    `/prospect/${prospectId}/proposals/${proposalId}/document-status?documentType=${documentType}`
  );
};

export {
  cancelSiteSurveyAppointment,
  createSiteSurveyAppointment,
  getDocumentStatus,
  patchUsage,
  postAttachment,
  postCheckoutAttachment,
  postCreditCheck,
  postLoanApplicationEmail,
  sendIpaEmail,
  sendLoanEmail,
  signDocument,
  voidAllProposals,
  voidDocument,
  voidProposal,
};
