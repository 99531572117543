import useSWR from "swr";
import { useAuthToken } from "./useAuthToken";
import { getDesignImages } from "services/lightmileProject";

const useDesignImages = (lightmileProjectId: string | undefined) => {
  const ihdToken = useAuthToken("IHD");
  const hybridToken = useAuthToken("HYBRID");
  const proposalToken = useAuthToken("PROPOSAL");
  return useSWR(
    [
      "designImages",
      ihdToken ?? hybridToken ?? proposalToken,
      lightmileProjectId,
    ],
    ([, authToken, lightmileProjectId]) => {
      if (!authToken) return;
      if (!lightmileProjectId) return;
      return getDesignImages(authToken, lightmileProjectId);
    }
  );
};

export { useDesignImages };
