import React from "react";
import styled from "@emotion/styled/macro";
import { Outlet, useMatch } from "react-router-dom";
import {
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import {
  CheckoutContentGrid,
  CheckoutGrid,
  CheckoutHeaderGrid,
  CheckoutSidebarGrid,
} from "../templates/CheckoutGrid";
import { CheckoutHeader } from "../molecules/CheckoutHeader";
import { TaskViewTemplate } from "../templates/TaskViewTemplate";
import { UpdateContactWarningBanner } from "../molecules/UpdateContactWarningBanner";
import { TaskList } from "checkout/components/organisms/TaskList";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { CheckoutNavGuard } from "checkout/components/molecules/CheckoutNavGuard";

const CheckoutMainPage: React.FC = () => {
  const match = useMatch(
    "/prospect/:prospectId/offer/:offerId/checkout/:taskSlug"
  );

  const {
    offer,
    currentTask,
    currentTaskId,
    loadingOverride,
    titleOverride,
    showSecondaryWarningBanner,
    setShowSecondaryWarningBanner,
    showUtilityBillWarningBanner,
    setShowUtilityBillWarningBanner,
  } = useCheckoutContext();

  if (!match) {
    throw new Error("CheckoutMainPage must be rendered within matching route.");
  }

  return (
    <CheckoutNavGuard>
      <CheckoutGrid>
        <CheckoutHeaderGrid>
          <CheckoutHeader
            headerTitle="Checkout"
            subtitle={
              <Typography
                variant="p"
                color={tokens.TINTS_OFF_WHITE_10}
                size={tokens.FONT_SIZE_1}
              >
                Complete the following tasks to finish your solar offer. If
                issues arise, log them with the{" "}
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href="https://vivintsolar.service-now.com/help?id=sc_cat_item&sys_id=d42ed9524f858600d59d76601310c76a"
                >
                  IT & Service Desk
                </StyledLink>
              </Typography>
            }
          />
          {showSecondaryWarningBanner && (
            <UpdateContactWarningBanner
              contactType="secondary"
              onClose={() => setShowSecondaryWarningBanner(false)}
            />
          )}
          {showUtilityBillWarningBanner && (
            <UpdateContactWarningBanner
              contactType="utility_bill"
              onClose={() => setShowUtilityBillWarningBanner(false)}
            />
          )}
        </CheckoutHeaderGrid>
        <CheckoutSidebarGrid>
          <TaskList
            title={`${offer?.systemSize ?? "-"} kW | ${
              offer?.financial.financePlan ?? "-"
            }`}
            installationPartner={offer?.financial.summary.purchasedThru}
          />
        </CheckoutSidebarGrid>
        <CheckoutContentGrid>
          {currentTask ? (
            <TaskViewTemplate
              loading={currentTask.shouldHideHeaderOnLoading && loadingOverride}
              icon={currentTask.icon}
              title={
                titleOverride?.[currentTaskId] ||
                currentTask.taskViewTitle ||
                currentTask.label
              }
            >
              <Outlet />
            </TaskViewTemplate>
          ) : (
            <LoadingWrapper>
              <LoadingIndicator color="black" />
            </LoadingWrapper>
          )}
        </CheckoutContentGrid>
      </CheckoutGrid>
    </CheckoutNavGuard>
  );
};

const LoadingWrapper = styled.div`
  margin-top: 32px;
`;
const StyledLink = styled.a`
  color: ${tokens.TINTS_OFF_WHITE_10};
`;

export { CheckoutMainPage };
