import styled from "@emotion/styled/macro";
import { EditOfferProduct } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { ProductVariantOptions } from "./ProductVariantOptions";
import { ProductTitleWithInfoModal } from "components/atoms/ProductTitleWithInfoModal";
import { ProductImage } from "components/atoms/ProductImage";

type IncludedProductProps = {
  disabled: boolean;
  imageUrl: string;
  onUpdate: (productId: string, variantId: string) => void;
  productId: string;
  selectedVariantId?: string;
  title: string;
  variants: EditOfferProduct["variants"];
};
const IncludedProduct = ({
  disabled,
  imageUrl,
  onUpdate,
  productId,
  selectedVariantId,
  title,
  variants,
}: IncludedProductProps) => {
  return (
    <IncludedProductsContainer>
      <ProductImage
        data-testid={`product-image-${imageUrl}`}
        imageUrl={imageUrl}
      />
      <ProductTitleWithInfoModal title={title} />
      <ProductVariantOptions
        productId={productId}
        disabled={disabled}
        selectedVariantId={selectedVariantId}
        variants={variants}
        onVariantChange={onUpdate}
      ></ProductVariantOptions>
    </IncludedProductsContainer>
  );
};

const IncludedProductsContainer = styled.div`
  width: 233px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 536px) {
    width: 100%;
    padding-bottom: 40px;
  }
`;

export type { IncludedProductProps };
export { IncludedProduct };
