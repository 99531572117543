import { ReviewOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import {
  consumptionCardData as consumptionCardDataFixture,
  productionCardData as productionCardDataFixture,
  systemSizeCardData as systemSizeCardDataFixture,
} from "../../fixtures/SystemCarouselData";

import usageOffsetImg from "../assets/images/carousel/usage-offset-img.png";
import { formatNumber } from "utils/formatHelpers";
import { isBigTipText } from "utils/textUtils";
import { FLEX_MONTHLY } from "constants/financialProducts";

const tipText =
  "This is an estimate and does not guarantee actual offset. The system design may change based on a detailed engineering site survey. Actual offset will vary based on the final system size, design, configuration, system location, local climate, and your family's energy usage.";

const getInfoToolTipTextLines = (
  production: number | undefined,
  consumption: number | undefined,
  usageOffset: number | undefined
) => {
  if (!production && !consumption && !usageOffset) {
    return {
      singleLineText: "",
      lines: "",
    };
  }

  const lines = [
    `${formatNumber(
      Math.round(Number(production))
    )} Expected Solar Production (kWh)\xA0/`,
    `${formatNumber(
      Math.round(Number(consumption))
    )} Estimated usage (kWh) from manual entry`,
    `= ${formatNumber(Math.round(Number(usageOffset)))}% Usage Offset`,
    tipText,
  ];

  const singleLineText = lines.join(" ");

  return {
    singleLineText,
    lines: lines.map((textLine, index) => <div key={index}>{textLine}</div>),
  };
};

const systemSizeCardData = (offer: ReviewOffer | undefined) => {
  let data = {
    ...systemSizeCardDataFixture,
    value: offer?.systemSize,
  };

  if (offer?.financial.summary.type === FLEX_MONTHLY) {
    data.extendedDescription = `Including ${offer?.financial.financePlan}`;
  }

  return data;
};

const productionCardData = (offer: ReviewOffer | undefined) => {
  let data = {
    ...productionCardDataFixture,
    value: offer?.production,
  };

  if (offer?.financial.summary.type === FLEX_MONTHLY) {
    data.value = offer.production;
    data.extendedDescription = `Including ${offer?.financial.financePlan}`;
  }

  return data;
};

const usageOffsetCardData = (offer: ReviewOffer | undefined) => {
  const { production, consumption, usageOffset } = offer ?? {};
  const usageDataTooltip = getInfoToolTipTextLines(
    production,
    consumption,
    usageOffset
  );

  let data = {
    headerText: "Usage Offset",
    infoToolTipText: usageDataTooltip.lines,
    footerTextSuffix: "%",
    iconMedia: usageOffsetImg,
    extendedDescription: "",
    isBigTip: isBigTipText(usageDataTooltip.singleLineText),
    value: usageOffset,
  };

  if (offer?.financial.summary.type === FLEX_MONTHLY) {
    data.extendedDescription = `Including ${offer?.financial.financePlan}`;
  }

  return data;
};

const consumptionCardData = (offer: ReviewOffer | undefined) => {
  let data = { ...consumptionCardDataFixture, value: offer?.consumption };
  if (offer?.financial.summary.type === FLEX_MONTHLY) {
    data.extendedDescription = "Current annual usage";
  }
  return data;
};

export {
  usageOffsetCardData,
  systemSizeCardData,
  consumptionCardData,
  productionCardData,
};
