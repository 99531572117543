import React from "react";
import styled from "@emotion/styled/macro";
import { Typography, Modal } from "@sunrun/experience-ui-components";
import { ErrorComponent } from "components/atoms/ErrorComponent";

type ProductErrorModalProps = {
  action?: string;
  title?: string;
  context: React.ComponentProps<typeof ErrorComponent>["context"];
  error: React.ComponentProps<typeof ErrorComponent>["error"];
  onClose: () => void;
  onRetry?: () => void;
};

const ErrorModal = ({
  action,
  context,
  error,
  onClose,
  onRetry,
  title,
}: ProductErrorModalProps) => {
  const formattedAction = action
    ? action[0].toUpperCase() + action.slice(1)
    : "An action";
  return (
    <Modal
      type="error"
      title={title || "Something went wrong"}
      {...(onRetry
        ? {
            primaryButton: { text: "Retry", onClick: onRetry },
            secondaryButton: { text: "Dismiss", onClick: onClose },
          }
        : {
            primaryButton: { text: "Dismiss", onClick: onClose },
          })}
      onClose={onClose}
      hideClose
    >
      <Grid>
        <Typography style={{ marginBottom: 12 }}>
          Our system is having trouble {action ?? "processing your preferences"}{" "}
          at the moment. Please try again to resolve the issue.
        </Typography>
        {action && (
          <Typography
            component="p"
            variant="p"
          >{`${formattedAction} failed to complete.`}</Typography>
        )}

        <ErrorComponent context={context} error={error} />
      </Grid>
    </Modal>
  );
};

const Grid = styled.div`
  display: grid;
`;

export { ErrorModal };
export type { ProductErrorModalProps };
