const stagingClientId = "p7eb9gml07lkjpeh2od37f5gn";
const productionClientId = "lppl5bti7vec8d3n2und78sal";
const COGNITO_CONFIG = {
  staging: {
    cognitoConfig: {
      aws_cognito_region: "us-west-2",
      aws_user_pools_id: "us-west-2_jUyflLPDL",
      aws_user_pools_web_client_id: stagingClientId,
      oauth: {
        domain: "dev-hub-domain.auth.us-west-2.amazoncognito.com",
        redirectSignIn: `${window.origin}/login/cognito`,
        redirectSignOut: `${window.origin}/`,
        clientId: stagingClientId,
        responseType: "code",
      },
    },
  },
  production: {
    cognitoConfig: {
      aws_cognito_region: "us-west-2",
      aws_user_pools_id: "us-west-2_iqlQcOgjT",
      aws_user_pools_web_client_id: productionClientId,
      oauth: {
        domain: "prd-hub-domain.auth.us-west-2.amazoncognito.com",
        redirectSignIn: `${window.origin}/login/cognito`,
        redirectSignOut: `${window.origin}/`,
        clientId: productionClientId,
        responseType: "code",
      },
    },
  },
};

export { COGNITO_CONFIG };
