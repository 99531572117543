import { Role } from "../../amplify/backend/function/OfferExpContacts/ts/public/types";

const monthKeys = [
  "janUsage",
  "febUsage",
  "marUsage",
  "aprUsage",
  "mayUsage",
  "junUsage",
  "julUsage",
  "augUsage",
  "sepUsage",
  "octUsage",
  "novUsage",
  "decUsage",
];

type Month = { month: string; days: number };

const monthsAndDays: Month[] = [
  { month: "Jan", days: 31 },
  { month: "Feb", days: 28 },
  { month: "Mar", days: 31 },
  { month: "Apr", days: 30 },
  { month: "May", days: 31 },
  { month: "Jun", days: 30 },
  { month: "Jul", days: 31 },
  { month: "Aug", days: 31 },
  { month: "Sep", days: 30 },
  { month: "Oct", days: 31 },
  { month: "Nov", days: 30 },
  { month: "Dec", days: 31 },
];

const isEmptyValue = (value: string | undefined | null) => {
  if (!value || value === "") {
    return true;
  }
  return false;
};

const formatDataForMonthly = (
  usageData: (number | undefined)[],
  months: Month[],
  usageCadence?: "monthly" | "daily"
): { month: string; value: number }[] =>
  usageData.map((val: number | undefined, index: number) => {
    const numValue = val ?? 0;
    return {
      month: months[index].month,
      value:
        usageCadence === "monthly" ? numValue : numValue * months[index].days,
    };
  });

const determineUpdatedValue = (
  val1: string | undefined | number | boolean,
  val2: string | undefined | number | boolean
): boolean => {
  if (typeof val1 === "boolean" || typeof val2 === "boolean") {
    return false;
  }
  if (val1 && val2) {
    return val1 !== val2;
  }
  const emptyArr: (string | undefined | number)[] = ["", undefined];

  if (emptyArr.includes(val1) && !emptyArr.includes(val2)) {
    return true;
  } else if (!emptyArr.includes(val1) && emptyArr.includes(val2)) {
    return true;
  }
  return false;
};

const PROPOSAL_VOIDING_FORM_FIELDS = [
  "customerState",
  "customerZipCode",
  "customerStreet",
  "customerCity",
  "customerFirstName",
  "customerLastName",
  "utilityCompany",
  ...monthKeys,
];

const defaultAllowedFileTypes = [
  "application/msword",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
  "image/tiff",
];

const imageFileTypes = ["jpeg", "jpg", "png", "tiff"];

type VoidingField = (typeof PROPOSAL_VOIDING_FORM_FIELDS)[number];

type UtilityInfoState = {
  utilityBillDate?: string;
  utilityCompany?: string;
  utilityServiceAddress?: string;
  rateSchedule?: string;
  accountNumber?: string;
  meterNumber?: string;
};

interface Attachment {
  documentName: string;
  documentType: string;
  contentType: string;
  url: string;
  documentBlob: Blob;
  new?: boolean;
}

type UsageCadence = "monthly" | "daily";

const ROLES: Record<string, Role> = {
  UTILITYBILLCONTACT: "UtilityBillContact",
  HOMEOWNER: "Homeowner",
  CONTRACTSIGNER: "Contract Signer",
  CONTRACTCOSIGNER: "Contract Cosigner",
  OTHER: "Other",
  CREDIT: "Credit",
  BILLING: "Billing",
  DEPOSIT: "Deposit",
  ACH: "ACH",
  HOMEBUILDER: "Homebuilder",
  CREDITCONTACT: "Credit Contact",
};

export type { Attachment, UsageCadence, UtilityInfoState, VoidingField };
export {
  defaultAllowedFileTypes,
  determineUpdatedValue,
  formatDataForMonthly,
  imageFileTypes,
  isEmptyValue,
  monthKeys,
  monthsAndDays,
  PROPOSAL_VOIDING_FORM_FIELDS,
  ROLES,
};
