import styled from "@emotion/styled/macro";
import { useMemo } from "react";
import { Modal } from "@sunrun/experience-ui-components";
import { PackageCompareCard } from "../organisms/PackageCompareCard";
import { PackageCompareTable } from "../organisms/PackageCompareTable";
import { EditOfferProductAvailability } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";

type PackageCompareModalProps = {
  modalTitle: string;
  modalDescription: string;
  productAvailability: EditOfferProductAvailability;
  onClose: () => void;
};

/** Business logic to add Shift green checkmark to Protection and Ultimate Control bundles **/
const addShift = (productAvailability: EditOfferProductAvailability) => {
  const shiftId = productAvailability.products.find(
    ({ title }) => title === "Shift"
  )?.id;

  const indexOfProtection = productAvailability.bundles.findIndex(
    ({ title }) => title === "Protection"
  );

  const indexOfUltimateControl = productAvailability.bundles.findIndex(
    ({ title }) => title === "Ultimate Control"
  );

  const copyObj = { ...productAvailability };

  // add Shift to Protection bundle
  if (
    shiftId &&
    indexOfProtection > -1 &&
    !copyObj.bundles[indexOfProtection].productIds.includes(shiftId)
  ) {
    copyObj.bundles[indexOfProtection].productIds.push(shiftId);
  }

  // add Shift to Ultimate Control
  if (
    shiftId &&
    indexOfUltimateControl > -1 &&
    !copyObj.bundles[indexOfUltimateControl].productIds.includes(shiftId)
  ) {
    copyObj.bundles[indexOfUltimateControl].productIds.push(shiftId);
  }

  return copyObj;
};

const PackageCompareModal = ({
  modalTitle,
  modalDescription,
  productAvailability,
  onClose,
}: PackageCompareModalProps) => {
  const uniqueProdIds = Array.from(
    new Set(productAvailability.bundles.flatMap((b) => b.productIds))
  );

  const findProductById = (id: string) =>
    productAvailability.products.find((product) => product.id === id);

  // rows
  const productTitles = uniqueProdIds.map((id) => findProductById(id)!.title);

  const productAvailabilityCopy: EditOfferProductAvailability = useMemo(
    () => addShift(productAvailability),
    [productAvailability]
  );

  return (
    <StyledModal
      title={modalTitle}
      subtitle={modalDescription}
      onClose={onClose}
    >
      {productAvailabilityCopy.bundles.map((bundle, i) => (
        <StyledPackageCompareCard
          key={`packageCompareCard${i}`}
          bundle={bundle}
          uniqueProdIds={uniqueProdIds}
          productTitles={productTitles}
        />
      ))}

      <StyledPackageCompareTable
        bundles={productAvailabilityCopy.bundles}
        uniqueProdIds={uniqueProdIds}
        productTitles={productTitles}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-container {
    width: 75%;
  }
`;

const StyledPackageCompareCard = styled(PackageCompareCard)`
  margin: 24px 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const StyledPackageCompareTable = styled(PackageCompareTable)`
  display: none;

  @media (min-width: 1024px) {
    display: table;
  }
`;

export { PackageCompareModal };
