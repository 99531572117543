import { Typography } from "@sunrun/experience-ui-components";
import {
  BreakdownBox,
  BreakdownRows,
  FirstColumn,
  Header,
  HeroText,
  StandardText,
  Total,
  TotalRow,
  StyledSecondColumn,
  StyledCategory,
  StyledHeader,
  StyledFootnote,
} from "../atoms/GlobalStyles";
import {
  RowContainer,
  RowContainerWithModal,
  RowContainerWithTooltip,
} from "../atoms/PriceGlobals";
import { ReviewLoanSummary } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { CardSectionDivider } from "components/atoms/Card";
import { formatCurrency } from "utils/formatHelpers";
import { Warranties } from "components/atoms/Warranties";
import {
  COSTCO_CARD_ESTIMATE,
  FEDERAL_SOLAR_TAX_CREDIT,
  LIFETIME_SAVINGS,
  LOAN_APR,
  POST_SOLAR_UTILITY_BILL,
  SRECII,
  STATE_LOCAL_REBATES,
} from "constants/pricing-tooltips";

const PriceLoan = ({ summary }: { summary: ReviewLoanSummary }) => {
  return (
    <>
      <BreakdownBox>
        <BreakdownRows>
          <FirstColumn>
            <Header>Have peace of mind</Header>
            <StandardText>
              Know that you're covered with premium protection.
            </StandardText>
            <Warranties warranties={summary.warranties} />
          </FirstColumn>
          <StyledSecondColumn>
            <Header>Loan Payment</Header>
            <HeroText>
              {formatCurrency(summary.monthlyWithVoluntary)}/mo.
            </HeroText>

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Terms</Typography>
              </StyledHeader>

              <RowContainer label="Total Loan Amount" cost={summary.total} />

              <RowContainerWithTooltip
                tooltip={LOAN_APR}
                label="APR includes 0.50% Autopay Discount"
                cost={`${summary.apr}%`}
              />

              <RowContainer
                label="Loan Term"
                cost={`${summary.termYears} years`}
              />
            </StyledCategory>

            {(summary.taxCredit > 0 ||
              summary.rebates > 0 ||
              summary.srec > 0 ||
              summary.costcoCard > 0) && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Rebates & Incentives</Typography>
                </StyledHeader>

                {summary.taxCredit > 0 && (
                  <RowContainerWithModal
                    modalDescription={FEDERAL_SOLAR_TAX_CREDIT}
                    label="Estimated Federal Solar Tax Credit"
                    ariaLabel="Open Estimated Federal Solar Tax Credit Modal"
                    cost={summary.taxCredit}
                  />
                )}

                {summary.rebates > 0 && (
                  <RowContainerWithTooltip
                    tooltip={STATE_LOCAL_REBATES}
                    label="Estimated State and Local Rebates"
                    cost={summary.rebates}
                  />
                )}

                {summary.srec > 0 && (
                  <RowContainerWithTooltip
                    tooltip={SRECII}
                    label="SRECII (SREC2)"
                    cost={summary.srec}
                  />
                )}

                {summary.costcoCard > 0 && (
                  <RowContainerWithTooltip
                    tooltip={COSTCO_CARD_ESTIMATE}
                    label="Est. Costco Shop Card"
                    cost={summary.costcoCard}
                  />
                )}
              </StyledCategory>
            )}

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Monthly Payments</Typography>
              </StyledHeader>

              {summary.market === "Y1_MONTHLY_PAYMENTS" && (
                <>
                  <RowContainer
                    label="Estimated Monthly Avg. Pre-Solar Utility Bill"
                    cost={summary.preSolarUtility}
                    unit="/mo"
                  />
                  <CardSectionDivider />
                </>
              )}

              <RowContainer
                label="Total Monthly Sunrun Bill"
                cost={formatCurrency(summary.monthlyWithVoluntary)}
                unit="/mo"
              />

              <RowContainerWithModal
                modalDescription={POST_SOLAR_UTILITY_BILL}
                label="Avg. Est. Post-Solar Utility Bill"
                ariaLabel="Open Avg. Est. Post-Solar Utility Bill Modal"
                cost={
                  summary.market === "CONTROL" ? "*" : summary.postSolarUtility
                }
                unit={summary.market === "CONTROL" ? "" : "/mo"}
              />

              <RowContainerWithModal
                modalDescription={
                  <>
                    The fixed Annual Percentage Rate for a {summary.termYears}{" "}
                    year loan is {summary.apr}%. The amount of your first 17
                    payments will be ${summary.monthlyWithVoluntary}. If you
                    make a 30% voluntary payment in month 18, your remaining
                    payments will remain approximately $
                    {summary.monthlyWithVoluntary}. If no voluntary payment is
                    made, the remaining payments in month 18 and beyond will be
                    ${summary.monthlyWithoutVoluntary}.
                  </>
                }
                label="Loan Payments 1-17 and 18+ with voluntary payment"
                ariaLabel="Open Loan Payments 1-17 and 18+ with voluntary payment modal"
                cost={summary.monthlyWithVoluntary}
                unit="/mo"
              />

              <RowContainerWithModal
                modalDescription={
                  <>
                    The fixed Annual Percentage Rate for a {summary.termYears}{" "}
                    year loan is {summary.apr}%. The amount of your first 17
                    payments will be ${summary.monthlyWithVoluntary}. If you
                    make a 30% voluntary payment in month 18, your remaining
                    payments will remain approximately $
                    {summary.monthlyWithVoluntary}. If no voluntary payment is
                    made, the remaining payments in month 18 and beyond will be
                    ${summary.monthlyWithoutVoluntary}.
                  </>
                }
                label="Loan Payments 18+ without voluntary payment"
                ariaLabel="Open Loan Payments 18+ without voluntary payment modal"
                cost={summary.monthlyWithoutVoluntary}
                unit="/mo"
              />
            </StyledCategory>

            {summary.market === "SAVINGS" && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Savings</Typography>
                </StyledHeader>

                <RowContainerWithModal
                  modalDescription={LIFETIME_SAVINGS}
                  label="Est. Lifetime Savings"
                  ariaLabel="Open Savings modal"
                  cost={`${formatCurrency(summary.savingsLow)} - 
                    ${formatCurrency(summary.savingsHigh)}`}
                />
              </StyledCategory>
            )}
          </StyledSecondColumn>
        </BreakdownRows>
        <TotalRow>
          <Total>Due at Signing</Total>
          <Total>{formatCurrency(summary.dueAtSigning)}</Total>
        </TotalRow>
        {summary.evChargerValue && (
          <TotalRow>
            <Total>EV Charger: Due at Installation</Total>
            <Total>{formatCurrency(summary.evChargerValue)}</Total>
          </TotalRow>
        )}
      </BreakdownBox>
      <StyledFootnote>
        Valid until {new Date(summary.expires).toLocaleDateString()}
      </StyledFootnote>
    </>
  );
};

export { PriceLoan };
