import styled from "@emotion/styled/macro";
import { type ReactNode, type PropsWithChildren } from "react";
import { TaskHeader } from "../molecules/TaskHeader";

type TaskViewTemplateProps = PropsWithChildren<{
  loading?: boolean;
  icon: string | ReactNode;
  title: string;
}>;

const TaskViewTemplate = ({
  loading,
  children,
  icon,
  title,
}: TaskViewTemplateProps) => {
  return (
    <Wrapper>
      {!loading && <TaskHeader icon={icon} label={title} />}
      <Content>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const Content = styled.main``;

export { TaskViewTemplate };
export type { TaskViewTemplateProps };
