import styled from "@emotion/styled/macro";
import { useMediaQuery } from "react-responsive";
import { Container } from "components/atoms/GlobalStyles";
import chevronDown from "components/assets/images/chevron-down.svg";
import { OPTY_PROGRESS_STEPS } from "checkout/util/constants";

type props = {
  showSolarStepsDetails: boolean;
  setShowSolarStepsDetails: (arg: boolean) => void;
};

type AgendaStepProps = {
  stepNumber: string;
  description: string;
  img: string;
  isMobile: boolean;
};

const AgendaStep = ({
  stepNumber,
  description,
  img,
  isMobile,
}: AgendaStepProps) => {
  return (
    <AgendaStepContainer isMobile={isMobile}>
      <img src={img} alt={description} />
      <div>
        <StyledStepNumber>{stepNumber}</StyledStepNumber>
        <StyledDescription>{description}</StyledDescription>
      </div>
    </AgendaStepContainer>
  );
};

const SolarSteps = ({
  showSolarStepsDetails,
  setShowSolarStepsDetails,
}: props) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <SolarStepsContainer>
      <SolarStepsHeader
        showSolarStepsDetails={showSolarStepsDetails}
        onClick={() => setShowSolarStepsDetails(!showSolarStepsDetails)}
      >
        <SolarStepsTitle>
          We are here as your expert guide every step of the way.
        </SolarStepsTitle>
        <SolarStepArrow
          showSolarStepsDetails={showSolarStepsDetails}
          src={chevronDown}
          alt="Chevron arrow"
        />
      </SolarStepsHeader>
      <SolarStepsContent showSolarStepsDetails={showSolarStepsDetails}>
        {OPTY_PROGRESS_STEPS.map((solarStep) => {
          return (
            <AgendaStep
              key={solarStep?.stepTitle}
              isMobile={isMobile}
              img={solarStep?.img}
              stepNumber={solarStep?.stepTitle}
              description={solarStep?.description}
            />
          );
        })}
      </SolarStepsContent>
    </SolarStepsContainer>
  );
};

const SolarStepsContainer = styled(Container)`
  padding-top: 60px;
`;

const SolarStepsHeader = styled.div<{ showSolarStepsDetails: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ showSolarStepsDetails }) =>
    showSolarStepsDetails ? "0px" : "32px"};
`;

const SolarStepsTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

const SolarStepArrow = styled.img<{ showSolarStepsDetails: boolean }>`
  transition: 0.5s;
  width: 24px;
  height: 24px;
  transform: ${({ showSolarStepsDetails }) =>
    showSolarStepsDetails ? "rotate(180deg)" : ""};
`;

const SolarStepsContent = styled.div<{ showSolarStepsDetails: boolean }>`
  transition: all 0.5s;
  padding-top: 40px;
  display: ${({ showSolarStepsDetails }) =>
    showSolarStepsDetails ? "flex" : "none"};
  align-items: baseline;
  flex-wrap: nowrap;
  justify-content: center;
`;

const StyledStepNumber = styled.div`
  font-size: 12px;
  line-height: 20px;
  padding-top: 12px;
  font-weight: 500;
`;

const StyledDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

const AgendaStepContainer = styled.div<{ isMobile: boolean }>`
  padding-bottom: 32px;
  padding-right: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: ${({ isMobile }) => (isMobile ? "11%" : "9%")};
`;

export { SolarSteps };
