import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";
import {
  Typography,
  SvgIcon,
  SvgNames,
} from "@sunrun/experience-ui-components";

import { Warranty } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";

type WarrantiesProps = {
  warranties: Warranty[];
};

const Warranties = ({
  warranties,
}: React.PropsWithChildren<WarrantiesProps>) => {
  if (!warranties?.length) {
    return null;
  }

  return (
    <Box>
      {warranties.map((warranty) => {
        const text = `${warranty.termYears} Year ${warranty.title}`;
        return (
          <StyledWarranty key={warranty.termYears + warranty.title}>
            <StyledYear>
              <IconContainer>
                <SvgIcon width={24} name={SvgNames.SuccessCircle} />
              </IconContainer>
              <Typography size="small">{text}</Typography>
            </StyledYear>
          </StyledWarranty>
        );
      })}
    </Box>
  );
};

const StyledWarranty = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StyledYear = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const Box = styled.div`
  border-radius: 8px;
  border: 1px solid ${tokens.BORDER_DARK};
  background: ${tokens.BACKGROUND_LIGHT};
  padding: 32px;
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

export type { WarrantiesProps };
export { Warranties };
