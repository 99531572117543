/* eslint-disable import/no-restricted-paths */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Modal,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { Contact } from "@sunrun/experience-ui-components/lib/components/ContactCard";
import {
  HeaderButton,
  HeaderContentLayout,
} from "../templates/HeaderContentLayout";
import { SplatProspectUpdate } from "../../../amplify/backend/function/OfferExpUsage/ts/public/types";
import { subscribeToAsyncRequest } from "utils/subscribeToAsyncRequest";
import { SplatProspectResponse } from "providers/Types";
import { patchUsage, postAttachment } from "services/prospect";
import { useAppSelector } from "store";
import {
  Attachment,
  determineUpdatedValue,
  isEmptyValue,
  monthKeys,
  monthsAndDays,
  ROLES,
  UsageCadence,
  UtilityInfoState,
} from "utils/usageUtils";
import { useContactsData, useUsageData, useUtilityData } from "utils/swrHooks";
import { utilityInfoFormSchema } from "providers/schema";
import { useNavigateToConfirm } from "utils/useNavigateHelper";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { UsagePageContent } from "components/organisms/UsagePageContent";
import { ErrorModal } from "components/molecules/ErrorModal";
import { editContact } from "services/contacts";
import { SalesforceUpdatedContext } from "providers/NewOfferProvider";
import { UsageFormContext } from "providers/UsageFormContext";
import { useScrollToElementWithAnchor } from "hooks/useScrollToElementWithAnchor";
import { UTILITY_ANCHOR_KEY } from "constants/navigation";
import { useOfferList } from "checkout/hooks/useCheckoutSwr";
import { findContact, getPrimaryContact } from "utils/contacts";

const UsagePage: React.FC = () => {
  const navigateToConfirm = useNavigateToConfirm();
  const usageContext = useContext(UsageFormContext);
  const salesforceUpdated = useContext(SalesforceUpdatedContext);
  const { prospectId } = useParams();
  const [originalUsage, setOriginalUsage] =
    useState<SplatProspectResponse | null>(null);
  const [isPatchLoading, setIsPatchLoading] = useState(false);
  const [monthlyUsage, setMonthlyUsage] = useState<
    null | (undefined | number)[]
  >(new Array(12).fill(undefined));
  const [dailyUsage, setDailyUsage] = useState(new Array(12).fill(undefined));
  const [usageCadence, setUsageCadence] = useState<UsageCadence>("monthly");
  const [showVoidProposalModal, setShowVoidProposalModal] = useState(false);
  const [modifiedField, setModifiedField] = useState<string | null>(null);
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { refetch, contacts } = useContactsData(prospectId!, authKey);
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );

  useEffect(() => {
    if (contacts?.length) {
      if (!selectedPrimaryContact) {
        setSelectedPrimaryContact(getPrimaryContact(contacts));
      }
      if (!selectedUtilityContact) {
        setSelectedUtilityContact(
          findContact(contacts, ROLES.UTILITYBILLCONTACT)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const { offerList, isOfferListLoading } = useOfferList(
    prospectId!,
    cognitoToken
  );

  const hasOffers = !!offerList?.length ?? false;

  const formUtils = useForm({
    resolver: zodResolver(utilityInfoFormSchema),
    defaultValues: {
      accountNumber: "",
      meterNumber: "",
      rateSchedule: "",
      utilityBillDate: "",
      utilityCompany: "",
      utilityServiceAddress: "",
    } as UtilityInfoState,
  });
  const [patchErrorMessage, setPatchErrorMessage] = useState<null | Error>(
    null
  );
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [newAttachments, setNewAttachments] = useState<Attachment[]>([]);
  const [selectedUtilityContact, setSelectedUtilityContact] =
    useState<Contact>();
  const [utilityContactChanged, setUtilityContactChanged] = useState(false);
  const [selectedPrimaryContact, setSelectedPrimaryContact] =
    useState<Contact>();
  const [primaryContactChanged, setPrimaryContactChanged] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  const {
    prospect: usage,
    attachments: attachmentsData,
    error: usageError,
    isLoading: isUsageLoading,
  } = useUsageData(prospectId);

  const {
    data: utilitiesOptions,
    error: utilityError,
    isLoading: isUtilitiesLoading,
  } = useUtilityData(usage?.customerState as string, authKey);

  const [scrollToRefSetter, containerRefSetter] = useScrollToElementWithAnchor({
    anchorKey: UTILITY_ANCHOR_KEY,
    containerIsWindow: false,
  });

  useEffect(() => {
    if (Array.isArray(attachmentsData) && attachmentsData.length) {
      setAttachments(attachmentsData);
    }
  }, [attachmentsData]);

  useEffect(() => {
    if (usage) {
      setOriginalUsage(usage);
      formUtils.reset({
        accountNumber: usage.accountNumber,
        meterNumber: usage.meterNumber,
        rateSchedule: usage.rateSchedule,
        utilityBillDate: usage.utilityBillDate,
        utilityCompany: usage.utilityCompany,
        utilityServiceAddress: usage.utilityServiceAddress,
      });

      setMonthlyUsage([
        usage.janUsage,
        usage.febUsage,
        usage.marUsage,
        usage.aprUsage,
        usage.mayUsage,
        usage.junUsage,
        usage.julUsage,
        usage.augUsage,
        usage.sepUsage,
        usage.octUsage,
        usage.novUsage,
        usage.decUsage,
      ]);
    }
  }, [usage, formUtils]);

  const calculateDailyUsage = useCallback((): (number | undefined)[] => {
    return monthsAndDays.map((monthObj, index: number) => {
      const dailyUsageVal = dailyUsage[index];
      if (dailyUsageVal === undefined) {
        return undefined;
      }
      return dailyUsageVal * monthObj.days;
    });
  }, [dailyUsage]);

  const saveContactPrimary = useCallback(
    (contact: Contact) => {
      if (!prospectId) {
        throw new Error("No prospect id is present.");
      }
      if (!selectedPrimaryContact?.contactId) {
        throw new Error("No contact id is present for your primary contact.");
      }
      return editContact(
        authKey,
        prospectId,
        selectedPrimaryContact.contactId,
        contact.customerPrimaryPhone,
        contact.customerEmail,
        { role: ROLES.HOMEOWNER, primary: true }
      );
    },
    [authKey, prospectId, selectedPrimaryContact]
  );

  const saveUtilityContact = useCallback(
    (contact: Contact) => {
      if (!prospectId) {
        throw new Error("No prospect id is present.");
      }
      if (!selectedUtilityContact?.contactId) {
        throw new Error("No contact id is present for your utility contact.");
      }
      return editContact(
        authKey,
        prospectId,
        selectedUtilityContact?.contactId,
        contact.customerPrimaryPhone,
        contact.customerEmail,
        { role: ROLES.UTILITYBILLCONTACT }
      );
    },
    [authKey, prospectId, selectedUtilityContact]
  );

  const diffInUsageBy = useCallback(
    (keys: (keyof Exclude<typeof originalUsage, null>)[], fieldValues: any) => {
      if (originalUsage) {
        return keys.reduce<typeof keys>(
          (diff, key) =>
            fieldValues[key] !== originalUsage[key] ? diff.concat(key) : diff,
          []
        );
      }
      return [];
    },
    [originalUsage]
  );

  const handleSave = useCallback(
    (voidProposals: boolean | undefined = undefined) => {
      if (!originalUsage) {
        return;
      }
      if (monthlyUsage && prospectId) {
        const formValues = formUtils.getValues();

        const calculatedMonthlyValues =
          usageCadence === "monthly" ? monthlyUsage : calculateDailyUsage();

        const calculatedMonthlyValuesObj = {
          janUsage: calculatedMonthlyValues[0] ?? null,
          febUsage: calculatedMonthlyValues[1] ?? null,
          marUsage: calculatedMonthlyValues[2] ?? null,
          aprUsage: calculatedMonthlyValues[3] ?? null,
          mayUsage: calculatedMonthlyValues[4] ?? null,
          junUsage: calculatedMonthlyValues[5] ?? null,
          julUsage: calculatedMonthlyValues[6] ?? null,
          augUsage: calculatedMonthlyValues[7] ?? null,
          sepUsage: calculatedMonthlyValues[8] ?? null,
          octUsage: calculatedMonthlyValues[9] ?? null,
          novUsage: calculatedMonthlyValues[10] ?? null,
          decUsage: calculatedMonthlyValues[11] ?? null,
        };

        const monthlyUsageChanged = diffInUsageBy(
          [
            "janUsage",
            "febUsage",
            "marUsage",
            "aprUsage",
            "mayUsage",
            "junUsage",
            "julUsage",
            "augUsage",
            "sepUsage",
            "octUsage",
            "novUsage",
            "decUsage",
          ],
          calculatedMonthlyValuesObj
        );

        const formValuesChanged = diffInUsageBy(
          [
            "accountNumber",
            "meterNumber",
            "utilityBillDate",
            "utilityServiceAddress",
            "rateSchedule",
            "utilityCompany",
          ],
          formValues
        );

        const prospectBody: SplatProspectUpdate = {
          ...calculatedMonthlyValuesObj,
          ...(voidProposals ? { voidProposals } : undefined),
          ...formValues,
        };

        if (
          formValues.utilityServiceAddress !==
          originalUsage.utilityServiceAddress
        ) {
          prospectBody.partialOverrideAddressStandardization = true;
        }

        setIsPatchLoading(true);

        const attachmentsPromiseArr: Promise<any>[] = newAttachments.map(
          (attachment: Attachment) =>
            postAttachment(authKey, prospectId, attachment)
        );

        const usagePromise =
          monthlyUsageChanged.length + formValuesChanged.length
            ? patchUsage(authKey, prospectId, prospectBody).then((result) =>
                subscribeToAsyncRequest(authKey, result.asyncRequestId)
              )
            : new Promise((res) => res(true));

        const contactPromisesArr: Promise<any>[] = [];
        if (selectedPrimaryContact && primaryContactChanged) {
          contactPromisesArr.push(saveContactPrimary(selectedPrimaryContact));
        }
        if (selectedUtilityContact && utilityContactChanged) {
          contactPromisesArr.push(saveUtilityContact(selectedUtilityContact));
        }

        Promise.all([
          usagePromise,
          ...attachmentsPromiseArr,
          ...contactPromisesArr,
        ])
          .then(async () => {
            refetch();

            const updatedFields = [];
            let key: keyof UtilityInfoState;
            for (key in formValues) {
              if (determineUpdatedValue(formValues[key], originalUsage[key])) {
                updatedFields.push(key);
              }
            }
            for (let i = 0; i < monthKeys.length; i++) {
              let monthKey = monthKeys[i];
              if (
                determineUpdatedValue(
                  calculatedMonthlyValues![i],
                  originalUsage[monthKey as keyof SplatProspectResponse]
                )
              ) {
                updatedFields.push(monthKey);
              }
            }
            salesforceUpdated(updatedFields);
            setIsPatchLoading(false);
            navigateToConfirm();
          })
          .catch((err) => {
            setShowVoidProposalModal(false);
            setIsPatchLoading(false);
            setPatchErrorMessage(err);
          });
      }
    },
    [
      originalUsage,
      monthlyUsage,
      prospectId,
      formUtils,
      usageCadence,
      calculateDailyUsage,
      newAttachments,
      authKey,
      selectedPrimaryContact,
      selectedUtilityContact,
      saveContactPrimary,
      saveUtilityContact,
      refetch,
      salesforceUpdated,
      navigateToConfirm,
      primaryContactChanged,
      utilityContactChanged,
      diffInUsageBy,
    ]
  );

  const checkIfVoidProposal = useCallback((): true | void => {
    if (!originalUsage || !selectedPrimaryContact) {
      return;
    }

    if (
      primaryContactChanged &&
      (isEmptyValue(selectedPrimaryContact.customerEmail) ||
        isEmptyValue(selectedPrimaryContact.customerPrimaryPhone))
    ) {
      setPatchErrorMessage(
        new Error(
          "The Primary Contact selected needs to have both an email and phone number on file."
        )
      );
      return;
    }
    if (
      selectedUtilityContact &&
      utilityContactChanged &&
      (isEmptyValue(selectedUtilityContact.customerEmail) ||
        isEmptyValue(selectedUtilityContact.customerPrimaryPhone))
    ) {
      setPatchErrorMessage(
        new Error(
          "The Utility Contact selected needs to have both an email and phone number on file."
        )
      );
      return;
    }

    if (usageCadence === "daily" && hasOffers) {
      setShowVoidProposalModal(true);
      setModifiedField("Energy Usage");
      return true;
    }

    const getFormData = formUtils.getValues();
    for (const key in getFormData) {
      if (
        key === "accountNumber" ||
        key === "meterNumber" ||
        key === "utilityServiceAddress" ||
        key === "utilityBillDate"
      ) {
        continue;
      }

      if (
        hasOffers &&
        determineUpdatedValue(
          getFormData[key as keyof UtilityInfoState],
          originalUsage[key as keyof UtilityInfoState]
        )
      ) {
        setShowVoidProposalModal(true);
        setModifiedField(key);
        return true;
      }
    }

    for (let i = 0; i < monthKeys.length; i++) {
      let monthKey = monthKeys[i];
      if (
        hasOffers &&
        determineUpdatedValue(
          monthlyUsage![i],
          originalUsage[monthKey as keyof SplatProspectResponse]
        )
      ) {
        setShowVoidProposalModal(true);
        setModifiedField(monthKey);
        return true;
      }
    }

    handleSave();
  }, [
    primaryContactChanged,
    utilityContactChanged,
    selectedPrimaryContact,
    selectedUtilityContact,
    originalUsage,
    usageCadence,
    formUtils,
    handleSave,
    hasOffers,
    monthlyUsage,
  ]);

  const handleCancel = useCallback(() => {
    if (usageContext.isDirty) {
      setShowExitModal(true);
    } else navigateToConfirm();
  }, [navigateToConfirm, usageContext.isDirty]);

  const checkForFormErrors = useCallback(() => {
    return formUtils.handleSubmit(checkIfVoidProposal)();
  }, [checkIfVoidProposal, formUtils]);

  const buttons = useMemo(() => {
    return [
      {
        type: "secondary",
        text: "Close",
        onClick: handleCancel,
      } as HeaderButton,
      {
        type: "primary",
        text: "Save",
        onClick: checkForFormErrors,
      } as HeaderButton,
    ];
  }, [checkForFormErrors, handleCancel]);

  if (usageError || utilityError) {
    return (
      <HeaderContentLayout
        content={
          <ErrorComponent
            context="UsagePage"
            error={usageError ?? utilityError}
          />
        }
        title="There was a Problem."
        description={`Something went wrong. Please try refreshing the page.`}
      />
    );
  }

  if (
    isUsageLoading ||
    isUtilitiesLoading ||
    isPatchLoading ||
    isOfferListLoading
  ) {
    return <LoadingIndicator fullScreen color="black" />;
  }

  return (
    <>
      {!!patchErrorMessage && (
        <ErrorModal
          context="UsagePage"
          error={patchErrorMessage}
          action={"Saving Usage & Utility"}
          onClose={() => setPatchErrorMessage(null)}
        />
      )}
      {showExitModal && usageContext.isDirty && (
        <Modal
          type="warning"
          title="Are you sure you want to exit without saving?"
          onClose={() => setShowExitModal(false)}
          primaryButton={{
            text: "Save & Close",
            onClick: () => handleSave(),
          }}
          secondaryButton={{
            text: "Close",
            onClick: () => navigateToConfirm(),
          }}
        >
          <Typography variant="p">
            All of your unsaved changes will be discarded.
          </Typography>
        </Modal>
      )}
      <HeaderContentLayout
        title="Usage & Utility"
        description="To find your best system, we need to understand your current electricity rate."
        buttons={buttons}
        refSetter={containerRefSetter}
        content={
          <UsagePageContent
            showUtilityBillWarningBanner={
              usageContext.showUtilityBillWarningBanner
            }
            setShowUtilityBillWarningBanner={
              usageContext.setShowUtilityBillWarningBanner
            }
            monthlyUsage={
              usageCadence === "monthly" ? monthlyUsage : dailyUsage
            }
            setMonthlyUsage={
              usageCadence === "monthly" ? setMonthlyUsage : setDailyUsage
            }
            showVoidProposalModal={showVoidProposalModal}
            setShowVoidProposalModal={setShowVoidProposalModal}
            handleSave={handleSave}
            modifiedField={modifiedField}
            utilitiesOptions={utilitiesOptions}
            formUtils={formUtils}
            usageCadence={usageCadence}
            setUsageCadence={setUsageCadence}
            attachments={attachments}
            newAttachments={newAttachments}
            setNewAttachments={setNewAttachments}
            selectedPrimaryContact={selectedPrimaryContact}
            setSelectedPrimaryContact={(contact) => {
              setPrimaryContactChanged(true);
              setSelectedPrimaryContact(contact);
            }}
            selectedUtilityContact={selectedUtilityContact}
            setSelectedUtilityContact={(contact) => {
              setUtilityContactChanged(true);
              setSelectedUtilityContact(contact);
            }}
            refSetter={scrollToRefSetter}
            hasOffers={hasOffers}
          />
        }
      />
    </>
  );
};

export { UsagePage };
