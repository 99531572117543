import camelCase from "lodash/camelCase";
import { CamelCase } from "type-fest";
import type {
  FinancialProduct,
  PricingFieldsPayload,
} from "../getPricingFields.types";
import { FLEX, FREEDOM, FREEDOM_V2 } from "../../../fixtures/pricingModelType";

const isFlex = (payload: PricingFieldsPayload): boolean =>
  payload.unifiedPricingData[
    camelCase(payload.financialProduct) as CamelCase<FinancialProduct>
  ].some((pricePoint) => {
    return pricePoint.pricingModelType === FLEX;
  });

const isFreedom = (payload: PricingFieldsPayload): boolean =>
  payload.unifiedPricingData[
    camelCase(payload.financialProduct) as CamelCase<FinancialProduct>
  ].some((pricePoint) => {
    return [FREEDOM, FREEDOM_V2].includes(pricePoint.pricingModelType);
  });

export { isFlex, isFreedom };
