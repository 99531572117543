import { LeafletHelper } from "components/molecules/LeafletHelper";
import { useSite } from "hooks/useSite";
import { useDesignImages } from "hooks/useDesignImages";
import { useDesign } from "hooks/useDesign";

type DesignRenderProps = {
  prospectId: string | undefined;
  lightmileProjectId: string | undefined;
  zoomToFeatures?: Array<
    | "PARCEL"
    | "ENABLED_ROOFS"
    | "DISABLED_ROOFS"
    | "VERTICAL_OBSTRUCTIONS"
    | "FLUSH_OBSTRUCTIONS"
    | "TREES"
    | "USABLE_ROOF_AREAS"
    | "MODULES"
  >;
};
const DesignRender = ({
  prospectId,
  lightmileProjectId,
  zoomToFeatures = ["MODULES"],
}: DesignRenderProps) => {
  const site = useSite(prospectId);
  const design = useDesign(lightmileProjectId);
  const designImages = useDesignImages(lightmileProjectId);
  return (
    <LeafletHelper
      bgImageUrl={designImages.data?.smallImageUrl}
      design={site?.data ? design?.data : undefined}
      key={`${prospectId}-${lightmileProjectId}`}
      showModules
      site={design?.data ? site?.data : undefined}
      zoomToFeatures={zoomToFeatures}
    />
  );
};

export { DesignRender };
