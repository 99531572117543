import styled from "@emotion/styled/macro";
import { Modal } from "@sunrun/experience-ui-components";
import { VoidingField } from "utils/usageUtils";

interface VoidProposalsConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  modifiedField: VoidingField;
}

const VoidProposalsConfirmationModal: React.FC<
  VoidProposalsConfirmationModalProps
> = ({ onCancel, onConfirm, modifiedField }) => {
  function formatField(modifiedField: VoidingField) {
    // Convert the field names into human-readable labels.
    let modifiedLabel = modifiedField
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .toLowerCase();
    // And capitalize the month names if we're showing a Usage warning.
    if (modifiedLabel.includes("usage")) {
      modifiedLabel =
        modifiedLabel.charAt(0).toUpperCase() + modifiedLabel.slice(1);
    }
    return modifiedLabel;
  }

  let modifiedLabel = formatField(modifiedField);
  return (
    <DQModalStyle>
      <Modal
        title="Void Offers?"
        onClose={onCancel}
        primaryButton={{ text: "No, Go Back", onClick: () => onCancel() }}
        secondaryButton={{ text: "Update", onClick: () => onConfirm() }}
      >
        <p>
          {`Updating ${modifiedLabel} requires voiding existing customer offers.`}
        </p>
      </Modal>
    </DQModalStyle>
  );
};

const DQModalStyle = styled.div`
  display: grid;
  > div {
    @media (max-width: 768px) {
      height: 100% !important;
      padding: 20px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  h3 {
    @media (max-width: 768px) {
      padding: 20px;
      grid-template-columns: repeat(1, 1fr);
    }
    padding-left: 52px;
    padding-right: 52px;
    font-family: "Roobert", sans-serif;
    font-size: 40px;
    font-weight: 400;
    margin: 0px;
    padding-bottom: 20px;
  }
`;

export { VoidProposalsConfirmationModal };
