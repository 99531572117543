import * as fullstory from "@fullstory/browser";
import { getEnv, type Env } from "utils/env";

const ORG_ID: Record<Env, string> = {
  production: "o-1ED2V5-na1",
  staging: "o-1ED2V5-na1",
};

const initFullstory = () => {
  const env = getEnv();
  if (env === "production" || env === "staging") {
    fullstory.init(
      {
        orgId: ORG_ID[env],
        recordOnlyThisIFrame: true,
      },
      ({ sessionUrl }) => {
        console.log(`Fullstory Session: ${sessionUrl}`);
      }
    );
  }
};

const identify = (email: string) => {
  if (fullstory.isInitialized()) {
    fullstory.setUserVars({ email });
  }
};

const sendTrackingEvent = (
  eventName: string,
  properties: { [key: string]: any }
) => {
  if (fullstory.isInitialized()) {
    fullstory.event(eventName, properties);
  }
};

export { identify, initFullstory, sendTrackingEvent };
