import { useState } from "react";
import styled from "@emotion/styled/macro";
import { Typography } from "@sunrun/experience-ui-components";
import { tokens } from "@sunrun/experience-ui-components";
import infoIcon from "../../assets/images/info.svg";
// eslint-disable-next-line import/no-restricted-paths
import { BatteryModal } from "../molecules/BatteryModal";

type ProductTitleWithInfoModalProps = {
  title: string;
};

const ProductTitleWithInfoModal = ({
  title,
}: ProductTitleWithInfoModalProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const onClose = () => setIsOpened(false);

  return (
    <ProductTitleWithInfoContainer>
      <Typography variant="h6" color={tokens.BRAND_HERO_BLUE}>
        {title}
        {title === "Backup" ? (
          <InfoModalButton
            onClick={() => setIsOpened(true)}
            aria-label="battery usage info"
          >
            <InfoModalIcon></InfoModalIcon>
          </InfoModalButton>
        ) : null}
      </Typography>
      {isOpened && <BatteryModal onClose={onClose} />}
    </ProductTitleWithInfoContainer>
  );
};

const ProductTitleWithInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const InfoModalButton = styled.button`
  border: none;
  background: none;
`;

const InfoModalIcon = styled.div`
  display: inline-block;
  padding-left: 35px;
  vertical-align: bottom;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: rgba(0, 0, 0, 0) url(${infoIcon}) no-repeat center;
`;

export { ProductTitleWithInfoModal };
