import { FC, useState } from "react";
import styled from "@emotion/styled/macro";
import {
  Button,
  Card,
  CardHeader,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import { RadioCard } from "../atoms/RadioCard";
import { CheckoutFooter } from "../molecules/CheckoutFooter";
import { DepositPaymentIFrame } from "../organisms/DepositPaymentIFrame";
import {
  Outbound_PaymentSuccess_MessagePayload,
  PayMethod,
} from "../../types/payments-ui-form";
import { SuccessfulPayment } from "../molecules/SuccessfulPayment";
import { formatCurrency } from "../../util/constants";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { useAppSelector } from "store";
import { useContactsData, useUsageData } from "utils/swrHooks";

const CheckoutDepositPage: FC = () => {
  const [paymentMethod, setPaymentMethod] = useState<PayMethod>(PayMethod.CARD);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [paymentResults, setPaymentResults] =
    useState<Outbound_PaymentSuccess_MessagePayload>();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { offer, navigateToNextTask, prospectId, currentTask } =
    useCheckoutContext();
  const { prospect } = useUsageData(prospectId);
  const { contacts } = useContactsData(prospectId as string, authKey);

  const dueAtSigning = offer?.financial.summary.dueAtSigning;

  const PaymentSelectionForm = (
    <StyledGrid>
      <Typography size={tokens.FONT_SIZE_2} color={tokens.BRAND_HEROBLUE}>
        Payment Amount
      </Typography>
      <AmountTypography
        size={tokens.FONT_SIZE_6}
        color={tokens.BRAND_HEROBLUE}
        data-testid="deposit-page-payment-amount"
      >
        {formatCurrency(dueAtSigning ?? 0)}
      </AmountTypography>
      <Typography size={tokens.FONT_SIZE_2} color={tokens.BRAND_HEROBLUE}>
        Payment Method
      </Typography>
      {[
        {
          label: "Credit Card",
          subLabel: "Visa, Mastercard, or American Express",
          value: "card",
        },
        {
          label: "Checking",
          subLabel: "One-time bank transfer",
          value: "echeck",
        },
        {
          label: "Savings",
          subLabel: "One-time bank transfer",
          value: "savings",
        },
      ].map((option: { label: string; value: string; subLabel: string }) => (
        <RadioCard
          key={option.label}
          data-testid={option.label}
          labelElement={
            <>
              <Typography
                size={tokens.FONT_SIZE_4}
                color={tokens.BRAND_HEROBLUE}
              >
                {option.label}
              </Typography>
              <Typography
                size={tokens.FONT_SIZE_4}
                color={tokens.TINTS_OFF_WHITE_20}
              >
                {option.subLabel}
              </Typography>
            </>
          }
          checked={paymentMethod === option.value}
          handleChange={() => setPaymentMethod(option.value as PayMethod)}
        />
      ))}
      <Typography size={tokens.FONT_SIZE_2} color={tokens.TINTS_OFF_WHITE_20}>
        Please allow up to 5 business days to see a change reflected in your
        total balance due.
        <br />
        No further action is needed if you’ve already made a payment.
      </Typography>
    </StyledGrid>
  );

  if (currentTask?.isComplete) {
    return (
      <>
        <SuccessfulPayment
          autoPay={false}
          lastFour={paymentResults?.lastFour ?? ""}
          paymentType={paymentMethod}
          paymentAmount={formatCurrency(500)}
          customerEmail={prospect?.customerEmail}
        />
        <CheckoutFooter buttonText="Continue" onClick={navigateToNextTask} />
      </>
    );
  }

  return (
    <>
      <GridLayoutDiv>
        {!showPaymentPage && (
          <Card>
            <CardHeader>
              <Typography
                size={tokens.FONT_SIZE_4}
                color={tokens.BRAND_HEROBLUE}
              >
                Payment Details
              </Typography>
            </CardHeader>
            {PaymentSelectionForm}
            <FlexEnd>
              <Button
                onClick={() => {
                  setShowPaymentPage(true);
                }}
                data-testid="payments-deposit-start"
              >
                Continue to Payment
              </Button>
            </FlexEnd>
          </Card>
        )}
        {showPaymentPage && contacts && prospect && (
          <DepositPaymentIFrame
            paymentMethod={paymentMethod}
            contacts={contacts || []}
            prospect={prospect}
            cancelPayment={() => setShowPaymentPage(false)}
            paymentResults={paymentResults}
            setPaymentResults={setPaymentResults}
          />
        )}
      </GridLayoutDiv>
      <CheckoutFooter buttonText="Continue" onClick={navigateToNextTask} />
    </>
  );
};

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledGrid = styled.div`
  display: grid;
  gap: 16px;
`;

const GridLayoutDiv = styled.div`
  display: grid;
  grid-row-gap: 32px;
`;

const AmountTypography = styled(Typography)`
  && {
    margin-top: -8px;
  }
`;

export { CheckoutDepositPage };
