import mixpanel from "mixpanel-browser";

const useMixpanelTracker = () => {
  mixpanel.init(
    window.location.host === "offer.sunrun.com"
      ? "1256e56363a5760a3ba1404947fdd808"
      : "5a178955f49947b3b86f18ed4f99e495"
  );
};

const Mixpanel = {
  identify: (id: any) => {
    mixpanel.identify(id);
  },
  alias: (id: any) => {
    mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props);
    },
  },
};

export { useMixpanelTracker, Mixpanel };
