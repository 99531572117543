import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import type { Role } from "@sunrun/experience-ui-components/lib/components/ContactCard";
import { SplatContact, NewSplatContact } from "providers/Types";
import { addContact, editContact, editContactRole } from "services/contacts";
import { useAppSelector } from "store";
import { useContactsData } from "utils/swrHooks";
import { ROLES } from "utils/usageUtils";

interface SplatContactWithRole
  extends Omit<
    SplatContact,
    "creationMethod" | "relationToHomeowner" | "creditStatus"
  > {
  role?: Role;
  roleToUpdate?: Role;
  newRole?: Role;
}

const useContactManagement = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    action?: string;
    error?: Error;
    title?: string;
    retry?: () => void;
  }>();

  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { isContactsLoading, contacts, refetch, contactsError } =
    useContactsData(prospectId!, authKey);
  const [isEditing, setIsEditing] = useState(false);

  const onEditContact = useCallback(
    async (contact: SplatContactWithRole) => {
      const { contactId, customerPrimaryPhone, customerEmail, role, primary } =
        contact;

      setLoading(true);
      setError(undefined);
      try {
        await editContact(
          authKey,
          prospectId!,
          contactId,
          customerPrimaryPhone,
          customerEmail,
          {
            role,
            ...(role === ROLES.HOMEOWNER && { primary }),
          }
        );
        await refetch();
      } catch (error) {
        setError({
          title: "Updating contact failed",
          action: "updating contact",
          error: error as Error,
          retry: () => onEditContact(contact),
        });
      } finally {
        setLoading(false);
        return contact;
      }
    },
    [authKey, prospectId, refetch]
  );

  const onEditContactRole = useCallback(
    async (contact: SplatContactWithRole) => {
      const {
        contactId,
        customerPrimaryPhone,
        customerEmail,
        roleToUpdate,
        newRole,
      } = contact;

      setLoading(true);
      setError(undefined);
      try {
        await editContactRole(
          authKey,
          prospectId!,
          contactId,
          customerPrimaryPhone,
          customerEmail,
          {
            roleToUpdate,
            newRole,
          }
        );
        await refetch();
      } catch (error) {
        setError({
          title: "Updating contact failed",
          action: "updating contact",
          error: error as Error,
          retry: () => onEditContactRole(contact),
        });
      } finally {
        setLoading(false);
        return contact;
      }
    },
    [authKey, prospectId, refetch]
  );

  const onAddContact = useCallback(
    async (contact: NewSplatContact) => {
      setLoading(true);
      setError(undefined);
      try {
        const responseContact = await addContact(authKey, prospectId!, contact);
        await refetch();
        return responseContact;
      } catch (error) {
        setError({
          title: "Updating contact failed",
          action: "updating contact",
          error: error as Error,
          retry: () => onAddContact(contact),
        });
      } finally {
        setLoading(false);
      }
    },
    [authKey, prospectId, refetch]
  );

  return {
    contactList: contacts,
    isContactsLoading,
    contactsError,
    onEditContact,
    onEditContactRole,
    onAddContact,
    isEditing,
    setIsEditing,
    loading,
    refetch,
    error,
    setError,
  };
};

export { useContactManagement };
export type { SplatContactWithRole };
