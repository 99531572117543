import type useSWR from "swr";
import React, { Children, PropsWithChildren, ReactNode } from "react";

const isInstanceOf = (
  child: React.ReactNode,
  targetClass: React.FC<any>
): child is React.ReactElement => {
  return (
    typeof child === "object" &&
    child !== null &&
    "type" in child &&
    child.type === targetClass
  );
};

type State = "NoData" | "Loading" | "Data" | "Error";

type SwrSuspenseProps = ReturnType<typeof useSWR>;
/**
 * @summary
 * A structured way to handle common states of SWR responses.
 *
 * @example
 * ```tsx
 * <SwrSuspense data={myData} isLoading={isLoading} error={myError}>
 *   <SwrSuspense.Error><MyLittleError error={error} /></SwrSuspense.Error>
 * </SwrSuspense>
 * ```
 * ```tsx
 * <SwrSuspense data={myData} isLoading={isLoading} error={myError}>
 *   <SwrSuspense.NoData>No data. Try a different filter.</SwrSuspense.NoData>
 *   <SwrSuspense.Loading>Loading!</SwrSuspense.Loading>
 *   <SwrSuspense.Data>
 *     {JSON.stringify(myData)}
 *   </SwrSuspense.Data>
 *   <SwrSuspense.Error><MyLittleError error={error} /></SwrSuspense.Error>
 * </SwrSuspense>
 * ```
 * ```tsx
 * <SwrSuspense data={myData} isLoading={isLoading} error={myError}>
 *   <SwrSuspense.Multi states={["Loading", "Data"]}>Column Header</SwrSuspense.Multi>
 * </SwrSuspense>
 * ```
 */
const SwrSuspense: React.FC<
  PropsWithChildren<Pick<SwrSuspenseProps, "data" | "isLoading" | "error">>
> & {
  NoData: React.FC<PropsWithChildren<unknown>>;
  Loading: React.FC<PropsWithChildren<unknown>>;
  Data: React.FC<PropsWithChildren<unknown>>;
  Error: React.FC<PropsWithChildren<unknown>>;
  Multi: React.FC<PropsWithChildren<{ states: Array<State> }>>;
} = ({ children, data, isLoading, error }) => {
  let filterPredicate: (c: ReactNode) => boolean = (c) =>
    isInstanceOf(c, SwrSuspense.NoData) ||
    (isInstanceOf(c, SwrSuspense.Multi) &&
      c.props?.states?.includes?.("NoData"));
  if (error) {
    filterPredicate = (c) =>
      isInstanceOf(c, SwrSuspense.Error) ||
      (isInstanceOf(c, SwrSuspense.Multi) &&
        c.props?.states?.includes?.("Error"));
  } else if (data) {
    filterPredicate = (c) =>
      isInstanceOf(c, SwrSuspense.Data) ||
      (isInstanceOf(c, SwrSuspense.Multi) &&
        c.props?.states?.includes?.("Data"));
  } else if (isLoading) {
    filterPredicate = (c) =>
      isInstanceOf(c, SwrSuspense.Loading) ||
      (isInstanceOf(c, SwrSuspense.Multi) &&
        c.props?.states?.includes?.("Loading"));
  }
  return <>{Children.toArray(children).filter(filterPredicate)}</>;
};

const RenderChildren: () => React.FC<PropsWithChildren> =
  () =>
  ({ children }) => {
    return <>{children}</>;
  };
SwrSuspense.Data = RenderChildren();
SwrSuspense.Error = RenderChildren();
SwrSuspense.Loading = RenderChildren();
SwrSuspense.NoData = RenderChildren();
SwrSuspense.Multi = RenderChildren();

export { SwrSuspense };
