import { forwardRef } from "react";
import qs from "qs";
import { IFrameAtom } from "components/atoms/IFrameAtom";
import { Env, getEnv } from "utils/env";
import { getQueryStringParams } from "utils/queryString";

const IHD_BASE_URL: Record<Env, string> = {
  staging: "https://ihd.devdesigntools.sunrun.com",
  production: "https://ihd.designtools.sunrun.com",
};

const getIhdBaseUrl = (env: Env) => {
  const override =
    env !== "production"
      ? (getQueryStringParams()?.ihdBaseUrlOverride as string)
      : undefined;
  return override ?? IHD_BASE_URL[env];
};

const HOST: Record<string, string> = {
  OFFER_BUILDER: "OFFER_BUILDER",
  LIGHTMILE_OFFER_BUILDER: "LIGHTMILE_OFFER_BUILDER",
};

type InHomeDesignProps = {
  offerId: string;
  prospectId: string;
  copyDesignOrProposalId?: string;
};
const InHomeDesign = forwardRef<HTMLIFrameElement, InHomeDesignProps>(
  ({ offerId, prospectId, copyDesignOrProposalId }, ref) => {
    const env = getEnv();
    /*
     * NOTE:
     *   IHD can be replaced via query strings for testing purposes. Leaving these
     *   values off of the URL will result in the staging or production IHD
     *   instance.
     *
     *   Available overrides:
     *   - "ihdBaseUrlOverride" (e.g., "https://pr1522.dvo2nnfm57yrv.amplifyapp.com")
     *   - "ihdEnvOverride" (e.g., "pr1522")
     *   - "ihdApiUrl" (e.g., "https://c3znw6oea5amflsnfozvojapoe.appsync-api.us-west-2.amazonaws.com/graphql")
     *
     *   Here is an example URL to swap out IHD:
     *   - https://staging-offer.sunrun.com/prospect/1296005144/offer/build/01H96CZJVRR8KFCNJ6D27E0TS3
     *       ?ihdBaseUrlOverride=https://pr1522.dvo2nnfm57yrv.amplifyapp.com
     *       &ihdEnvOverride=pr1522
     *       &ihdApiUrl=https://c3znw6oea5amflsnfozvojapoe.appsync-api.us-west-2.amazonaws.com/graphql
     */
    const ihdEnvOverride =
      env !== "production" ? getQueryStringParams()?.ihdEnvOverride : undefined;
    const ihdApiUrl =
      env !== "production" ? getQueryStringParams()?.ihdApiUrl : undefined;
    let ihdParams = {};
    try {
      const ihdParamsString = getQueryStringParams()?.ihdParams as string;
      ihdParams = JSON.parse(ihdParamsString);
    } catch {}
    const host = getQueryStringParams()?.host;
    let iHDHost = HOST.OFFER_BUILDER;
    if (host === "LIGHTMILE") {
      iHDHost = HOST.LIGHTMILE_OFFER_BUILDER;
    }
    const queryStrings = {
      host: iHDHost,
      envOverride: ihdEnvOverride,
      apiUrl: ihdApiUrl,
      copyFromDesignId: undefined as string | undefined,
      copyFromProposalId: undefined as string | undefined,
      ...ihdParams,
    };
    if (copyDesignOrProposalId) {
      if (copyDesignOrProposalId?.length > 14) {
        queryStrings.copyFromDesignId = copyDesignOrProposalId;
      } else {
        queryStrings.copyFromProposalId = copyDesignOrProposalId;
      }
    }
    const baseUrl = getIhdBaseUrl(env);
    const src = `${baseUrl}/imports/lightmile/projects/prospects/${prospectId}/offers/${offerId}?${qs.stringify(
      queryStrings
    )}`;
    return (
      <IFrameAtom ref={ref} basePath={src} src={src} title="In Home Design" />
    );
  }
);

export { getIhdBaseUrl, IHD_BASE_URL, InHomeDesign };
