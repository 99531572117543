import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { getEnv } from "utils/env";
import { getAuthToken } from "utils/authUtils";

const storefrontAPIEndpoint =
  getEnv() === "production"
    ? "https://cffkquzgdvat7o3d6wjihmdaui.appsync-api.us-west-2.amazonaws.com/graphql"
    : "https://ujryydofazbhnkvpryhpxj4jsy.appsync-api.us-west-2.amazonaws.com/graphql";

const asyncAuthLink = setContext((request, { headers }) =>
  getAuthToken().then((token) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }))
);

const httpLink = createHttpLink({
  uri: storefrontAPIEndpoint,
});

const graphqlLink = createSubscriptionHandshakeLink(
  {
    url: storefrontAPIEndpoint,
    region: "us-west-2",
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS",
      jwtToken: getAuthToken,
    },
  },
  asyncAuthLink.concat(httpLink)
);

const storeFront = new ApolloClient({
  cache: new InMemoryCache(),
  link: graphqlLink,
});

export { storeFront, getAuthToken };
export { gql } from "@apollo/client";
