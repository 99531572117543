import styled from "@emotion/styled/macro";
import { UseFormReturn } from "react-hook-form";
import { Text, tokens, Typography } from "@sunrun/experience-ui-components";
import { useContext, useEffect } from "react";
import { SplatUtilityResponse } from "providers/Types";
import { Card, CardHeaderDivider } from "components/atoms/Card";
import { UtilityCompanyDropdown } from "components/molecules/UtilityCompanyDropdown";
import { UsageFormContext } from "providers/UsageFormContext";
import { CardActionFooter } from "checkout/components/molecules/CardActionFooter";
import { UtilityInfoState } from "utils/usageUtils";

type UtilityInfoProps = {
  utilitiesOptions?: SplatUtilityResponse[];
  formUtils: UseFormReturn<UtilityInfoState>;
  disabledFields?: (
    | "utilityCompany"
    | "accountNumber"
    | "meterNumber"
    | "utilityServiceAddress"
    | "utilityBillDate"
  )[];
  hiddenFields?: (
    | "utilityCompany"
    | "accountNumber"
    | "meterNumber"
    | "utilityServiceAddress"
    | "utilityBillDate"
  )[];
  actionFooter?: {
    primaryButtonText: string;
    secondaryButtonText?: string;
    primaryButtonTestId: string;
    secondaryButtonTestId?: string;
    primaryButtonFunction: () => void;
    secondaryButtonFunction?: () => void;
    isPrimaryLoading?: boolean;
  };
};

const UtilityInfo = ({
  utilitiesOptions,
  formUtils,
  disabledFields = [],
  hiddenFields = [],
  actionFooter,
}: UtilityInfoProps) => {
  const {
    register,
    formState: { errors },
  } = formUtils;

  const { setIsDirty } = useContext(UsageFormContext);

  useEffect(() => {
    setIsDirty(formUtils.formState.isDirty);
  }, [setIsDirty, formUtils.formState.isDirty]);

  return (
    <Card>
      <header data-testid="utility-info-header">
        <Typography size={tokens.FONT_SIZE_3}>Utility Information</Typography>
      </header>
      <CardHeaderDivider />
      <StyledGridContainer>
        {!hiddenFields.includes("utilityCompany") && (
          <UtilityCompanyDropdown
            formUtils={formUtils}
            utilitiesOptions={utilitiesOptions}
            disableComponent={disabledFields.includes("utilityCompany")}
          />
        )}
        {!hiddenFields.includes("accountNumber") && (
          <Text
            label="Account Number"
            data-testid="utility-info-account-number"
            isError={!!errors?.accountNumber}
            helperText={errors?.accountNumber?.message?.toString()}
            disabled={disabledFields.includes("accountNumber")}
            {...register("accountNumber")}
          />
        )}
        {!hiddenFields.includes("meterNumber") && (
          <Text
            label="Meter Number"
            data-testid="utility-info-meter-number"
            isError={!!errors?.meterNumber}
            helperText={errors?.meterNumber?.message?.toString()}
            disabled={disabledFields.includes("meterNumber")}
            {...register("meterNumber")}
          />
        )}
        {!hiddenFields.includes("utilityServiceAddress") && (
          <div>
            <Text
              label="Utility Service Address"
              data-testid="utility-info-utility-service-address"
              isError={!!errors?.utilityServiceAddress}
              helperText={errors?.utilityServiceAddress?.message?.toString()}
              disabled={disabledFields.includes("utilityServiceAddress")}
              {...register("utilityServiceAddress")}
            />
            <Typography color={tokens.TINTS_OFF_WHITE_20}>
              Must exactly match address on utility bill
            </Typography>
          </div>
        )}
        {!hiddenFields.includes("utilityBillDate") && (
          <div>
            <Text
              data-testid="utility-info-utility-bill-date"
              label="Date of latest utility bill"
              isError={!!errors?.utilityBillDate}
              helperText={errors?.utilityBillDate?.message?.toString()}
              disabled={disabledFields.includes("utilityBillDate")}
              type="date"
              {...register("utilityBillDate")}
            />
            <Typography color={tokens.TINTS_OFF_WHITE_20}>
              Date of the most recent utility bill uploaded
            </Typography>
          </div>
        )}
      </StyledGridContainer>
      {actionFooter && (
        <CardActionFooter
          primaryButtonText={actionFooter.primaryButtonText}
          secondaryButtonText={actionFooter.secondaryButtonText}
          primaryButtonFunction={actionFooter.primaryButtonFunction}
          secondaryButtonFunction={actionFooter.secondaryButtonFunction}
          isPrimaryLoading={actionFooter.isPrimaryLoading}
          primaryButtonTestId={actionFooter.primaryButtonTestId}
          secondaryButtonTestId={actionFooter.secondaryButtonTestId}
        />
      )}
    </Card>
  );
};

const StyledGridContainer = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 32px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
  }
`;

export { UtilityInfo };
