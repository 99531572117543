import axios from "axios";
import jwtDecode from "jwt-decode";
import { COGNITO_CONFIG } from "cognitoConfig";
import { CognitoToken } from "slices/authSlice";
import { getEnv } from "utils/env";

const STORAGE_KEY = "cognitoTokenData";

function getCognitoToken() {
  const tokenDataString = localStorage.getItem(STORAGE_KEY);
  if (!tokenDataString) {
    return null;
  }
  const tokenData: CognitoToken = JSON.parse(tokenDataString);
  if (
    tokenData &&
    tokenData.token &&
    tokenData.token.length > 0 &&
    Date.now() < tokenData.expiration
  ) {
    return tokenData;
  }
  return null;
}

function validateCognitoToken() {
  const tokenDataString = localStorage.getItem(STORAGE_KEY);
  if (!tokenDataString) {
    return false;
  }
  const tokenData: CognitoToken = JSON.parse(tokenDataString);
  if (tokenData.expiration < Date.now()) {
    return false;
  }
  return true;
}

function setCognitoToken(tokenData: CognitoToken) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(tokenData));
}

async function refreshCognitoToken() {
  const tokenDataString = localStorage.getItem(STORAGE_KEY);
  if (!tokenDataString) {
    return false;
  }
  const tokenData = JSON.parse(tokenDataString);
  if (!tokenData || !tokenData.refreshToken) {
    return false;
  }
  const env = getEnv();
  const urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append(
    "client_id",
    COGNITO_CONFIG[env].cognitoConfig.oauth.clientId
  );
  urlencoded.append("refresh_token", tokenData.refreshToken);
  const response = await axios.post<{ id_token: string; expires_in: number }>(
    `https://${COGNITO_CONFIG[env].cognitoConfig.oauth.domain}/oauth2/token`,
    urlencoded,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  const tokenResponse = response.data;
  return {
    token: tokenResponse.id_token,
    expiration: Date.now() + tokenResponse.expires_in * 1000,
    refreshToken: tokenData.refreshToken,
  };
}

function getUserInfo(tokenData: CognitoToken): { email: string } | undefined {
  if (!tokenData) {
    return undefined;
  }
  return jwtDecode(tokenData.token) as any;
}

export type { CognitoToken };
export {
  getCognitoToken,
  getUserInfo,
  refreshCognitoToken,
  setCognitoToken,
  validateCognitoToken,
};
