const FeatureFlags = {
  CHECKOUT_V2: "sales-exp-checkout-v2",
  OFFER_EV_SPAN: "sales-exp-offer-ev-span",
  USE_HQS_LINK: "sales-use-hqs-url",
  UPDATED_DISCLAIMERS: "sales-exp-updated-disclaimers",
  REQUIRE_HQS: "sales-exp-hqs-required",
  AUTOPAY_WARNING: "sales-exp-autopay-warning",
  CAP_AUTOPAY: "sales-exp-cap-autopay-requirement",
  IL_IPA_EMAIL_ENABLED: "send-il-ipa-email",
};

export { FeatureFlags };
