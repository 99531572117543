import { useState } from "react";
import { API } from "aws-amplify";
import { orderBy } from "lodash";
import { SplatCheckoutDocument } from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { useAppSelector } from "store";
import { openPDFInNewTab } from "utils/fileUtils";

type UseCheckDocViewing = {
  viewDocLoading: boolean;
  viewDocument: () => void;
  viewDocError?: Error;
  resetError: () => void;
};

const findMostRecentAttachment = (
  attachments: SplatCheckoutDocument[]
): SplatCheckoutDocument => {
  return orderBy(attachments, ["lastModifiedDate"], ["desc"])[0];
};

const useCheckoutDocViewing = (
  prospectId: string,
  proposalId: string,
  documentType: string
): UseCheckDocViewing => {
  const hybridToken = useAppSelector((state) => state?.auth?.hybridToken);
  const [isLoading, setIsLoading] = useState(false);
  const [viewDocError, setViewDocError] = useState<Error>();

  const CHECKOUT_ATTACHMENTS_URL = `/prospect/${prospectId}/proposals/${proposalId}/attachments?documentType=${documentType}`;

  const viewDocument = async () => {
    setIsLoading(true);
    setViewDocError(undefined);
    try {
      const docVersions: SplatCheckoutDocument[] = await API.get(
        "OfferExpApi",
        CHECKOUT_ATTACHMENTS_URL,
        {
          headers: {
            Authorization: `Bearer ${hybridToken}`,
          },
        }
      );
      const recentDocument = findMostRecentAttachment(docVersions);
      if (!recentDocument) {
        // Console error to help with testing
        console.error("No attachments found.");
        throw new Error("No attachments found.");
      }
      await openPDFInNewTab(recentDocument.url);
    } catch (e) {
      setViewDocError(
        new Error("Failed to view checkout attachment", { cause: e })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    viewDocLoading: isLoading,
    viewDocError,
    viewDocument,
    resetError: () => {
      setViewDocError(undefined);
    },
  } as const;
};

export { useCheckoutDocViewing };
