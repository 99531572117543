import styled from "@emotion/styled/macro";
import { useCallback, useState } from "react";
import { publish } from "utils/messages";

const IntegrationDemoPage = () => {
  const sendExit = useCallback(() => {
    publish.exit();
  }, []);
  const [proposalId, setProposalId] = useState("");
  const sendCheckout = useCallback(() => {
    publish.checkout(proposalId);
  }, [proposalId]);
  const [offerId, setOfferId] = useState("");
  const sendReview = useCallback(() => {
    publish.review(offerId);
  }, [offerId]);
  const [url, setUrl] = useState("");
  const sendChangeUrl = useCallback(() => {
    publish.changeUrl(url, false);
  }, [url]);

  return (
    <BasicLayout>
      <div>
        This demo page is to test the behavior of the containing application:
      </div>
      <div>
        Send exit command:
        <button data-testid="send-exit-button" onClick={sendExit}>
          Send
        </button>
      </div>
      <div>
        Send checkout command:
        <input
          data-testid="send-checkout-command-input"
          value={proposalId}
          onChange={(e) => setProposalId(e.target.value)}
        />
        <button
          data-testid="send-checkout-command-button"
          onClick={sendCheckout}
        >
          Send
        </button>
      </div>
      <div>
        Send review command:
        <input
          data-testid="send-review-command-input"
          value={offerId}
          onChange={(e) => setOfferId(e.target.value)}
        />
        <button data-testid="send-review-command-button" onClick={sendReview}>
          Send
        </button>
      </div>
      <div>
        Send change url command:
        <input
          data-testid="send-change-url-command-input"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button
          data-testid="send-change-url-command-button"
          onClick={sendChangeUrl}
        >
          Send
        </button>
      </div>
    </BasicLayout>
  );
};

const BasicLayout = styled.div`
  margin: 2rem;
`;

export { IntegrationDemoPage };
