import debounce from "lodash/debounce";
import memo from "lodash/memoize";
import { useEffect } from "react";
import { CognitoToken } from "services/cognitoService";

type Source =
  | "Legacy Splat"
  | "Offer Experience"
  | "IHD"
  | "Offer Experience Standalone";
type TokenType = "IHD" | "HybridAuth" | "Proposal";

// Messages
type MessageCommandGenerateDesigns = {
  type: "COMMAND:GENERATE_DESIGNS";
  source: Source;
};

type MessageEventGenerateDesigns = {
  type: "EVENT:GENERATE_DESIGNS";
  source: Source;
  payload: {};
};

type MessageEventReady = {
  type: "EVENT:READY";
  source: Source;
};

type MessageEventBootstrap = {
  type: "EVENT:BOOTSTRAP";
  source: Source;
  payload: {
    baseUrl: string;
  };
};

type MessageExit = {
  type: "COMMAND:EXIT";
  source: Source;
};

type MessageQueryAuthToken = {
  type: "QUERY:AUTH_TOKEN";
  source: Source;
  payload: {
    type: TokenType;
  };
};

type CognitoTokenPayload = {
  token: CognitoToken;
  type: "IHD";
};

type HybridTokenPayload = {
  identity: string;
  token: string;
  type: "HybridAuth";
};

type ProposalTokenPayload = {
  token: string;
  type: "Proposal";
};

type IHDTokenPayload = {
  token: CognitoToken;
  type: "IHD";
};

type TokenPayload = HybridTokenPayload | IHDTokenPayload | ProposalTokenPayload;

type MessageOnAuthToken = {
  type: "EVENT:AUTH_TOKEN";
  source: Source;
  payload: TokenPayload | CognitoTokenPayload;
};

type MessageReloadMFE = {
  type: "COMMAND:RELOAD_MFE";
  source: Source;
};

type MessageChangeURL = {
  type: "COMMAND:CHANGE_URL";
  source: Source;
  payload: {
    url: string;
    hash?: string;
    replace?: boolean;
  };
};

type MessageCheckout = {
  type: "COMMAND:NAVIGATE";
  source: Source;
  payload: {
    to: "CHECKOUT";
    proposalId: string;
  };
};

type MessageReview = {
  type: "COMMAND:NAVIGATE";
  source: Source;
  payload: {
    to: "REVIEW";
    offerId: string;
  };
};

type MessageFinished = {
  type: "EVENT:FINISHED";
  source: Source;
};

type Message =
  | MessageCommandGenerateDesigns
  | MessageEventGenerateDesigns
  | MessageEventReady
  | MessageEventBootstrap
  | MessageExit
  | MessageQueryAuthToken
  | MessageOnAuthToken
  | MessageChangeURL
  | MessageCheckout
  | MessageReview
  | MessageFinished
  | MessageReloadMFE;

type Subscriber = {
  id: string;
  action: (action: Message) => void;
};

let subscribers: Subscriber[] = [];

const subscribe = (action: Subscriber["action"]) => {
  const id = Math.random().toString(36);
  subscribers.push({ id, action });
  return () => {
    subscribers = subscribers.filter((subscriber) => subscriber.id !== id);
  };
};

const useSubscribe = (action: Subscriber["action"]) => {
  useEffect(() => {
    return subscribe(action);
  });
};

let baseUrl = "";

const getBaseUrl = (): string => baseUrl;
const setBaseUrl = (url: string) => {
  baseUrl = url;
};

window.addEventListener<Message | any>("message", (event) => {
  const message: Message = event.data;
  // Ignore events from ourself - with the exception of the "standalone" authentication
  // NOTE: localhost is excluded for convenience to assist in local development
  if (
    event.origin === window.origin &&
    !event.origin.includes("localhost") &&
    message.source !== "Offer Experience Standalone"
  ) {
    return;
  }
  if (!shouldHandleMessage(message)) {
    return;
  }
  logMessage(message);
  subscribers.forEach((subscriber) => subscriber.action(message));
});

// Many things use postMessage to communicate, such as Redux and React devtools - this filters to messages that Offer Experience should respond to
const shouldHandleMessage = (message: any) => {
  if (!message || !message?.type) {
    return false;
  }
  switch (message.type as Message["type"]) {
    case "EVENT:AUTH_TOKEN":
    case "EVENT:READY":
    case "EVENT:GENERATE_DESIGNS":
    case "EVENT:BOOTSTRAP":
    case "QUERY:AUTH_TOKEN":
    case "COMMAND:CHANGE_URL":
      return true;
    default:
      return false;
  }
};

const publishMessage = (message: Message) => {
  logMessage(message);
  // NOTE: If there is no host, do nothing
  if (window === window.parent) return;
  window.parent.postMessage(message, "*");
};

const logMessage = (message: Message) => {
  if ("source" in message && message.source === "IHD") return;
  switch (message.type) {
    case "EVENT:AUTH_TOKEN":
    case "EVENT:READY":
    case "EVENT:BOOTSTRAP":
    case "EVENT:GENERATE_DESIGNS":
    case "COMMAND:EXIT":
    case "QUERY:AUTH_TOKEN":
    case "COMMAND:GENERATE_DESIGNS":
    case "COMMAND:CHANGE_URL":
    case "COMMAND:NAVIGATE":
    case "COMMAND:RELOAD_MFE":
    case "EVENT:FINISHED":
      // eslint-disable-next-line no-console
      console.log(
        "📬",
        message.source,
        message.type,
        "payload" in message ? message.payload : undefined
      );
      break;
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = message;
    }
  }
};

const publish = {
  reloadMFE: () => {
    const message: MessageReloadMFE = {
      source: "Offer Experience",
      type: "COMMAND:RELOAD_MFE",
    };
    publishMessage(message);
  },
  changeUrl: (url: string, replace: boolean, hash?: string) => {
    if (!baseUrl) {
      console.error("Cannot change url - no baseUrl set");
      return;
    }
    const message: MessageChangeURL = {
      source: "Offer Experience",
      type: "COMMAND:CHANGE_URL",
      payload: {
        url: baseUrl + url,
        hash,
        replace,
      },
    };
    publishMessage(message);
  },
  checkout: (proposalId: string) => {
    const message: MessageCheckout = {
      source: "Offer Experience",
      type: "COMMAND:NAVIGATE",
      payload: {
        to: "CHECKOUT",
        proposalId,
      },
    };
    publishMessage(message);
  },
  eventReady: memo(() => {
    const message: MessageEventReady = {
      source: "Offer Experience",
      type: "EVENT:READY",
    };
    publishMessage(message);
  }),
  exit: () => {
    const message: MessageExit = {
      source: "Offer Experience",
      type: "COMMAND:EXIT",
    };
    publishMessage(message);
  },
  queryCognitoToken: debounce(
    () => {
      const message: MessageQueryAuthToken = {
        source: "Offer Experience",
        type: "QUERY:AUTH_TOKEN",
        payload: {
          type: "IHD",
        },
      };
      publishMessage(message);
    },
    5000,
    { leading: true, trailing: false }
  ),
  queryHybridToken: debounce(
    () => {
      const message: MessageQueryAuthToken = {
        source: "Offer Experience",
        type: "QUERY:AUTH_TOKEN",
        payload: {
          type: "HybridAuth",
        },
      };
      publishMessage(message);
    },
    5000,
    { leading: true, trailing: false }
  ),
  review: (offerId: string) => {
    const message: MessageReview = {
      source: "Offer Experience",
      type: "COMMAND:NAVIGATE",
      payload: {
        to: "REVIEW",
        offerId,
      },
    };
    publishMessage(message);
  },
  finished: () => {
    const message: MessageFinished = {
      source: "Offer Experience",
      type: "EVENT:FINISHED",
    };
    publishMessage(message);
  },
  message: (message: Message) => {
    publishMessage(message);
  },
};

export type { Message };
export { useSubscribe, publish, getBaseUrl, setBaseUrl };
