import { FC, useEffect, useState } from "react";
import {
  Banner,
  BannerTypes,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { useParams } from "react-router-dom";
import {
  SplatContact,
  SplatLoan,
  SplatLoanStatus,
} from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import {
  Card,
  CardContent,
  CardHeader,
  CardSectionDivider,
} from "../molecules/Card";
import { StatusPill } from "../atoms/StatusPill";
import { SlimContactCard } from "../molecules/SlimContactCard";
import { ContactStatusNotification } from "../atoms/ContactStatusNotification";
import { CheckoutFooter } from "../molecules/CheckoutFooter";
import { ApplyForLoanCard } from "../molecules/ApplyForLoanCard";
import { StatusMessage } from "../../util/constants";
import { useAppSelector } from "store";
import { useContactsData } from "utils/swrHooks";
import { formatNumber } from "utils/formatHelpers";
import { useLoanApplicationUrl, useLoans } from "checkout/hooks/useCheckoutSwr";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";

const DECLINED_LOAN_TITLE =
  "We need a loan approved to continue. Please try applying for a new loan.";

const APPLY_FOR_ANOTHER_LOAN_CTA = "Apply for another loan";

const LoanApprovalTaskView: FC = () => {
  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { proposalId, currentTask, navigateToNextTask, refreshTasks } =
    useCheckoutContext();
  const { loans, loansError, isLoansLoading, refetchLoans, isValidatingLoans } =
    useLoans(prospectId, authKey, true);
  const { contacts, contactsError, isContactsLoading } = useContactsData(
    prospectId as string,
    authKey
  );

  const [showDeclinedLoanBanner, setShowDeclinedLoanBanner] = useState(false);
  const [showApply, setShowApply] = useState(false);

  const { loanUrlError } = useLoanApplicationUrl(
    prospectId,
    proposalId,
    authKey
  );

  const LoanStatusMap: Record<SplatLoanStatus, string> = {
    Approved: StatusMessage.SUCCESS,
    Pending: StatusMessage.INFO,
    Declined: StatusMessage.FAILURE,
    Stipped: StatusMessage.WARNING,
  };
  const getStatusMessage = (status: SplatLoanStatus, approvedValue: number) => {
    if (status === "Declined") {
      return "Loan Not Approved";
    }
    if (status === "Stipped") {
      return "Stipped";
    }
    if (status === "Pending") {
      return "Waiting for Approval";
    }
    return `Approved for $${formatNumber(approvedValue)}`;
  };
  const findContactById = (id: string) => {
    return contacts?.find((contact: SplatContact) => contact.contactId === id);
  };
  const findSecondaryContact = (loan: SplatLoan): SplatContact | undefined => {
    return loan.Secondary_Loan_Signer__c
      ? findContactById(loan.Secondary_Loan_Signer__c)
      : undefined;
  };

  useEffect(() => {
    let failedLoansNum = loans?.reduce(
      (accumulator, loan) =>
        loan.Credit_Results__c === "Declined" ? accumulator + 1 : accumulator,
      0
    );
    if (failedLoansNum) {
      setShowDeclinedLoanBanner(failedLoansNum === loans?.length);
    }
  }, [loans]);

  const currentTaskComplete = currentTask?.isComplete;

  if (loansError || contactsError || loanUrlError) {
    return (
      <ErrorComponent
        context="LoanApprovalTaskView"
        error={loansError ?? contactsError}
      />
    );
  }

  if (isLoansLoading || isContactsLoading || !loans || !contacts) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <GridLayoutDiv>
        <div>
          <Typography>
            A credit application is required to go forward with a loan
            agreement.
          </Typography>
          <br />
          <Typography>
            It may take several minutes to update.{" "}
            <InlineLink
              onClick={() => {
                setShowApply(false);
                refetchLoans();
                refreshTasks();
              }}
            >
              Please refresh this page
            </InlineLink>
            {isValidatingLoans && (
              <LoadingIndicator
                color="black"
                height={15}
                width={15}
                showLoadingMessage={false}
                inline
              />
            )}
          </Typography>
        </div>
        {loans.length === 0 && !currentTaskComplete && <ApplyForLoanCard />}
        {loans.length > 0 && (
          <>
            <Card>
              <CardHeader>
                <Typography
                  size={tokens.FONT_SIZE_3}
                  color={tokens.BRAND_HEROBLUE}
                >
                  Existing Loans
                </Typography>
              </CardHeader>
              <CardSectionDivider />
              <CardContent>
                {loans.map((loan: SplatLoan) => (
                  <SlimContactCard
                    contact={findContactById(loan.Id) ?? contacts[0]}
                    secondaryContact={findSecondaryContact(loan)}
                    label="Borrowers"
                    key={loan.Id}
                  >
                    <StatusPill
                      label={getStatusMessage(
                        loan.Credit_Results__c ?? "Pending",
                        loan.Pre_Approved_Amt__c ?? 0
                      )}
                      status={
                        LoanStatusMap[loan.Credit_Results__c ?? "Pending"]
                      }
                    />
                  </SlimContactCard>
                ))}
                {loans.find((loan) => loan.Credit_Results__c === "Stipped") && (
                  <ContactStatusNotification
                    status="Warning"
                    message="Loan approval returned with stipulations. Please have the
                  customer check their email for information from the loan
                  provider. Meanwhile you can continue with the checkout."
                  />
                )}
              </CardContent>
            </Card>
            {!currentTaskComplete && showApply && <ApplyForLoanCard />}
            {showDeclinedLoanBanner && !showApply && (
              <DeclinedLoanBanner
                bannerType={BannerTypes.ErrorLight}
                showClose={false}
                show={true}
                title={DECLINED_LOAN_TITLE}
                primaryAction={{
                  action: () => setShowApply(true),
                  title: APPLY_FOR_ANOTHER_LOAN_CTA,
                  testId: "apply-for-another-loan-button",
                }}
              ></DeclinedLoanBanner>
            )}
          </>
        )}
      </GridLayoutDiv>
      <CheckoutFooter
        buttonText="Continue"
        onClick={() => navigateToNextTask()}
      />
    </>
  );
};

const InlineLink = styled.span`
  text-decoration: underline;
  font-weight: 200;
  cursor: pointer;
  margin-right: 10px;
  color: ${tokens.BRAND_HERO_BLUE};
`;

const DeclinedLoanBanner = styled(Banner)`
  border: none;

  & .sr-button-primary {
    padding: 12px;
  }

  & .sr-banner-action-buttons {
    min-width: 178px;
    max-height: unset;
    margin-left: 20px;

    > span {
      margin: 0 !important; // this is unfortunately required to get the margin-auto off/unset
    }
  }
`;

const GridLayoutDiv = styled.div`
  display: grid;
  margin-top: 32px;
  grid-row-gap: 32px;
  margin-bottom: 32px;
`;
export {
  LoanApprovalTaskView,
  DECLINED_LOAN_TITLE,
  APPLY_FOR_ANOTHER_LOAN_CTA,
};
