import styled from "@emotion/styled/macro";
import {
  Button,
  Modal,
  Snackbar,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { useCallback, useRef, useState } from "react";
import type {
  Contact,
  Role,
} from "@sunrun/experience-ui-components/lib/components/ContactCard";
import { StatusChip } from "../atoms/StatusChip";
import { SlimContactCard } from "../molecules/SlimContactCard";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { ReadSignDocsStep } from "../../hooks/useReadSignDocs";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardSectionDivider,
} from "../molecules/Card";
import { ConfirmModal } from "../molecules/ConfirmModal";
import { ROLES } from "utils/usageUtils";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";
import { useCheckoutDocViewing } from "checkout/hooks/useCheckoutDocViewing";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { ErrorModal } from "components/molecules/ErrorModal";
import {
  getDocumentStatus,
  sendIpaEmail,
  sendLoanEmail,
  signDocument,
  voidDocument,
} from "services/prospect";
import { useAppSelector } from "store";
import { useContactManagement } from "hooks/useContactManagement";
import { ErrorComponent } from "components/atoms/ErrorComponent";

type DocumentCardProps = Omit<
  ReadSignDocsStep,
  "prerequisites" | "isRequired"
> & {
  primaryContact: SplatContact;
  secondaryContact?: SplatContact;
  setSecondaryContact?: (contact: SplatContact | undefined) => void;
  refreshTasks: () => Promise<any>;
};

const testIds = {
  SECONDARY_SIGNER_ADD:
    "offer-experience--DocumentCard--secondaryContactDocumentCard--add--button",
  SECONDARY_SIGNER_CHANGE:
    "offer-experience--DocumentCard--secondaryContactDocumentCard--change--button",
  SECONDARY_SIGNER_REMOVE:
    "offer-experience--DocumentCard--secondaryContactDocumentCard--remove--button",
};

const DocumentCard = ({
  odiName,
  docName,
  isUnlocked,
  completed,
  description,
  allowSecondaryContact,
  primarySignerRole,
  primaryContact,
  secondaryContact,
  documentType,
  showRead,
  showEmail,
  showSign,
  disableEmail,
  isOptional,
  refreshTasks,
  setSecondaryContact,
}: DocumentCardProps) => {
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const status = completed ? "signed" : isUnlocked ? "readyToSign" : "locked";
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [documentActionError, setDocumentActionError] = useState(false);
  const [documentCardError, setDocumentCardError] = useState<Error>();
  const [documentActionErrorMessage, setDocumentActionErrorMessage] =
    useState("");
  const [documentActionErrorFunction, setDocumentActionErrorFunction] =
    useState<
      | undefined
      | "SendEmail"
      | "SendIPAEmail"
      | "SignInPerson"
      | "CheckDocStatus"
      | "VoidDocument"
    >();
  const [removeSecondarySignerError, setRemoveSecondarySignerError] = useState<{
    action?: string;
    error?: Error;
  }>();
  const [confirmVoidModal, setConfirmVoidModal] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [loadingInPerson, setLoadingInPerson] = useState(false);
  const [loadingVoidDocument, setLoadingVoidDocument] = useState(false);
  const newDocusignType = useRef<"EMAIL" | "INPERSON">("EMAIL");
  const oldDocusignType = useRef<"EMAIL" | "INPERSON">("EMAIL");
  const hasSecondaryContact = secondaryContact && allowSecondaryContact;
  const { prospectId, proposalId, navigateToTask } = useCheckoutContext();
  const { viewDocument, viewDocLoading, viewDocError, resetError } =
    useCheckoutDocViewing(prospectId, proposalId, documentType);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showIpaSuccessSnackbar, setShowIpaSuccessSnackbar] = useState(false);

  const toggleSendEmailModal = () => setSendEmailModal((prev) => !prev);
  const toggleConfirmVoidModal = () => setConfirmVoidModal((prev) => !prev);

  const { onEditContactRole } = useContactManagement();

  const handleSendEmail = useCallback(async () => {
    setLoadingSendEmail(true);
    try {
      if (docName === "Illinois Power Authority") {
        await sendIpaEmail(authKey, prospectId, proposalId);
      } else if (docName === "Loan") {
        await sendLoanEmail(
          authKey,
          prospectId,
          proposalId,
          secondaryContact?.contactId
        );
      } else {
        const sendEmailSecondaryContact = odiName !== "cpuc";
        await signDocument(
          {
            authKey,
            prospectId,
            proposalId,
            documentType,
            eSignatureSource: "Sales Platform",
            eSignatureType: "EMAIL",
            primaryContactId: primaryContact.contactId,
          },
          sendEmailSecondaryContact ? secondaryContact?.contactId : undefined
        );
      }

      if (docName === "Illinois Power Authority") {
        setShowIpaSuccessSnackbar(true);
      } else {
        setShowSuccessSnackbar(true);
      }
      setDocumentActionError(false);
    } catch (error: any) {
      setDocumentActionErrorMessage(
        docName === "Illinois Power Authority"
          ? "Something went wrong in sending the IPA document"
          : "Sorry, we couldn't send your email."
      );
      setDocumentActionErrorFunction(
        docName === "Illinois Power Authority" ? "SendIPAEmail" : "SendEmail"
      );
      setDocumentActionError(true);
      setDocumentCardError(error);
    } finally {
      setSendEmailModal(false);
      setLoadingSendEmail(false);
    }
  }, [
    docName,
    authKey,
    prospectId,
    proposalId,
    secondaryContact?.contactId,
    odiName,
    documentType,
    primaryContact.contactId,
  ]);

  const handleSignInPerson = useCallback(async () => {
    try {
      const signResponse: any = await signDocument(
        {
          authKey,
          prospectId,
          proposalId,
          documentType,
          eSignatureSource: "Sales Platform",
          eSignatureType: "INPERSON",
          primaryContactId: primaryContact.contactId,
        },
        secondaryContact?.contactId
      );
      const docusignWindow = window.open(signResponse.url, "_blank");
      setLoadingInPerson(false);

      const timer = setInterval(async () => {
        if (docusignWindow && docusignWindow.closed) {
          clearInterval(timer);
          await refreshTasks();
        }
      }, 1000);
      setDocumentActionError(false);
    } catch (error: any) {
      setDocumentCardError(error);
      setDocumentActionErrorMessage("Sorry, we couldn't sign your document.");
      setDocumentActionErrorFunction("SignInPerson");
      setDocumentActionError(true);
    } finally {
      setLoadingInPerson(false);
    }
  }, [
    authKey,
    documentType,
    primaryContact.contactId,
    proposalId,
    prospectId,
    secondaryContact?.contactId,
    refreshTasks,
  ]);

  const updateSecondaryContact = useCallback(
    () => navigateToTask("confirmNames", { secondaryEdit: true }),
    [navigateToTask]
  );

  const handleCheckDocumentStatus = useCallback(async () => {
    try {
      if (newDocusignType.current === "INPERSON") {
        setLoadingInPerson(true);
      }
      const docStatus = await getDocumentStatus({
        authKey,
        prospectId,
        proposalId,
        documentType,
      });
      const { documentStatus, eSignType } = docStatus;
      if (documentStatus !== "uninitiated" && documentStatus !== "voided") {
        oldDocusignType.current = eSignType ?? newDocusignType.current;
        toggleConfirmVoidModal();
        return;
      }
      newDocusignType.current === "EMAIL"
        ? toggleSendEmailModal()
        : handleSignInPerson();
      setDocumentActionError(false);
    } catch (error: any) {
      setDocumentCardError(error);
      setDocumentActionErrorMessage(
        "Sorry, we couldn't get the Document Status."
      );
      setDocumentActionErrorFunction("CheckDocStatus");
      setDocumentActionError(true);
    }
  }, [
    authKey,
    documentType,
    proposalId,
    prospectId,
    handleSignInPerson,
    newDocusignType,
  ]);

  const voidAndReInitiateDocusign = useCallback(async () => {
    setLoadingVoidDocument(true);
    try {
      await voidDocument({
        authKey,
        prospectId,
        proposalId,
        documentType,
        eSignatureSource: "Sales Platform",
        eSignatureType: oldDocusignType.current,
        primaryContactId: primaryContact.contactId,
      });
      newDocusignType.current === "INPERSON"
        ? await handleSignInPerson()
        : await handleSendEmail();
      setDocumentActionError(false);
    } catch (error: any) {
      setDocumentCardError(error);
      setDocumentActionErrorMessage("Sorry, we couldn't Void the Document.");
      setDocumentActionErrorFunction("VoidDocument");
      setDocumentActionError(true);
    } finally {
      setLoadingVoidDocument(false);
      setConfirmVoidModal(false);
    }
  }, [
    authKey,
    documentType,
    proposalId,
    prospectId,
    primaryContact.contactId,
    oldDocusignType,
    newDocusignType,
    handleSendEmail,
    handleSignInPerson,
  ]);

  const handleDocuments = useCallback(
    async (docusignType: "EMAIL" | "INPERSON") => {
      newDocusignType.current = docusignType;
      await handleCheckDocumentStatus();
    },
    [handleCheckDocumentStatus]
  );

  const handleRetryFunction = () => {
    switch (documentActionErrorFunction) {
      case "SendEmail":
        handleSendEmail();
        break;
      case "SendIPAEmail":
        handleSendEmail();
        break;
      case "SignInPerson":
        handleSignInPerson();
        break;
      case "CheckDocStatus":
        handleCheckDocumentStatus();
        break;
      case "VoidDocument":
        voidAndReInitiateDocusign();
    }
  };
  const handleRemoveSecondarySigner = useCallback(
    async (contact: Contact, roleToUpdate: Role, newRole: Role) => {
      try {
        // Update ROLE of the contact
        await onEditContactRole({ ...contact, roleToUpdate, newRole });
      } catch (error) {
        setRemoveSecondarySignerError({
          action: "Removing Secondary Contact",
          error: error as Error,
        });
      }
    },
    [onEditContactRole]
  );

  const primaryContactLabel = {
    [ROLES.HOMEOWNER]: "Primary signer",
    [ROLES.UTILITYBILLCONTACT]: "Utility Bill Contact",
  }[primarySignerRole];

  return (
    <>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        message="Email sent successfully!"
        onClose={() => setShowSuccessSnackbar(false)}
        type="success"
      />
      <Snackbar
        open={showIpaSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        message="Request sent, may take 3 mins to appear in your inbox."
        onClose={() => setShowIpaSuccessSnackbar(false)}
        type="success"
      />
      {showSecondaryModal && (
        <Modal
          type="warning"
          onClose={() => {
            setShowSecondaryModal(false);
          }}
          title="Are you sure you want to remove this signer?"
          primaryButton={{
            text: "Remove secondary signer",
            onClick: async () => {
              await handleRemoveSecondarySigner(
                secondaryContact!,
                ROLES.CONTRACTCOSIGNER,
                ROLES.OTHER
              );
              setSecondaryContact!(undefined);
              setShowSecondaryModal(false);
            },
          }}
          secondaryButton={{
            text: "Cancel",
            onClick: () => setShowSecondaryModal(false),
          }}
        >
          <Typography size={tokens.FONT_SIZE_1} color={tokens.BRAND_HEROBLUE}>
            If you remove the secondary signer, only{" "}
            {primaryContact.customerFirstName} {primaryContact.customerLastName}{" "}
            will be able to sign.
          </Typography>
        </Modal>
      )}
      <Card>
        <CardHeader>
          <HeaderTitle>
            <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HEROBLUE}>
              {docName}
            </Typography>
            {isOptional && (
              <Typography
                size={tokens.FONT_SIZE_3}
                color={tokens.TINTS_OFF_WHITE_20}
                data-testid="checkout-read-and-sign-net-task-metering-header"
              >
                Optional
              </Typography>
            )}
          </HeaderTitle>
          <StatusChip status={status} />
        </CardHeader>
        <CardSectionDivider />
        <CardContent>
          <Typography variant="p" color={tokens.TINTS_OFF_WHITE_20}>
            {description}
          </Typography>
          {docName === "Loan" && (
            <SlimContactCard
              contact={primaryContact}
              secondaryContact={secondaryContact}
              label={secondaryContact ? "Borrowers" : "Borrower"}
            />
          )}
          {docName !== "Loan" && (
            <>
              <SlimContactCard
                contact={primaryContact}
                label={primaryContactLabel}
              />

              {hasSecondaryContact && (
                <SlimContactCard
                  contact={secondaryContact!}
                  label="Secondary signer"
                >
                  {!completed && (
                    <SecondarySignerActions>
                      <HyperlinkButton
                        data-testid={testIds.SECONDARY_SIGNER_REMOVE}
                        onClick={() => setShowSecondaryModal(true)}
                      >
                        Remove
                      </HyperlinkButton>
                      <HyperlinkButton
                        onClick={updateSecondaryContact}
                        data-testid={testIds.SECONDARY_SIGNER_CHANGE}
                      >
                        Edit
                      </HyperlinkButton>
                    </SecondarySignerActions>
                  )}
                </SlimContactCard>
              )}

              {allowSecondaryContact && !hasSecondaryContact && !completed && (
                <div style={{ alignSelf: "flex-start" }}>
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={updateSecondaryContact}
                  >
                    Add Secondary Signer
                  </Button>
                </div>
              )}
            </>
          )}
        </CardContent>
        <CardFooter>
          <>
            {showRead && !completed ? (
              <HyperlinkButton disabled={viewDocLoading} onClick={viewDocument}>
                Read
              </HyperlinkButton>
            ) : (
              // Layout element to shift ActionWrapper to the right
              <div />
            )}
            <ActionWrapper>
              {showRead && completed && (
                <Button
                  disabled={!isUnlocked}
                  style={{ width: 200 }}
                  size="sm"
                  color="secondary"
                  onClick={viewDocument}
                >
                  {viewDocLoading ? (
                    <LoadingIndicator
                      height={25}
                      width={25}
                      showLoadingMessage={false}
                      color="black"
                    />
                  ) : (
                    "View signed document"
                  )}
                </Button>
              )}
              {!completed && showEmail && (
                <Button
                  disabled={!isUnlocked || disableEmail}
                  onClick={() => handleDocuments("EMAIL")}
                  style={{ width: 165 }}
                  size="sm"
                  color={showSign ? "secondary" : "primary"}
                  data-testid="email-button"
                >
                  Email
                </Button>
              )}
              {!completed && showSign && (
                <Button
                  disabled={!isUnlocked || loadingInPerson}
                  style={{ width: 165 }}
                  size="sm"
                  onClick={() => handleDocuments("INPERSON")}
                >
                  {loadingInPerson ? "Loading" : "Sign"}
                </Button>
              )}
            </ActionWrapper>
          </>
        </CardFooter>
        {viewDocError && (
          <ErrorModal
            action="Viewing document"
            context="checkout-documents-read"
            error={viewDocError}
            onClose={resetError}
            onRetry={viewDocument}
          />
        )}
        {removeSecondarySignerError && (
          <ErrorModal
            action={removeSecondarySignerError.action}
            context="checkout-documents-remove-secondary-signer"
            error={removeSecondarySignerError.error}
            onClose={() => setRemoveSecondarySignerError(undefined)}
          />
        )}

        {confirmVoidModal && (
          <ConfirmModal
            title="Are you sure you want to re-start signing for this agreement?"
            confirmLabel="Void & Proceed"
            onClose={toggleConfirmVoidModal}
            onConfirm={voidAndReInitiateDocusign}
            loading={loadingVoidDocument}
          >
            <Content>
              <Typography variant="p">
                This action will void the Docusign Envelope for signing the{" "}
                {docName} that has already been initiated.
              </Typography>
            </Content>
          </ConfirmModal>
        )}
        {sendEmailModal && (
          <ConfirmModal
            title="Send Document"
            confirmLabel="Send Email"
            onClose={toggleSendEmailModal}
            onConfirm={handleSendEmail}
            loading={loadingSendEmail}
          >
            <Content>
              <Typography variant="p">
                Your {documentType} will be sent to:
              </Typography>
              <List>
                {primaryContact.customerEmail && (
                  <li>
                    <Typography variant="p">
                      {primaryContact.customerEmail}
                    </Typography>
                  </li>
                )}
                {allowSecondaryContact && secondaryContact?.customerEmail && (
                  <li>
                    <Typography variant="p">
                      {secondaryContact.customerEmail}
                    </Typography>
                  </li>
                )}
              </List>
            </Content>
          </ConfirmModal>
        )}
        {documentActionError && (
          <ConfirmModal
            title={documentActionErrorMessage}
            confirmLabel="Retry"
            onClose={() => setDocumentActionError(false)}
            onConfirm={handleRetryFunction}
            loading={loadingSendEmail || loadingVoidDocument}
            modalType={"error"}
          >
            <>
              {documentActionErrorFunction === "SendIPAEmail" && (
                <Typography variant="p">
                  Please text Sales Support Pre SRA at{" "}
                  <a href="tel:385-317-0193">385-317-0193</a> for help
                  completing the Illinois Power Authority (IPA) document.
                </Typography>
              )}
              <ErrorComponent
                error={documentCardError}
                context={documentActionErrorFunction ?? ""}
              />
            </>
          </ConfirmModal>
        )}
      </Card>
    </>
  );
};

const SecondarySignerActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const List = styled.ul`
  margin: 0;
`;

const HeaderTitle = styled.div`
  display: flex;
  gap: 16px;
`;

export { DocumentCard, testIds };
export type { DocumentCardProps };
