import styled from "@emotion/styled/macro";
import {
  Modal,
  Select,
  Typography,
  tokens,
} from "@sunrun/experience-ui-components";
import { useState } from "react";

type Props = {
  cancellationReasons: Array<{ label: string; value: string }>;
  onSubmit: (reason: string) => void;
  onClose: () => void;
  name: string;
  loading?: boolean;
};

const SiteSurveyCancelAppointmentModal: React.FC<Props> = ({
  loading,
  cancellationReasons,
  onSubmit,
  onClose,
  name,
}) => {
  const [reason, setReason] = useState(cancellationReasons[0].value);

  return (
    <Modal
      hideIcon
      onClose={onClose}
      secondaryButton={{
        text: "Keep Appointment",
        onClick: onClose,
      }}
      primaryButton={{
        text: "Cancel Appointment",
        onClick: () => onSubmit(reason),
      }}
      title="Are you sure?"
    >
      <Typography color={tokens.BRAND_HERO_BLUE}>
        Are you sure you want to cancel the site survey for {name}?
      </Typography>
      <Typography color={tokens.BRAND_HERO_BLUE}>
        Remember, you can still schedule a new appointment.
      </Typography>
      <Label color={tokens.TINTS_OFF_WHITE_20}>Cancellation Reason</Label>
      <Select
        data-testid="cancellation-reason-select"
        options={cancellationReasons}
        onChange={(e) => {
          setReason(e.target.value);
        }}
        value={reason}
      />
      {loading && <LoadingIndicator data-testid="loading-overlay" />}
    </Modal>
  );
};

const Label = styled(Typography)`
  && {
    margin-top: 26px;
    margin-bottom: 6px;
    font-size: 14px;
  }
`;

const LoadingIndicator = styled.div`
  cursor: progress;
  position: absolute;
  background: white;
  opacity: 0.5;
  margin-left: -40px;
  width: 100%;
  height: 134px;
  border-radius: 50%;
`;

export { SiteSurveyCancelAppointmentModal };
export type { Props };
