import styled from "@emotion/styled/macro";
import { tokens, Typography, Button } from "@sunrun/experience-ui-components";
import { CheckoutBackground } from "../templates/CheckoutGrid";
import { useNavigateToList } from "utils/useNavigateHelper";

const CheckoutNoOfferPage = () => {
  const navigateToList = useNavigateToList();

  return (
    <CheckoutNoOfferContainer>
      <Typography
        variant="p"
        size={tokens.FONT_SIZE_3}
        lineHeight={tokens.LINE_HEIGHTS_12}
        style={{ marginBottom: 10 }}
      >
        Looks like you haven't built an offer yet
      </Typography>
      <Typography
        variant="p"
        size={tokens.FONT_SIZE_8}
        lineHeight={tokens.LINE_HEIGHTS_9}
        style={{ marginBottom: 40 }}
      >
        Start creating your new offer
      </Typography>
      <Button
        data-testid={"new-offer-btn"}
        size="md"
        style={{ width: 132 }}
        onClick={navigateToList}
      >
        Start now
      </Button>
    </CheckoutNoOfferContainer>
  );
};

const CheckoutNoOfferContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${CheckoutBackground}
`;

export { CheckoutNoOfferPage };
