import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

type HyperlinkButtonProps = React.ComponentPropsWithoutRef<"button">;

const HyperlinkButton = ({
  className,
  children,
  ...otherProps
}: HyperlinkButtonProps) => (
  <StyledHyperlinkButton className={className} {...otherProps}>
    {children}
  </StyledHyperlinkButton>
);

const StyledHyperlinkButton = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
  font-family: "Roobert";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${tokens.BRAND_HERO_BLUE};

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export { HyperlinkButton };
