import React from "react";
import styled from "@emotion/styled/macro";
import { Button, LoadingIndicator } from "@sunrun/experience-ui-components";
import { CardFooter } from "./Card";

type CardActionFooterProps = {
  primaryButtonText: string;
  secondaryButtonText?: string;
  primaryButtonTestId: string;
  secondaryButtonTestId?: string;
  primaryButtonFunction: () => void;
  secondaryButtonFunction?: () => void;
  isPrimaryLoading?: boolean;
  isSecondaryLoading?: boolean;
};

const CardActionFooter = ({
  primaryButtonText,
  secondaryButtonText,
  primaryButtonTestId,
  secondaryButtonTestId,
  primaryButtonFunction,
  secondaryButtonFunction,
  isPrimaryLoading = false,
  isSecondaryLoading = false,
}: CardActionFooterProps) => {
  return (
    <FooterContainer>
      <CardFooter>
        <div></div>
        <ActionButtonsContainer>
          {secondaryButtonText && (
            <Button
              color="secondary"
              size="sm"
              style={{ width: "145px" }}
              onClick={secondaryButtonFunction}
              data-testid={primaryButtonTestId}
            >
              {isSecondaryLoading ? (
                <LoadingIndicator color="black" showLoadingMessage={false} />
              ) : (
                secondaryButtonText
              )}
            </Button>
          )}
          <Button
            color="primary"
            size="sm"
            style={{ width: "145px" }}
            onClick={primaryButtonFunction}
            data-testid={secondaryButtonTestId}
          >
            {isPrimaryLoading ? (
              <LoadingIndicator color="white" showLoadingMessage={false} />
            ) : (
              primaryButtonText
            )}
          </Button>
        </ActionButtonsContainer>
      </CardFooter>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  margin-top: 24px;
  & > header {
    margin-bottom: 0;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export { CardActionFooter };
