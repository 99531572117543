import { MutableRefObject, useEffect, useRef, useState } from "react";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import TripleDots from "../assets/images/triple-dots.svg";
import DocumentIcon from "../assets/images/document-icon.svg";
// eslint-disable-next-line import/no-restricted-paths
import { PreviewModal } from "./PreviewModal";
import { useOutsideClick } from "hooks/useOutsideClick";
import { Attachment, imageFileTypes } from "utils/usageUtils";

type Props = {
  file: Attachment;
  deleteFile?: () => void;
  disabled?: boolean;
  allowDownload?: boolean;
  allowPreview?: boolean;
  allowDelete?: boolean;
};

const AttachmentActionButton = ({
  file,
  deleteFile,
  disabled = false,
  allowPreview,
  allowDownload,
  allowDelete = true,
}: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");

  const checkIfVisible = () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  };

  const downloadFile = () => {
    let alink = document.createElement("a");
    alink.href = documentUrl;
    alink.download = file.documentName;
    alink.target = "_blank";
    alink.rel = "noreferrer";
    alink.click();
  };

  const openFileNewTab = () => {
    let alink = document.createElement("a");
    alink.href = documentUrl;
    alink.target = "_blank";
    alink.rel = "noreferrer";
    alink.click();
  };

  useEffect(() => {
    if (file.url) {
      setDocumentUrl(file.url);
    } else {
      setDocumentUrl(URL.createObjectURL(file.documentBlob));
    }
  }, [file]);

  const ref: MutableRefObject<HTMLDivElement | undefined> = useRef();
  useOutsideClick(ref, checkIfVisible);

  return (
    <>
      <RelativeDiv
        key={file.documentName}
        style={{ position: "relative" }}
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <AlignCenterDiv>
          <img src={DocumentIcon} alt="Document icon" />
          <Typography>
            <EllipsedDiv title={file.documentName}>
              {file.documentName}
            </EllipsedDiv>
          </Typography>
          <FileOptionsButton
            disabled={disabled}
            iconUrl={TripleDots}
            onClick={() => {
              setMenuVisible(true);
            }}
            data-testid="attachment-button"
          />
        </AlignCenterDiv>
        {menuVisible ? (
          <SubMenu>
            <StyledUl>
              {allowDelete ? (
                <StyledLi onClick={deleteFile}>
                  <Typography>Delete</Typography>
                </StyledLi>
              ) : null}
              {allowPreview ? (
                <StyledLi
                  onClick={() => {
                    let found = false;
                    imageFileTypes.forEach((type) => {
                      if (file.documentName.includes(type)) {
                        found = true;
                      }
                    });
                    found ? setPreviewVisible(true) : openFileNewTab();
                  }}
                >
                  <Typography>Preview</Typography>
                </StyledLi>
              ) : null}
              {allowDownload ? (
                <StyledLi onClick={downloadFile}>
                  <Typography>Download</Typography>
                </StyledLi>
              ) : null}
            </StyledUl>
          </SubMenu>
        ) : null}
      </RelativeDiv>
      {previewVisible ? (
        <PreviewModal
          handleClose={() => setPreviewVisible(false)}
          fileName={file.documentName}
          fileUrl={documentUrl}
          primaryButton={{
            text: "Close",
            onClick: () => setPreviewVisible(false),
          }}
        />
      ) : null}
    </>
  );
};

const FileOptionsButton = styled.button<{ iconUrl: string }>`
  width: 48px;
  height: 48px;
  margin-left: 5px;
  color: ${tokens.BRAND_HERO_BLUE};
  border-color: ${tokens.BRAND_HERO_BLUE};
  border-radius: 50%;
  background: ${(props) =>
    `${tokens.WHITE} url(${props.iconUrl}) no-repeat center`};
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

const EllipsedDiv = styled.div`
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const StyledLi = styled.li`
  border-bottom: 1px solid ${tokens.BORDER_LIGHT};
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 0px;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }

  > p {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
`;

const RelativeDiv = styled.div`
  position: relative;
  padding-bottom: 10px;
`;

const AlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  & > div,
  & > img {
    margin-right: 5px;
  }
`;

const SubMenu = styled.div`
  position: absolute;
  z-index: 1;
  width: 160px;
  top: 30px;
  right: -25px;
  border: 1px solid ${tokens.BORDER_LIGHT};
  border-radius: 12px;
  background: ${tokens.WHITE};
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.08);
`;

export { AttachmentActionButton };
