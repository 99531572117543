import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

const LinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: ${tokens.BRAND_HEROBLUE};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${tokens.BRAND_HEROBLUE};
    opacity: 0.8;
  }

  &:focus {
    outline: 1px solid ${tokens.BRAND_HEROBLUE};
    outline-offset: 2px;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export { LinkButton };
