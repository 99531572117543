import styled from "@emotion/styled/macro";
import {
  Button,
  Modal,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { ButtonProps } from "@sunrun/experience-ui-components/lib/components/Button/Button";
import { ReactNode, useState } from "react";
import { ModalButtonProp } from "@sunrun/experience-ui-components/lib/components/Modal";
import Resizer from "react-image-file-resizer";
import { ReactComponent as UploadIcon } from "../assets/images/upload-icon.svg";
import { Collapse } from "../atoms/GlobalStyles";
import { SplatCheckoutDocument } from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { AttachmentActionButton } from "components/atoms/AttachmentActionButton";
import { Attachment, defaultAllowedFileTypes } from "utils/usageUtils";

const resizeFile = (file: File) =>
  new Promise<File>((resolve) => {
    Resizer.imageFileResizer(
      file,
      3000,
      3000,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });

const UploadAttachments = ({
  setIsDirty = () => {},
  uploadedFiles,
  uploadedNewFiles,
  onUploadedNewFiles,
  onError,
  description,
  documentType,
  allowedFileTypes = defaultAllowedFileTypes,
  allowDownload = false,
  allowPreview = false,
  maxAllowedMBFileSize,
  acceptMultipleFiles = true,
  buttonSize = "lg",
  disabled = false,
  confirmationModal,
  documentLoading,
}: UploadAttachmentsProps) => {
  const uploadId = documentType.toLowerCase().replaceAll(" ", "-");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const browseFiles = () => {
    setShowConfirmationModal(false);
    document.getElementById(uploadId)?.click();
  };

  const confirmationModalPrimaryButton: ModalButtonProp = {
    text: "Continue upload",
    onClick: browseFiles,
  };

  const confirmationModalSecondaryButton: ModalButtonProp = {
    text: "Cancel",
    onClick: () => setShowConfirmationModal(false),
  };

  const handleUploadClick = () => {
    if (uploadId === "utility-bill" && confirmationModal) {
      setShowConfirmationModal(true);
    } else {
      browseFiles();
    }
  };

  const deleteFile = (index: number) => {
    const len = uploadedNewFiles.length;
    if (!len || len <= index) {
      return;
    }

    uploadedNewFiles.splice(index, 1);
    onUploadedNewFiles([...uploadedNewFiles]);
  };

  // This function is mocked in the test file, if updated here, update there
  const handleFileSelected = async (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const fileObjects = event.currentTarget.files!;
    const filesArray = Array.from(fileObjects);
    if (!filesArray || filesArray.length === 0) return;

    let errors: FileUploadError[] = [];
    let hasFormattedFiles = false;

    const returnArrPromise = filesArray.reduce((newFilesArr, file: File) => {
      if (!allowedFileTypes.includes(file.type)) {
        return newFilesArr;
      }

      if (
        maxAllowedMBFileSize &&
        file.size > maxAllowedMBFileSize * Math.pow(1024, 2)
      ) {
        if (file.type.search(/(png|jpeg|jpg|webp)$/i) !== -1) {
          hasFormattedFiles = true;
          // try and reformat the file to fit if size requirements if it still does not fit throw the error
          return newFilesArr.concat(resizeFile(file));
        }
        errors.push({
          fileName: file.name,
          errorType: 1,
        });
        return newFilesArr;
      }
      return newFilesArr.concat(file);
    }, [] as Array<File | Promise<File>>);

    const newFilesArr = hasFormattedFiles
      ? await Promise.all(returnArrPromise)
      : (returnArrPromise as Array<File>);

    const formattedFileData = newFilesArr.reduce((files, file) => {
      return [
        ...files,
        {
          documentName: file.name,
          documentType: documentType,
          url: URL.createObjectURL(file),
          contentType: file.type,
          documentBlob: file,
          new: true,
        },
      ];
    }, [] as Array<Attachment>);

    if (onError && errors.length) {
      onError(errors);
    }

    onUploadedNewFiles([...uploadedNewFiles, ...formattedFileData]);
    setIsDirty(true);
  };

  return (
    <div>
      <FlexDescriptionButtonDiv>
        <InfoTextContainer>
          {description && <Typography>{description}</Typography>}
          {maxAllowedMBFileSize && (
            <Typography
              style={{ marginTop: description ? 8 : 0 }}
              variant="span"
              color={tokens.TINTS_OFF_WHITE_10}
            >
              File must be up to {maxAllowedMBFileSize} MB in size.
            </Typography>
          )}
        </InfoTextContainer>

        <Collapse>
          <Button
            disabled={disabled || documentLoading}
            size={buttonSize}
            iconRight={<UploadIcon />}
            onClick={handleUploadClick}
            data-testid={`checkout-upload-documents-step-upload-${uploadId}`}
          >
            {documentLoading ? (
              <LoadingIndicator color="white" showLoadingMessage={false} />
            ) : (
              "Upload"
            )}
          </Button>
        </Collapse>
        <InputNoDisplay
          disabled={disabled || documentLoading}
          type="file"
          accept={allowedFileTypes.join(",")}
          onChange={handleFileSelected}
          id={uploadId}
          data-testid={`file-upload-input-${uploadId}`}
          {...(acceptMultipleFiles && { multiple: true })}
        />
      </FlexDescriptionButtonDiv>
      <br />
      <FlexCenterDiv>
        {uploadedFiles.map((file: any, index: number) => (
          <AttachmentActionButton
            disabled={disabled || documentLoading}
            file={file}
            allowPreview={allowPreview}
            allowDownload={allowDownload}
            allowDelete={false}
            key={`${file.documentName}${index}`}
            data-testid={`checkout-upload-documents-step-document-${file.documentName}${index}`}
          />
        ))}
        {uploadedNewFiles.map((file: any, index: number) => (
          <AttachmentActionButton
            disabled={disabled || documentLoading}
            file={file}
            deleteFile={() => deleteFile(index)}
            allowPreview={allowPreview}
            allowDownload={allowDownload}
            allowDelete
            key={`${file.documentName}${index}`}
            data-testid={`checkout-upload-documents-step-document-${file.documentName}${index}`}
          />
        ))}
      </FlexCenterDiv>
      {confirmationModal && showConfirmationModal && (
        <Modal
          title={confirmationModal.title}
          type="info"
          onClose={() => setShowConfirmationModal(false)}
          primaryButton={confirmationModalPrimaryButton}
          secondaryButton={confirmationModalSecondaryButton}
          data-testid="checkout-upload-documents-step-confirm-upload-modal"
        >
          {confirmationModal.body}
        </Modal>
      )}
    </div>
  );
};

const FlexDescriptionButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexCenterDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 25px;
  }
`;

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputNoDisplay = styled.input`
  display: none;
`;

enum ErrorType {
  "FILE_TOO_LARGE" = 1,
}

type FileUploadError = {
  fileName: string;
  errorType: ErrorType;
};

type ConfirmationModalProps = {
  title: string;
  body: ReactNode;
};

type UploadAttachmentsProps = {
  uploadedFiles: Attachment[] | SplatCheckoutDocument[];
  uploadedNewFiles: Attachment[];
  onUploadedNewFiles: (files: Attachment[]) => void;
  onError?: (errors: FileUploadError[]) => void;
  description?: string;
  documentType: string;
  allowedFileTypes?: string[];
  allowDownload?: boolean;
  allowPreview?: boolean;
  maxAllowedMBFileSize?: number;
  acceptMultipleFiles?: boolean;
  buttonSize?: ButtonProps["size"];
  disabled?: boolean;
  setIsDirty?: (arg0: boolean) => void;
  confirmationModal?: ConfirmationModalProps;
  documentLoading?: boolean;
};

export type { UploadAttachmentsProps, FileUploadError, ConfirmationModalProps };
export { UploadAttachments };
