import React, { useCallback, useEffect } from "react";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { getEnv } from "utils/env";
import { useAppSelector } from "store";

const FlagsmithEnvProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const env = getEnv();
  const identity = useAppSelector((state) => state.auth.identity);

  const setIdentity = useCallback((identityToSet: string) => {
    if (!flagsmith.initialised || flagsmith.loadingState?.isFetching) {
      window.setTimeout(() => {
        setIdentity(identityToSet);
      }, 100);
    } else {
      flagsmith.identify(identityToSet);
    }
  }, []);

  useEffect(() => {
    if (identity) {
      setIdentity(identity);
    }
  }, [identity, setIdentity]);

  return (
    <FlagsmithProvider
      options={{
        environmentID:
          env === "production"
            ? "eyZgnuwbveRVcUsUC5PDre"
            : "QL5PWdxYnNQ4DdSJLusfqA",
      }}
      flagsmith={flagsmith}
    >
      <>{children}</>
    </FlagsmithProvider>
  );
};

export { FlagsmithEnvProvider };
