import { Modal } from "@sunrun/experience-ui-components";
import { BatteryTable } from "../atoms/BatteryTable";

const BatteryModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal
      title="What Can I Power?"
      subtitle="Sunrun batteries can power a range of essential household appliances
    during power outages. The specific appliances that can be powered will
    depend on the capacity of the battery and the energy consumption of
    the appliances."
      onClose={onClose}
      hideIcon
    >
      <BatteryTable />
    </Modal>
  );
};
export { BatteryModal };
