import styled from "@emotion/styled/macro";
import { Typography, tokens } from "@sunrun/experience-ui-components";

import { maxWidth } from "components/atoms/GlobalStyles";
import { SplatContact } from "providers/Types";

type InformationSectionProps = {
  label: string;
  value: string | JSX.Element;
};

const InformationSection = ({ label, value }: InformationSectionProps) => {
  return (
    <InfoWrapper>
      <Typography variant="p" color={tokens.TINTS_OFF_WHITE_20}>
        {label}
      </Typography>
      <Typography variant="p" color={tokens.TINTS_HERO_BLUE_50}>
        {value}
      </Typography>
    </InfoWrapper>
  );
};

const ContactView = ({
  contact,
  overrideFields = [],
  additionalFields = [],
  itemsPerRow = 3,
  children,
}: ContactViewProps) => {
  const mainFields: InformationSectionProps[] = [
    {
      label: "Name",
      value: (
        <>{`${contact?.customerFirstName} ${contact?.customerLastName} `}</>
      ),
    },
    {
      label: "Phone",
      value: contact?.customerPrimaryPhone || "-",
    },
    {
      label: "Email",
      // Email is an optional field
      value: contact?.customerEmail || "-",
    },
  ];

  const fields =
    overrideFields.length > 0
      ? overrideFields
      : [...mainFields, ...additionalFields];

  return (
    <Wrapper $columns={itemsPerRow}>
      {fields.map((field) => (
        <InformationSection key={field.label} {...field} />
      ))}
      {children && (
        <ChildWrapper $columns={itemsPerRow}>{children}</ChildWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $columns: number }>`
  display: grid;
  gap: 24px 16px;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);

  @media (max-width: ${maxWidth}px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ChildWrapper = styled.div<{ $columns: number }>`
  grid-column: span ${({ $columns }) => $columns};

  @media (max-width: ${maxWidth}px) {
    grid-column: span 1;
  }
`;

export interface ContactViewProps extends React.PropsWithChildren {
  /**
   * The contact to display
   * */
  contact: SplatContact;
  /**
   * Override the default fields to display and does NOT display "additionalFields"
   * @default ["Name", "Phone", "Email"]
   * */
  overrideFields?: Array<InformationSectionProps>;
  /**
   * Additional fields to display together with the default fields
   * @default ["Name", "Phone", "Email"]
   */
  additionalFields?: Array<InformationSectionProps>;
  /**
   * Whether to show the calling details section
   *
   * @default false
   * @fields ["Best contact method", "Best time to contact"]
   * */
  showCallingDetails?: boolean;
  /**
   * The number of items to display per row
   * @default 3
   * */
  itemsPerRow?: number;
}

export { ContactView };
