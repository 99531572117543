import { useLayoutEffect, useRef, useState } from "react";
import leafletHelpers from "@sunrun/leaflet-helpers";
import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";
import { type useDesign } from "hooks/useDesign";
import { type useSite } from "hooks/useSite";

type LeafletHelperProps = {
  design?: ReturnType<typeof useDesign>["data"];
  bgImageUrl?: string | undefined;
  site?: ReturnType<typeof useSite>["data"];
  showDisabledRoofs?: boolean;
  showEnabledRoofs?: boolean;
  showFlushObstructions?: boolean;
  showModules?: boolean;
  showParcel?: boolean;
  showTrees?: boolean;
  showUsableRoofAreas?: boolean;
  showVerticalObstructions?: boolean;
  zoomToFeatures: Array<
    | "PARCEL"
    | "ENABLED_ROOFS"
    | "DISABLED_ROOFS"
    | "VERTICAL_OBSTRUCTIONS"
    | "FLUSH_OBSTRUCTIONS"
    | "TREES"
    | "USABLE_ROOF_AREAS"
    | "MODULES"
  >;
};
const LeafletHelper = ({
  bgImageUrl,
  design,
  showDisabledRoofs,
  showEnabledRoofs,
  showFlushObstructions,
  showModules,
  showParcel,
  showTrees,
  showUsableRoofAreas,
  showVerticalObstructions,
  site,
  zoomToFeatures,
}: LeafletHelperProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<unknown>(undefined);
  useLayoutEffect(() => {
    if (!mapRef.current) return;
    if (!site) return;
    // HACK: leaflet-helpers fails on "map already initialized" errors.
    if (mapRef.current.classList.contains("leaflet-touch-zoom")) return;
    setMap(
      leafletHelpers.buildMap({
        baseNode: mapRef.current,
        design,
        isEditMode: false,
        isForImageCapture: false,
        map,
        showDisabledRoofs,
        showEnabledRoofs,
        showFlushObstructions,
        showModules,
        showParcel,
        showTrees,
        showUsableRoofAreas,
        showVerticalObstructions,
        site,
        zoomToFeatures,
      })
    );
  }, [
    design,
    map,
    mapRef,
    showDisabledRoofs,
    showEnabledRoofs,
    showFlushObstructions,
    showModules,
    showParcel,
    showTrees,
    showUsableRoofAreas,
    showVerticalObstructions,
    site,
    zoomToFeatures,
  ]);
  return (
    <MapContainer
      bgImageUrl={bgImageUrl!}
      data-testid="leaflet-image-render"
      hq={!!map}
      isDesign={Boolean(design)}
      ref={mapRef}
    />
  );
};

const MapContainer = styled.div<{
  bgImageUrl: string;
  hq: boolean;
  isDesign: boolean;
}>`
  overflow: hidden;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  ::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    ${({ bgImageUrl }) => (bgImageUrl ? `background: url(${bgImageUrl});` : "")}
    ${({ hq }) => (hq ? `filter: blur(6px) brightness(50%);` : ``)}
    background-color: ${tokens.BACKGROUND_DARK};
    transform: scale(1.1);
    background-size: cover;
    background-size: ${({ hq }) => (hq ? `cover` : `contain`)};
    background-repeat: no-repeat;
    background-position: center;
  }
  .leaflet-overlay-pane {
    margin-left: ${({ isDesign }) => (isDesign ? "-100%;" : "0")};
  }
  .tree-height {
    background-color: #8bba5a;
    padding: 1px 4px 0px 5px;
    color: #ffffff;
  }
  .marker-text {
    background-color: transparent;
    padding: 1px 4px 0px 5px;
    color: #ffffff;
  }
  .leaflet-zoom-animated {
    position: absolute !important;
    z-index: auto !important;
  }
  .leaflet-zoom-animated {
    position: absolute !important;
    z-index: auto !important;
  }
  .leaflet-container {
    background-color: rgba(255, 0, 0, 0) !important;
  }
`;

export { LeafletHelper };
