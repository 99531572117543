import styled from "@emotion/styled/macro";
// eslint-disable-next-line import/no-restricted-paths
import { maxWidth } from "../atoms/GlobalStyles";

const ResponsiveCardLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  > div {
    flex: 50%;
  }
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
  }
`;

export { ResponsiveCardLayout };
