import styled from "@emotion/styled/macro";
import { Typography, tokens } from "@sunrun/experience-ui-components";
import { SplatContact } from "providers/Types";
import { CreditStatusNotification } from "checkout/components/atoms/CreditStatusNotification";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";

type ContactRadioProps = {
  contact: SplatContact;
  checked: boolean;
  onChange: () => void;
  onEdit: () => void;
  showCreditStatus?: boolean;
  showContactInfo: boolean;
};

const ContactRadio = ({
  checked,
  contact,
  onChange,
  onEdit,
  showCreditStatus,
  showContactInfo,
}: ContactRadioProps) => {
  return (
    <RadioLabel
      className="sr-radio"
      $checked={checked}
      data-testid={contact.contactId}
    >
      <FlexSplaceBetweenContainer>
        <RadioDiv>
          <input
            type="radio"
            checked={checked}
            onChange={onChange}
            aria-label={`Select contact ${contact.customerFirstName} ${contact.customerLastName}`}
            data-testid={"standard-radio"}
          />
          <ContactInfoWrapper>
            <Typography color={tokens.TINTS_HERO_BLUE_50}>
              {contact.customerFirstName} {contact.customerLastName}
            </Typography>
            {(contact.primary ||
              contact.customerEmail ||
              contact.customerPrimaryPhone) &&
              showContactInfo && (
                <div>
                  {contact.primary && (
                    <Typography variant="p" color={tokens.TINTS_OFF_WHITE_20}>
                      Main Contact
                    </Typography>
                  )}
                  <Typography variant="p" color={tokens.TINTS_HERO_BLUE_50}>
                    {contact.customerPrimaryPhone}
                  </Typography>
                  <Typography variant="p" color={tokens.TINTS_HERO_BLUE_50}>
                    {contact.customerEmail}
                  </Typography>
                </div>
              )}
          </ContactInfoWrapper>
        </RadioDiv>
        <HyperlinkButton
          color="secondary"
          onClick={onEdit}
          aria-label={`Edit contact ${contact.customerFirstName} ${contact.customerLastName}`}
        >
          Edit
        </HyperlinkButton>
      </FlexSplaceBetweenContainer>
      {showCreditStatus && contact?.creditStatus && (
        <CreditStatusNotification contact={contact} />
      )}
    </RadioLabel>
  );
};

const ContactSelect = ({
  selectedContact,
  contactList,
  onContactSelect,
  onEditContact,
  itemsPerRow = 2,
  showCreditStatus,
  onClickAddContact,
  showContactInfo,
}: ContactSelectProps) => {
  return (
    <Wrapper $itemsPerRow={itemsPerRow}>
      {contactList.map((contact) => (
        <ContactRadio
          key={contact.contactId}
          contact={contact}
          checked={selectedContact?.contactId === contact.contactId}
          onChange={() => {
            onContactSelect(contact);
          }}
          onEdit={() => {
            onEditContact(contact);
          }}
          showCreditStatus={showCreditStatus}
          showContactInfo={showContactInfo}
        />
      ))}
      <AddButtonRadio>
        <FlexContainer>
          <EmptySpace />
          <Typography>Add New Contact</Typography>
        </FlexContainer>
        <HyperlinkButton onClick={onClickAddContact}>Add</HyperlinkButton>
      </AddButtonRadio>
    </Wrapper>
  );
};

const FlexContainer = styled.div`
  display: flex;
`;

const EmptySpace = styled.span`
  width: 40px;
`;

const AddButtonRadio = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${tokens.TINTS_OFF_WHITE_40};
`;

const Wrapper = styled.div<{ $itemsPerRow: number }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const RadioLabel = styled.label<{ $checked: boolean }>`
  padding: 24px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  display: grid;
  gap: 16px;

  ${({ $checked }) =>
    $checked &&
    `
    border: 1px solid ${tokens.TINTS_HERO_BLUE_50};
    background: ${tokens.WHITE};
  `}
`;

const FlexSplaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export interface ContactSelectProps extends React.PropsWithChildren {
  selectedContact?: SplatContact;
  contactList: SplatContact[];
  onContactSelect: (contact: SplatContact) => void;
  onEditContact: (contact: SplatContact) => void;
  itemsPerRow?: number;
  showCreditStatus: boolean;
  showContactInfo: boolean;
  onClickAddContact: () => void;
}

export { ContactSelect };
