import styled from "@emotion/styled/macro";
import { ReviewOfferPromotion } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { Container, Title, maxWidth } from "components/atoms/GlobalStyles";

const Promotions = ({ promotions }: { promotions: ReviewOfferPromotion[] }) => {
  if (!promotions || promotions.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <PromoContainer>
        <Title>Promotions</Title>
        <PromoRow>
          {promotions.map((promotion) => {
            return (
              <Promo key={promotion.id}>
                <PromoText>
                  {promotion.imageUrl && promotion.benefit && (
                    <div>{promotion.benefit}</div>
                  )}
                  <div>{promotion.description}</div>
                </PromoText>
                {promotion.benefit && (
                  <Background>
                    {promotion.imageUrl ? (
                      <PromoImage
                        alt="Promos"
                        src={promotion.imageUrl}
                      ></PromoImage>
                    ) : (
                      <div>{promotion.benefit}</div>
                    )}
                  </Background>
                )}
              </Promo>
            );
          })}
        </PromoRow>
      </PromoContainer>
    </Container>
  );
};

const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
`;

const PromoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  padding: 20px 0px 20px 0px;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
  }
`;

const Promo = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 80px;
  background: #fbf8f1 60%;
  border: 0.5px solid rgba(208, 204, 195, 0.6);
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${maxWidth}px) {
    width: 100%;
    flex-direction: column-reverse;
    height: auto;
  }
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f1ec;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 116px;
  height: 78px;
  font-size: 26px;
  font-weight: 400;
  @media (max-width: ${maxWidth}px) {
    width: 100%;
    padding: 10px 0;
    height: auto;
  }
`;

const PromoImage = styled.img`
  height: inherit;
`;

const PromoText = styled.div`
  padding: 0 20px 0 20px;
  @media (max-width: ${maxWidth}px) {
    padding: 20px 0px 20px 0px;
  }
`;

export { Promotions };
