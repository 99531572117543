import { API } from "aws-amplify";
import {
  SplatContact,
  NewSplatContact,
  ConfirmSplatContacts,
} from "../../amplify/backend/function/OfferExpContacts/ts/public/types";

const performRequest = async <Response>(
  method: "get" | "post" | "del" | "patch",
  jwt: string,
  path: string,
  body?: any,
  contentType?: string
): Promise<Response> => {
  const response = await API[method]("OfferExpApi", path, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      ...(contentType && { "Content-Type": contentType }),
    },
    body,
  });
  return response as Response;
};

const editContact = async (
  jwt: string,
  prospectId: string,
  contactId: string,
  customerPrimaryPhone: string,
  customerEmail: string,
  // Additional fields to be patched that are not required
  rest?: Record<string, unknown>
): Promise<string> => {
  return await performRequest<string>(
    "patch",
    jwt,
    `/prospect/${prospectId}/contacts/${contactId}`,
    {
      customerPrimaryPhone,
      customerEmail,
      ...rest,
    }
  );
};

const editContactRole = async (
  jwt: string,
  prospectId: string,
  contactId: string,
  customerPrimaryPhone: string,
  customerEmail: string,
  // Additional fields to be patched that are not required
  rest?: Record<string, unknown>
): Promise<string> => {
  return await performRequest<string>(
    "patch",
    jwt,
    `/prospect/${prospectId}/contacts/${contactId}/role`,
    {
      customerPrimaryPhone,
      customerEmail,
      ...rest,
    }
  );
};

const confirmContacts = async (
  jwt: string,
  prospectId: string,
  proposalId: string,
  contacts: ConfirmSplatContacts
): Promise<string> => {
  return await performRequest<string>(
    "post",
    jwt,
    `/prospect/${prospectId}/contacts/confirm`,
    {
      proposalId,
      contacts,
    }
  );
};

const addContact = async (
  jwt: string,
  prospectId: string,
  contact: NewSplatContact
): Promise<SplatContact> => {
  return await performRequest<SplatContact>(
    "post",
    jwt,
    `/prospect/${prospectId}/contacts/`,
    contact
  );
};

export { editContact, editContactRole, addContact, confirmContacts };
