import { ListOffer } from "../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";

const getOfferToShow = (data: ListOffer[]) => {
  let mostRecentOffer: ListOffer = data[0];
  let signedOffer: ListOffer | undefined = undefined;
  data.forEach((offer) => {
    if (signedOffer) {
      return;
    }
    if (offer.isSigned) {
      signedOffer = offer;
      return;
    }
    if (mostRecentOffer.createdAt < offer.createdAt) {
      mostRecentOffer = offer;
      return;
    }
  });
  return signedOffer ?? mostRecentOffer;
};

const getOfferFromProposalId = (data: ListOffer[], proposalId: string) => {
  const offer = data.find((offer) => offer.proposalId === proposalId);
  return offer;
};

export { getOfferToShow, getOfferFromProposalId };
