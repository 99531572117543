import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  Snackbar,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckoutFooter } from "../molecules/CheckoutFooter";
import {
  Card,
  CardContent,
  CardHeader,
  CardSectionDivider,
} from "../molecules/Card";
import {
  SplatProspectResponse,
  SplatProspectUpdate,
} from "../../../../amplify/backend/function/OfferExpUsage/ts/public/types";
import { ConfirmationModalProps } from "../../../components/molecules/UploadAttachments";
import { UnknownOdiModal } from "../molecules/UnknownOdiModal";
import { subscribeToAsyncRequest } from "utils/subscribeToAsyncRequest";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";
import { DocumentUpload } from "components/organisms/DocumentUpload";
import { UtilityInfo } from "components/organisms/UtilityInfo";
import { useUsageData, useUtilityData } from "utils/swrHooks";
import { Attachment, UtilityInfoState } from "utils/usageUtils";
import { utilityInfoFormSchema } from "providers/schema";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { patchUsage, postCheckoutAttachment } from "services/prospect";
import { useAuthToken } from "hooks/useAuthToken";
import {
  uploadDocsOdis,
  useConfirmUtility,
  UtilityCheckListStep,
} from "checkout/hooks/useConfirmUtility";
import { useCheckoutAttachmentsNoType } from "checkout/hooks/useCheckoutSwr";
import { reloadMFE } from "utils/useNavigateHelper";

const utilityBillsConfirmationModalProps: ConfirmationModalProps = {
  title: "Document requirements",
  body: (
    <div>
      <Typography>
        The utility bill(s) must show all of the below information and be dated
        within the last 6 months.
      </Typography>
      <ul>
        <li>Meter number</li>
        <li>Account number</li>
        <li>Customer name</li>
        <li>Customer address</li>
        <li>Rate schedule</li>
        <li>Usage</li>
      </ul>
    </div>
  ),
};

const ConfirmUtilityTaskView: React.FC = () => {
  const {
    tasksLoading,
    prospectId,
    currentTask,
    proposalId,
    refreshTasks,
    refreshAndNavigateNext,
  } = useCheckoutContext();
  const { utilityChecklistSteps = [], unknownOdis } = useConfirmUtility({
    proposalId,
    prospectId,
  });
  const [showUnknownOdisModal, setShowUnknownOdisModal] = useState(true);
  const authKey = useAuthToken("HYBRID")?.token || "no-token";
  const [newVariableCheckoutAttachments, setNewVariableCheckoutAttachments] =
    useState<Attachment[]>([]);
  const [editUtilityInfo, setEditUtilityInfo] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const successSnackbarMessage = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [originalUsage, setOriginalUsage] =
    useState<SplatProspectResponse | null>(null);
  const attachmentsMutateLoading = useRef(false);
  const [patchErrorMessage, setPatchErrorMessage] = useState<null | Error>(
    null
  );
  const [attachmentErrorMessage, setAttachmentErrorMessage] =
    useState<null | Error>(null);
  const formUtils = useForm({
    resolver: zodResolver(utilityInfoFormSchema),
    defaultValues: {
      accountNumber: "",
      meterNumber: "",
      utilityCompany: "",
      rateSchedule: "",
    } as UtilityInfoState,
  });

  const toggleUtilityInfoEdit = () => setEditUtilityInfo((prev) => !prev);

  const { prospect: usage, error: usageError } = useUsageData(prospectId);

  const {
    data: utilitiesOptions,
    error: utilityError,
    isLoading: isUtilitiesLoading,
  } = useUtilityData(usage?.customerState as string, authKey);

  const {
    data: checkoutAttachments,
    error: allTypesDocumentAttachmentsError,
    isLoading: allTypesAttachmentsLoading,
    mutate: allTypesDocumentAttachmentsMutate,
  } = useCheckoutAttachmentsNoType(prospectId, proposalId);

  useEffect(() => {
    if (usage) {
      setOriginalUsage(usage);
      formUtils.reset({
        utilityBillDate: usage.utilityBillDate,
        utilityCompany: usage.utilityCompany,
        utilityServiceAddress: usage.utilityServiceAddress,
        rateSchedule: usage.rateSchedule,
        accountNumber: usage.accountNumber,
        meterNumber: usage.meterNumber,
      });
    }
  }, [usage, formUtils]);

  const handleSaveUtilityInfoAndConfirm = useCallback(async () => {
    setIsLoading(true);
    const formValues = formUtils.getValues();

    if (
      formValues.accountNumber === originalUsage?.accountNumber &&
      formValues.meterNumber === originalUsage?.meterNumber &&
      formValues.utilityServiceAddress ===
        originalUsage?.utilityServiceAddress &&
      formValues.utilityBillDate === originalUsage?.utilityBillDate
    ) {
      return;
    }

    const prospectBody: Partial<SplatProspectUpdate> = {
      ...formValues,
    };
    if (
      formValues.utilityServiceAddress !== originalUsage?.utilityServiceAddress
    ) {
      prospectBody.partialOverrideAddressStandardization = true;
    }
    try {
      await patchUsage(authKey, prospectId, prospectBody).then((result) => {
        return subscribeToAsyncRequest(authKey, result.asyncRequestId);
      });

      successSnackbarMessage.current = "Utility Info updated successfully!";
      setShowSuccessSnackbar(true);
    } catch (err: any) {
      setPatchErrorMessage(err);
    } finally {
      setIsLoading(false);
      setEditUtilityInfo(false);
    }
  }, [originalUsage, prospectId, formUtils, authKey]);

  useEffect(() => {
    if (newVariableCheckoutAttachments.length > 0) {
      setIsDocumentLoading(true);
      const attachmentsPromiseArr: Promise<any>[] = [];

      newVariableCheckoutAttachments.forEach((attachment: Attachment) => {
        const promise = postCheckoutAttachment(
          authKey,
          prospectId,
          proposalId,
          attachment
        );
        attachmentsPromiseArr.push(promise);
      });
      Promise.all([...attachmentsPromiseArr])
        .then((uploadResults) => {
          successSnackbarMessage.current = `Successfully uploaded ${
            uploadResults.length
          } document${uploadResults.length > 1 ? "s" : ""}!`;
          setShowSuccessSnackbar(true);
          setNewVariableCheckoutAttachments([]);
          attachmentsMutateLoading.current = true;
          allTypesDocumentAttachmentsMutate().then(() => {
            attachmentsMutateLoading.current = false;
          });
          refreshTasks();
        })
        .catch((err: any) => {
          setAttachmentErrorMessage(err);
        })
        .finally(() => {
          setIsDocumentLoading(false);
        });
    }
  }, [
    authKey,
    prospectId,
    proposalId,
    allTypesDocumentAttachmentsMutate,
    refreshTasks,
    setNewVariableCheckoutAttachments,
    newVariableCheckoutAttachments,
  ]);

  if (!currentTask || tasksLoading) {
    return (
      <LoadingWrapper>
        <LoadingIndicator color="black" />
      </LoadingWrapper>
    );
  }

  if (usageError || utilityError || allTypesDocumentAttachmentsError) {
    return (
      <ErrorComponent
        context="UsagePage"
        error={usageError ?? utilityError ?? allTypesDocumentAttachmentsError}
      />
    );
  }

  return (
    <>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        message={successSnackbarMessage.current}
        onClose={() => setShowSuccessSnackbar(false)}
        type="success"
      />
      {(!!patchErrorMessage || !!attachmentErrorMessage) && (
        <Modal
          type="error"
          title={
            patchErrorMessage
              ? "There was an issue saving utility information"
              : "There was an issue uploading your files"
          }
          primaryButton={{
            text: "Refresh",
            onClick: () => reloadMFE(),
          }}
          secondaryButton={{
            text: "Cancel",
            onClick: () => {
              setPatchErrorMessage(null);
              setAttachmentErrorMessage(null);
            },
          }}
          onClose={() => {
            setPatchErrorMessage(null);
            setAttachmentErrorMessage(null);
          }}
        >
          <Typography>
            {patchErrorMessage
              ? "Our system is having trouble saving your utility information at the moment. Please refresh the page and try again."
              : "Our system is having trouble uploading your files at the moment. Please refresh the page and try again."}
          </Typography>
        </Modal>
      )}
      {unknownOdis && unknownOdis.length > 0 && showUnknownOdisModal && (
        <UnknownOdiModal
          odiNames={unknownOdis}
          isConfirmUtility={true}
          onClose={() => {
            setShowUnknownOdisModal(false);
          }}
        />
      )}
      <CardsContainer>
        {isUtilitiesLoading && <LoadingIndicator color="black" />}
        {!isUtilitiesLoading && (
          <>
            {!editUtilityInfo && (
              <Card>
                <CardHeader>
                  <Typography
                    size={tokens.FONT_SIZE_3}
                    color={tokens.BRAND_HEROBLUE}
                  >
                    Utility Information
                  </Typography>
                  <HyperlinkButton
                    onClick={toggleUtilityInfoEdit}
                    data-testid="checkout-confirm-utility-edit-utility-info"
                  >
                    Edit
                  </HyperlinkButton>
                </CardHeader>
                <CardSectionDivider />
                <CardContent>
                  <UtilityInfoFields>
                    <UtilityInfoField>
                      <Typography color={tokens.TINTS_OFF_WHITE_20}>
                        Account Number
                      </Typography>
                      <Typography>
                        {formUtils.getValues().accountNumber || "Not entered"}
                      </Typography>
                    </UtilityInfoField>
                    <UtilityInfoField>
                      <Typography color={tokens.TINTS_OFF_WHITE_20}>
                        Meter Number
                      </Typography>
                      <Typography>
                        {formUtils.getValues().meterNumber || "Not entered"}
                      </Typography>
                    </UtilityInfoField>
                    <UtilityInfoField>
                      <Typography color={tokens.TINTS_OFF_WHITE_20}>
                        Utility Service Address
                      </Typography>
                      <div>
                        <Typography>
                          {`${
                            formUtils.getValues().utilityServiceAddress ?? "-"
                          }`}
                        </Typography>
                      </div>
                    </UtilityInfoField>
                    <UtilityInfoField>
                      <Typography color={tokens.TINTS_OFF_WHITE_20}>
                        Date of latest utility bill
                      </Typography>
                      <div>
                        <Typography>
                          {`${formUtils.getValues().utilityBillDate ?? "-"}`}
                        </Typography>
                      </div>
                    </UtilityInfoField>
                  </UtilityInfoFields>
                </CardContent>
              </Card>
            )}
            {editUtilityInfo && (
              <UtilityInfo
                utilitiesOptions={utilitiesOptions}
                formUtils={formUtils}
                disabledFields={["utilityCompany"]}
                hiddenFields={["utilityCompany"]}
                actionFooter={{
                  primaryButtonText: "Save",
                  secondaryButtonText: "Cancel",
                  primaryButtonTestId:
                    "checkout-confirm-utility-save-utility-info",
                  secondaryButtonTestId:
                    "checkout-confirm-utility-cancel-utility-info",
                  primaryButtonFunction: handleSaveUtilityInfoAndConfirm,
                  secondaryButtonFunction: () => {
                    if (originalUsage) {
                      formUtils.reset({
                        utilityCompany: originalUsage.utilityCompany,
                        rateSchedule: originalUsage.rateSchedule,
                        accountNumber: originalUsage.accountNumber,
                        meterNumber: originalUsage.meterNumber,
                        utilityBillDate: originalUsage.utilityBillDate,
                        utilityServiceAddress:
                          originalUsage.utilityServiceAddress,
                      });
                    }
                    setEditUtilityInfo(false);
                  },
                  isPrimaryLoading: isLoading,
                }}
              />
            )}
          </>
        )}
        {(allTypesAttachmentsLoading || attachmentsMutateLoading.current) && (
          <LoadingIndicator color="black" />
        )}
        {utilityChecklistSteps
          .filter(
            (step: UtilityCheckListStep) =>
              !!(step && uploadDocsOdis.includes(step.name))
          )
          .sort((a, b) => a.order - b.order)
          .map((step) => (
            <DocumentUpload
              uploadedFiles={
                checkoutAttachments?.filter(
                  (doc) => doc.documentType === step?.documentType
                ) ?? []
              }
              uploadedNewFiles={newVariableCheckoutAttachments.filter(
                (doc) => doc.documentType === step?.documentType
              )}
              setUploadedNewFiles={setNewVariableCheckoutAttachments}
              documentType={step?.documentType ?? "Other"}
              description={step?.description ?? ""}
              title={step?.title ?? ""}
              documentLoading={isDocumentLoading}
              confirmationModal={
                step.documentType === "Utility Bill"
                  ? utilityBillsConfirmationModalProps
                  : undefined
              }
            />
          ))}
        {!attachmentsMutateLoading.current && (
          <DocumentUpload
            uploadedFiles={
              checkoutAttachments?.filter(
                (doc) => doc.documentType === "Other"
              ) ?? []
            }
            uploadedNewFiles={newVariableCheckoutAttachments.filter(
              (doc) => doc.documentType === "Other"
            )}
            setUploadedNewFiles={setNewVariableCheckoutAttachments}
            documentType="Other"
            description="Upload any other documents that may be required for Sunrun Approval."
            title="Other Documents"
            documentLoading={isDocumentLoading}
          />
        )}
      </CardsContainer>
      <CheckoutFooter
        buttonText="Continue"
        isDisabled={isDocumentLoading}
        onClick={refreshAndNavigateNext}
        isLoading={isLoading}
      />
    </>
  );
};

const LoadingWrapper = styled.div`
  margin-top: 32px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const UtilityInfoFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const UtilityInfoField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 16px;
`;

export { ConfirmUtilityTaskView, utilityBillsConfirmationModalProps };
