/* eslint-disable import/no-restricted-paths */
import styled from "@emotion/styled/macro";
import { useEffect, useState } from "react";
import chevronDown from "components/assets/images/chevron-down.svg";

type Option = {
  text: string;
  value: any;
};

type props = {
  selectedIndex?: number;
  options: Option[];
  onChange: (option: Option) => void;
  top?: boolean;
};

const Dropdown = ({ selectedIndex = 0, options, onChange, top }: props) => {
  const [focus, setFocus] = useState(false);
  const [renderedSelectedIndex, setRenderedSelectedIndex] =
    useState(selectedIndex);

  useEffect(() => {
    setRenderedSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  return (
    <Container>
      {focus && (
        <Background
          onClick={() => {
            setFocus(false);
          }}
          data-testid="background-element"
        />
      )}
      <Text
        onClick={() => setFocus(!focus)}
        className={focus ? "focus" : ""}
        data-testid="text-element"
      >
        {options[renderedSelectedIndex]?.text}
      </Text>
      <OptionsContainerPlacement
        data-testid="options-container-placement"
        className={top ? "top" : ""}
      >
        <OptionsContainer
          data-testid="options-container"
          className={(focus ? "focus" : "") + " " + (top ? "top" : "")}
        >
          {options.map((option, index) => (
            <StyledOption
              key={option.value}
              onClick={() => {
                setFocus(false);
                onChange(option);
                setRenderedSelectedIndex(index);
              }}
            >
              {option.text}
            </StyledOption>
          ))}
        </OptionsContainer>
      </OptionsContainerPlacement>
    </Container>
  );
};

const Container = styled.div`
  padding: 1px;
  min-width: 200px;
`;
const Background = styled.div`
  opacity: 0;
  position: absolute;
  min-width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;
const Text = styled.div`
  cursor: pointer;
  padding: 18px 16px;
  border: 1px solid #a3a09a;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: #ffffff url(${chevronDown}) no-repeat 96% center;
  appearance: none;
  -webkit-appearance: none;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.2s;
  &.focus {
    background: #ffffff no-repeat 96% center;
    transition-delay: 0s;
    border-radius: 8px 8px 8px 0px;
    border: 1px 0px 0px 0px solid #74716d;
  }
`;
const OptionsContainerPlacement = styled.div`
  position: relative;
  &.top {
    bottom: 64px;
  }
`;
const OptionsContainer = styled.div`
  position: absolute;
  max-height: 0px;
  overflow: hidden;
  min-width: 180px;
  z-index: 99;
  transition: 0.2s;
  transition-delay: 0s;
  border-radius: 8px;
  border: solid #74716d;
  border-width: 0px;
  &.top {
    bottom: 0px;
  }
  &.focus {
    overflow: scroll;
    max-height: 200px;
    transition-delay: 0.2s;
    border-width: 1px;
  }
`;
const StyledOption = styled.div`
  cursor: pointer;
  padding: 10px 16px;
  border: solid #a3a09a;
  border-width: 0px 0px 1px 0px;
  border-bottom-color: #a3a09a7a;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: #ffffff;
  &:hover {
    background: #fcfcfc;
  }
`;

export { Dropdown };
export type { Option };
