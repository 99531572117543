import { useState } from "react";
import {
  Button,
  Modal,
  Snackbar,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { useParams } from "react-router-dom";
import { ContactStatusNotification } from "../atoms/ContactStatusNotification";
import {
  SplatContact,
  SplatLoan,
} from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { Card, CardContent, CardHeader, CardSectionDivider } from "./Card";
import { SlimContactCard } from "./SlimContactCard";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";
import { useLoanApplicationUrl, useLoans } from "checkout/hooks/useCheckoutSwr";
import { useAppSelector } from "store";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { findContact } from "utils/contacts";
import { useContactsData } from "utils/swrHooks";
import { ROLES } from "utils/usageUtils";
import { postLoanApplicationEmail } from "services/prospect";
import { ContactSelectionManagement } from "components/organisms/ContactSelectionManagement";
import { useContactManagement } from "hooks/useContactManagement";
import { ErrorModal } from "components/molecules/ErrorModal";

const ApplyForLoanCard = () => {
  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { proposalId } = useCheckoutContext();
  const { loans } = useLoans(prospectId, authKey);
  const { contacts } = useContactsData(prospectId as string, authKey);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const { ...contactProps } = useContactManagement();
  const [secondarySigner, setSecondarySigner] = useState<
    SplatContact | undefined
  >();
  const [loanEmailError, setLoanEmailError] = useState<Error>();
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const { loanApplicationUrl, isLoanUrlLoading, isLoanUrlValidating } =
    useLoanApplicationUrl(
      prospectId,
      proposalId,
      authKey,
      secondarySigner?.contactId
    );

  const applyForLoan = () => {
    window.open(loanApplicationUrl);
  };

  const emailLoan = () => {
    if (!prospectId) {
      throw Error("No prospect Id present");
    }

    setEmailLoading(true);
    postLoanApplicationEmail(
      authKey,
      prospectId,
      proposalId,
      secondarySigner?.contactId
    )
      .then(() => {
        setShowSuccessSnackbar(true);
      })
      .catch((err) => {
        setLoanEmailError(
          new Error("There was a problem emailing your loan application.")
        );
      })
      .finally(() => {
        setEmailLoading(false);
        setShowConfirmModal(false);
      });
  };

  const secondaryHasContactInfo = () => {
    if (!secondarySigner) {
      return true;
    }
    if (
      secondarySigner.customerPrimaryPhone?.length &&
      secondarySigner.customerEmail?.length
    ) {
      return true;
    }
    return false;
  };

  if (!loans) {
    return <LoadingIndicator />;
  }

  const existingLoanCombinations: string[] = [];
  loans.forEach((loan: SplatLoan) => {
    const {
      Credit_Results__c,
      Primary_Loan_Signer__c,
      Secondary_Loan_Signer__c,
    } = loan;
    if (Credit_Results__c === "Declined") {
      return;
    }
    let loanKey = Primary_Loan_Signer__c;

    if (Secondary_Loan_Signer__c) {
      loanKey += Secondary_Loan_Signer__c;
    }

    existingLoanCombinations.push(loanKey);
  });

  const primaryContact = findContact(contacts, ROLES.HOMEOWNER);

  const loanAlreadyExists = existingLoanCombinations.includes(
    `${primaryContact?.contactId}${
      secondarySigner ? secondarySigner.contactId : ""
    }`
  );

  return (
    <>
      {loanEmailError && (
        <ErrorModal
          context="LoanApprovalTaskView"
          error={loanEmailError}
          action={"Email Loan Application"}
          onClose={() => setLoanEmailError(undefined)}
        />
      )}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        message="Your Loan Application was successfully emailed."
        onClose={() => setShowSuccessSnackbar(false)}
        type="success"
      />
      {showConfirmModal && (
        <Modal onClose={() => setShowConfirmModal(false)}>
          <ModalGrid>
            <div>
              <Typography variant="h2">Email Loan Document</Typography>
            </div>
            <div>
              <Typography>Your loan document will be emailed to:</Typography>
              <Typography>
                <ul>
                  <li>{primaryContact?.customerEmail}</li>
                </ul>
              </Typography>
            </div>
            <ButtonContainerFull>
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  emailLoan();
                }}
                disabled={emailLoading}
              >
                {emailLoading ? (
                  <LoadingIndicator inline color="white" />
                ) : (
                  "Send Email"
                )}
              </Button>
              <Button
                size="sm"
                color="secondary"
                onClick={() => setShowConfirmModal(false)}
              >
                Cancel
              </Button>
            </ButtonContainerFull>
          </ModalGrid>
        </Modal>
      )}
      {showSecondaryModal && (
        <Modal
          onClose={() => {
            setShowSecondaryModal(false);
          }}
        >
          <ContactSelectionManagement
            contact={secondarySigner}
            title="Add a Co-Borrower"
            buttonLabel="Change Co-Borrower"
            onSelectContact={(blah) => {
              setSecondarySigner(blah);
              setShowSecondaryModal(false);
            }}
            isOptional
            specifiedRole="Other"
            showContactInfo={false}
            showCreditStatus={false}
            {...contactProps}
            contactList={contacts?.filter(
              (contact) => contact.contactId !== primaryContact?.contactId
            )}
            hideViewState
          />
        </Modal>
      )}
      <Card>
        <CardHeader>
          <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HEROBLUE}>
            Apply for {loans.length === 0 ? "a" : "another"} loan
          </Typography>
        </CardHeader>
        <CardSectionDivider />
        <CardContent>
          <SlimContactCard
            contact={primaryContact ?? contacts![0]}
            label="Borrower"
          />
          {secondarySigner && (
            <SlimContactCard contact={secondarySigner} label="Co-Borrower">
              <SecondarySignerActions>
                <HyperlinkButton
                  onClick={() => {
                    setSecondarySigner(undefined);
                  }}
                >
                  Remove
                </HyperlinkButton>
                <HyperlinkButton
                  onClick={() => {
                    setShowSecondaryModal(true);
                  }}
                >
                  Change
                </HyperlinkButton>
              </SecondarySignerActions>
            </SlimContactCard>
          )}
          {!secondarySigner && (
            <div>
              <Button
                size="sm"
                color="secondary"
                onClick={() => {
                  setShowSecondaryModal(true);
                }}
              >
                Add Co-Borrower
              </Button>
            </div>
          )}
          <AlignRightDiv>
            <Button
              color="secondary"
              size="md"
              disabled={
                isLoanUrlLoading ||
                isLoanUrlValidating ||
                !secondaryHasContactInfo()
              }
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              Email
            </Button>
            <Button
              size="md"
              disabled={
                isLoanUrlLoading ||
                isLoanUrlValidating ||
                !secondaryHasContactInfo()
              }
              onClick={applyForLoan}
            >
              Apply
            </Button>
          </AlignRightDiv>
          {loanAlreadyExists && (
            <ContactStatusNotification
              status="Error"
              message="An approved or pending loan application with this combination of borrowers already exists."
            />
          )}
          {!secondaryHasContactInfo() && (
            <ContactStatusNotification
              status="Error"
              message="The Co-Borrower selected does not have valid contact info."
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

const SecondarySignerActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const AlignRightDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const ButtonContainerFull = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    width: 48%;
  }
`;

const ModalGrid = styled.div`
  display: grid;
  grid-row-gap: 16px;

  ul {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

export { ApplyForLoanCard };
