import styled from "@emotion/styled/macro";
import {
  Banner,
  BannerTypes,
  Typography,
  tokens,
} from "@sunrun/experience-ui-components";
import { ContactType } from "@sunrun/experience-ui-components/lib/components/ContactCardCheckout";
import { useState } from "react";

type UpdateContactWarningBannerProps = {
  contactType: Exclude<ContactType, "primary">;
  onClose?: () => void;
};

const CONTACT_TYPE = {
  secondary: "Secondary Signer",
  utility_bill: "Utility bill contact",
} as const;

const AGREEMENT_TYPE = {
  secondary: "Sunrun Agreement",
  utility_bill: "NEM Agreement",
} as const;

const UpdateContactWarningBanner = ({
  contactType,
  onClose,
}: UpdateContactWarningBannerProps) => {
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <Banner
      show
      bannerType={BannerTypes.WarningLight}
      onClose={() => {
        setShow(false);
        onClose?.();
      }}
    >
      <Typography>
        {CONTACT_TYPE[contactType]} was updated, contact Sales Support Desk to
        re-send {AGREEMENT_TYPE[contactType]} for a new signature.
      </Typography>
      <Typography>
        Call Sales Support Desk at{" "}
        <TelLink href="tel:888-840-7180">888-840-7180</TelLink> or text at{" "}
        <TelLink href="tel:385-317-0193">385-317-0193</TelLink>.
      </Typography>
    </Banner>
  );
};

const TelLink = styled.a`
  color: ${tokens.BRAND_HERO_BLUE};
  text-decoration: underline;
  font-size: ${tokens.FONT_SIZE_1}px;
  font-weight: 500;
`;

export { UpdateContactWarningBanner };
