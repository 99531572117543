import styled from "@emotion/styled/macro";
import { Modal } from "@sunrun/experience-ui-components";
import { ComponentProps } from "react";

const withTransientProps = {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

const StyledModal = styled(Modal, withTransientProps)<{
  $scrollPosition?: number;
}>`
  .sr-modal-container {
    top: 50% !important;
    transform: translateY(calc(-50% + ${(props) => props.$scrollPosition}px));
  }

  .sr-modal-close {
    line-height: 14px;
  }
`;

interface CenteredModalProps extends ComponentProps<typeof Modal> {
  isOpen: boolean;
}

function CenteredModal({ isOpen, ...rest }: CenteredModalProps) {
  if (!isOpen) {
    return null;
  }

  const scrollYPos = window.scrollY || 0;

  return (
    <StyledModal
      datatest-id="StyledModal"
      $scrollPosition={scrollYPos}
      {...rest}
    />
  );
}

export { CenteredModal };
