/*
  This file is contains types from https://github.com/SunRun/experience-payments-ui/blob/6b41371/src/types/external-message-types.ts
  It contains all of the types we use to communicate between experience-payments-UI and offer-experience
*/

type Payload =
  | Inbound_InitializePayment_MessagePayload
  | Outbound_PaymentSuccess_MessagePayload
  | Inbound_InitializeAutopay_MessagePayload
  | Outbound_AutopaySuccess_MessagePayload
  | null;

type EventData = {
  title?: string;
  type: MessageType;
  payload: Payload;
};

enum MessageType {
  INBOUND_INITIALIZE_AUTOPAY = "SUNRUN_PAYMENTS_UI_INBOUND_INITIALIZE_AUTOPAY",
  INBOUND_INITIALIZE_PAYMENT = "SUNRUN_PAYMENTS_UI_INBOUND_INITIALIZE_PAYMENT",
  OUTBOUND_AUTOPAY_SUCCESS = "SUNRUN_PAYMENTS_UI_OUTBOUND_AUTOPAY_SUCCESS",
  OUTBOUND_PAYMENT_SUCCESS = "SUNRUN_PAYMENTS_UI_OUTBOUND_PAYMENT_SUCCESS",
  OUTBOUND_SESSION_EXPIRED = "SUNRUN_PAYMENTS_UI_OUTBOUND_SESSION_EXPIRED",
  OUTBOUND_WINDOW_RESIZE = "SUNRUN_PAYMENTS_UI_OUTBOUND_WINDOW_RESIZE",
  OUTBOUND_CANCEL_PAYMENT = "SUNRUN_PAYMENTS_UI_OUTBOUND_CANCEL_PAYMENT",
  OUTBOUND_LOG = "SUNRUN_PAYMENTS_UI_OUTBOUND_LOG",
}

interface Message<T = null> {
  payload: T;
  type: MessageType;
}

interface UserInfo {
  address: string;
  address2?: string;
  city: string;
  contactId?: string;
  email?: string;
  firstName: string;
  lastName: string;
  mobilePhoneNumber?: string;
  phoneNumber?: string;
  proposalId?: string;
  prospectId: string;
  ptoDate?: string;
  serviceContractNumber?: string;
  state: string;
  zip: string;
  billingType?: string;
}

// method chosen by the user in the parent app
enum PayMethod {
  CARD = "card",
  CHECKING = "echeck",
  SAVINGS = "savings",
}

enum BillingScheduleTypeEnum {
  STANDARD_MONTHLY = "STANDARD_MONTHLY", // Monthly after pto date. Users with ptoDate on or after 2015-05-01 all use this.
  LEGACY_CALENDAR_MONTHLY = "LEGACY_CALENDAR_MONTH", // Beginning of each calendar month. Users with ptoDate before 2015-05-01 still use this.
}

// Base message payload type inherited by all initialization messages
interface InitializeBase_MessagePayload {
  authToken: string;
  payMethod: PayMethod;
  userInfo: UserInfo;
  isIframe?: boolean;
  showCancelPaymentButton?: boolean;
  scheduledAutoPayDate?: string;
  billingScheduleType?: BillingScheduleTypeEnum;
}

// INBOUND_INITIALIZE_AUTOPAY
interface Inbound_InitializeAutopay_MessagePayload
  extends InitializeBase_MessagePayload {
  isUpdate?: boolean;
}

enum SpecialInvoiceType {
  FIELD_SERVICE = "1_FS_C",
  CONTRACT_MOD = "1_SC_C",
}

// INBOUND_INITIALIZE_PAYMENT
interface Inbound_InitializePayment_MessagePayload
  extends InitializeBase_MessagePayload {
  lastDueDate?: string; // ISO date
  totalAmountDue?: number;
  amount: number;
  autopayEnrollmentEnabled: boolean; // Specify whether or not autopay is allowed for this user or scenario
  referenceNumberPrefix?: SpecialInvoiceType; // Optional: Used with certain one time payment types such as contract mod (1_SC_C_) or field service invoices (1_FC_C_)
  invoiceDate?: string;
  invoiceDueDate?: string;
  isDeposit?: boolean;
}

// OUTBOUND_AUTOPAY_SUCCESS
interface Outbound_AutopaySuccess_MessagePayload {
  lastFour?: string;
  // submissionDate: string; //ISO date
}

// OUTBOUND_PAYMENT_SUCCESS
interface Outbound_PaymentSuccess_MessagePayload {
  autopayEnrolled?: boolean;
  autopayEnrollmentFailed?: boolean;
  lastFour?: string;
  amount?: string;
  // submissionDate: string; //ISO date
}

type LogLevel = "info" | "warning" | "error" | "debug";

interface OutboundLog {
  logLevel: LogLevel;
  message: string;
  params?: unknown;
}

export type {
  BillingScheduleTypeEnum,
  Inbound_InitializeAutopay_MessagePayload,
  Inbound_InitializePayment_MessagePayload,
  Message,
  Outbound_PaymentSuccess_MessagePayload,
  Outbound_AutopaySuccess_MessagePayload,
  OutboundLog,
  UserInfo,
  EventData,
};

export { MessageType, PayMethod };
