import { Typography, tokens, Field } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { ContractTerms } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { TitledSection } from "components/molecules/TitledSection";
import { formatCurrency, formatPercent } from "utils/formatHelpers";

const CurrentContractTerms = ({
  contractTerms,
}: {
  contractTerms: ContractTerms;
}) => {
  let paymentSpecificFields = [];
  switch (contractTerms.type) {
    case "Cash":
      paymentSpecificFields.push(
        <Field
          key="service-package"
          title="Service Package"
          value={contractTerms.servicePackage}
          testId="service-package"
        />,
        <Field
          key="total"
          title="Total Price (with tax)"
          value={formatCurrency(contractTerms.totalPrice, 2)}
          testId="total-price"
        />,
        <Field
          key="rate"
          title="Rate"
          value={`${formatCurrency(contractTerms.pricePerWatt, 3)}/W`}
          testId="rate"
        />
      );
      break;
    case "Loan":
      paymentSpecificFields.push(
        <Field
          key="service-package"
          title="Service Package"
          value={contractTerms.servicePackage}
          testId="service-package"
        />,
        <Field
          key="total"
          title="Total Loan amount (with tax)"
          value={formatCurrency(contractTerms.totalPrice, 2)}
          testId="total-loan"
        />,
        <Field
          key="rate"
          title="Rate"
          value={`${formatCurrency(contractTerms.pricePerWatt, 3)}/W`}
          testId="rate"
        />,
        <Row>
          <Field
            key="loan-term"
            title="Loan Term"
            value={`${contractTerms.termYears} years`}
            testId="loan-term"
          />
          <Field
            key="apr"
            title="APR"
            value={`${contractTerms.apr}%`}
            testId="apr"
          />
        </Row>
      );
      break;
    case "Monthly":
      paymentSpecificFields.push(
        <Field
          key="total"
          title="Est. Total Monthly Payment"
          value={formatCurrency(contractTerms.year1MonthlyPayment, 2)}
          testId="total-monthly"
        />,
        <Row>
          <Field
            key="rate"
            title="Rate"
            value={`${formatCurrency(contractTerms.year1RateKWh, 3)}/kWh`}
            testId="rate"
          />
          <Field
            key="escalator"
            title="Escalator"
            value={formatPercent(contractTerms.annualEscalator)}
            testId="escalator"
          />
        </Row>
      );
      break;
    case "Prepaid":
      paymentSpecificFields.push(
        <Field
          key="total"
          title="Total Price (with tax)"
          value={formatCurrency(contractTerms.totalPrice, 2)}
          testId="total-price"
        />,
        <Field
          key="rate"
          title="Rate"
          value={`${formatCurrency(contractTerms.pricePerWatt, 3)}/W`}
          testId="rate"
        />
      );
      break;
    case "FlexMonthly":
      const flexRate =
        contractTerms.consumptionRate - contractTerms.productionRate;
      paymentSpecificFields.push(
        <Field
          key="rate"
          title="Flex Rate"
          value={formatCurrency(flexRate, 3)}
          testId="rate"
        />,
        <Row>
          <Field
            key="estimated-monthly-payment"
            title="Est. Total Monthly Payment"
            value={`${formatCurrency(
              contractTerms.year1MonthlyPaymentMinimum,
              2
            )} | ${formatCurrency(
              contractTerms.year1MonthlyPaymentMaximum,
              2
            )}`}
            testId="estimated-monthly-payment"
          />
        </Row>
      );
      break;
  }

  return (
    <TitledSection>
      <Typography size={tokens.FONT_SIZE_3}>Current Contract Terms</Typography>
      <ContactTermsContent>
        <Field
          title="Agreement Type"
          value={contractTerms.type}
          testId="agreement-type"
        />
        <Field
          title="Product Bundle"
          value={contractTerms.productBundle}
          testId="product-bundle"
        />
        {paymentSpecificFields}
      </ContactTermsContent>
    </TitledSection>
  );
};

const ContactTermsContent = styled.div`
  > * + * {
    border-top: 1px solid ${tokens.OFFWHITE_40};
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
`;

export { CurrentContractTerms };
