import styled from "@emotion/styled/macro";
import { type UseFormReturn } from "react-hook-form";
import { Contact } from "@sunrun/experience-ui-components/lib/components/ContactCard";
import { type SplatUtilityResponse } from "../../../amplify/backend/function/OfferExpUsage/ts/public/types";
import { UtilityInfo } from "./UtilityInfo";
import { UsageInfo } from "./UsageInfo";
import { DocumentUpload } from "./DocumentUpload";
import { UsagePageContacts } from "./UsagePageContacts";
import { VoidProposalsConfirmationModal } from "components/molecules/VoidProposalsConfirmationModal";
import { Attachment, UsageCadence, UtilityInfoState } from "utils/usageUtils";
import { UpdateContactWarningBanner } from "checkout/components/molecules/UpdateContactWarningBanner";

type UsagePageContentProps = {
  monthlyUsage: (number | undefined)[] | null;
  setMonthlyUsage: (arr: (number | undefined)[]) => void;
  showVoidProposalModal: boolean;
  setShowVoidProposalModal: (show: boolean) => void;
  handleSave: (voidProposals: boolean | undefined) => void;
  modifiedField: string | null;
  utilitiesOptions?: SplatUtilityResponse[];
  formUtils: UseFormReturn<UtilityInfoState>;
  usageCadence: UsageCadence;
  setUsageCadence: (cadence: UsageCadence) => void;
  attachments: Attachment[];
  newAttachments: Attachment[];
  setNewAttachments: (files: Attachment[]) => void;
  selectedPrimaryContact?: Contact;
  setSelectedPrimaryContact: (contact: Contact) => void;
  selectedUtilityContact?: Contact;
  setSelectedUtilityContact: (contact: Contact) => void;
  refSetter?: (node: HTMLDivElement | null) => void;
  showUtilityBillWarningBanner: boolean;
  setShowUtilityBillWarningBanner: (show: boolean) => void;
  hasOffers?: boolean;
};

const UsagePageContent: React.FC<UsagePageContentProps> = ({
  monthlyUsage,
  setMonthlyUsage,
  showVoidProposalModal,
  setShowVoidProposalModal,
  handleSave,
  modifiedField,
  utilitiesOptions,
  formUtils,
  usageCadence,
  setUsageCadence,
  attachments,
  newAttachments,
  setNewAttachments,
  selectedPrimaryContact,
  setSelectedPrimaryContact,
  selectedUtilityContact,
  setSelectedUtilityContact,
  refSetter,
  showUtilityBillWarningBanner,
  setShowUtilityBillWarningBanner,
  hasOffers,
}) => {
  return (
    <StyledUsagePageContent>
      {showUtilityBillWarningBanner && (
        <UpdateContactWarningBanner
          contactType="utility_bill"
          onClose={() => setShowUtilityBillWarningBanner(false)}
        />
      )}
      {showVoidProposalModal && modifiedField && (
        <VoidProposalsConfirmationModal
          onCancel={() => setShowVoidProposalModal(false)}
          onConfirm={() => handleSave(true)}
          modifiedField={modifiedField}
        />
      )}
      <DocumentUpload
        uploadedFiles={attachments}
        uploadedNewFiles={newAttachments}
        setUploadedNewFiles={setNewAttachments}
        documentType="Utility Bill"
        description="Upload monthly utility bill(s) for review."
        title="Utility Bills"
      />
      <UsageInfo
        monthlyUsageData={monthlyUsage}
        setMonthlyUsageData={setMonthlyUsage}
        usageCadence={usageCadence}
        setUsageCadence={setUsageCadence}
      />
      <UtilityInfo utilitiesOptions={utilitiesOptions} formUtils={formUtils} />
      <UsagePageContacts
        selectedPrimaryContact={selectedPrimaryContact}
        setSelectedPrimaryContact={setSelectedPrimaryContact}
        selectedUtilityContact={selectedUtilityContact}
        setSelectedUtilityContact={setSelectedUtilityContact}
        refSetter={refSetter}
        hasOffers={hasOffers}
      />
    </StyledUsagePageContent>
  );
};

const StyledUsagePageContent = styled.div`
  display: grid;
  grid-row-gap: 32px;
  margin-bottom: 32px;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export { UsagePageContent };
export type { UsagePageContentProps };
