import { API } from "aws-amplify";
import { loader } from "graphql.macro";
import flagsmith from "flagsmith";
import {
  OfferSuccess,
  ListOffer,
  ReviewOffer,
  OfferLineCreate,
  OfferLineUpdate,
  EditOffer,
  EditOfferInitialize,
  EditOfferProductAvailability,
  PricingOffer,
  SyncSalesforceResponse,
} from "../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { AcpMarketType } from "../../amplify/backend/function/offerexpstoreFrontApi/ts/public/constants";
import { storeFront, gql } from "./storeFrontApolloConnection";
import { UnifiedPricingData } from "providers/pricing/PricePoints";
import { NewOfferData } from "providers/Types";
import {
  CASH,
  FLEX_MONTHLY,
  LOAN,
  MONTHLY,
  PREPAID,
} from "constants/financialProducts";

const performRequest = async <Response>(
  method: "get" | "post" | "del" | "patch",
  path: string,
  jwt?: string,
  body?: any
): Promise<Response> => {
  let headers = {};
  if (jwt) {
    headers = {
      Authorization: `Bearer ${jwt}`,
    };
  }
  const response = await API[method]("OfferExpApi", path, {
    headers,
    body: JSON.stringify(body),
  });
  return response as Response;
};

const getOffer = async (
  jwt: string,
  offerId: string,
  identity: string
): Promise<ReviewOffer> => {
  return await performRequest<ReviewOffer>(
    "get",
    `/offers/${offerId}?identity=${identity}`,
    jwt
  );
};

const getEditOffer = async (
  jwt: string,
  offerId: string
): Promise<EditOffer> => {
  return await performRequest<EditOffer>("get", `/offers/edit/${offerId}`, jwt);
};

const getPricingOffer = async (
  jwt: string,
  offerId: string
): Promise<PricingOffer> => {
  return await performRequest<PricingOffer>(
    "get",
    `/offers/pricing/${offerId}`,
    jwt
  );
};

const listOffers = async (
  jwt: string,
  prospectId: string,
  identity: string
): Promise<ListOffer[]> => {
  return await performRequest<ListOffer[]>(
    "get",
    `/offers/prospect/${prospectId}?identity=${identity}`,
    jwt
  );
};

const customerListOffers = async (
  proposalToken: string
): Promise<ListOffer[]> => {
  return await performRequest<ListOffer[]>("get", `/customer/${proposalToken}`);
};

const customerGetOffer = async (
  proposalToken: string,
  offerId: string
): Promise<ReviewOffer> => {
  return await performRequest<ReviewOffer>(
    "get",
    `/customer/${proposalToken}/${offerId}`
  );
};

// Create will return a new offer that is empty
// const createOffer = async (
//   jwt: string,
//   prospectId: string
// ): Promise<OfferSuccess> => {
//   return await performRequest<OfferSuccess>("post", "/offers/create", jwt, {
//     prospectId,
//   });
// };

// Empty will return an offer that is guaranteed empty, but is not necessarily new
const emptyOffer = async (
  jwt: string,
  prospectId: string,
  updatedFields?: string[]
): Promise<OfferSuccess> => {
  return await performRequest<OfferSuccess>("post", "/offers/empty", jwt, {
    prospectId,
    updatedFields,
  });
};

const createOfferInitialize = async (
  jwt: string,
  newOffer: NewOfferData
): Promise<EditOfferInitialize> => {
  const products = newOffer.productAvailability;
  const initialBundle = products.bundles[0];
  const initialProducts = products.products.filter(
    (product) => initialBundle && initialBundle.productIds.includes(product.id)
  );
  const initialOfferLines = initialProducts.map((product) => {
    const initialOfferLine = {
      variantId: product.defaultVariantId,
    } as OfferLineCreate;
    if (product.type === "OFF_THE_SHELF") {
      initialOfferLine.quantity = 1;
    }
    return initialOfferLine;
  });
  if (!initialOfferLines || initialOfferLines.length === 0) {
    return {
      offerId: newOffer.offerId,
      offer: undefined,
      products,
    };
  }
  const offerLinesResponse = await createOfferLines(
    jwt,
    newOffer.offerId,
    initialOfferLines,
    newOffer.lockVersion
  );
  return {
    offerId: offerLinesResponse.id,
    offer: offerLinesResponse,
    products,
  };
};

const createOfferLines = async (
  jwt: string,
  offerId: string,
  lines: Array<OfferLineCreate>,
  expectedLockVersion: number
): Promise<EditOffer> => {
  return await performRequest("post", `/offers/${offerId}/lines`, jwt, {
    lines,
    expectedLockVersion,
  });
};

const copyOffer = async (
  jwt: string,
  offerId: string
): Promise<OfferSuccess> => {
  return await performRequest<OfferSuccess>("post", "/offers/copy", jwt, {
    offerId,
  });
};

const updateOfferLines = async (
  jwt: string,
  offerId: string,
  lines: Array<OfferLineUpdate>,
  expectedLockVersion: number,
  designId?: string,
  designVersion?: number
): Promise<EditOffer> => {
  return await performRequest("patch", `/offers/${offerId}/lines`, jwt, {
    lines,
    expectedLockVersion,
    designId,
    designVersion,
  });
};

const deleteOfferLines = async (
  jwt: string,
  offerId: string,
  lineIds: Array<string>,
  expectedLockVersion: number
): Promise<EditOffer> => {
  return await performRequest("del", `/offers/${offerId}/lines`, jwt, {
    lineIds,
    expectedLockVersion,
  });
};

const deleteOffer = async (
  jwt: string,
  offerId: string,
  expectedLockVersion: number
): Promise<void> => {
  return await performRequest("del", `/offers/${offerId}`, jwt, {
    expectedLockVersion,
  });
};

const syncSalesforce = async (
  jwt: string,
  prospectId: string,
  parentProposalId?: string,
  signedRootId?: string
): Promise<void> => {
  const res = await performRequest<SyncSalesforceResponse>(
    "post",
    `/offers/prospect/${prospectId}/sync`,
    jwt,
    {
      parentProposalId,
      signedRootId,
    }
  );
  return await new Promise((resolve, reject) => {
    if (!res?.syncOffersFromSalesforceAsync) {
      reject(
        new Error(
          `Error While Syncing No Async Token Given, ${JSON.stringify(res)}`
        )
      );
    } else {
      onDomainEvent(res.syncOffersFromSalesforceAsync).subscribe((res) => {
        const errors = res.data?.onDomainEvent.errors || [];
        if (errors.length) {
          reject(new Error(`Error While Syncing, ${JSON.stringify(errors)}`));
        } else {
          resolve();
        }
      });
    }
  });
};

const amendOffer = async (
  jwt: string,
  prospectId: string,
  parentProposalId: string,
  signedRootId: string
): Promise<string> => {
  return await performRequest(
    "post",
    `/offers/prospect/${prospectId}/amend`,
    jwt,
    {
      parentProposalId,
      signedRootId,
    }
  );
};

const amendOfferWithSync = async (
  jwt: string,
  prospectId: string,
  parentProposalId: string,
  signedRootId: string
): Promise<string> => {
  try {
    return await amendOffer(jwt, prospectId, parentProposalId, signedRootId);
  } catch (e) {
    await syncSalesforce(jwt, prospectId, parentProposalId, signedRootId);
    return await amendOffer(jwt, prospectId, parentProposalId, signedRootId);
  }
};

const getProducts = async (
  jwt: string,
  offerId: string,
  state: string,
  market: AcpMarketType,
  ePermitting: boolean,
  identity: string
): Promise<EditOfferProductAvailability> => {
  return await performRequest(
    "get",
    `/products/${offerId}?state=${state}&acpMarketType=${market}&ePermitting=${ePermitting}&identity=${identity}`,
    jwt
  );
};

const submitOffer = (
  offerId: string,
  pricePointId: string,
  expectedLockVersion: number,
  oneHost: string,
  evSpanAvailable: boolean
) => {
  let proposalOrigin = "Offer Experience";
  if (evSpanAvailable) {
    proposalOrigin = "Offer Experience D2";
  }
  let host = "One (Offer Experience)";
  if (oneHost === "LIGHTMILE") {
    host = "Lightmile (Offer Experience)";
    proposalOrigin = "Offer Experience D2";
  }
  const submitOfferQuery = gql`
    mutation submitOffer(
      $offerId: ID!
      $pricePointId: ID!
      $expectedLockVersion: Int!
      $host: String!
      $proposalOrigin: String!
    ) {
      submitOffer(
        expectedLockVersion: $expectedLockVersion
        offerId: $offerId
        pricePointId: $pricePointId
        host: $host
        proposalOrigin: $proposalOrigin
      ) {
        correlationId
      }
    }
  `;
  return storeFront
    .mutate<{
      submitOffer: {
        correlationId: string;
      };
    }>({
      mutation: submitOfferQuery,
      variables: {
        offerId,
        pricePointId,
        expectedLockVersion,
        host,
        proposalOrigin,
      },
    })
    .then((result) => {
      if (result.errors && result.errors.length > 0) {
        throw result.errors[0];
      }
      return result;
    });
};

type onDomainEventResponse = {
  onDomainEvent: {
    correlationId: string;
    errors: string[];
    occurredOn: string;
  };
};
const onDomainEvent = (correlationId: string) => {
  const onDomainEventQuery = gql`
    subscription onDomainEvent($correlationId: ID) {
      onDomainEvent(correlationId: $correlationId) {
        __typename
        ... on ErrorOccurred {
          __typename
          errors
          correlationId
          occurredOn
        }
        ... on OfferSubmitted {
          __typename
          correlationId
          offerId
          occurredOn
        }
        ... on OffersSynced {
          __typename
          correlationId
          numOffersImported
          numOffersUpdated
          occurredOn
          errors
        }
      }
    }
  `;
  return storeFront.subscribe<onDomainEventResponse>({
    query: onDomainEventQuery,
    variables: {
      correlationId,
    },
  });
};

const getPricePoints = async (bearerToken: string, offerId: string) => {
  const query = loader("./getPricing.gql");
  const cashPricePoints = storeFront
    .query<{ getPricePoints: UnifiedPricingData }>({
      query,
      variables: {
        offerId,
        financialProduct: CASH,
      },
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.error) {
        throw result.error;
      }
      if (result.errors && result.errors.length > 0) {
        throw result.errors[0];
      }
      return result;
    });
  const monthlyPricePoints = storeFront
    .query<{ getPricePoints: UnifiedPricingData }>({
      query,
      variables: {
        offerId,
        financialProduct: MONTHLY,
      },
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.error) {
        throw result.error;
      }
      if (result.errors && result.errors.length > 0) {
        throw result.errors[0];
      }
      return result;
    });
  const flexMonthlyPoints = !flagsmith.hasFeature("flex-monthly-get-pricing")
    ? ({} as any)
    : storeFront
        .query<{ getPricePoints: UnifiedPricingData }>({
          query,
          variables: {
            offerId,
            financialProduct: FLEX_MONTHLY,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          if (result.error) {
            throw result.error;
          }
          if (result.errors && result.errors.length > 0) {
            throw result.errors[0];
          }
          return result;
        });
  const prepaidPricePoints = storeFront
    .query<{ getPricePoints: UnifiedPricingData }>({
      query,
      variables: {
        offerId,
        financialProduct: PREPAID,
      },
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.error) {
        throw result.error;
      }
      if (result.errors && result.errors.length > 0) {
        throw result.errors[0];
      }
      return result;
    });
  const loanPricePoints = storeFront
    .query<{ getPricePoints: UnifiedPricingData }>({
      query,
      variables: {
        offerId,
        financialProduct: LOAN,
      },
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.error) {
        throw result.error;
      }
      if (result.errors && result.errors.length > 0) {
        throw result.errors[0];
      }
      return result;
    });

  return Promise.all([
    cashPricePoints,
    monthlyPricePoints,
    prepaidPricePoints,
    loanPricePoints,
    flexMonthlyPoints,
  ]).then((results) => {
    return {
      cash: results[0].data.getPricePoints.cash,
      monthly: results[1].data.getPricePoints.monthly,
      prepaid: results[2].data.getPricePoints.prepaid,
      loan: results[3].data.getPricePoints.loan,
      flexMonthly: results[4]?.data?.getPricePoints?.flexMonthly,
    } as UnifiedPricingData;
  });
};

export {
  amendOffer,
  amendOfferWithSync,
  copyOffer,
  createOfferInitialize,
  createOfferLines,
  customerGetOffer,
  customerListOffers,
  deleteOffer,
  deleteOfferLines,
  emptyOffer,
  getEditOffer,
  getOffer,
  getPricePoints,
  getPricingOffer,
  getProducts,
  listOffers,
  onDomainEvent,
  storeFront,
  submitOffer,
  syncSalesforce,
  updateOfferLines,
};
