import { useParams } from "react-router-dom";
import { LoadingIndicator } from "@sunrun/experience-ui-components";
import { useCallback, useEffect, useState } from "react";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { HeaderContentLayout } from "components/templates/HeaderContentLayout";
import { copyOffer, listOffers, syncSalesforce } from "services/storeFront";
import { useAppSelector } from "store";
import { getOfferFromProposalId } from "utils/offerListHelpers";
import { useNavigateToBuild } from "utils/useNavigateHelper";

export const CopyProposalPage = () => {
  const navigateToBuild = useNavigateToBuild();
  const [error, setError] = useState<Error>();

  const { prospectId, proposalId } = useParams();
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const identity = useAppSelector((state) => state.auth.identity);

  const navigateToCopyOffer = useCallback(() => {
    if (!cognitoToken || !proposalId || !prospectId) return;
    return listOffers(cognitoToken, prospectId, identity)
      .then((data) => {
        if (!data || data.length <= 0) return false;
        const offerToCopy = getOfferFromProposalId(data, proposalId);
        if (!offerToCopy) return false;
        copyOffer(cognitoToken, offerToCopy.id)
          .then((result) => {
            navigateToBuild(
              result.id,
              offerToCopy.designId || offerToCopy.proposalId
            );
          })
          .catch((error) => {
            setError(error);
          });
      })
      .catch((error) => {
        setError(error);
      });
  }, [cognitoToken, navigateToBuild, proposalId, prospectId, identity]);

  useEffect(() => {
    if (!cognitoToken || !proposalId || !prospectId) return;

    try {
      const success = navigateToCopyOffer();
      if (!success) {
        syncSalesforce(cognitoToken, prospectId, proposalId).then(() =>
          navigateToCopyOffer()
        );
      }
    } catch {
      setError(error);
    }
  }, [
    cognitoToken,
    error,
    navigateToBuild,
    navigateToCopyOffer,
    proposalId,
    prospectId,
  ]);

  if (error) {
    return (
      <HeaderContentLayout
        content={<ErrorComponent context="OfferListPage" error={error} />}
        title="There was a Problem."
        description="Something went wrong. Please try refreshing the page."
      />
    );
  }
  return <LoadingIndicator fullScreen color="black" showLoadingMessage />;
};
