import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

const CARD_PADDING = "24px";

const Card = styled.div`
  background: ${tokens.TINTS_OFF_WHITE_80};
  border-color: ${tokens.BORDER_LIGHT};
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: 0;
`;

const CardHeader = styled.header`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin: ${CARD_PADDING};
  padding: 0;
`;

const CardSectionDivider = styled.hr`
  background-color: ${tokens.OFFWHITE_40};
  border: none;
  height: 1px;
  margin: ${CARD_PADDING} 0;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: ${CARD_PADDING};
`;

const CardFooter = styled(CardHeader)`
  margin-top: 0;
`;

export { Card, CardHeader, CardFooter, CardContent, CardSectionDivider };
