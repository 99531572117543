/* eslint-disable import/no-restricted-paths */
import styled from "@emotion/styled/macro";

type PageHeaderOrganismProps = {
  title?: string;
  description?: string;
  kicker?: string;
  className?: string;
};

const PageHeaderOrganism = ({
  title,
  description,
  kicker,
  className,
}: PageHeaderOrganismProps) => {
  return (
    <PageHeaderOrganismContainer className={className}>
      <HeaderTitleSection>
        {kicker && <Kicker>{kicker}</Kicker>}
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </HeaderTitleSection>
    </PageHeaderOrganismContainer>
  );
};

const PageHeaderOrganismContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  padding-top: 28px;
  padding-bottom: 36px;
  width: 90%;
  margin: 0 auto;
`;

const HeaderTitleSection = styled.div``;

const Title = styled.div`
  font-size: 44px;
  color: #1f2647;
  padding-bottom: 16px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #474643;
`;

const Kicker = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #1f2647;
  padding-bottom: 12px;
`;

export { PageHeaderOrganism, PageHeaderOrganismContainer };
