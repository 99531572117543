import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

const CARD_LR_PADDING = "24px";

// Taken from https://www.figma.com/file/SGPBzrHisRa1x1bv5y5RBF/Base-Library?type=design&node-id=42-28295&t=Z5mpz3BLmW0rKn8t-0
const Card = styled.div`
  box-sizing: border-box;
  background: ${tokens.TINTS_OFF_WHITE_80};
  border-color: ${tokens.BORDER_LIGHT};
  border-width: 1px;
  border-style: solid;
  padding: 32px ${CARD_LR_PADDING};
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

const CardSectionDivider = styled.hr`
  border: none;
  background-color: ${tokens.OFFWHITE_40};
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const CardHeaderDivider = styled(CardSectionDivider)`
  margin: 24px -${CARD_LR_PADDING};
`;

export { Card, CardSectionDivider, CardHeaderDivider };
