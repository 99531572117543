import Sockette from "sockette";
import axios from "axios";
import { getEnv } from "./env";
import { rollbar } from "providers/rollbar";

const subscribeToAsyncRequest = (authToken: string, asyncRequestId: string) => {
  const env = getEnv();

  return new Promise<any>((resolve, reject) => {
    const ws = new Sockette(
      env === "production"
        ? `wss://n45oroy0ni.execute-api.us-west-2.amazonaws.com/prod?hybrid_token=${authToken}`
        : `wss://tgbg85y0g2.execute-api.us-west-2.amazonaws.com/prod?hybrid_token=${authToken}`,
      {
        timeout: 5e3,
        maxAttempts: 10,
        onopen: () => {
          ws.json({ requestId: asyncRequestId });
        },
        onmessage: (message) => {
          ws.close();
          if (message && message.data) {
            const data = JSON.parse(message.data);
            if (data.error) {
              return reject(data.error);
            }
            if (data.asyncResponseS3Location) {
              return axios
                .get(data.asyncResponseS3Location)
                .then((res) => {
                  resolve(res);
                })
                .catch(reject);
            }
            if (data.asyncRequestId && Object.keys(data).length === 1) {
              return subscribeToAsyncRequest(
                authToken,
                data.asyncRequestId
              ).then((res) => resolve(res));
            }
            return resolve(data);
          }
          rollbar.error("No message.data provided in async response", message);
          return reject(
            new Error("No message.data provided in async response.")
          );
        },
        onerror: (err: any) => {
          ws.close();
          reject(err);
        },
      }
    );
  });
};

export { subscribeToAsyncRequest };
