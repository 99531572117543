import { Typography } from "@sunrun/experience-ui-components";
import { ReviewMonthlySummary } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import {
  RowContainer,
  RowContainerWithModal,
  RowContainerWithTooltip,
} from "../atoms/PriceGlobals";
import {
  BreakdownBox,
  BreakdownRows,
  FirstColumn,
  Header,
  HeroText,
  StandardText,
  Total,
  TotalRow,
  StyledSecondColumn,
  StyledCategory,
  StyledHeader,
  StyledFootnote,
} from "components/atoms/GlobalStyles";
import { formatCurrency, formatPercent } from "utils/formatHelpers";
import { Warranties } from "components/atoms/Warranties";
import { CardSectionDivider } from "components/atoms/Card";
import {
  COSTCO_CARD_ESTIMATE,
  LIFETIME_SAVINGS,
  POST_SOLAR_UTILITY_BILL,
  SRECII,
} from "constants/pricing-tooltips";

const PriceMonthlyTPO = ({ summary }: { summary: ReviewMonthlySummary }) => {
  // in the first year you'll pay something with the escalator or no escalator if there is a down payment to flatten the escalator
  // different product options through Costco - differences in the contract

  return (
    <>
      <BreakdownBox>
        <BreakdownRows>
          <FirstColumn>
            <Header>Have peace of mind</Header>
            <StandardText>
              Know that you're covered with premium protection.
            </StandardText>
            <Warranties warranties={summary.warranties} />
          </FirstColumn>
          <StyledSecondColumn>
            <Header>
              Total Monthly Payment
              {summary.annualEscalator > 0 ? " in the first year" : ""}
            </Header>
            <HeroText>{formatCurrency(summary.firstYearMonthly)}/mo.</HeroText>

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Terms</Typography>
              </StyledHeader>

              <RowContainer
                label="Year 1 Energy Rate"
                cost={summary.year1RateKWh}
                decimals={3}
                unit="/kWh"
              />

              <RowContainer
                label="Annual Escalator on Monthly Payment"
                cost={`${formatPercent(summary.annualEscalator)}`}
              />

              <RowContainer
                label="Agreement Term"
                cost={summary.termYears.toString()}
                unit="Years"
              />
            </StyledCategory>

            {(summary.rebates > 0 ||
              summary.srec > 0 ||
              summary.costcoCard > 0) && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Rebates & Incentives</Typography>
                </StyledHeader>

                {summary.rebates > 0 && (
                  <RowContainer
                    label="Estimated State and Local Rebates"
                    cost={summary.rebates}
                  />
                )}

                {summary.srec > 0 && (
                  <RowContainerWithTooltip
                    tooltip={SRECII}
                    label="SRECII (SREC2)"
                    cost={summary.srec}
                  />
                )}

                {summary.costcoCard > 0 && (
                  <RowContainerWithTooltip
                    tooltip={COSTCO_CARD_ESTIMATE}
                    label="Est. Costco Shop Card"
                    cost={summary.costcoCard}
                  />
                )}
              </StyledCategory>
            )}

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Monthly Payments</Typography>
              </StyledHeader>

              {summary.market === "Y1_MONTHLY_PAYMENTS" && (
                <>
                  <RowContainer
                    label="Estimated Monthly Avg. Pre-Solar Utility Bill"
                    cost={summary.preSolarUtility}
                    unit="/mo"
                  />
                  <CardSectionDivider />
                </>
              )}

              {summary.market === "CONTROL" && (
                <RowContainerWithModal
                  label="Total Monthly Sunrun Bill"
                  ariaLabel="Total Monthly Sunrun Bill"
                  modalDescription="You will still have a bill with your utility company. The amount you owe your utility depends on utility rate schedules, fixed charges, seasonal changes in solar output, and if you use more power than you did in the past."
                  cost={formatCurrency(summary.firstYearMonthly)}
                  unit="/mo"
                />
              )}
              {summary.market !== "CONTROL" && (
                <>
                  <RowContainer
                    label="Total Monthly Sunrun Bill"
                    cost={formatCurrency(summary.firstYearMonthly)}
                    unit="/mo"
                  />

                  <RowContainerWithModal
                    modalDescription={POST_SOLAR_UTILITY_BILL}
                    label="Avg. Est. Post-Solar Utility Bill"
                    ariaLabel="Open Avg. Est. Post-Solar Utility Bill Modal"
                    cost={summary.postSolarUtility}
                    unit={"/mo"}
                  />
                </>
              )}
            </StyledCategory>

            {summary.market === "SAVINGS" && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Savings</Typography>
                </StyledHeader>

                <RowContainerWithModal
                  modalDescription={LIFETIME_SAVINGS}
                  label="Est. Lifetime Savings"
                  ariaLabel="Open Savings modal"
                  cost={`${formatCurrency(
                    summary.savingsLow
                  )} - ${formatCurrency(summary.savingsHigh)}`}
                />
              </StyledCategory>
            )}
          </StyledSecondColumn>
        </BreakdownRows>
        <TotalRow>
          <Total>Due at Signing</Total>
          <Total>{formatCurrency(summary.dueAtSigning)}</Total>
        </TotalRow>
        {summary.evChargerValue && (
          <TotalRow>
            <Total>EV Charger: Due at Installation</Total>
            <Total>{formatCurrency(summary.evChargerValue)}</Total>
          </TotalRow>
        )}
      </BreakdownBox>
      <StyledFootnote>
        Valid until {new Date(summary.expires).toLocaleDateString()}
      </StyledFootnote>
    </>
  );
};

export { PriceMonthlyTPO };
