import styled from "@emotion/styled/macro";
import { useFlags } from "flagsmith/react";
import { FeatureFlags } from "fixtures/features";

const Disclaimer = () => {
  const flags = useFlags([FeatureFlags.UPDATED_DISCLAIMERS]);

  const showAdditionalDisclaimers =
    flags[FeatureFlags.UPDATED_DISCLAIMERS]?.enabled;

  return (
    <>
      <p>
        <b>Proposals</b>: This proposal is an estimate and does not guarantee
        actual system production or savings. The system design may change based
        on a detailed engineering site survey. Actual system production and
        savings will vary based on the final system size, design, configuration,
        utility rates, applicable rebates, actual sales tax owed by you, and
        your family’s energy usage.
      </p>
      <p>
        <b>System Design</b>: The system design may change based on a detailed
        engineering site audit. Actual system production, offset and savings
        will vary based on the final system size, design, configuration, utility
        rates, applicable rebates, system location, local climate, and your
        family’s energy usage.
      </p>
      <p>
        <b>Energy Rate</b>: Sunrun estimates your implied year 1 energy rate
        with your utility by dividing your estimated annual utility bill by your
        estimated annual energy consumption. Similarly, we calculate your
        implied year 1 Sunrun energy rate by dividing your annual Sunrun bill by
        your solar system’s estimated annual production.
      </p>
      <p>
        <b>Utility Yearly Increase</b>: Yearly increase is an estimate only and
        is subject to change.
      </p>
      <p>
        <b>Savings</b>: Under Net Energy Metering (“NEM”) 3.0, solar customers
        are required to be on a time-of-use ("TOU") tariff and to pay
        non-bypassable charges for all of their energy, including exports. This
        savings calculation assumes the customer is on a TOU tariff after going
        solar, and the post-solar bills are based on a typical customer's
        consumption and solar production load profile.
      </p>
      <p>
        <b>Sales Tax</b>: This number is an estimate of your anticipated sales
        and/or local tax.
      </p>
      <p>
        <b>SREC</b>: Depending on the jurisdiction in which your system is
        located, your system may produce solar renewable energy credits
        (“SRECs”). Please consult&nbsp;
        <a href="https://srectrade.com/" target="_blank" rel="noreferrer">
          SRECTrade | Home
        </a>{" "}
        and&nbsp;
        <a
          href="https://resource-solutions.org/wp-content/uploads/2015/07/Guidelines-for-Renewable-Energy-Claims.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://resource-solutions.org/wp-content/uploads/2015/07/Guidelines-for-Renewable-Energy-Claims.pdf
        </a>
        &nbsp;for more information about SRECs. Please consult your agreement to
        see how SRECs produced by your system will be treated. Estimate only.
        Sunrun does not facilitate the sale of SRECs, nor guarantee the number
        produced or market price. You are solely responsible for registering
        your PV system with your applicable SREC Program Administrator and for
        all sales of SRECs. SREC values are subject to actual system production
        in future dates; changes in the SREC program and changes to SREC market
        conditions, SREC laws, regulations and policies; and future SREC price
        changes.
      </p>
      <p>
        <b>SGIP</b>: Sunrun pricing is contingent upon Sunrun filing for and
        collecting the SGIP storage incentive, which will require the customer
        to provide documentation and a signature allowing Sunrun to claim the
        incentive on his/her behalf.
      </p>
      <p>
        <b>Federal Solar Tax Credit</b>: The federal solar tax credit gives you
        a dollar-for-dollar reduction against your federal income tax. You must
        file for the Federal Solar Tax Credit in your next federal income tax
        return. Amount is estimated. The purchaser of a solar system may qualify
        for certain federal, state, local or other rebates, tax credits or
        incentives. No representation or warranty is made as to whether such
        credits will be available to the purchaser. Please consult a qualified
        tax professional for assistance and details.
      </p>
      <p>
        <b>State and Local Rebates and Credits</b>: You are solely responsible
        for claiming the state and local tax credit or rebate.
      </p>
      <p>
        <b>Costco Cash Card</b>: Costco Cash Card amount is an estimate only and
        will be verified by Sunrun prior to system installation. The amount is
        based on the pre-tax price of the modules, racking, and inverters used
        for your system. Installation is not included.
      </p>
      <p>
        <ACPTable data-testid="offer-experience--Disclaimer--modal--acpTable">
          <thead>
            <tr>
              <th>State</th>
              <th>Utility</th>
              <th>ACP Disclaimer</th>
            </tr>
          </thead>
          <tbody>
            {showAdditionalDisclaimers && (
              <>
                <tr>
                  <td>AZ</td>
                  <td>APS</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on a time-of-use (&quot;TOU&quot;) export tariff after
                    going solar, and the post-solar bills are based on a typical
                    customer&apos;s consumption and solar production load
                    profile.
                  </td>
                </tr>
                <tr>
                  <td>AZ</td>
                  <td>TEP</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on a time-of-use (&quot;TOU&quot;) export tariff after
                    going solar, and the post-solar bills are based on a typical
                    customer&apos;s consumption and solar production load
                    profile.
                  </td>
                </tr>
                <tr>
                  <td>AZ</td>
                  <td>SRP</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on a time-of-use (&quot;TOU&quot;) export tariff after
                    going solar, and the post-solar bills are based on a typical
                    customer&apos;s consumption and solar production load
                    profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>LADWP</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-1-A tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>SMUD</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-TOD tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Roseville Electric</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Imperial Irrigation District</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule D tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Merced Irrigation District</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RES-2 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Modesto Irrigation District</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule D-NEM2 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Redding Electric Utility</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule E-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Colton Electric Utility</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Lathrop Irrigation District</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Lodi Electric Utility Department</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule EA tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>APU</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule DS-A tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Riverside Public Utilities</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule D tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Pasadena</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Burbank</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule Residential tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Moreno Valley Electric Utility</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule A-Non TOU tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Turlock Irrigation District</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule DE tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Glendale Water & Power</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule L-1-A tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Banning Electric Utility</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CA</td>
                  <td>Silicon Valley Power</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule D-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>CA</td>
              <td>PG&E</td>
              <td>
                Savings: Under Net Energy Metering (&quot;NEM&quot;) 3.0, solar
                customers are required to be on a time-of-use (&quot;TOU&quot;)
                tariff and to pay non-bypassable charges for all of their
                energy, including exports. This savings calculation assumes the
                customer is on a TOU tariff after going solar, and the
                post-solar bills are based on a typical customer&apos;s
                consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>CA</td>
              <td>SCE</td>
              <td>
                Savings: Under Net Energy Metering (&quot;NEM&quot;) 3.0, solar
                customers are required to be on a time-of-use (&quot;TOU&quot;)
                tariff and to pay non-bypassable charges for all of their
                energy, including exports. This savings calculation assumes the
                customer is on a TOU tariff after going solar, and the
                post-solar bills are based on a typical customer&apos;s
                consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>CA</td>
              <td>SDG&E</td>
              <td>
                Savings: Under Net Energy Metering (&quot;NEM&quot;) 3.0, solar
                customers are required to be on a time-of-use (&quot;TOU&quot;)
                tariff and to pay non-bypassable charges for all of their
                energy, including exports. This savings calculation assumes the
                customer is on a TOU tariff after going solar, and the
                post-solar bills are based on a typical customer&apos;s
                consumption and solar production load profile.
              </td>
            </tr>
            {showAdditionalDisclaimers && (
              <>
                <tr>
                  <td>CO</td>
                  <td>XCEL</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CO</td>
                  <td>IREA</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule A tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CO</td>
                  <td>United Power</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CO</td>
                  <td>Colorado Springs Utilities</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule E1R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CO</td>
                  <td>Fort Collins Utilities</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-TOD tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CO</td>
                  <td>Mountain View Electric Association</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CT</td>
                  <td>Eversource</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>CT</td>
                  <td>United Illuminating</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>DC</td>
                  <td>Pepco</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>FL</td>
                  <td>Duke Energy</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>FL</td>
                  <td>FPL</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>FL</td>
                  <td>TECO Energy</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>FL</td>
                  <td>OUC</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>FL</td>
                  <td>Withlacoochee River Elec Coop</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>FL</td>
                  <td>Sumter Electric Coop</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>HI</td>
              <td>HECO</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                a time-of-use (&quot;TOU&quot;) SMART DER export tariff after
                going solar, and the post-solar bills are based on a typical
                customer&apos;s consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>HI</td>
              <td>HELCO</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                a time-of-use (&quot;TOU&quot;) SMART DER export tariff after
                going solar, and the post-solar bills are based on a typical
                customer&apos;s consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>HI</td>
              <td>MECO</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                a time-of-use (&quot;TOU&quot;) SMART DER export tariff after
                going solar, and the post-solar bills are based on a typical
                customer&apos;s consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>HI</td>
              <td>KIUC</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                the Schedule D tariff after going solar, and the post-solar
                bills are based on a typical customer&apos;s consumption and
                solar production load profile.
              </td>
            </tr>
            {showAdditionalDisclaimers && (
              <>
                <tr>
                  <td>IL</td>
                  <td>ComEd</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule BES tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>IL</td>
                  <td>Ameren</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule DS-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>National Grid</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>National Grid - LOW INCOME</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-2 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>WMECO</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>WMECO - LOW INCOME</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-2 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>UNITIL</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RD-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>UNITIL-LOW INCOME</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RD-2 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MD</td>
                  <td>BG&E</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MD</td>
                  <td>Delmarva</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MD</td>
                  <td>SMECO</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MD</td>
                  <td>Potomac Edison</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>MD</td>
                  <td>Choptank Electric</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NH</td>
                  <td>Liberty Utilities</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule D tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NJ</td>
                  <td>PSE&G</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NJ</td>
                  <td>ACE</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NJ</td>
                  <td>JCP&L</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NJ</td>
                  <td>O&R</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NJ</td>
                  <td>Rockland Electric</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NJ</td>
                  <td>Vineland</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NM</td>
                  <td>PNM</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1A tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NV</td>
                  <td>NV Energy South</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NY</td>
                  <td>PSEGLI</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 180 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NY</td>
                  <td>ConEdison</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule EL-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NY</td>
                  <td>Central Hudson</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>NY</td>
                  <td>NYSE&G</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule SC1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>PA</td>
                  <td>PPL</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>PA</td>
                  <td>Met-Ed</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>PA</td>
                  <td>PECO</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>PR</td>
                  <td>PREPA</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule GRS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>SC</td>
                  <td>SCE&G</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 8 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>VA</td>
                  <td>Dominion Virginia Power</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>VA</td>
                  <td>NOVEC</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule R-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>VA</td>
                  <td>Rappahannock Electric Coop</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule A-1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>VT</td>
                  <td>Green Mountain Power</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>VT</td>
                  <td>Vermont Electric Cooperative</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>VT</td>
                  <td>Washington Electric Co-Op</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule 1 & 2 tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
                <tr>
                  <td>VT</td>
                  <td>Burlington Electric Department</td>
                  <td>
                    This post-solar utility bill estimate assumes the customer
                    is on Schedule RS tariff after going solar, and the
                    post-solar bills are based on a typical customer&apos;s
                    consumption and solar production load profile.
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </ACPTable>
      </p>
      <p>
        <b>Contractor License Information</b>: AZ 294836; CA CSLB 750184/969975;
        CO EC.0007080/180464; CT ELC.0200835-E1/HIC.0642655; DC ECC
        904168/420216000005; FL EC13007964; HI CT-28011/CT-35178; IL 18-0817; MA
        1141/178937/HIC.180120; MD 10953916/HIC 109813/HIC 13259; NM 392099; NH
        0376C; NJ 34EI01574500/3EB01539300/
        <br />
        34EI01539300/HIC.13VH05700500; NV 0079413/HIC 180120; NYC HIC
        2015496-DCA; NYC Electrician #12610; OR 180464; PA PA075650/PA112037; PR
        APM-535; RI A-003621; SC M113485 MC/113485/RBS 57579; TX 28447; UT
        10094018-5501/9398170-550; VT EM-06461; WI 1410087/1410088. See&nbsp;
        <a href="https://www.sunrun.com/state-contractor-license-information">
          State contractor license information
        </a>
        &nbsp;for other contractor licenses.
      </p>
      <p> ©2018 Sunrun, Inc. All rights reserved.</p>
    </>
  );
};

const ACPTable = styled.table`
  th,
  td {
    padding: 0.5rem;
  }
`;

export { Disclaimer };
