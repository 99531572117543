import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

export const HeaderContentLayoutContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    ${tokens.CHARGEBLUE_50} 0,
    ${tokens.BACKGROUND_LIGHT} 16rem
  );
  display: grid;
  grid-template-columns: [main] 1fr;
  grid-template-rows: [page-top] auto [header-end] 1fr [content-end] auto;
`;
