import styled from "@emotion/styled/macro";
import {
  Button,
  Checkbox,
  Modal,
  Select,
  SvgIcon,
  SvgNames,
  tokens,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { useMediaQuery } from "react-responsive";
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useFlags } from "flagsmith/react";
import {
  ListOffer,
  ReviewOffer,
} from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import heroBg from "../assets/images/bg-review-hero-prism.svg";
import bg from "../assets/images/carousel/bg.svg";
import ExpandArrows from "../../assets/images/expand-arrows.svg";
import { Mixpanel } from "../../providers/mixpanel";
// eslint-disable-next-line import/no-restricted-paths
import { ProductBreakdown } from "./ProductBreakdown";
// eslint-disable-next-line import/no-restricted-paths
import { Promotions } from "./Promotions";
// eslint-disable-next-line import/no-restricted-paths
import { PriceBreakdown } from "./PriceBreakdown";
// eslint-disable-next-line import/no-restricted-paths
import { GraphContent } from "./GraphContent";
import { FeatureFlags } from "fixtures/features";
import { Disclaimer } from "components/atoms/Disclaimer";
import { ActionButtons, maxWidth } from "components/atoms/GlobalStyles";
import {
  customerGetOffer,
  customerListOffers,
  deleteOffer,
  getOffer,
  listOffers,
} from "services/storeFront";
import { useAppSelector } from "store";
import { EnvironmentalImpactOrganism } from "components/organisms/EnvironmentalImpactOrganism";
import { publish } from "utils/messages";
import {
  reloadMFE,
  useNavigateToCheckout,
  useNavigateToList,
  useNavigateToReview,
  useNavigateToUsage,
} from "utils/useNavigateHelper";

import { CardCarouselData } from "providers/Types";
import { ShareProspectForm } from "components/organisms/ShareProspectForm";
import {
  consumptionCardData,
  productionCardData,
  systemSizeCardData,
  usageOffsetCardData,
} from "components/atoms/UsageOffsetCardData";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { formatNumber } from "utils/formatHelpers";
import { DesignRender } from "components/organisms/DesignRender";
import { getOfferFromProposalId, getOfferToShow } from "utils/offerListHelpers";
import { ImageInfoGrid } from "components/organisms/ImageInfoGrid";
import { SolarSteps } from "components/molecules/SolarSteps";

const ActionButtonContainer = ({
  onCheckoutClick,
  isMobile,
  disableCheckout,
}: any) => {
  return (
    <>
      {!isMobile ? (
        <ButtonContainer>
          <PrimaryActionButton
            disabled={disableCheckout}
            onClick={onCheckoutClick}
          >
            Checkout
          </PrimaryActionButton>
        </ButtonContainer>
      ) : (
        <MobileButtonContainer>
          <MobilePrimaryActionButton
            disabled={disableCheckout}
            onClick={onCheckoutClick}
          >
            Checkout
          </MobilePrimaryActionButton>
        </MobileButtonContainer>
      )}
    </>
  );
};

const ReviewPage = () => {
  const navigateToList = useNavigateToList();
  const navigateToUsage = useNavigateToUsage();
  const navigateToReview = useNavigateToReview();
  const navigateToCheckout = useNavigateToCheckout();
  const flags = useFlags([FeatureFlags.CHECKOUT_V2]);
  const isMobile = useMediaQuery({ maxWidth: maxWidth });
  const {
    prospectId,
    offerId: offerIdParam,
    proposalId: proposalIdParam,
  } = useParams();
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const proposalToken = useAppSelector((state) => state.auth.proposalToken);
  const identity = useAppSelector((state) => state.auth.identity);
  const [defaultOfferId, setDefaultOfferId] = useState<string>();
  const [offer, setOffer] = useState<ReviewOffer>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [offers, setOffers] = useState<ListOffer[]>();
  const [isOffersLoading, setIsOffersLoading] = useState<boolean>(true);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [selectedOffers, setSelectedOffers] = useState<string[]>([]);
  const [error, setError] = useState<Error>();
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [showMapModalOpen, setShowMapModalOpen] = useState(false);
  const [showSolarStepsDetails, setShowSolarStepsDetails] = useState(true);

  const offerId = useMemo(() => {
    return offerIdParam ?? defaultOfferId;
  }, [offerIdParam, defaultOfferId]);

  const offerOptions = useMemo(() => {
    if (!offers) {
      return [];
    }
    return offers.map((e) => {
      return { label: e.label, value: e.id };
    });
  }, [offers]);

  useEffect(() => {
    if (((!cognitoToken || !identity) && !proposalToken) || !offerId) return;
    setIsLoading(true);
    let request;
    if (cognitoToken && identity) {
      request = getOffer(cognitoToken, offerId, identity);
    } else if (proposalToken) {
      request = customerGetOffer(proposalToken, offerId);
    } else {
      setIsLoading(false);
      setError(new Error(`Invalid token type.`));
      return;
    }
    request
      .then((data) => {
        if (data) {
          setOffer(data);
        }
      })
      .catch((error) => {
        setError(
          new Error("There was a problem fetching the offer data.", {
            cause: error,
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [cognitoToken, proposalToken, offerId, identity]);
  useEffect(() => {
    if (((!cognitoToken || !identity) && !proposalToken) || !prospectId) return;
    setIsOffersLoading(true);
    let request;
    if (cognitoToken && identity) {
      request = listOffers(cognitoToken, prospectId, identity);
    } else if (proposalToken) {
      request = customerListOffers(proposalToken);
    } else {
      setIsOffersLoading(false);
      setError(new Error("Invalid token type."));
      return;
    }
    request
      .then((data) => {
        if (data && data.length > 0) {
          setOffers(data);
          if (!offerId) {
            let offerToShow;
            if (proposalIdParam) {
              offerToShow =
                getOfferFromProposalId(data, proposalIdParam) ??
                getOfferToShow(data);
            } else {
              offerToShow = getOfferToShow(data);
            }

            setDefaultOfferId(offerToShow.id);
            navigateToReview(offerToShow.id);
          }
        } else {
          navigateToList();
        }
      })
      .catch((error) => {
        setIsOffersLoading(false);
        setError(
          new Error("There was a problem fetching the offer list data.", {
            cause: error,
          })
        );
      })
      .finally(() => {
        setIsOffersLoading(false);
      });
  }, [
    cognitoToken,
    navigateToList,
    navigateToReview,
    offerId,
    proposalIdParam,
    proposalToken,
    prospectId,
    identity,
  ]);
  const onOfferSelect = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      navigateToReview(event.target.value);
    },
    [navigateToReview]
  );
  const closeRemoveModal = useCallback(() => {
    setShowRemoveModal(false);
  }, []);
  const closeShareModal = useCallback(() => {
    setShowShareModal(false);
  }, []);
  const onExitClick = useCallback(() => {
    navigateToList();
  }, [navigateToList]);
  const onCheckoutClick = useCallback(() => {
    if (flags[FeatureFlags.CHECKOUT_V2].enabled) {
      Mixpanel.track("Checkout V2 - Offer Review Page cta", { prospectId });
      navigateToCheckout("confirm-offer", offer!.id);
    } else {
      publish.checkout(offer!.proposalId);
    }
  }, [flags, prospectId, navigateToCheckout, offer]);
  const onSelectAllOffer = useCallback(
    (selected: boolean) => {
      if (selected) {
        setSelectedOffers(offerOptions!.map((e) => e.value));
      } else {
        setSelectedOffers([]);
      }
    },
    [setSelectedOffers, offerOptions]
  );
  const onSelectOffer = useCallback(
    (offerId: string, selected: boolean) => {
      if (selected) {
        setSelectedOffers([...selectedOffers, offerId]);
      } else {
        setSelectedOffers(selectedOffers.filter((item) => item !== offerId));
      }
    },
    [setSelectedOffers, selectedOffers]
  );
  const onRemoveSelectedOffers = useCallback(() => {
    if (!cognitoToken || !offers) {
      return;
    }
    closeRemoveModal();
    setIsModalLoading(true);
    const selectedOfferObjects = offers.filter((e) =>
      selectedOffers.includes(e.id)
    );
    const promises = [] as Promise<void>[];
    selectedOfferObjects.forEach((selectedOffer) => {
      promises.push(
        deleteOffer(cognitoToken, selectedOffer.id, selectedOffer.lockVersion)
      );
    });
    Promise.all(promises).finally(() => {
      // Succuss or failure, reload the page to show the current state
      reloadMFE();
    });
  }, [selectedOffers, cognitoToken, offers, closeRemoveModal]);
  const onSendOffersStart = useCallback(() => {
    closeShareModal();
    setIsModalLoading(true);
  }, [closeShareModal]);
  const onSendOffers = useCallback(() => {
    // Succuss or failure, reload the page to show the current state
    reloadMFE();
  }, []);
  const onShareUsageClick = useCallback(() => {
    navigateToUsage();
  }, [navigateToUsage]);

  const banners = useMemo(() => {
    if (!offer) {
      return [];
    }
    const messages = [] as ReactElement[];
    if (offer.usage.homeSizeEstimate > 0) {
      messages.push(
        <WarningBanner>
          This offer is based on an estimated square footage of{" "}
          {formatNumber(offer.usage.homeSizeEstimate)}. Please{" "}
          <HyperlinkButton onClick={onShareUsageClick}>
            share your usage
          </HyperlinkButton>{" "}
          for greater accuracy.
        </WarningBanner>
      );
    }
    if (offer.isExpired) {
      messages.push(
        <WarningBanner>
          This offer is expired. You won't be able to checkout with this offer.
        </WarningBanner>
      );
    }
    if (offer.isSigned) {
      messages.push(<InfoBanner>This is the signed offer.</InfoBanner>);
    }
    return messages;
  }, [offer, onShareUsageClick]);
  const systemCarouselData = useMemo(() => {
    const cards = [
      systemSizeCardData(offer),
      productionCardData(offer),
      usageOffsetCardData(offer),
      consumptionCardData(offer),
    ] as CardCarouselData[];
    return cards;
  }, [offer]);

  if (!prospectId) {
    console.error(
      "Prospect Id is required from the URL! prospectId:",
      prospectId
    );
    return (
      <ErrorComponent
        context="ReviewPage"
        error={new Error(`Prospect Id is missing: ${prospectId}`)}
      />
    );
  }

  if (error) {
    return <ErrorComponent context="ReviewPage" error={error} />;
  }

  if (isLoading || !offer) {
    return (
      <LoadingIndicator color="black" fullScreen={true}></LoadingIndicator>
    );
  }

  return (
    <>
      <Header>
        <DesignRender
          prospectId={prospectId}
          lightmileProjectId={offer?.lightmileProjectId}
        />
        <ActionRow>
          {isOffersLoading && (
            <div>
              <LoadingIndicator></LoadingIndicator>
            </div>
          )}
          {!isOffersLoading &&
            offerOptions &&
            offerOptions.length > 0 &&
            cognitoToken && (
              <SelectContainer>
                <Button
                  onClick={onExitClick}
                  size="md"
                  iconLeft={
                    <SvgIcon
                      name={SvgNames.ChevronLeft}
                      width="20px"
                      color="white"
                    />
                  }
                >
                  All Offers
                </Button>
              </SelectContainer>
            )}
          {!isOffersLoading &&
            offerOptions &&
            offerOptions.length > 0 &&
            proposalToken && (
              <SelectContainer>
                <StyledSelect
                  value={offer.id}
                  options={offerOptions}
                  onChange={onOfferSelect}
                />
              </SelectContainer>
            )}
          {!isMobile && (
            <ActionButtonContainer
              disableCheckout={offer.isExpired}
              isMobile={isMobile}
              onExitClick={onExitClick}
              onCheckoutClick={onCheckoutClick}
            />
          )}
        </ActionRow>
        <TitleInformation>
          <span>
            <FinancialPlanText>
              <div>
                {offer.financial.financePlan}
                {offer.financial.summary.type === "FlexMonthly" ? "™" : ""}
              </div>
            </FinancialPlanText>
            <HeroText>
              <div>{offer.title}</div>
            </HeroText>
          </span>
          <ExpandMapButton onClick={() => setShowMapModalOpen(true)}>
            View Solar Design
            <img src={ExpandArrows} alt="Expand Site Model Map Button" />
          </ExpandMapButton>
        </TitleInformation>
      </Header>
      {banners.length > 0 && (
        <div>
          {banners.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      )}
      <StyledBgContainer>
        {showMapModalOpen && (
          <StyledSiteModelModal onClose={() => setShowMapModalOpen(false)}>
            <SiteModelContainer>
              <DesignRender
                prospectId={prospectId}
                lightmileProjectId={offer?.lightmileProjectId}
                zoomToFeatures={["ENABLED_ROOFS"]}
              />
            </SiteModelContainer>
          </StyledSiteModelModal>
        )}
        <ImageInfoGrid cardCarouselData={systemCarouselData} />
        <GraphContent offer={offer} />
        <ProductBreakdown offer={offer} prospectId={prospectId} />
        <Promotions promotions={offer.promotions} />
        <PriceBreakdown
          financialBreakdown={offer.financial}
          consumption={offer.consumption}
          production={offer.production}
        />
        <SolarSteps
          showSolarStepsDetails={showSolarStepsDetails}
          setShowSolarStepsDetails={setShowSolarStepsDetails}
        />
        <CheckoutBanner>
          <div>Take your first step towards solar</div>
          <BannerButton color="secondary" onClick={onCheckoutClick}>
            Go To Checkout
          </BannerButton>
        </CheckoutBanner>
        <EnvironmentalImpactOrganism kWhPerYear={offer.production} />
        <StyledLauncherContainer>
          <StyledLauncherModal
            type="button"
            onClick={() => setShowDisclaimerModal(true)}
          >
            View Disclaimers
          </StyledLauncherModal>
        </StyledLauncherContainer>
        <StyledFooterActionButtons>
          <Button onClick={() => setShowShareModal(!showShareModal)} size="md">
            Send Offers
          </Button>
          <Button onClick={onExitClick} color="secondary" size="md">
            Back to All Offers
          </Button>
        </StyledFooterActionButtons>
      </StyledBgContainer>
      {isMobile && (
        <ActionButtonContainer
          disableCheckout={offer.isExpired}
          isMobile={isMobile}
          onExitClick={onExitClick}
          onCheckoutClick={onCheckoutClick}
        />
      )}
      {showRemoveModal && (
        <Modal onClose={closeRemoveModal}>
          <ModalTitle>Select Offers to Remove</ModalTitle>
          <CheckboxContainer>
            <div>
              <Checkbox
                label="All current proposals (toggle to deselect all)"
                checked={selectedOffers.length === offers!.length}
                onChange={(event) => onSelectAllOffer(event.target.checked)}
              />
            </div>
            {offerOptions!.map((option) => {
              return (
                <div key={option.value}>
                  <Checkbox
                    label={option.label}
                    checked={selectedOffers.includes(option.value)}
                    onChange={(event) =>
                      onSelectOffer(option.value, event.target.checked)
                    }
                  />
                </div>
              );
            })}
          </CheckboxContainer>
          <ModalButtonContainer>
            <Button
              style={{ width: 155, marginLeft: "auto" }}
              disabled={selectedOffers.length === 0}
              onClick={onRemoveSelectedOffers}
            >
              Remove
            </Button>
          </ModalButtonContainer>
        </Modal>
      )}
      {showShareModal && offers && (
        <Modal onClose={closeShareModal}>
          <ShareProspectForm
            prospectId={prospectId}
            offers={offers}
            customerName={offer.customerName}
            onSubmitStart={onSendOffersStart}
            onSubmitComplete={onSendOffers}
            closeModal={closeShareModal}
          />
        </Modal>
      )}
      {isModalLoading && (
        <Modal hideClose>
          <AlignCenter>
            <LoadingIndicator
              color="black"
              fullScreen={false}
            ></LoadingIndicator>
          </AlignCenter>
        </Modal>
      )}

      {showDisclaimerModal && (
        <StyledModal
          title="Disclaimers"
          onClose={() => setShowDisclaimerModal(false)}
          hideIcon
        >
          <Disclaimer />
        </StyledModal>
      )}
    </>
  );
};

const StyledModal = styled(Modal)`
  > .modal-container {
    height: 82%;
    width: 82%;
    overflow: scroll;
  }
`;

const TitleInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  z-index: 5;
  width: 94%;
  margin: 0 auto;
  text-align: left;
`;

const ExpandMapButton = styled.button`
  background: ${tokens.BRAND_HERO_BLUE};
  border-radius: 25px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  min-width: 190px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;

  &:hover {
    opacity: 0.9;
  }
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin: 0 auto;
  z-index: 10;

  @media (max-width: ${maxWidth}px) {
    display: block;
  }
`;

const TextRow = styled.div`
  z-index: 5;
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin: 0 auto;
  align-items: end;
  text-align: left;
`;

const Header = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 400px;
  position: relative;
  text-align: center;
  color: white;
  justify-content: space-around;
  flex-direction: column;

  & > ${ActionRow} {
    z-index: 10;
  }

  & > ${TextRow} {
    z-index: 5;
  }

  @media (max-width: ${maxWidth}px) {
    height: 600px;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  ::after {
    content: "";
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url(${heroBg});
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  ::after {
    z-index: 1;
    background-size: 100% auto;
  }
`;

const StyledBgContainer = styled.div`
  background: url(${bg}) no-repeat center top;
  background-size: 100% auto;
`;

const HeroText = styled.div`
  width: 60%;
  font-size: 68px;
  text-shadow: 0 0 8px black;
  @media (max-width: ${maxWidth}px) {
    font-size: 42px;
  }
`;

const StyledSiteModelModal = styled(Modal)`
  .modal-container {
    width: auto;
  }
`;

const SiteModelContainer = styled.div`
  position: relative;
  width: 87vw;
  height: 70vh;
  margin: auto;
  padding-top: 10px;
  text-align: center;
`;

const FinancialPlanText = styled.div`
  font-size: 36px;
  text-shadow: 0 0 8px black;
  margin-right: 40px;
  @media (max-width: ${maxWidth}px) {
    font-size: 20px;
    margin-right: 0px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${maxWidth}px) {
    justify-content: space-between;
  }
`;

const ButtonContainer = styled.div`
  font-size: 16px;
  padding: 15px 25px 15px 25px;
  border-radius: 28px;
`;

const MobileButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  font-size: 16px;
  padding: 15px 25px 15px 25px;
  border-radius: 28px;
  flex-direction: column-reverse;
`;

const MobilePrimaryActionButton = styled.button`
  width: 100%;
  font-size: 16px;
  padding: 15px;
  border-radius: 28px;
  color: white;
  border-color: #1f2647;
  background: #1f2647;
  margin-right: 10px;
  border-style: solid;

  &:hover {
    opacity: 0.6;
  }
`;

const PrimaryActionButton = styled.button`
  font-size: 16px;
  padding: 15px;
  border-radius: 28px;
  color: black;
  border-color: white;
  background: white;
  margin-right: 10px;
  border-style: solid;

  &:hover {
    opacity: 0.6;
  }
`;

const StyledSelect = styled(Select)`
  .sr-select-button,
  .sr-option-list {
    background: rgba(0, 0, 0, 0.28) no-repeat 94%;
    color: white;
    font-family: "Roobert";
  }

  .chevron path {
    fill: white;
  }
`;

const WarningBanner = styled.div`
  background-color: ${tokens.WARNING_DARK};
  text-align: center;
  padding: 0.5rem;
`;

const InfoBanner = styled.div`
  background-color: ${tokens.BRAND_MEDIUMBLUE};
  color: ${tokens.WHITE};
  text-align: center;
  padding: 0.5rem;
`;

const ModalTitle = styled.div`
  text-align: left;
  font-size: 44px;
  margin-bottom: 0.5rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalButtonContainer = styled.div`
  margin-top: 1rem;
`;

const AlignCenter = styled.div`
  text-align: center;
`;

const StyledLauncherContainer = styled.div`
  width: 94%;
  margin: 20px 0 20px auto;
`;

const StyledLauncherModal = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${tokens.INFO_40};
  }
`;

const CheckoutBanner = styled.div`
  height: 156px;
  padding: 0px 60px 0px 100px;
  background-color: #1f2647;
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
  }
`;

const BannerButton = styled(Button)`
  background-color: white;
  color: #1f2647;
`;

const StyledFooterActionButtons = styled(ActionButtons)`
  padding: 20px 0px 40px 75px;
`;

export { ReviewPage };
