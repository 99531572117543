import { tokens, SvgIcon, SvgNames } from "@sunrun/experience-ui-components";

type TaskCheckMarkProps = {
  size?: number;
  locked?: boolean;
  checked?: boolean;
  halfChecked?: boolean;
};

const TaskCheckMark = ({
  size = tokens.FONT_SIZE_4,
  checked = false,
  halfChecked = false,
  locked = false,
}: TaskCheckMarkProps) => {
  if (checked) {
    return (
      <SvgIcon
        name={SvgNames.CheckFilled}
        height={size}
        width={size}
        color={tokens.SUCCESS_60}
        data-testid="checked"
      />
    );
  }

  if (locked) {
    return (
      <SvgIcon
        name={SvgNames.LockFilled}
        height={size}
        width={size}
        color={tokens.TINTS_HERO_BLUE_70}
        data-testid="locked"
      />
    );
  }

  if (halfChecked) {
    return (
      <SvgIcon
        name={SvgNames.InProcess}
        height={size}
        width={size}
        color={tokens.SUCCESS_60}
        data-testid="half-checked"
      />
    );
  }

  return (
    <SvgIcon
      name={SvgNames.ToDo}
      height={size}
      width={size}
      color={tokens.TINTS_HERO_BLUE_70}
      data-testid="unchecked"
    />
  );
};

export { TaskCheckMark };
export type { TaskCheckMarkProps };
