import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";
import {
  Button,
  Modal,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { AxiosError } from "axios";
import {
  EditOffer,
  EditOfferProductAvailability,
} from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { ProductSelectionOrganism } from "components/organisms/ProductSelectionOrganism";
// This is an exception as this "page" renders pages.
// eslint-disable-next-line import/no-restricted-paths
import { PricingPage } from "components/pages/PricingPage";
import { DesignOrganism } from "components/organisms/DesignOrganism";
import {
  HeaderButton,
  HeaderContentLayout,
} from "components/templates/HeaderContentLayout";
import { PackageCompareModal } from "components/templates/PackageCompareModal";
import { useAppSelector } from "store";
import {
  useNavigateToBuild,
  useNavigateToChangeOrder,
  useNavigateToList,
  useNavigateToReview,
} from "utils/useNavigateHelper";
import {
  amendOfferWithSync,
  getEditOffer,
  getProducts,
} from "services/storeFront";
import { createOfferInitialize } from "services/storeFront";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { ErrorModal } from "components/molecules/ErrorModal";
import type { ErrorType } from "components/organisms/ProductSelectionOrganism";
import { publish } from "utils/messages";
import { IhdMessage, logMessage } from "utils/IhdMessage";
import { getIhdBaseUrl } from "components/molecules/InHomeDesignMolecule";
import { getEnv } from "utils/env";
import { sendTrackingEvent } from "services/fullstory";
import { getQueryStringParams } from "utils/queryString";
import {
  NewOfferContext,
  OfferConsumedContext,
} from "providers/NewOfferProvider";
import { NewOfferData } from "providers/Types";

type Page = "PRODUCT_SELECTION" | "DESIGN" | "PRICING";

const env = getEnv();

const CreatePage = ({
  builderOnly,
  changeOrder = false,
}: {
  builderOnly: boolean;
  changeOrder?: boolean;
}) => {
  const [page, setPage] = useState<Page>("PRODUCT_SELECTION");
  const newOffer = useContext(NewOfferContext);
  const offerConsumed = useContext(OfferConsumedContext);
  const { prospectId, offerId, copyDesignOrProposalId } = useParams();
  const [productAvailability, setProductAvailability] =
    useState<EditOfferProductAvailability>();
  const [loadingRefetch, setLoadingRefetch] = useState<boolean>(false);
  const [lightmileProjectId, setLightmileProjectId] = useState<string>();
  const [submittingOffer, setSubmittingOffer] = useState<boolean>(false);
  const [selectedPricePointId, setSelectedPricePointId] = useState<string>();
  const [ihdRef, setIhdRef] = useState<
    React.RefObject<HTMLIFrameElement> | undefined
  >();
  const bundleIdCache = useRef<string>();
  const expectedLockVersion = useRef(0);
  const offerLinesIdLookup = useRef<EditOffer["lines"]>([]);
  const currentBundle = useRef(0);
  const previousBundle = useRef(-1);
  const [amplifyApiErrors, setAmplifyApiErrors] = useState<ErrorType>();
  const [showProductErrorModal, setShowProductErrorModal] =
    useState<boolean>(false);
  const isOfferRetry = useRef(false);
  const [offerLineErrors, setOfferLineErrors] = useState<ErrorType>();
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const identity = useAppSelector((state) => state.auth.identity);
  const [status, setStatus] = useState<EditOffer["status"]>();

  const checkProductAvailabilityError = useCallback(
    (productAvailability: EditOfferProductAvailability, offerId: string) => {
      let error;
      if (
        !productAvailability.bundles ||
        productAvailability.bundles.length === 0
      ) {
        error = new Error("No bundles available for offer " + offerId);
      } else if (
        !productAvailability.products ||
        productAvailability.products.length === 0
      ) {
        error = new Error("No products available for offer " + offerId);
      }
      return error;
    },
    []
  );
  const refetchOffer = useCallback(
    (initialOfferToFetch?: string) => {
      if (!cognitoToken || !identity) {
        return;
      }
      const offerToFetch = offerId ?? initialOfferToFetch;
      if (!offerToFetch) {
        return;
      }
      setLoadingRefetch(true);
      return getEditOffer(cognitoToken, offerToFetch)
        .then((data) => {
          setStatus(data.status);
          setLightmileProjectId(data?.lightmileProjectId);
          if (data.lines.length > 0 && data.bundleId) {
            if (!isOfferRetry.current) {
              bundleIdCache.current = data.bundleId;
              offerLinesIdLookup.current = data.lines;
            }
          }
          expectedLockVersion.current = data.lockVersion;

          const ePermitting =
            getQueryStringParams()?.ePermitting?.toString() === "true";
          return getProducts(
            cognitoToken,
            offerToFetch,
            data.opportunity && data.opportunity.state,
            data.opportunity && data.opportunity.acpMarketType,
            ePermitting,
            identity
          )
            .then((data) => {
              let error = checkProductAvailabilityError(data, offerToFetch);
              if (error) {
                setAmplifyApiErrors({
                  error,
                  attemptedAction: "creating a new Offer",
                });
                return;
              }
              setProductAvailability(data);
            })
            .catch((error) => {
              console.error(error);
              setAmplifyApiErrors({
                error,
                attemptedAction: "fetching the list of products",
              });
            });
        })
        .catch((error) => {
          console.error(error);
          setAmplifyApiErrors({
            error,
            attemptedAction: "fetching the existing Offer",
          });
        })
        .then(() => {
          setLoadingRefetch(false);
        });
    },
    [checkProductAvailabilityError, cognitoToken, identity, offerId]
  );
  const goToProductSelection = useCallback(() => {
    setPage("PRODUCT_SELECTION");
    refetchOffer();
  }, [setPage, refetchOffer]);
  const openProductErrorModal = useCallback(
    () => setShowProductErrorModal(true),
    [setShowProductErrorModal]
  );
  const closeProductErrorModal = useCallback(() => {
    setShowProductErrorModal(false);
    goToProductSelection();
  }, [setShowProductErrorModal, goToProductSelection]);
  const retryAfterProductErrorModalClose = useCallback(() => {
    isOfferRetry.current = true;
    closeProductErrorModal();
  }, [closeProductErrorModal, isOfferRetry]);
  const goToDesign = useCallback(
    (allowSkipMessage = false) => {
      const iframe = ihdRef?.current;
      if (!iframe && !allowSkipMessage) {
        return;
      }
      if (iframe) {
        const message = {
          source: "Offer Experience",
          type: "IHD_HACK_GO_TO_DESIGN",
        } as IhdMessage;
        logMessage(message);
        iframe.contentWindow?.postMessage(message, getIhdBaseUrl(env));
      }
      setPage("DESIGN");
    },
    [ihdRef]
  );
  const goToPricing = useCallback(() => {
    setPage("PRICING");
    refetchOffer();
  }, [setPage, refetchOffer]);
  const goToList = useNavigateToList();
  const goToBuild = useNavigateToBuild({ replace: true });
  const goToChangeOrder = useNavigateToChangeOrder({ replace: true });
  const goToReview = useNavigateToReview({ replace: true });
  const hasRunCreateOffer = useRef(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState<boolean>(false);
  const openPackageCompareModal = useCallback(
    () => setIsCompareModalOpen(true),
    [setIsCompareModalOpen]
  );
  const closePackageCompareModal = useCallback(
    () => setIsCompareModalOpen(false),
    [setIsCompareModalOpen]
  );
  const [isPrimaryActionButtonDisabled, setIsPrimaryActionButtonDisabled] =
    useState<boolean>();
  const handlePrimaryButtonDisable = useCallback(
    (isLoading: boolean) => setIsPrimaryActionButtonDisabled(isLoading),
    [setIsPrimaryActionButtonDisabled]
  );
  useEffect(() => {
    if (hasRunCreateOffer.current) return;
    if (!cognitoToken || !identity) return;
    if (offerId) {
      refetchOffer()?.then(() => {
        if (changeOrder || copyDesignOrProposalId) {
          goToDesign(true);
        }
        sendTrackingEvent("New Offer Ready", { source: "Refetch" });
      });
      hasRunCreateOffer.current = true;
      return;
    }
    if (changeOrder) {
      const parentProposalId =
        getQueryStringParams()?.parentProposalId?.toString();
      const signedRootId = getQueryStringParams()?.signedRootId?.toString();
      if (!parentProposalId || !signedRootId) {
        setAmplifyApiErrors({
          error: new Error("Missing parent proposal id or signed root id"),
          attemptedAction: "starting a change order",
        });
        return;
      }
      amendOfferWithSync(
        cognitoToken,
        prospectId!,
        parentProposalId,
        signedRootId
      )
        .then((result) => {
          goToChangeOrder(result);
          refetchOffer(result)?.then(() => {
            goToDesign(true);
            sendTrackingEvent("New Offer Ready", { source: "Initialize" });
          });
        })
        .catch((error) => {
          console.error(error);
          setAmplifyApiErrors({
            error,
            attemptedAction: "starting a change order",
          });
        });
    } else {
      if (!newOffer) {
        // Loading is still in progress
        return;
      }
      if (!(newOffer as NewOfferData).offerId) {
        setAmplifyApiErrors({
          error: newOffer as AxiosError,
          attemptedAction: "starting a new offer",
        });
        return;
      }
      createOfferInitialize(cognitoToken, newOffer as NewOfferData)
        .then((result) => {
          offerConsumed();
          if (!result.offer) {
            let error = checkProductAvailabilityError(
              result.products,
              result.offerId
            );
            if (!error) {
              error = new Error("Unknown Error");
            }
            setAmplifyApiErrors({
              error,
              attemptedAction: "creating a new Offer",
            });
            return;
          }
          expectedLockVersion.current = result.offer.lockVersion;
          bundleIdCache.current = result.offer.bundleId;
          if (result.offer.lines.length > 0 && result.offer.bundleId) {
            if (!isOfferRetry.current) {
              bundleIdCache.current = result.offer.bundleId;
              offerLinesIdLookup.current = result.offer.lines;
            }
          }
          setProductAvailability(result.products);
          goToBuild(result.offer.id);
          sendTrackingEvent("New Offer Ready", { source: "Initialize" });
        })
        .catch((error) => {
          console.error(error);
          setAmplifyApiErrors({
            error,
            attemptedAction: "creating a new Offer",
          });
        });
    }
    hasRunCreateOffer.current = true;
  }, [
    cognitoToken,
    offerId,
    prospectId,
    goToBuild,
    refetchOffer,
    checkProductAvailabilityError,
    changeOrder,
    copyDesignOrProposalId,
    goToDesign,
    goToChangeOrder,
    newOffer,
    offerConsumed,
    identity,
  ]);
  const productHeaderButtons = useMemo(() => {
    const buttons: HeaderButton[] = [
      {
        type: "primary",
        text: "Go to Design",
        disabled: isPrimaryActionButtonDisabled,
        onClick: goToDesign,
        dataTestId: "go-to-design-btn",
      },
    ];
    if (!builderOnly) {
      buttons.unshift({
        type: "secondary",
        text: "Back to Offer Manager",
        disabled: submittingOffer,
        onClick: goToList,
      });
    }
    return buttons;
  }, [
    builderOnly,
    goToDesign,
    goToList,
    isPrimaryActionButtonDisabled,
    submittingOffer,
  ]);
  if (amplifyApiErrors) {
    return (
      <HeaderContentLayout
        content={
          <ErrorComponent context="CreatePage" error={amplifyApiErrors.error} />
        }
        title="There was a Problem."
        description={`Something went wrong ${amplifyApiErrors.attemptedAction}. Please try refreshing the page.`}
      />
    );
  }
  if (
    !(
      ["DRAFT", "SUBMIT_PENDING", "SUBMIT_FAILED", undefined] as Array<
        EditOffer["status"] | undefined
      >
    ).includes(status)
  ) {
    return (
      <HeaderContentLayout
        content={
          <StyleOfferUneditable>
            <em className="banner">{`This Offer cannot be further edited. Offer status is "${status}".`}</em>
            {!builderOnly && (
              <div className="actions">
                <Button
                  data-testid={"go-to-review-btn"}
                  onClick={() => goToReview(offerId)}
                >
                  Go to offer
                </Button>
                <Button onClick={() => goToList()}>Go to list</Button>
              </div>
            )}
          </StyleOfferUneditable>
        }
        title="Build a bundle."
        kicker="Offer Builder"
        buttons={
          builderOnly
            ? []
            : [
                {
                  type: "secondary",
                  text: "Back",
                  onClick: goToList,
                },
              ]
        }
      />
    );
  }
  if (!offerId || !productAvailability) {
    return (
      <HeaderContentLayout
        content={<LoadingIndicator color="black" fullScreen={false} />}
        title="Build a bundle."
        kicker="Offer Builder"
        description="Make it yours. Assemble the package that best fits your needs."
        buttons={
          builderOnly
            ? []
            : [
                {
                  type: "secondary",
                  text: "Back",
                  disabled: submittingOffer,
                  onClick: goToList,
                },
              ]
        }
      />
    );
  }
  return (
    <>
      {page === "PRODUCT_SELECTION" && (
        <>
          <HeaderContentLayout
            content={
              <ProductSelectionOrganism
                offerId={offerId}
                productAvailability={productAvailability}
                onBundleLearnMore={openPackageCompareModal}
                bundleIdCache={bundleIdCache}
                expectedLockVersion={expectedLockVersion}
                offerLinesIdLookup={offerLinesIdLookup}
                onIsLoading={handlePrimaryButtonDisable}
                openProductErrorModal={openProductErrorModal}
                setOfferLineErrors={setOfferLineErrors}
                isOfferRetry={isOfferRetry}
                currentBundle={currentBundle}
                previousBundle={previousBundle}
              />
            }
            title="Build a bundle"
            description="Make it yours. Assemble the package that best fits your needs."
            buttons={productHeaderButtons}
            kicker="Offer Builder"
          />

          {isCompareModalOpen && (
            <PackageCompareModal
              modalTitle="Bundle Options"
              modalDescription="Sunrun offers product bundles with a range of capabilities so you can find the solar package that is right for you're needs. Compare our options below."
              productAvailability={productAvailability}
              onClose={closePackageCompareModal}
            />
          )}
          {showProductErrorModal && (
            <ErrorModal
              action={offerLineErrors?.attemptedAction}
              error={offerLineErrors?.error}
              context="CreatePage"
              onClose={closeProductErrorModal}
              onRetry={
                offerLineErrors?.hasRetry
                  ? retryAfterProductErrorModalClose
                  : undefined
              }
            />
          )}
        </>
      )}
      <div
        style={{
          display: page === "DESIGN" ? "block" : "none",
        }}
      >
        <DesignOrganism
          offerId={offerId}
          prospectId={prospectId!}
          copyDesignOrProposalId={copyDesignOrProposalId}
          goToProductSelection={goToProductSelection}
          goToPricing={goToPricing}
          setIhdRef={setIhdRef}
        />
      </div>
      {page === "PRICING" && (
        <div>
          <HeaderContentLayout
            smallPadding
            content={
              <PricingPage
                lightmileProjectId={lightmileProjectId!}
                offerId={offerId}
                prospectId={prospectId!}
                changeOrder={changeOrder}
                offerEvSpan={productAvailability.offerEvSpan}
                submittingOffer={submittingOffer}
                onPricePointIdSelected={(pricePointId) =>
                  setSelectedPricePointId(pricePointId)
                }
                onSubmitFailure={() => setSubmittingOffer(false)}
                onSubmitComplete={() => {
                  if (!builderOnly) {
                    goToReview(offerId);
                  }
                  publish.finished();
                }}
              />
            }
            title="Pricing"
            buttons={[
              {
                type: "secondary",
                text: "Back",
                disabled: submittingOffer,
                onClick: goToDesign,
              },
              {
                type: "primary",
                text: builderOnly ? "Finish" : "Go to Review",
                disabled: !selectedPricePointId || submittingOffer,
                onClick: () => setSubmittingOffer(true),
              },
            ]}
          />
        </div>
      )}
      {loadingRefetch && (
        <Modal hideClose>
          <AlignCenter>
            <LoadingIndicator
              color="black"
              fullScreen={false}
            ></LoadingIndicator>
          </AlignCenter>
        </Modal>
      )}
    </>
  );
};

const StyleOfferUneditable = styled.div`
  .banner {
    display: block;
    font-size: 1.25em;
    margin: 1em 0;
  }
  .actions {
    display: flex;
    gap: 1em;
  }
`;

const AlignCenter = styled.div`
  text-align: center;
`;

export { CreatePage };
