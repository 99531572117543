import { useCallback, useContext, useState } from "react";
import {
  ContactCardCheckout,
  LoadingOverlay,
} from "@sunrun/experience-ui-components";
import type { Contact } from "@sunrun/experience-ui-components/lib/components/ContactCard";
import { useParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { Role } from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { useContactManagement } from "hooks/useContactManagement";
import { ROLES } from "utils/usageUtils";
import { ErrorModal } from "components/molecules/ErrorModal";
import { voidAllProposals } from "services/prospect";
import { useAppSelector } from "store";
import { UsageFormContext } from "providers/UsageFormContext";
import { modals } from "fixtures/modals";

const { VOID_NEM, RESEND_NEM } = modals;

type UsagePageContactProps = {
  selectedPrimaryContact: Contact | undefined;
  setSelectedPrimaryContact: (contact: Contact) => void;
  selectedUtilityContact: Contact | undefined;
  setSelectedUtilityContact: (contact: Contact) => void;
  refSetter?: (node: HTMLDivElement | null) => void;
  hasOffers?: boolean;
};

const UsagePageContacts = ({
  selectedPrimaryContact,
  setSelectedPrimaryContact,
  selectedUtilityContact,
  setSelectedUtilityContact,
  refSetter,
  hasOffers,
}: UsagePageContactProps) => {
  const { prospectId = "" } = useParams();
  const { setShowUtilityBillWarningBanner } = useContext(UsageFormContext);

  const [isVoiding, setIsVoiding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectState = createSelector(
    [
      (state) => state?.auth?.hybridToken,
      (state) => state?.auth?.identity,
      (state) => state?.auth?.cognitoToken?.token,
    ],
    (authKey, identity, cognitoToken) => ({
      authKey: authKey,
      identity: identity,
      cognitoToken: cognitoToken,
    })
  );
  const { authKey, identity, cognitoToken } = useAppSelector(selectState);

  const {
    contactList,
    onAddContact: saveContact,
    onEditContact: editContact,
    isContactsLoading,
    error,
    setError,
  } = useContactManagement();

  const findContactById = (id: string) =>
    contactList?.find((contact) => contact.contactId === id);

  const onContactInfoSave = useCallback(
    async (contactToUpdate: Contact, role?: Role) => {
      const {
        contactId,
        customerFirstName,
        customerLastName,
        customerPrimaryPhone,
        customerEmail,
      } = contactToUpdate;

      const isEditingAnyName = !!contactList?.find((contact) => {
        return (
          contact.contactId === contactId &&
          (contact.customerFirstName !== customerFirstName ||
            contact.customerLastName !== customerLastName)
        );
      });

      if (isEditingAnyName || !contactToUpdate.contactId) {
        try {
          setIsLoading(true);
          const savedContact = await saveContact({
            customerEmail,
            customerFirstName,
            customerLastName,
            customerPrimaryPhone,
            role: role ?? ROLES.OTHER,
            ...(role === ROLES.HOMEOWNER && { isPrimary: true }),
          });
          setIsLoading(false);
          return savedContact;
        } catch (error) {
          setError({ action: "Creating new Contact", error: error as Error });
          return contactToUpdate;
        }
      } else {
        try {
          setIsLoading(true);
          await editContact(contactToUpdate);
          setIsLoading(false);
          return contactToUpdate;
        } catch (error) {
          setError({ action: "Editing Contact", error: error as Error });
        }
      }
    },
    [contactList, saveContact, editContact, setError]
  );

  const handlePrimarySelectionUpdate = async (selectedContactId: string) => {
    const selectedContact = findContactById(selectedContactId);
    if (selectedContact) {
      setSelectedPrimaryContact(selectedContact);
    }
    if (hasOffers && selectedContactId !== selectedPrimaryContact?.contactId) {
      setIsVoiding(true);
      return voidAllProposals({
        authKey,
        prospectId,
        identity,
        cognitoToken: cognitoToken ?? "",
      })
        .catch((error: unknown) => {
          setError({ action: "Voiding Proposals", error: error as Error });
        })
        .finally(() => setIsVoiding(false));
    }
  };

  const handleBillingSelectionUpdate = async (selectedContactId: string) => {
    const selectedContact = findContactById(selectedContactId);

    if (selectedContact) {
      setSelectedUtilityContact(selectedContact);
    }
    if (selectedContactId !== selectedUtilityContact?.contactId) {
      setShowUtilityBillWarningBanner(true);
    }
  };

  return (
    <>
      <div ref={refSetter}>
        <ContactCardCheckout
          selectedContactId={selectedPrimaryContact?.contactId}
          toolTipText="The primary signer must be on the title of the home and represent the legal rights surrounding the ownership and use of the residential property."
          contactList={contactList || []}
          contactType="primary"
          cardTitleText="Primary Signer (Optional)"
          showCreditStatus
          disableEditEmail={false}
          onContactInfoSave={(updatedContact: Contact) => {
            return onContactInfoSave(updatedContact, ROLES.HOMEOWNER);
          }}
          onSelectionUpdate={handlePrimarySelectionUpdate}
          modals={[VOID_NEM]}
          hasOffer={hasOffers}
        />
      </div>
      <div ref={!selectedPrimaryContact ? refSetter : null}>
        <ContactCardCheckout
          disableEditEmail={false}
          selectedContactId={selectedUtilityContact?.contactId}
          toolTipText="The utility bill contact must exactly match the name/spelling on the household's utility bills. This contact will be the signer for the NEM agreements and apply for utility approval."
          contactList={contactList || []}
          contactType="utility_bill"
          cardTitleText="Utility Bill Contact (Optional)"
          showCreditStatus={false}
          onContactInfoSave={onContactInfoSave}
          onSelectionUpdate={handleBillingSelectionUpdate}
          modals={[RESEND_NEM]}
          hasOffer={hasOffers}
        />
      </div>
      {!!error && (
        <ErrorModal
          context="UsagePageContacts"
          error={error.error}
          action={error.action}
          onClose={() => setError(undefined)}
        />
      )}
      {!!isVoiding && (
        <LoadingOverlay
          message="Voiding offers..."
          data-testid="loading-overlay"
        />
      )}
      {(!!isContactsLoading || !!isLoading) && (
        <LoadingOverlay
          message="Processing..."
          data-testid="processing-overlay"
        />
      )}
    </>
  );
};

export { UsagePageContacts };
export type { UsagePageContactProps };
