import { useCallback, useEffect, useRef, useState } from "react";
import {
  ContactCardCheckout,
  LoadingOverlay,
} from "@sunrun/experience-ui-components";
import type { Contact } from "@sunrun/experience-ui-components/lib/components/ContactCard";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import { modals } from "fixtures/modals";
import { useContactManagement } from "hooks/useContactManagement";
import { ROLES } from "utils/usageUtils";
import { voidAllProposals } from "services/prospect";
import { useAppSelector } from "store";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { useProposals } from "utils/swrHooks";
import { useReadSignDocs } from "checkout/hooks/useReadSignDocs";
import { rollbar } from "providers/rollbar";

const {
  VOID_SUNRUN,
  SAME_SIGNER,
  SAME_SIGNER_THEN_VOID_SUNRUN,
  SAME_SIGNER_THEN_RESEND_SUNRUN,
  REMOVE_SECONDARY,
  RESEND_SUNRUN_SECONDARY,
  RESEND_SUNRUN_UTILITY,
} = modals;

type CheckoutContactsProps = {
  selectedPrimaryContact: Contact | undefined;
  setSelectedPrimaryContact: (contact: Contact) => Promise<void>;
  selectedUtilityContact: Contact | undefined;
  setSelectedUtilityContact: (contact: Contact) => Promise<void>;
  selectedSecondaryContact: Contact | undefined;
  setSelectedSecondaryContact: (
    contact: Contact,
    remove: boolean
  ) => Promise<void>;
  refSetter?: (node: HTMLDivElement | null) => void;
};

const CheckoutContacts = ({
  selectedPrimaryContact,
  setSelectedPrimaryContact,
  selectedUtilityContact,
  setSelectedUtilityContact,
  selectedSecondaryContact,
  setSelectedSecondaryContact,
  refSetter,
}: CheckoutContactsProps) => {
  const location = useLocation();
  const secondaryRef = useRef<HTMLDivElement>(null);
  const secondaryEditState = Boolean(
    qs.parse(location.search.replace("?", ""))?.secondaryEdit
  );

  const [isVoiding, setIsVoiding] = useState(false);
  const [isNew, setIsNewContact] = useState(false);

  const {
    offer,
    proposalId,
    prospectId,
    setShowSecondaryWarningBanner,
    setShowUtilityBillWarningBanner,
  } = useCheckoutContext();
  const navigate = useNavigate();
  const { authKey, identity, cognitoToken } = useAppSelector((state) => ({
    authKey: state?.auth?.hybridToken,
    identity: state?.auth?.identity,
    cognitoToken: state?.auth?.cognitoToken?.token,
  }));

  const {
    contactList,
    onAddContact: saveContact,
    onEditContact: editContact,
    loading: updateContactLoading,
  } = useContactManagement();
  const { data: proposals } = useProposals(prospectId!, authKey);
  const { readSignDocsSteps } = useReadSignDocs({ prospectId, proposalId });

  const findContactById = (id: string) =>
    contactList?.find((contact) => contact.contactId === id);

  const hasSignedProposal = !!proposals?.find(({ signed }) => signed);
  const isNEMSigned = !!readSignDocsSteps?.find(
    (step) => step.odiName === "nemAgreement"
  )?.completed;

  useEffect(() => {
    if (secondaryEditState) {
      secondaryRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [secondaryEditState]);

  const voidAllAndNavigate = async (authKey: string, prospectId: string) => {
    setIsVoiding(true);
    try {
      await voidAllProposals({
        authKey,
        prospectId,
        identity,
        cognitoToken: cognitoToken ?? "",
      });
      navigate(`/prospect/${prospectId}/offer/list/`);
    } catch (error: unknown) {
      rollbar.error(error as any);
    } finally {
      setIsVoiding(false);
    }
  };

  const onContactInfoSave = useCallback(
    async (newContact: Contact) => {
      const {
        contactId,
        customerFirstName,
        customerLastName,
        customerPrimaryPhone,
        customerEmail,
      } = newContact;

      const isEditingAnyName = !!contactList?.find((contact) => {
        return (
          contact.contactId === contactId &&
          (contact.customerFirstName !== customerFirstName ||
            contact.customerLastName !== customerLastName)
        );
      });

      if (isEditingAnyName || !contactId) {
        setIsNewContact(true);
        const savedContact = await saveContact({
          customerEmail,
          customerFirstName,
          customerLastName,
          customerPrimaryPhone,
          role: ROLES.OTHER,
        });

        setIsNewContact(false);
        return savedContact;
      } else {
        return editContact(newContact);
      }
    },
    [contactList, setIsNewContact, saveContact, editContact]
  );

  const handlePrimarySelectionUpdate = async (selectedContactId: string) => {
    const selectedContact = findContactById(selectedContactId);
    selectedContact && (await setSelectedPrimaryContact(selectedContact));
    if (selectedContactId !== selectedPrimaryContact?.contactId) {
      await voidAllAndNavigate(authKey, prospectId);
    }
    setIsVoiding(false);
  };

  const handleSecondarySelectionUpdate = async (selectedContactId: string) => {
    const selectedContact = findContactById(selectedContactId);

    if (selectedContact) {
      await setSelectedSecondaryContact(selectedContact, false);
    } else {
      // If selectedContact is undefined, it means the user is trying to remove the secondary signer
      await setSelectedSecondaryContact(selectedSecondaryContact!, true);
    }

    if (
      selectedContactId !== selectedSecondaryContact?.contactId &&
      hasSignedProposal
    ) {
      setShowSecondaryWarningBanner(true);
    }
  };

  const handleBillingSelectionUpdate = async (selectedContactId: string) => {
    const selectedContact = findContactById(selectedContactId);

    selectedContact && (await setSelectedUtilityContact(selectedContact));
    if (
      selectedContactId !== selectedUtilityContact?.contactId &&
      isNEMSigned
    ) {
      setShowUtilityBillWarningBanner(true);
    }
  };

  return (
    <>
      <div ref={refSetter}>
        <ContactCardCheckout
          selectedContactId={selectedPrimaryContact?.contactId}
          toolTipText="The primary signer must be on the title of the home and represent the legal rights surrounding the ownership and use of the residential property."
          contactList={contactList || []}
          contactType="primary"
          hasOffer={hasSignedProposal}
          showCreditStatus={offer?.financial.financePlan === "Monthly Plan"}
          disableEditEmail={hasSignedProposal}
          onContactInfoSave={onContactInfoSave}
          onSelectionUpdate={handlePrimarySelectionUpdate}
          modals={[SAME_SIGNER_THEN_VOID_SUNRUN, VOID_SUNRUN]}
        />
      </div>
      <div ref={secondaryRef}>
        <ContactCardCheckout
          initialEditState={secondaryEditState}
          selectedContactId={selectedSecondaryContact?.contactId}
          toolTipText="Secondary signers are optional. If the secondary signer is added as a credit passer, they must be on the title of the home OR they must be a spouse/domestic partner of the title holder."
          contactList={contactList || []}
          contactType="secondary"
          hasOffer={hasSignedProposal}
          showCreditStatus={offer?.financial.financePlan === "Monthly Plan"}
          disableEditEmail={hasSignedProposal}
          onContactInfoSave={onContactInfoSave}
          onSelectionUpdate={handleSecondarySelectionUpdate}
          modals={[
            SAME_SIGNER_THEN_RESEND_SUNRUN,
            SAME_SIGNER,
            RESEND_SUNRUN_SECONDARY,
            REMOVE_SECONDARY,
          ]}
        />
      </div>
      <div ref={!selectedPrimaryContact ? refSetter : null}>
        <ContactCardCheckout
          disableEditEmail={hasSignedProposal}
          selectedContactId={selectedUtilityContact?.contactId}
          toolTipText="The utility bill contact must exactly match the name/spelling on the household's utility bills. This contact will be the signer for the NEM agreements and apply for utility approval."
          contactList={contactList || []}
          contactType="utility_bill"
          hasOffer={isNEMSigned}
          showCreditStatus={offer?.financial.financePlan === "Monthly Plan"}
          onContactInfoSave={onContactInfoSave}
          onSelectionUpdate={handleBillingSelectionUpdate}
          modals={[RESEND_SUNRUN_UTILITY]}
        />
      </div>
      {!!isVoiding && (
        <LoadingOverlay
          message="Voiding offers..."
          data-testid="loading-overlay"
        />
      )}
      {updateContactLoading && (
        <LoadingOverlay
          message={`${isNew ? "Creating" : "Updating"} contact...`}
        />
      )}
    </>
  );
};

export { CheckoutContacts };
export type { CheckoutContactsProps };
