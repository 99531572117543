import styled from "@emotion/styled/macro";
import {
  ReviewCashSummary,
  ReviewFinancialBreakdown,
  ReviewLoanSummary,
  ReviewMonthlySummary,
  ReviewFlexMonthlySummary,
  ReviewPrepaidSummary,
} from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { BundleCost, Title, maxWidth } from "components/atoms/GlobalStyles";
import { PriceCash } from "components/molecules/PriceCash";
import { PriceLoan } from "components/molecules/PriceLoan";
import { PriceMonthlyTPO } from "components/molecules/PriceMonthlyTPO";
import { PriceFlexMonthlyTPO } from "components/molecules/PriceFlexMonthlyTPO";
import { PricePrepaidTPO } from "components/molecules/PricePrepaidTPO";
import { formatCurrency } from "utils/formatHelpers";

const PriceBreakdown = ({
  financialBreakdown,
  consumption,
  production,
}: {
  financialBreakdown: ReviewFinancialBreakdown;
  consumption: number;
  production: number;
}) => {
  const renderSwitch = () => {
    switch (financialBreakdown.summary?.type) {
      case "Monthly":
        return (
          <PriceMonthlyTPO
            summary={financialBreakdown.summary as ReviewMonthlySummary}
          />
        );
      case "FlexMonthly":
        return (
          <PriceFlexMonthlyTPO
            summary={financialBreakdown.summary as ReviewFlexMonthlySummary}
            consumption={consumption}
            production={production}
          />
        );
      case "Loan":
        return (
          <PriceLoan
            summary={financialBreakdown.summary as ReviewLoanSummary}
          />
        );
      case "Prepaid":
        return (
          <PricePrepaidTPO
            summary={financialBreakdown.summary as ReviewPrepaidSummary}
          />
        );
      case "Cash":
        return (
          <PriceCash
            summary={financialBreakdown.summary as ReviewCashSummary}
          />
        );
      default:
        return <div>This offer does not contain pricing data.</div>;
    }
  };

  return (
    <Container>
      {financialBreakdown.summaryLines.map((summaryLine) => {
        return (
          <SummaryLine key={summaryLine.id}>
            <Title>{summaryLine.description}</Title>
            <BundleCost>{formatCurrency(summaryLine.value)}/total</BundleCost>
          </SummaryLine>
        );
      })}
      <BreakdownArea>{renderSwitch()}</BreakdownArea>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 60px 0px 100px;
  @media (max-width: ${maxWidth}px) {
    padding: 20px;
    flex-direction: column;
  }
`;

const BreakdownArea = styled.div`
  padding-top: 40px;
`;

const SummaryLine = styled.div`
  display: flex;
  padding: 20px 0px 20px 0px;
  justify-content: space-between;
`;

export { PriceBreakdown };
