import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { tokens } from "@sunrun/experience-ui-components";

const CheckoutHeaderGrid = styled.div`
  grid-area: header;
  & > :not(:only-child):first-child {
    margin-bottom: 48px;
  }

  & > :not(:first-child):not(:last-child) {
    margin-bottom: 20px;
  }
`;
const CheckoutSidebarGrid = styled.div`
  grid-area: sidebar;
`;
const CheckoutContentGrid = styled.div`
  border-top: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  grid-area: content;
`;

const CheckoutFullWidthContentGrid = styled.div`
  grid-row-start: sidebar;
  grid-row-end: sidebar;
  grid-column-start: sidebar;
  grid-column-end: content;
  border-top: 1px solid ${tokens.TINTS_HERO_BLUE_80};
`;

const CheckoutFooterGrid = styled.div`
  margin-left: -64px;
  margin-right: -64px;
`;

const CheckoutBackground = css`
  background: linear-gradient(
    180deg,
    ${tokens.CHARGEBLUE_50} 0,
    ${tokens.BACKGROUND_LIGHT} 16rem
  );
`;

const CheckoutGrid = styled.div`
  min-height: 100vh;
  box-sizing: border-box;
  ${CheckoutBackground}
  display: grid;
  row-gap: 38px;
  column-gap: 25px;
  grid-template-columns: 230px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header header" "sidebar content";
  padding: 62px 64px 0;
`;

export {
  CheckoutGrid,
  CheckoutHeaderGrid,
  CheckoutSidebarGrid,
  CheckoutContentGrid,
  CheckoutFooterGrid,
  CheckoutFullWidthContentGrid,
  CheckoutBackground,
};
