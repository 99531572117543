import styled from "@emotion/styled/macro";
import { Typography } from "@sunrun/experience-ui-components";
import plusIcon from "../../assets/images/plus.svg";
import checkIcon from "../../assets/images/check.svg";

type AddPillProps = {
  disabled: boolean;
  itemAdded: boolean;
  toggleAdded: () => void;
};

const AddPill = ({ itemAdded, toggleAdded, disabled }: AddPillProps) => {
  return (
    <AddAccessoryOrProductPill
      added={itemAdded}
      onClick={toggleAdded}
      disabled={disabled}
      data-testid={`add-pill`}
    >
      <Typography color={itemAdded ? "white" : undefined}>
        {itemAdded ? "Added" : "Add"}
      </Typography>
      <PillIcon iconUrl={itemAdded ? checkIcon : plusIcon} />
    </AddAccessoryOrProductPill>
  );
};

const AddAccessoryOrProductPill = styled.button<{ added: boolean }>`
  margin: 8px 8px 0 0;
  width: 84px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 4px;
  border: 1px solid #1f2647;
  border-radius: 24px;
  cursor: pointer;
  user-select: none;
  background: ${(props) => (props.added ? "#1F2647" : "none")};
  > div {
    color: ${(props) => (props.added ? "#fff" : "#1F2647")};
  }

  &:disabled {
    cursor: progress;
  }
`;

const PillIcon = styled.div<{ iconUrl: string }>`
  height: 10px;
  width: 10px;
  background: ${(props) =>
    `rgba(0,0,0,0) url(${props.iconUrl}) no-repeat center`};
`;

export { AddPill };
