import { z } from "zod";

const contactFormSchema = z.object({
  customerFirstName: z.string().min(1, { message: "Must have value" }),
  customerLastName: z.string().min(1, { message: "Must have value" }),
  customerEmail: z.string().email({ message: "Must be valid email" }),
  customerPrimaryPhone: z
    .string()
    .min(10, { message: "Must be complete phone number" })
    .max(15, {
      message: "Phone number should be in the format (###) ###-####",
    })
    .refine((phone) => phone.trim().replace(/_/g, "").length > 13, {
      message: "Must be complete phone number",
    }),
});

const utilityInfoFormSchema = z.object({
  utilityCompany: z
    .string()
    .min(2, { message: "Utility Company must have value" }),
});

const requestSiteModelFormSchema = z.object({
  isWithCustomer: z
    .string({ invalid_type_error: "Please select a value" })
    .min(2, { message: "Please select a value" }),
  notesToDesigner: z
    .string()
    .min(1, { message: "Please explain the required changes." })
    .max(255, "Please limit your response to 255 characters."),
  image: z
    .object({
      documentName: z.string(),
      contentType: z.string(),
      url: z.string(),
      documentBlob: z.instanceof(File),
      new: z.boolean().optional(),
    })
    .optional(),
});

export { contactFormSchema, utilityInfoFormSchema, requestSiteModelFormSchema };
