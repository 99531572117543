import useSWR from "swr";
import { useAuthToken } from "./useAuthToken";
import { getSite } from "services/lightmileProject";

const useSite = (prospectId: string | undefined) => {
  const ihdToken = useAuthToken("IHD");
  return useSWR(["site", ihdToken, prospectId], ([, authToken, prospectId]) => {
    if (!authToken) return;
    if (!prospectId) return;
    return getSite(authToken, prospectId);
  });
};

export { useSite };
