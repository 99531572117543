import styled from "@emotion/styled/macro";
import {
  Typography,
  SvgIcon,
  SvgNames,
  tokens,
} from "@sunrun/experience-ui-components";
import React from "react";
import { type ReactNode, isValidElement } from "react";

type TaskHeaderProps = {
  icon: string | ReactNode;
  iconSize?: number;
  label: string;
};

const TaskHeader = ({ icon, label, iconSize = 24 }: TaskHeaderProps) => {
  const TaskIcon = React.memo(() => {
    if (isValidElement(icon)) {
      return icon;
    }

    if (typeof icon === "string") {
      if (Object.values(SvgNames).includes(icon as SvgNames)) {
        return <SvgIcon width={iconSize} name={icon as SvgNames} />;
      }

      return <Icon src={icon} $size={iconSize} />;
    }

    return null;
  });

  return (
    <Header>
      <TaskIcon />
      <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HEROBLUE}>
        {label}
      </Typography>
    </Header>
  );
};

const Icon = styled.img<{ $size: number }>`
  height: ${(props) => props.$size}px;
  width: ${(props) => props.$size}px;
`;

const Header = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  gap: 6px;
`;

export { TaskHeader };
export type { TaskHeaderProps };
