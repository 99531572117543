import styled from "@emotion/styled/macro";
import { useMemo, useState } from "react";
import { Tooltip, Typography, tokens } from "@sunrun/experience-ui-components";
import infoIcon from "../assets/images/info-icon.svg";
import { InfoToolTip } from "components/atoms/InfoToolTip";
import { CardCarouselData, TitleSection } from "providers/Types";
import { Grid, maxWidth, ModalButton } from "components/atoms/GlobalStyles";
import { formatNumber } from "utils/formatHelpers";
import { CenteredModal } from "components/atoms/CenteredModal";

type ImageInfoProps = {
  cardData: CardCarouselData;
};

const ImageInfoComponent = ({ cardData }: ImageInfoProps) => {
  const {
    value,
    footerTextSuffix,
    headerText,
    iconMedia,
    infoToolTipText,
    isBigTip,
    extendedDescription,
  } = cardData;

  const [showImageInfoModal, setShowImageInfoModal] = useState(false);

  const formattedValue = useMemo(() => {
    let val;

    if (value) {
      if (footerTextSuffix === "%") {
        val = Number(value.toFixed(2));
      } else if (headerText === "Consumption") {
        val = formatNumber(Math.round(Number(value)));
      } else {
        val = formatNumber(value);
      }
    }

    return val;
  }, [value, footerTextSuffix, headerText]);

  return (
    <ImageInfoContainer
      data-testid="offer-experience--ReviewPage--ImageInfoComponent"
      iconMedia={iconMedia}
    >
      <ImageHeader>
        <StyledHeaderText>{headerText}</StyledHeaderText>
        {infoToolTipText && isBigTip && (
          <>
            <CenteredModal
              isOpen={showImageInfoModal}
              onClose={() => setShowImageInfoModal(false)}
              focusOnExit={false}
            >
              <Grid>
                <Typography
                  component="h5"
                  variant="h4"
                  style={{ marginBottom: 20 }}
                >
                  {headerText}
                </Typography>
                <Typography>{infoToolTipText}</Typography>
              </Grid>
            </CenteredModal>
            <StyledModalButton onClick={() => setShowImageInfoModal(true)}>
              <StyledIcon alt="info icon tooltip" src={infoIcon}></StyledIcon>
            </StyledModalButton>
          </>
        )}
        {infoToolTipText && !isBigTip && (
          <StyledTooltip content={infoToolTipText} direction="bottom-left">
            {infoToolTipText && <InfoToolTip />}
          </StyledTooltip>
        )}
      </ImageHeader>
      <div>
        {formattedValue && (
          <ImageFooterContainer>
            <ImageFooter>{formattedValue}</ImageFooter>
            <ImageFooterSuffix>{footerTextSuffix}</ImageFooterSuffix>
          </ImageFooterContainer>
        )}
        {extendedDescription && (
          <ExtendedDescriptionContainer>
            <Typography style={{ textWrap: "nowrap" }} color={tokens.WHITE}>
              {extendedDescription}
            </Typography>
          </ExtendedDescriptionContainer>
        )}
      </div>
    </ImageInfoContainer>
  );
};

type ImageInfoGridProps = {
  cardCarouselData: Array<CardCarouselData>;
  titleInfo?: TitleSection;
};

const ImageInfoGrid = ({ cardCarouselData, titleInfo }: ImageInfoGridProps) => {
  const hasTitle = titleInfo?.title;
  return (
    <ImageInfoGridContainer data-testid="offer-experience--ReviewPage--ImageInfoGrid">
      {hasTitle && (
        <TitleContainer>
          <CarouselTitle>{titleInfo.title}</CarouselTitle>
          <CarouselSubTitle>{titleInfo.subTitle}</CarouselSubTitle>
        </TitleContainer>
      )}
      {cardCarouselData.map((cardData: CardCarouselData, index: number) => {
        return <ImageInfoComponent cardData={cardData} key={index} />;
      })}
    </ImageInfoGridContainer>
  );
};

const ImageInfoGridContainer = styled.div`
  padding: 50px 92px 0px 92px;
  display: grid;

  grid-template-columns:
    minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr)
    minmax(200px, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px 5px;
  }
  @media (max-width: ${maxWidth}px) {
    padding: 60px 10px;
    grid-template-columns: 1fr 1fr;
  }
`;

const ImageInfoContainer = styled.div<{
  iconMedia: any;
}>`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  position: relative;

  font-size: 12px;
  line-height: 20px;
  color: #fff;

  min-width: 100px;
  margin: 0 8px;
  padding: 24px;
  height: 210px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.iconMedia})`};
  border-radius: 8px;

  @media (max-width: ${maxWidth}px) {
    height: 180px;
  }
`;

const ImageHeader = styled.div`
  display: flex;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;

  @media (max-width: ${maxWidth}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const StyledHeaderText = styled.div`
  display: inline-block;
`;

const StyledTooltip = styled(Tooltip)`
  .sr-tooltip-content {
    padding: 16px;
    font-size: 12px;
    line-height: 1.4;

    @media (min-width: 1024px) {
      padding: 32px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.big .sr-tooltip-content {
    min-width: 300px;

    @media (min-width: 768px) {
      min-width: 480px;
    }

    @media (min-width: 1024px) {
      min-width: 530px;
    }
  }
`;

const StyledModalButton = styled(ModalButton)`
  padding-left: 6px;
  height: 25px;
`;

const StyledIcon = styled.img`
  width: 15px;
  padding-top: 2px;
`;

const TitleContainer = styled.div`
  width: 94%;
  margin-left: auto;
  padding-bottom: 80px;
  color: #1f2647;
`;

const CarouselTitle = styled.div`
  font-size: 44px;
  font-weight: 400;
  line-height: 44px;
  padding-bottom: 40px;
`;

const CarouselSubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

const ImageFooterContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const ImageFooter = styled.div`
  font-size: 64px;
  line-height: 72px;
  padding-bottom: 10px;

  @media (max-width: 1300px) {
    font-size: 48px;
    line-height: 54px;
  }

  @media (max-width: 1100px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

const ImageFooterSuffix = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: ${maxWidth}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ExtendedDescriptionContainer = styled.div`
  margin-top: -10px;
  overflow: hidden;
`;

export { ImageInfoGrid };
