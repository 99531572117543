import { ReactNode } from "react";
import styled from "@emotion/styled/macro";
import { tokens, Typography } from "@sunrun/experience-ui-components";

type CheckoutHeaderProps = {
  headerTitle: string;
  subtitle?: ReactNode;
};

const CheckoutHeader = ({ headerTitle, subtitle }: CheckoutHeaderProps) => {
  return (
    <CheckoutPageHeader>
      <InnerHeader>
        <Typography
          variant="h1"
          color={tokens.BRAND_HERO_BLUE}
          size={tokens.FONT_SIZE_7}
          fontWeight="medium"
        >
          {headerTitle}
        </Typography>
        {subtitle}
      </InnerHeader>
    </CheckoutPageHeader>
  );
};

const CheckoutPageHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const InnerHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 16px;
`;

export { CheckoutHeader };
export type { CheckoutHeaderProps };
