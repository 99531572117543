import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import type { PricePoint } from "../PricePoints";
import {
  ContractTerms,
  LoanContractTerms,
  MonthlyContractTerms,
} from "../../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import type { Permission } from "./Permission";
import { columns, ColumnIds } from "./Columns";

enum FilterType {
  "Dropdown",
  "MinMaxSlider",
}

type DropdownPayload = {
  text: string;
  value: any;
};
type MinMaxPayload = {
  min: number;
  max: number;
};

type Payload = DropdownPayload | MinMaxPayload;

enum Group {
  "Payment",
  "PricingOption",
}

type valueFn<V> = (pricePoint: PricePoint) => V;
type displayFilter<V> = (
  value: V,
  pricePoint?: PricePoint
) => string | [value: string, subtitle?: string];

type FilterTypeMinMaxSlider = {
  type: FilterType.MinMaxSlider;
  defaultPayload?: (pricePoints: PricePoint[]) => MinMaxPayload;
  step: number;
};

type FilterTypeDropdownPayload<V> = (
  pricePoints: PricePoint[],
  valueFn: valueFn<V>,
  displayFilter: displayFilter<V>
) => DropdownPayload[];

type FilterTypeDefaultPayload = (
  payloads: DropdownPayload[]
) => DropdownPayload;

type FilterTypeDropdown<V> = {
  type: FilterType.Dropdown;
  payloads?: FilterTypeDropdownPayload<V>;
  defaultPayload?: FilterTypeDefaultPayload;
};

type FilterTypes<VF> = FilterTypeMinMaxSlider | FilterTypeDropdown<VF>;

const createFilter = <V>(
  Filter: {
    title: string;
    permission?: Permission;
    value: valueFn<V>;
    displayFilter?: displayFilter<V>;
    group: Group;
    resetsOtherFilters?: boolean;
    orderBy?: (a: PricePoint, b: PricePoint) => number;
    currentContractValue?: (currentContract: ContractTerms) => V;
  } & FilterTypes<V>
) => {
  if (Filter.type === FilterType.Dropdown) {
    const payloadsFallback: typeof Filter.payloads = (
      pricePoints,
      valueFn,
      displayFilter
    ) => {
      return uniqBy(
        pricePoints
          .sort(Filter.orderBy || ((a, b) => 0))
          .reduce((optionList, pricePoint): { text: string; value: V }[] => {
            const value = valueFn(pricePoint);
            const displayValue = displayFilter
              ? displayFilter(value, pricePoint)
              : (value as any as string);
            return [
              ...optionList,
              {
                text: Array.isArray(displayValue)
                  ? displayValue[0]
                  : displayValue,
                value: value,
              },
            ];
          }, [] as DropdownPayload[]),
        "value"
      );
    };
    Filter.payloads = Filter.payloads || payloadsFallback;

    const defaultPayloadFallback: typeof Filter.defaultPayload = (payloads) => {
      const sortedPayloads = sortBy(payloads, "value");
      return sortedPayloads[0];
    };
    Filter.defaultPayload = Filter.defaultPayload || defaultPayloadFallback;
    return {
      ...Filter,
      value: Filter.value,
      displayFilter: (Filter.displayFilter || ((v) => v)) as (
        value: any,
        pricePoint?: PricePoint
      ) => string,
      payloads: Filter.payloads as FilterTypeDropdownPayload<V>,
      defaultPayload: Filter.defaultPayload as FilterTypeDefaultPayload,
      currentContractValue: Filter.currentContractValue,
    };
  }

  return {
    ...Filter,
    value: Filter.value,
    displayFilter: (Filter.displayFilter || ((v) => v)) as (
      value: any,
      pricePoint?: PricePoint
    ) => string,
  };
};

const getColumnById = (columnId: ColumnIds) => {
  const columnData = columns.filter((c) => c.id === columnId)[0];
  return {
    permission: columnData.permission,
    value: columnData.value,
    displayFilter: columnData.displayFilter,
  };
};

const filters = [
  createFilter({
    ...getColumnById("PPS"),
    title: "PPS",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Battery Price"),
    title: "Battery Price",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Monthly Battery"),
    title: "Monthly Battery",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Est. Commission*"),
    title: "Est. Commission*",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Est. Monthly Solar"),
    title: "Est. Monthly Solar",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Est. Monthly Payment"),
    title: "Est. Monthly Payment",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Est. Total Monthly Payment"),
    title: "Est. Total Monthly Payment",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Solar Price"),
    title: "Solar Price",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    ...getColumnById("Total Loan Amount"),
    title: "Total Loan Amount",
    type: FilterType.MinMaxSlider,
    group: Group.PricingOption,
    step: 1,
  }),
  createFilter({
    title: "Loan Term",
    type: FilterType.Dropdown,
    resetsOtherFilters: true,
    permission: {
      allow: (payload) => payload.financialProduct === "Loan",
    },
    value: (pricePoint) => `${pricePoint.term} : ${pricePoint.apr}`,
    displayFilter: (value, payload) => {
      const values = value.toString().split(" : ");
      return `${values[0]}yr ${values[1]}%`;
    },
    group: Group.Payment,
    currentContractValue: (terms: ContractTerms) => {
      if (terms.type !== "Loan") {
        return "";
      }
      const loanTerms = terms as LoanContractTerms;
      return `${loanTerms.termYears} : ${loanTerms.apr}`;
    },
  }),
  createFilter({
    title: "Annual Escalator",
    type: FilterType.Dropdown,
    resetsOtherFilters: true,
    permission: {
      allow: (payload) => payload.financialProduct === "Monthly",
    },
    value: (pricePoint) => Number(pricePoint.escalator) || 0,
    displayFilter: (value) => Math.floor(value * 10000) / 100 + "%",
    group: Group.Payment,
    orderBy: (a, b) => Number(b.escalator || 0) - Number(a.escalator || 0),
    defaultPayload: (payloads) => sortBy(payloads, "value").reverse()[0],
    currentContractValue: (terms: ContractTerms) => {
      if (terms.type !== "Monthly") {
        return 0;
      }
      const monthlyTerms = terms as MonthlyContractTerms;
      return monthlyTerms.annualEscalator;
    },
  }),
];

type Filters = typeof filters;
type ActiveFilters = { filter: Filters[0]; payload: any }[];

export { filters, FilterType, Group };
export type {
  ActiveFilters,
  Filters,
  Payload,
  DropdownPayload,
  MinMaxPayload,
  FilterTypeDropdown,
};
