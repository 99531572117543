import styled from "@emotion/styled/macro";
import {
  Button,
  Checkbox,
  Textarea,
  Typography,
  tokens,
} from "@sunrun/experience-ui-components";
import { useCallback, useMemo, useState } from "react";
import { ListOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { sendProposalEmail } from "services/shareProposal";
import { useAppSelector } from "store";
import { deleteOffer } from "services/storeFront";
import { ErrorComponent } from "components/atoms/ErrorComponent";

type ShareProspectFormProps = {
  customerName: string;
  offers: ListOffer[];
  onSubmitStart?: () => void;
  onSubmitComplete?: () => void;
  prospectId: string;
  closeModal: () => void;
};
const ShareProspectForm = ({
  customerName,
  offers,
  onSubmitStart,
  onSubmitComplete,
  prospectId,
  closeModal,
}: ShareProspectFormProps) => {
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const hybridToken = useAppSelector((state) => state?.auth?.hybridToken);
  const [shareNote, setShareNote] = useState<string>("");
  const [error, setError] = useState<Error>();

  const offerOptions = useMemo(() => {
    if (!offers) {
      return [];
    }
    return offers.map((e) => {
      return { label: e.label, value: e.id };
    });
  }, [offers]);
  const [selectedOffers, setSelectedOffers] = useState<string[]>(
    offerOptions.map((e) => e.value)
  );
  const [unCheckedOffers, setUnCheckedOffers] = useState<string[]>([]);

  const onSelectOffer = useCallback(
    (offerId: string, selected: boolean) => {
      if (selected) {
        setSelectedOffers([...selectedOffers, offerId]);
        setUnCheckedOffers(unCheckedOffers.filter((item) => item !== offerId));
      } else {
        setSelectedOffers(selectedOffers.filter((item) => item !== offerId));
        setUnCheckedOffers([...unCheckedOffers, offerId]);
      }
    },
    [setSelectedOffers, selectedOffers, unCheckedOffers]
  );
  const onSend = useCallback(async () => {
    if (!cognitoToken) {
      return;
    }
    if (selectedOffers.length === 0) {
      setError(new Error("No offer is selected!"));
    }
    onSubmitStart?.();
    // Remove Not Selected Offers
    const selectedOfferObjects = offers.filter(
      (e) => !selectedOffers.includes(e.id)
    );
    const promises = [] as Promise<void>[];
    selectedOfferObjects.forEach((selectedOffer) => {
      promises.push(
        deleteOffer(cognitoToken, selectedOffer.id, selectedOffer.lockVersion)
      );
    });
    await Promise.all(promises);
    // Share Proposal
    await sendProposalEmail(hybridToken, prospectId, shareNote);

    onSubmitComplete?.();
  }, [
    selectedOffers,
    offers,
    hybridToken,
    prospectId,
    shareNote,
    onSubmitStart,
    onSubmitComplete,
    cognitoToken,
  ]);

  const getUnCheckedOffersText = (offerOptions: any) => {
    let resp: string = "";
    if (unCheckedOffers.length === 1) {
      resp = offerOptions.find(
        (option: { value: string; label: string }) =>
          option.value === unCheckedOffers[0]
      ).label;
    } else {
      unCheckedOffers.map((unChecked, i) => {
        return (resp +=
          offerOptions.find(
            (option: { value: string; label: string }) =>
              option.value === unChecked
          ).label + (unCheckedOffers.length - 1 === i ? "" : " and "));
      });
    }
    return resp;
  };

  if (!offerOptions || offerOptions.length === 0) {
    return (
      <ErrorComponent
        context="ShareProspectForm"
        error={
          new Error(
            "Prospect cannot be shared, it does not contain any offers."
          )
        }
      />
    );
  }

  if (error) {
    return <ErrorComponent context="ShareProspectForm" error={error} />;
  }

  const oneOffer = offerOptions.length === 1;
  const singleUnCheckedOffer = unCheckedOffers.length === 1;
  return (
    <StyledShareOfferContainer>
      <ModalTitle>Share Offer{oneOffer ? "" : "s"}</ModalTitle>
      {!oneOffer && (
        <ModalSubTitle>
          <div>
            Select offers to share with <b>{customerName}</b>.
          </div>
          <div>Any offers that are not selected will be deleted.</div>
        </ModalSubTitle>
      )}
      <CheckboxGridContainer>
        {oneOffer && (
          <div data-testid="share-one-offer-option">
            Share Offer <b>{offerOptions[0].label}</b> with{" "}
            <b>{customerName}</b>
          </div>
        )}
        {!oneOffer &&
          offerOptions.map((option) => {
            return (
              <div key={option.value}>
                <StyledCheckbox
                  data-testid={"checkbox-" + option.label}
                  label={option.label}
                  checked={selectedOffers.includes(option.value)}
                  onChange={(event) =>
                    onSelectOffer(option.value, event.target.checked)
                  }
                />
              </div>
            );
          })}
      </CheckboxGridContainer>
      {unCheckedOffers.length >= 1 && (
        <WarningMessage data-testid="warning-message">
          Option{singleUnCheckedOffer ? " " : "s "}
          <b>{getUnCheckedOffersText(offerOptions)}</b>
          {singleUnCheckedOffer ? " is " : " are "} going to be deleted.
        </WarningMessage>
      )}
      <NoteContainer>
        <label>
          <Typography component="span">Optional Note</Typography>
          <br />
          <Textarea onChange={(event) => setShareNote(event.target.value)} />
        </label>
      </NoteContainer>
      <ModalButtonContainer>
        <Button style={{ width: 155 }} onClick={onSend}>
          Send
        </Button>
        <Button style={{ width: 155 }} color="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </ModalButtonContainer>
    </StyledShareOfferContainer>
  );
};

const StyledShareOfferContainer = styled.div`
  padding: 0 20px;
  color: ${tokens.BRAND_HEROBLUE};
`;

const CheckboxGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
`;

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const NoteContainer = styled.div`
  margin-top: 1rem;
  padding-bottom: 12px;
`;

const WarningMessage = styled.div`
  background-color: ${tokens.ERROR_90};
  width: 95%;
  min-height: 20px;

  color: ${tokens.ERROR_50};
  padding: 14px;
`;

const ModalTitle = styled.div`
  text-align: left;
  font-size: 44px;
  margin-bottom: 0.5rem;
  padding-bottom: 36px;
  font-weight: 400;
  line-height: 44px;
`;

const ModalSubTitle = styled.div`
  padding-bottom: 22px;
  font-size: 16px;
  line-height: 24px;
`;

const ModalButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 16px;
`;

export { ShareProspectForm };
