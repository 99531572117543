import { useState } from "react";
import { Button, SvgIcon, SvgNames } from "@sunrun/experience-ui-components";
import dashDot from "../assets/images/dash-dot.svg";
import { ReviewOfferProduct } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import {
  BundleCost,
  Collapse,
  CollapseContainer,
  Container,
  ImageText,
  OptionCost,
  Product,
  ProductImage,
  ProductsContainer,
  RowProductImages,
  Title,
} from "components/atoms/GlobalStyles";
import { formatProductCost } from "utils/formatHelpers";
import { FinancialProduct } from "providers/pricing/PricePoints";

const EnergyAccessories = ({
  total,
  paymentType,
  products,
}: {
  total: number;
  paymentType: FinancialProduct;
  products: ReviewOfferProduct[];
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Container>
      <ProductsContainer>
        {visible ? (
          <CollapseContainer>
            <Title>Energy Accessories</Title>
            <Collapse>
              {total > 0 && (
                <BundleCost>
                  +{formatProductCost(total, paymentType)}
                </BundleCost>
              )}
              <Button
                size={"sm"}
                iconMiddle={
                  <SvgIcon
                    height={22}
                    color="white"
                    name={SvgNames.Plus}
                  ></SvgIcon>
                }
                onClick={() => setVisible((visible) => !visible)}
              ></Button>
            </Collapse>
          </CollapseContainer>
        ) : (
          <>
            <CollapseContainer>
              <Title>Energy Accessories</Title>
              <Collapse onClick={() => setVisible((visible) => !visible)}>
                {total > 0 && (
                  <BundleCost>
                    +{formatProductCost(total, paymentType)}
                  </BundleCost>
                )}
                <img alt="expand" src={dashDot} height="44px"></img>
              </Collapse>
            </CollapseContainer>
            <RowProductImages>
              {products.map((product, index) => {
                const productCost = formatProductCost(
                  product.cost,
                  product.paymentType
                );
                return (
                  <Product key={product.productId ?? index}>
                    <ProductImage
                      alt={product.name}
                      src={product.imageUrl}
                    ></ProductImage>
                    <ImageText>
                      {product.name + " (" + product.count + ")"}
                    </ImageText>
                    {productCost && <OptionCost>{productCost}</OptionCost>}
                  </Product>
                );
              })}
            </RowProductImages>
          </>
        )}
      </ProductsContainer>
    </Container>
  );
};

export { EnergyAccessories };
