import { useMemo } from "react";
import styled from "@emotion/styled/macro";
import { Typography } from "@sunrun/experience-ui-components";
import { EditOfferProduct } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { IncludedProductRadioButton } from "../molecules/IncludedProductRadioButton";
import { HorizontalLine } from "components/atoms/HorizontalLine";

type AllOptionsType = EditOfferProduct["variants"][0]["selectedOptions"];

type SubOptionsValue = {
  [key: string]: string[];
};

type SubOptionsType = {
  name: string;
  values: SubOptionsValue;
};

type OptionsType = {
  name: string;
  values: Array<string>;
  subOptions?: SubOptionsType;
};

const SOLAR_PRODUCT_ID = "01GVPR696VA8FYBW8AHF1JJVA9";
const BACKUP_PRODUCT_ID = "01GWAWKQHA3SY3CKS55R24FHKX";

const hiddenOptions = new Map<string, string[]>();
hiddenOptions.set(SOLAR_PRODUCT_ID, ["inverter type"]);

type ProductVariantOptionsProps = {
  productId: string;
  disabled: boolean;
  selectedVariantId?: string;
  variants?: EditOfferProduct["variants"];
  onVariantChange: (productId: string, variantId: string) => void;
};

const ProductVariantOptions = ({
  productId,
  disabled,
  selectedVariantId,
  variants = [],
  onVariantChange,
}: ProductVariantOptionsProps) => {
  const allOptions: AllOptionsType = variants.reduce(
    (options: AllOptionsType, variant) =>
      options.concat(variant.selectedOptions),
    []
  );
  const availableOptions = allOptions
    .filter(
      (option, index, self) =>
        index ===
        self.findIndex(
          (t) => t.name === option.name && t.value === option.value
        )
    )
    .filter((option) => {
      const matchingHiddenOptions = hiddenOptions.get(productId);
      if (
        matchingHiddenOptions &&
        matchingHiddenOptions.includes(option.name.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
  const uniqueNames = availableOptions
    .map((option) => option.name)
    .filter(
      (optionName, index, self) =>
        index === self.findIndex((t) => t === optionName)
    );
  const sortedOptions = useMemo(() => {
    const getOptionValues = (filterName: string) => {
      return availableOptions
        .filter((option) => option.name === filterName)
        .map((option) => option.value);
    };

    if (productId === BACKUP_PRODUCT_ID) {
      const groupName = uniqueNames[0];
      const batteryType = uniqueNames[1];
      const groupValues = getOptionValues(groupName);

      const subOptionsValuesMapping = variants.reduce(
        (acc: SubOptionsType["values"], variant) => {
          const selectedOptions = variant.selectedOptions;
          const group = selectedOptions[0];
          const batteryType = selectedOptions[1];

          if (!(group.value in acc)) {
            acc[group.value] = [];
          }

          acc[group.value].push(batteryType.value);
          return acc;
        },
        {}
      );

      return [
        {
          name: groupName,
          values: groupValues,
          subOptions: {
            name: batteryType,
            values: subOptionsValuesMapping,
          },
        },
      ];
    }

    return uniqueNames.map((name) => {
      return {
        name,
        values: getOptionValues(name),
      };
    });
  }, [productId, uniqueNames, variants, availableOptions]);

  const selectedVariant = useMemo(() => {
    if (!variants || variants.length === 0) {
      return undefined;
    }
    const matchingVariant = variants.find(
      (variant) => variant.id === selectedVariantId
    );
    if (!matchingVariant) {
      return variants[0];
    }
    return matchingVariant;
  }, [variants, selectedVariantId]);

  if (
    !variants ||
    variants.length === 0 ||
    variants[0].selectedOptions?.length === 0 ||
    !selectedVariant
  ) {
    return <></>;
  }

  return (
    <ProductSelectionRadioGroup key="options" data-testid="radio-options">
      {sortedOptions.map((optionGroup: OptionsType) => {
        return (
          <div key={optionGroup.name}>
            <HorizontalLine />
            {!optionGroup.subOptions && (
              <OptionTitle>
                <Typography>{optionGroup.name}</Typography>
              </OptionTitle>
            )}
            {optionGroup.values.map((value) => {
              const label = (
                <LabelWrapper key={`${optionGroup.name}:${value}`}>
                  <Typography>{value}</Typography>
                </LabelWrapper>
              );
              return (
                <IncludedProductRadioButton
                  key={value}
                  selected={
                    selectedVariant!.selectedOptions.findIndex(
                      (option) => option.value === value
                    ) !== -1
                  }
                  groupName={optionGroup.name}
                  groupValue={value}
                  label={label}
                  disabled={disabled}
                  subOptions={optionGroup.subOptions}
                  setOption={(name: string, value: string) => {
                    const otherOptionInVariant =
                      selectedVariant!.selectedOptions.find(
                        (options) => options.name !== name
                      );
                    const variantMatchingBothOptions = variants.find(
                      (variant) =>
                        (!otherOptionInVariant ||
                          variant.selectedOptions.findIndex(
                            (selectedOption) =>
                              selectedOption.name ===
                                otherOptionInVariant!.name &&
                              selectedOption.value ===
                                otherOptionInVariant!.value
                          ) !== -1) &&
                        variant.selectedOptions.findIndex(
                          (selectedOption) =>
                            selectedOption.name === name &&
                            selectedOption.value === value
                        ) !== -1
                    );
                    if (variantMatchingBothOptions) {
                      onVariantChange(productId, variantMatchingBothOptions.id);
                    } else {
                      console.warn(
                        `No Variant matching existing selection ${
                          otherOptionInVariant!.value
                        } and new ${value}. Defaulting to first option matching new.`
                      );
                      const variantMatchingCurrentOption = variants.find(
                        (variant) =>
                          variant.selectedOptions.findIndex(
                            (selectedOption) =>
                              selectedOption.name === name &&
                              selectedOption.value === value
                          ) !== -1
                      );
                      if (variantMatchingCurrentOption) {
                        onVariantChange(
                          productId,
                          variantMatchingCurrentOption.id
                        );
                      }
                    }
                  }}
                  selectedVariant={selectedVariant}
                />
              );
            })}
          </div>
        );
      })}
    </ProductSelectionRadioGroup>
  );
};

const OptionTitle = styled.div`
  margin-bottom: 10px;
`;
const LabelWrapper = styled.div``;
const ProductSelectionRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export { ProductVariantOptions };
