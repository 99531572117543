import styled from "@emotion/styled/macro";
import { Text } from "@sunrun/experience-ui-components";
import { ComponentPropsWithoutRef, useState } from "react";
import { formatNumber } from "utils/formatHelpers";

interface NumberInputProps extends ComponentPropsWithoutRef<"input"> {
  value: number | undefined | string;
}

const NumberInput = (props: NumberInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const { value, ...rest } = props;

  return (
    <>
      {isFocused ? (
        <StyledNumberInput
          {...rest}
          type="number"
          onBlur={() => {
            setIsFocused(false);
          }}
          // allows for user to type '-' for negative numbers, removes leading 0s
          value={typeof value !== "number" ? value : Number(value).toString()}
          max={99999}
          min={-99999}
        />
      ) : (
        <StyledNumberInput
          {...rest}
          value={
            (!value && value !== 0) || typeof value !== "number"
              ? "--"
              : formatNumber(value as number)
          }
          type="text"
          onFocus={() => {
            setIsFocused(true);
          }}
        />
      )}
    </>
  );
};

const StyledNumberInput = styled(Text)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  input[type="number"] {
    appearance: textfield;
    margin: 0;
  }
  .sr-text-input-container {
    width: 62px;
  }
  input {
    padding: 0px;
    height: 62px;
    width: 60px;
    text-align: center;
    font-family: "ROOBERT", sans-serif;
  }
`;

export { NumberInput };
