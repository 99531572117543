import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Amplify } from "aws-amplify";
import styled from "@emotion/styled/macro";
import { Global, css } from "@emotion/react";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "react-error-boundary";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import awsconfig from "./aws-exports";
import { ErrorPage } from "./components/pages/ErrorPage";
import "providers/rollbar";
import { initFullstory } from "./services/fullstory";
import { getEnv } from "utils/env";
import { COGNITO_CONFIG } from "cognitoConfig";
import { rollbar } from "providers/rollbar";

initFullstory();
const env = getEnv();

Amplify.configure({ ...awsconfig, ...COGNITO_CONFIG[env].cognitoConfig });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Container = styled.div`
  font-family: "Roobert", sans-serif;
  width: 100%;
`;

root.render(
  <React.StrictMode>
    <Container>
      <Global
        styles={css`
          @import url("https://fonts.cdnfonts.com/css/roobert");
          @import url("https://fonts.cdnfonts.com/css/sf-pro-display");
          fieldset {
            border: none;
            font-family: "Roobert", sans-serif;
          }
        `}
      />
      <SWRConfig //see https://swr.vercel.app/docs/api#options
        value={{
          errorRetryCount: 2,
          revalidateOnFocus: false,
        }}
      >
        <ErrorBoundary fallback={<ErrorPage />} onError={rollbar.critical}>
          <App />
        </ErrorBoundary>
      </SWRConfig>
    </Container>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
