import {
  SvgIcon,
  SvgNames,
  tokens,
  Tooltip,
  Typography,
} from "@sunrun/experience-ui-components";
import { ReviewFlexMonthlySummary } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import {
  RowContainer,
  RowContainerWithModal,
  RowContainerWithTooltip,
} from "../atoms/PriceGlobals";
import {
  BreakdownBox,
  BreakdownRows,
  FirstColumn,
  Header,
  HeroText,
  StandardText,
  Total,
  TotalRow,
  StyledSecondColumn,
  StyledCategory,
  StyledHeader,
  StyledFootnote,
} from "components/atoms/GlobalStyles";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "utils/formatHelpers";
import { Warranties } from "components/atoms/Warranties";
import { CardSectionDivider } from "components/atoms/Card";
import {
  COSTCO_CARD_ESTIMATE,
  LIFETIME_SAVINGS,
  POST_SOLAR_UTILITY_BILL,
  SRECII,
} from "constants/pricing-tooltips";

const PriceFlexMonthlyTPO = ({
  summary,
  consumption,
  production,
}: {
  summary: ReviewFlexMonthlySummary;
  consumption: number;
  production: number;
}) => {
  const formattedMinMonthlyPayment = formatCurrency(
    summary.minimumMonthlyPayment,
    2
  );
  const formattedMaxMonthlyPayment = formatCurrency(
    summary.maximumMonthlyPayment,
    2
  );

  const marginalRateValue = summary.rates.find(
    (flexRate) => flexRate.kind === "Marginal"
  )?.value;

  const marginalRate = marginalRateValue
    ? Number.parseFloat(marginalRateValue)
    : null;

  const consumptionRate = Number.parseFloat(
    summary.rates.find((flexRate) => flexRate.kind === "Consumption")?.value ??
      "0"
  );
  const productionRate = Number.parseFloat(
    summary.rates.find((flexRate) => flexRate.kind === "Production")?.value ??
      "0"
  );

  const formattedFlexRate = formatCurrency(consumptionRate - productionRate, 3);
  const formattedConsumption = formatNumber(consumption);
  const formattedDelta = formatNumber(production - consumption);

  return (
    <>
      <BreakdownBox>
        <BreakdownRows>
          <FirstColumn>
            <Header>Have peace of mind</Header>
            <StandardText>
              Know that you're covered with premium protection.
            </StandardText>
            <Warranties warranties={summary.warranties} />
          </FirstColumn>
          <StyledSecondColumn>
            <Tooltip content="An included component of your Minimum Monthly Bill is a production charge applied to Total Estimated Production.">
              <Header>
                Minimum monthly payment in the first year
                <SvgIcon
                  color={tokens.BLACK}
                  width="24"
                  height="22"
                  name={SvgNames.InfoOutline}
                  style={{ marginLeft: 8 }}
                />
              </Header>
            </Tooltip>

            <HeroText>{formattedMinMonthlyPayment}/mo</HeroText>
            <StandardText style={{ padding: "0px" }}>
              {`For ${formattedConsumption} annual kWh`}
            </StandardText>

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Terms</Typography>
              </StyledHeader>

              <RowContainerWithTooltip
                label="Year 1 Flex Rate for additional energy"
                description={`For up to an additional ${formattedDelta} annual kWh consumed`}
                cost={marginalRate ?? formattedFlexRate}
                decimals={3}
                unit="/kWh"
                tooltip={`Flex has additional discounted electricity ready for your needs, and you only pay for what you use.  Your maximum monthly bill would be ${formattedMaxMonthlyPayment}, if you used the additional ${formattedDelta} kWh available.`}
              />

              <RowContainerWithTooltip
                label="Annual Escalator"
                cost={`${formatPercent(summary.annualEscalator)}`}
                unit="Yearly"
                tooltip="Your Customer Agreement will have additional information"
              />

              <RowContainer
                label="Agreement Term"
                cost={summary.termYears.toString()}
                unit="Years"
              />
            </StyledCategory>

            {(summary.rebates > 0 ||
              summary.srec > 0 ||
              summary.costcoCard > 0) && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Rebates & Incentives</Typography>
                </StyledHeader>

                {summary.rebates > 0 && (
                  <RowContainer
                    label="Estimated State and Local Rebates"
                    cost={summary.rebates}
                  />
                )}

                {summary.srec > 0 && (
                  <RowContainerWithTooltip
                    tooltip={SRECII}
                    label="SRECII (SREC2)"
                    decimals={2}
                    cost={summary.srec}
                  />
                )}

                {summary.costcoCard > 0 && (
                  <RowContainerWithTooltip
                    tooltip={COSTCO_CARD_ESTIMATE}
                    label="Est. Costco Shop Card"
                    decimals={2}
                    cost={summary.costcoCard}
                  />
                )}
              </StyledCategory>
            )}

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Monthly Payments</Typography>
              </StyledHeader>

              {summary.market === "Y1_MONTHLY_PAYMENTS" && (
                <>
                  <RowContainer
                    label="Estimated Monthly Avg. Pre-Solar Utility Bill"
                    cost={summary.preSolarUtility}
                    unit="/mo"
                  />
                  <CardSectionDivider />
                </>
              )}

              <RowContainerWithModal
                modalDescription={POST_SOLAR_UTILITY_BILL}
                label="Estimated Monthly Avg. Post-Solar Utility Bill"
                ariaLabel="Open Estimated Monthly Avg. Post-Solar Utility Bill"
                cost={
                  summary.market === "CONTROL" ? "*" : summary.postSolarUtility
                }
                unit={summary.market === "CONTROL" ? "" : "/mo"}
              />

              <RowContainerWithTooltip
                label="Sunrun Minimum Monthly Bill"
                cost={formattedMinMonthlyPayment}
                maxCost={formattedMaxMonthlyPayment}
                tooltip={`Comparison shows your Monthly Minimum bill of ${formattedMinMonthlyPayment}. If you used all ${formattedDelta} kilowatt hours of additional discounted electricity available, your maximum monthly bill in year one would be ${formattedMaxMonthlyPayment}. Prices include the ACH autopay discount`}
              />
            </StyledCategory>

            {summary.market === "SAVINGS" && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Savings</Typography>
                </StyledHeader>

                <RowContainerWithModal
                  modalDescription={LIFETIME_SAVINGS}
                  label="Est. Lifetime Savings"
                  ariaLabel="Open Savings modal"
                  cost={`${formatCurrency(
                    summary.savingsLow,
                    2
                  )} - ${formatCurrency(summary.savingsHigh, 2)}`}
                />
              </StyledCategory>
            )}
          </StyledSecondColumn>
        </BreakdownRows>
        <TotalRow>
          <Total>Due at Signing</Total>
          <Total>{formatCurrency(summary.dueAtSigning, 2)}</Total>
        </TotalRow>
        {summary.evChargerValue && (
          <TotalRow>
            <Total>EV Charger: Due at Installation</Total>
            <Total>{formatCurrency(summary.evChargerValue, 2)}</Total>
          </TotalRow>
        )}
      </BreakdownBox>
      <StyledFootnote>
        Valid until {new Date(summary.expires).toLocaleDateString()}
      </StyledFootnote>
    </>
  );
};

export { PriceFlexMonthlyTPO };
