import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";
import { Typography } from "@sunrun/experience-ui-components";

type PackageHeaderProps = React.ComponentPropsWithoutRef<"div">;

const PackageHeader = ({ children, ...otherProps }: PackageHeaderProps) => (
  <StyledHeaderWrapper {...otherProps}>
    <StyledPill>
      <Typography>{children}</Typography>
    </StyledPill>
  </StyledHeaderWrapper>
);

const StyledHeaderWrapper = styled.div`
  background-color: ${tokens.BRAND_CHARGEBLUE};
`;

const StyledPill = styled.div`
  display: inline-block;
  margin: 84px 4px 16px 16px;
  padding: 4px 16px;
  border-radius: 24px;
  background-color: ${tokens.BRAND_OFF_WHITE};
`;

export { PackageHeader };
