import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "@sunrun/experience-ui-components";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";

const CheckoutNavGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { tasks, tasksLoading, currentTask, currentTaskId, isRefetchingTasks } =
    useCheckoutContext();
  const [guardTriggered, setGuardTriggered] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (tasks?.length > 0) {
      if (currentTask && currentTask.isUnlocked) {
        setGuardTriggered(false);
      } else {
        setGuardTriggered(true);
      }
    }
  }, [tasks, currentTaskId, currentTask]);

  //TODO: Add 'feature flag' guard

  // Only show loading indicator on first load, not on refetch. This allows for pages to show
  // more specific loading indicators instead of always being the entire screen loading.
  if ((tasksLoading && !isRefetchingTasks) || guardTriggered === undefined)
    return (
      <LoadingIndicator
        fullScreen
        color="black"
        message="Getting latest Checkout info..."
      />
    );

  //TODO: Implement treatment for nav guard.
  if (guardTriggered) return <div>YOU AINT SUPPOSED TO BE HERE</div>;

  return <>{children}</>;
};

export { CheckoutNavGuard };
