import styled from "@emotion/styled/macro";
import { Modal } from "@sunrun/experience-ui-components";
import { ModalButtonProp } from "@sunrun/experience-ui-components/lib/components/Modal";

type Props = {
  handleClose: () => void;
  fileName: string;
  fileUrl: string;
  title?: string;
  primaryButton: ModalButtonProp;
};

const PreviewModal = ({
  handleClose,
  fileName,
  fileUrl,
  title,
  primaryButton,
}: Props) => {
  return (
    <StyledModal
      onClose={handleClose}
      title={title}
      primaryButton={primaryButton}
    >
      <h1>{fileName}</h1>
      <ScrollableContainer>
        <ImagePreviewViewer src={fileUrl} />
      </ScrollableContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .sr-modal-container {
    max-width: 100%;
  }
`;

const ImagePreviewViewer = styled.img`
  margin-top: 10px;
  width: 100%;
`;

const ScrollableContainer = styled.div`
  overflow: scroll;
`;

export { PreviewModal };
