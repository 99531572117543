import styled from "@emotion/styled/macro";
import { useState, useEffect, useCallback } from "react";

type props = {
  min: number;
  max: number;
  defaultMin?: number;
  defaultMax?: number;
  twoDots?: boolean;
  displayFn?: (value: any) => string;
  onChange: (min: number, max: number) => void;
  step?: number;
};

const MinMaxSlider = ({
  max,
  min,
  defaultMax,
  defaultMin,
  twoDots,
  displayFn = (v) => v,
  onChange,
  step,
}: props) => {
  const [dot1Value, setDot1Value] = useState(defaultMin ?? min);
  const [dot2Value, setDot2Value] = useState(defaultMax ?? max);

  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);

  const [width, setWidth] = useState("100%");
  const [marginLeft, setMarginLeft] = useState("0%");
  const [marginRight, setMarginRight] = useState("100%");

  const isDisabled = min === max;

  useEffect(() => {
    if (min !== undefined && max !== undefined) {
      setWidth(`${((maxValue - minValue) / (max - min)) * 100}%`);
      setMarginLeft(`${((minValue - min) / (max - min)) * 100}%`);
      setMarginRight(`${((maxValue - min) / min) * 100}%`);
    }
  }, [minValue, maxValue, min, max]);

  const onMinMaxChange = useCallback(
    (dot1Value: number, dot2Value: number) => {
      const minValue = dot1Value < dot2Value ? dot1Value : dot2Value;
      const maxValue = dot1Value >= dot2Value ? dot1Value : dot2Value;
      setMinValue(minValue);
      setMaxValue(maxValue);
      onChange(minValue, maxValue);
    },
    [setMinValue, setMaxValue, onChange]
  );

  return (
    <Container>
      <MinMaxContainer>
        <Line />
        <InBetweenBar
          style={{
            width,
            marginLeft,
            marginRight,
          }}
          className={isDisabled ? "disabled" : ""}
        />
        <TextOverContainer>
          {twoDots && (
            <TextOver
              data-testid="slider-text-over"
              style={{
                width,
                marginLeft,
                marginRight,
              }}
            >
              {displayFn(minValue)}
            </TextOver>
          )}
          <TextOver
            data-testid="single-dot-text-over"
            className="right"
            style={{
              width,
              marginLeft,
              marginRight,
            }}
          >
            {displayFn(maxValue)}
          </TextOver>
        </TextOverContainer>

        {twoDots && (
          <input
            data-testid="min-input"
            title="min"
            id="min"
            name="min"
            type="range"
            step={step ?? 1}
            min={min}
            max={max}
            defaultValue={defaultMin || min}
            onChange={(v1) => {
              setDot1Value(Number(v1.target.value));
              onMinMaxChange(Number(v1.target.value), dot2Value);
            }}
            className={isDisabled ? "disabled" : ""}
          />
        )}
        <input
          data-testid="max-input"
          title="max"
          id="max"
          name="max"
          type="range"
          step={step ?? 1}
          min={min}
          max={max}
          defaultValue={defaultMax || max}
          onChange={(v1) => {
            setDot2Value(Number(v1.target.value));
            onMinMaxChange(dot1Value, Number(v1.target.value));
          }}
          className={isDisabled ? "disabled" : ""}
        />
      </MinMaxContainer>
    </Container>
  );
};
const Container = styled.div`
  height: 1rem;
  position: relative;
`;
const TextOverContainer = styled.div`
  width: calc(100% - 5px);
  position: relative;
  left: 2.5px;
`;
const TextOver = styled.div`
  position: absolute;
  top: 20px;
  color: #6f6d68;
  &.right {
    text-align: right;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 5px;
  background-color: #dbdce5;
  border-radius: 5px;

  position: relative;
  top: 7px;
`;
const InBetweenBar = styled.div`
  background-color: black;
  &.disabled {
    background-color: gray;
  }
  position: absolute;
  height: 5px;
  top: 7px;
  margin: 0px 15px;
`;
const MinMaxContainer = styled.span`
  & input {
    :hover {
      z-index: 1;
    }
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    accent-color: black;
    &.disabled {
      accent-color: gray;
    }
    width: 100%;
    font-size: smaller;
    margin: 0;
    height: 0px;
    position: absolute;
    cursor: grab;
    top: 0.6rem;
    pointer-events: none;
    &:active::-webkit-slider-thumb {
      cursor: grabbing;
    }
    &.disabled::-webkit-slider-thumb {
      background-color: gray;
    }
    &::-webkit-slider-thumb {
      pointer-events: auto; /* catch clicks */
      -webkit-appearance: none;
      appearance: none;
      background-color: black;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      border: white 3px solid;
    }
    &:active::-moz-range-thumb {
      cursor: grabbing;
    }
    &::-moz-range-thumb {
      pointer-events: auto; /* catch clicks */
      background-color: black;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      border: white 3px solid;
      &:before {
        content: attr(min) " ";
        position: relative;
        bottom: -30px;
        font-size: large;
      }
    }
  }
`;

export { MinMaxSlider };
