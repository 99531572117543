import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { LoadingIndicator } from "@sunrun/experience-ui-components";
import { PageCenter } from "components/templates/PageCenter";

const LoginPage = () => {
  useEffect(() => {
    Auth.federatedSignIn();
  }, []);
  return (
    <PageCenter>
      <LoadingIndicator color="black" />
    </PageCenter>
  );
};

export { LoginPage };
