import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { DateTime, DateTimeFormatOptions } from "luxon";
import {
  Button,
  Modal,
  Skeleton,
  SvgIcon,
  SvgNames,
  Typography,
  tokens,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { useParams } from "react-router-dom";
import {
  CancelSiteSurveyAppointmentRequestData,
  SiteSurveyAppointment,
  SiteSurveySlotRequestData,
} from "../../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { Card, CardContent, CardHeader } from "../molecules/Card";
import { LinkButton } from "../atoms/LinkButton";
import { SiteSurveyCancelAppointmentModal } from "../molecules/SiteSurveyCancelAppointmentModal";
import { SwrSuspense } from "utils/SwrSuspense";
import {
  LinkHelpEmail,
  LinkHelpPhone,
} from "checkout/components/atoms/ContactLinks";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import {
  SiteSurveySlot,
  useSiteSurveyAppointments,
  useSiteSurveySlots,
} from "checkout/api/siteSurvey";
import { CardSectionDivider } from "components/atoms/Card";
import {
  createSiteSurveyAppointment,
  cancelSiteSurveyAppointment,
} from "services/prospect";
import { Collapse } from "components/atoms/GlobalStyles";
import { useUsageData } from "utils/swrHooks";
import { useAuthToken } from "hooks/useAuthToken";

const SCHEDULE_SUBTITLE =
  "A Site Technician will visit your home to make measurements and check for certain requirements - like whether your roof is in good condition or if we need to make electrical updates - to confirm that your home is ready for solar panels.";
const SLOTS_ERROR = "We had a problem loading appointments, please try again.";
const SLOTS_ERROR_BUTTON = "Retry";
const SLOTS_UNAVAILABLE =
  "To book an appointment, view dates further out. If you're not able to make an appointment, please contact sales support.";
const SLOTS_UNAVAILABLE_BUTTON = "Contact sales support";
const SLOTS_UNAVAILABLE_CONTACT = [
  "Speak with your manager, or email ",
  <LinkHelpEmail linkColor={tokens.BLACK} />,
  ", or call Sales Support Desk at ",
  <LinkHelpPhone linkColor={tokens.BLACK} />,
  ".",
];
const SLOTS_UNAVAILABLE_EXPLANATION =
  "Sorry but we can't get more appointments available please contact our team to set one manually or fix it.";

const HEADER_DATE_FORMAT: DateTimeFormatOptions = {
  weekday: "short",
  month: "short",
  day: "numeric",
};
const HEADER_DATE_FORMAT_LONG: DateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
};
const SLOT_TIME_FORMAT: DateTimeFormatOptions = {
  minute: "2-digit",
  hour: "numeric",
  hourCycle: "h12",
};

const Header = styled.div`
  display: flex;
  align-items: start;
  gap: 1em;
  margin-bottom: 1em;
`;
const Actions = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: end;
`;
const TimeZone = styled(Card)`
  display: inline-block;
  padding: 1em;
  background-color: ${tokens.TINTS_CHARGE_BLUE_70};
  border: 1px solid ${tokens.TINTS_CHARGE_BLUE_40};
  white-space: nowrap;
`;
const SlotsHeader = styled(CardHeader)`
  display: flex;
  position: relative;
  & > * {
    flex: 1 1 0;
  }
`;
const buttonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  :hover {
    background-color: ${tokens.INFO_90};
  }
  :active {
    background-color: ${tokens.INFO_70};
  }
`;
const SlotsPagerControlLeft = styled.button`
  ${buttonStyles}
  position: absolute;
  left: 0;
`;
const SlotsPagerControlRight = styled.button`
  ${buttonStyles}
  position: absolute;
  right: 0;
`;
const SlotsHeaderText = styled.span`
  text-align: center;
  user-select: none;
`;
const SlotsGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  & > * {
    flex: 1 1 0;
  }
`;
const Column = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const SlotSkeleton = styled(Skeleton)`
  height: 3em;
`;
const SlotCheckmark = styled(SvgIcon)`
  height: 1.5em;
  vertical-align: bottom;
  position: absolute;
  right: 16px;
  svg,
  path {
    fill: ${tokens.SUCCESS_60};
  }
`;
const SlotTimeText = styled.span`
  user-select: none;
`;
const ConfirmationCard = styled.div`
  max-width: 340px;
`;
const Slot = styled(Card)`
  border: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  padding: 1em 2.5em;
  text-align: center;
  position: relative;
  cursor: pointer;
  &[aria-selected="true"] {
    background-color: ${tokens.HEROBLUE_90};
    border: 1px solid ${tokens.TINTS_CHARGE_BLUE_40};
    color: black;
  }
  :hover {
    color: ${tokens.WHITE};
    background-color: ${tokens.BRAND_MEDIUM_BLUE};
    border: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  }
`;

type CircleIconProps = {
  name: SvgNames;
  backgroundColor?: string;
  iconColor?: string;
};
const CircleIcon: React.FC<CircleIconProps> = ({
  backgroundColor,
  iconColor,
  name,
}: CircleIconProps) => {
  const Circle = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${backgroundColor ?? tokens.HEROBLUE_90};
    border-radius: 50%;
    padding: 0.5em;
    height: 1.5em;
    width: 1.5em;
  `;
  const Icon = styled(SvgIcon)`
    height: 1.25em;
    width: 1.25em;
    > path {
      fill: ${iconColor ?? tokens.BLACK};
    }
  `;
  return (
    <Circle>
      <Icon name={name} />
    </Circle>
  );
};

const ScheduleAppointment: React.FC = () => {
  const authKey = useAuthToken("HYBRID");
  const {
    currentTask,
    opportunityId,
    hasNextTask,
    navigateToNextTask,
    refreshTasks,
  } = useCheckoutContext();
  const { prospectId } = useParams();
  const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
  const [showUnavailableModal, setShowUnavailableModal] =
    useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [loadingCancelAppt, setLoadingCancelAppt] = useState<boolean>(false);
  const incrementDate = useCallback(() => {
    setStartDate((startDate) =>
      DateTime.max(startDate.plus({ day: 3 }), DateTime.now())
    );
  }, []);
  const decrementDate = useCallback(() => {
    setStartDate((startDate) =>
      DateTime.max(startDate.plus({ day: -3 }), DateTime.now())
    );
  }, []);
  const [selectedSlot, _setSelectedSlot] = useState<SiteSurveySlot>();
  const setSelectedSlot = useCallback((slot: SiteSurveySlot) => {
    _setSelectedSlot(slot);
  }, []);
  const [siteSurveyLoading, setSiteSurveyLoading] = useState(false);
  const [showScheduleErrorModal, setShowScheduleErrorModal] = useState(false);
  const [cancelApptReason, setCancelApptReason] = useState<string>();

  const slots = useSiteSurveySlots(
    prospectId || "",
    startDate.set({ hour: 0, minute: 0 }).toISODate() as string,
    startDate.plus({ days: 2 }).toISODate() as string
  );
  const {
    siteSurveyAppointment,
    siteSurveyAppointmentsLoading,
    mutateSiteSurveyAppointments,
  } = useSiteSurveyAppointments(prospectId);

  const { prospect, isLoading: isProspectLoading } = useUsageData(prospectId);

  const onSchedule = useCallback(() => {
    if (!selectedSlot || !prospect?.salesRep || !prospectId) {
      return;
    }

    setSiteSurveyLoading(true);

    const requestObj: SiteSurveySlotRequestData = {
      ...selectedSlot,
      prospectId,
      virtual: false,
      flexibility: true,
      form: true,
      appointmentType: "Site Survey",
      externalSource: "Sales Platform",
      sobjectId: opportunityId,
      primaryRepIds: [prospect?.salesRep],
      userId: prospect?.salesRep,
      appointmentId: "", // ok to be blank
    };

    createSiteSurveyAppointment(authKey?.token ?? "", prospectId, requestObj)
      .then(() => {
        mutateSiteSurveyAppointments();
        refreshTasks();
      })
      .catch(() => {
        setShowScheduleErrorModal(true);
      })
      .finally(() => {
        setSiteSurveyLoading(false);
      });
  }, [
    selectedSlot,
    prospect?.salesRep,
    prospectId,
    opportunityId,
    authKey,
    mutateSiteSurveyAppointments,
    refreshTasks,
  ]);

  const onUnschedule = useCallback(
    (reason: string) => {
      if (!siteSurveyAppointment || !prospectId) {
        return;
      }

      setLoadingCancelAppt(true);

      const requestObj: CancelSiteSurveyAppointmentRequestData = {
        ...siteSurveyAppointment,
        cancellationReason: reason,
      } as any;

      cancelSiteSurveyAppointment(
        authKey?.token ?? "",
        prospectId,
        siteSurveyAppointment.appointmentId,
        requestObj
      )
        .then(() => {
          mutateSiteSurveyAppointments();
          refreshTasks();
        })
        .catch(() => {
          setCancelApptReason(reason);
        })
        .finally(() => {
          setShowCancelModal(false);
          setLoadingCancelAppt(false);
        });
    },
    [
      authKey,
      mutateSiteSurveyAppointments,
      prospectId,
      refreshTasks,
      siteSurveyAppointment,
    ]
  );

  const SlotsColumn: React.FC<{ slots: Array<SiteSurveySlot> }> = useMemo(
    () =>
      ({ slots }) => {
        return (
          <Column>
            {slots.map((s) => (
              <Slot
                key={s.startTime}
                onClick={() => setSelectedSlot(s)}
                role="option"
                aria-selected={s === selectedSlot ? "true" : "false"}
              >
                <SlotTimeText>
                  {DateTime.fromISO(s.startTime)
                    .toLocaleString(SLOT_TIME_FORMAT)
                    .replace(/ /g, "\u00A0")}
                  &nbsp;-{" "}
                  {DateTime.fromISO(s.endTime)
                    .toLocaleString(SLOT_TIME_FORMAT)
                    .replace(/ /g, "\u00A0")}
                </SlotTimeText>
                {s === selectedSlot && (
                  <SlotCheckmark name={SvgNames.CheckFilled} />
                )}
              </Slot>
            ))}
          </Column>
        );
      },
    [selectedSlot, setSelectedSlot]
  );
  const SlotsError: React.FC = useMemo(
    () => () => {
      const SlotsErrorWrapper = styled.div`
        padding: 2em 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
      `;
      const SlotsErrorP = styled.p`
        text-align: center;
        text-wrap: balance;
      `;
      return (
        <SlotsErrorWrapper>
          <CircleIcon name={SvgNames.WarningOutline} />
          <SlotsErrorP>{SLOTS_ERROR}</SlotsErrorP>
          <Button
            color="secondary"
            data-testid="api-slots-retry"
            size="sm"
            onClick={() => slots.retry(undefined)}
          >
            {SLOTS_ERROR_BUTTON}
          </Button>
        </SlotsErrorWrapper>
      );
    },
    [slots]
  );
  const SlotsUnavailable: React.FC = useMemo(
    () => () => {
      const SlotsUnavailableWrapper = styled.div`
        padding: 2em 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
      `;
      const SlotsUnavailableP = styled.p`
        text-align: center;
        text-wrap: balance;
      `;
      return (
        <SlotsUnavailableWrapper>
          <CircleIcon name={SvgNames.WarningOutline} />
          <SlotsUnavailableP>{SLOTS_UNAVAILABLE}</SlotsUnavailableP>
          <Button
            color="secondary"
            data-testid="show-unavailable-modal"
            size="sm"
            onClick={() => setShowUnavailableModal(true)}
          >
            {SLOTS_UNAVAILABLE_BUTTON}
          </Button>
        </SlotsUnavailableWrapper>
      );
    },
    []
  );

  const ErrorAppointmentModal: React.FC<{
    title: string;
    description: string;
    onClick: () => void;
    onClose: () => void;
  }> = useMemo(
    () =>
      ({ title, description, onClick, onClose }) => {
        return (
          <Modal
            type="error"
            title={title}
            primaryButton={{
              text: "Retry",
              onClick,
            }}
            secondaryButton={{
              text: "Cancel",
              onClick: onClose,
            }}
            onClose={onClose}
          >
            <Typography>{description}</Typography>
          </Modal>
        );
      },
    []
  );

  const SlotsUnavailableModal: React.FC = useMemo(
    () => () => {
      return (
        <Modal
          hideIcon
          onClose={() => setShowUnavailableModal(false)}
          primaryButton={{
            text: "Cancel",
            onClick: () => setShowUnavailableModal(false),
          }}
          title="Contact Team for Help"
        >
          <p>{SLOTS_UNAVAILABLE_EXPLANATION}</p>
          <p>{SLOTS_UNAVAILABLE_CONTACT}</p>
        </Modal>
      );
    },
    []
  );
  const SlotPicker: React.FC = useMemo(
    () => () => {
      const slots1 = (slots.data ?? []).filter((slot) =>
        DateTime.fromISO(slot.startTime).hasSame(
          startDate.plus({ day: 0 }),
          "day"
        )
      );
      const slots2 = (slots.data ?? []).filter((slot) =>
        DateTime.fromISO(slot.startTime).hasSame(
          startDate.plus({ day: 1 }),
          "day"
        )
      );
      const slots3 = (slots.data ?? []).filter((slot) =>
        DateTime.fromISO(slot.startTime).hasSame(
          startDate.plus({ day: 2 }),
          "day"
        )
      );
      const slotsEmpty =
        slots1.length === 0 && slots2.length === 0 && slots3.length === 0;
      return (
        <Card>
          <SlotsHeader role="heading">
            <SlotsPagerControlLeft
              aria-label="Previous Date"
              onClick={decrementDate}
            >
              <SvgIcon
                name={SvgNames.ChevronLeft}
                height="2em"
                color={tokens.BRAND_HERO_BLUE}
              />
            </SlotsPagerControlLeft>
            <SlotsPagerControlRight
              aria-label="Next Date"
              onClick={incrementDate}
            >
              <SvgIcon
                name={SvgNames.ChevronRight}
                height="2em"
                color={tokens.BRAND_HERO_BLUE}
              />
            </SlotsPagerControlRight>
            <SlotsHeaderText>
              {startDate.toLocaleString(HEADER_DATE_FORMAT)}
            </SlotsHeaderText>
            <SlotsHeaderText>
              {startDate.plus({ days: 1 }).toLocaleString(HEADER_DATE_FORMAT)}
            </SlotsHeaderText>
            <SlotsHeaderText>
              {startDate.plus({ days: 2 }).toLocaleString(HEADER_DATE_FORMAT)}
            </SlotsHeaderText>
          </SlotsHeader>
          <CardSectionDivider />
          <CardContent role="listbox">
            <SwrSuspense
              data={slots.data}
              isLoading={slots.isLoading}
              error={slots.error}
            >
              <SwrSuspense.Loading>
                <SlotsGrid>
                  <Column>
                    <SlotSkeleton />
                    <SlotSkeleton />
                    <SlotSkeleton />
                  </Column>
                  <Column>
                    <SlotSkeleton />
                    <SlotSkeleton />
                    <SlotSkeleton />
                  </Column>
                  <Column>
                    <SlotSkeleton />
                    <SlotSkeleton />
                    <SlotSkeleton />
                  </Column>
                </SlotsGrid>
              </SwrSuspense.Loading>
              <SwrSuspense.Error>
                <SlotsError />
              </SwrSuspense.Error>
              <SwrSuspense.Data>
                {slotsEmpty && <SlotsUnavailable />}
                {!slotsEmpty && (
                  <SlotsGrid>
                    <SlotsColumn slots={slots1} />
                    <SlotsColumn slots={slots2} />
                    <SlotsColumn slots={slots3} />
                  </SlotsGrid>
                )}
              </SwrSuspense.Data>
            </SwrSuspense>
          </CardContent>
        </Card>
      );
    },
    [
      decrementDate,
      incrementDate,
      slots,
      startDate,
      SlotsColumn,
      SlotsError,
      SlotsUnavailable,
    ]
  );
  const ScheduledAppointmentCard: React.FC<{ appt: SiteSurveyAppointment }> =
    useMemo(
      () =>
        ({ appt }) => {
          const { arrivalStartDate, arrivalEndDate } = appt;
          const formattedStartDate =
            DateTime.fromISO(arrivalStartDate).toLocaleString(SLOT_TIME_FORMAT);
          const formattedEndDate =
            DateTime.fromISO(arrivalEndDate).toLocaleString(SLOT_TIME_FORMAT);

          return (
            <ConfirmationCard>
              <Card>
                <CardContent>
                  <Typography>
                    You have scheduled your appointment. A site technician will
                    arrive between:
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    size={tokens.WEB_BODY_LG_FONT_SIZE}
                  >
                    {`${formattedStartDate} - ${formattedEndDate}`}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    size={tokens.WEB_BODY_LG_FONT_SIZE}
                  >
                    {DateTime.fromISO(arrivalStartDate).toLocaleString(
                      HEADER_DATE_FORMAT_LONG
                    )}
                  </Typography>
                  <Collapse>
                    <LinkButton
                      onClick={() => setShowCancelModal(true)}
                      data-testid="api-site-survey-cancel"
                    >
                      Cancel appointment
                    </LinkButton>
                  </Collapse>
                </CardContent>
              </Card>
            </ConfirmationCard>
          );
        },
      []
    );

  if (
    !currentTask ||
    siteSurveyAppointmentsLoading ||
    isProspectLoading ||
    siteSurveyLoading
  ) {
    return <LoadingIndicator color="black" />;
  }

  return (
    <>
      <Header>
        <p>{SCHEDULE_SUBTITLE}</p>
        <TimeZone>
          {DateTime.now().zone.offsetName(0, { format: "long" })}
        </TimeZone>
      </Header>
      {siteSurveyAppointment && (
        <ScheduledAppointmentCard appt={siteSurveyAppointment} />
      )}
      {!siteSurveyAppointment && <SlotPicker />}
      <Actions>
        {!currentTask.isComplete && (
          <Button
            data-testid="select-slot"
            disabled={!selectedSlot}
            onClick={onSchedule}
          >
            Schedule
          </Button>
        )}
        {currentTask.isComplete && hasNextTask && (
          <Button data-testid="continue" onClick={navigateToNextTask}>
            Confirm
          </Button>
        )}
      </Actions>
      {showUnavailableModal && <SlotsUnavailableModal />}
      {showCancelModal && (
        <SiteSurveyCancelAppointmentModal
          loading={loadingCancelAppt}
          cancellationReasons={
            siteSurveyAppointment?.cancellationReasons?.map((reason) => ({
              label: reason,
              value: reason,
            })) || [{ label: "", value: "" }]
          }
          name={siteSurveyAppointment?.homeownerName || ""}
          onClose={() => setShowCancelModal(false)}
          onSubmit={(reason) => onUnschedule(reason)}
        />
      )}
      {showScheduleErrorModal && (
        <ErrorAppointmentModal
          title="Something went wrong creating your appointment"
          description="Our system is having trouble processing your appointment at the
              moment. Please try again or call Sales Support Desk to resolve the
              issue."
          onClick={() => {
            setShowScheduleErrorModal(false);
            onSchedule();
          }}
          onClose={() => {
            setShowScheduleErrorModal(false);
          }}
        />
      )}
      {Boolean(cancelApptReason) && (
        <ErrorAppointmentModal
          title="Something went wrong cancelling your appointment"
          description="Our system is having trouble processing your cancelation at the moment.
          Please try again or call Sales Support Desk to resolve the issue."
          onClick={() => {
            setCancelApptReason(undefined);
            onUnschedule(cancelApptReason!);
          }}
          onClose={() => {
            setCancelApptReason(undefined);
          }}
        />
      )}
    </>
  );
};

export {
  HEADER_DATE_FORMAT,
  SLOTS_ERROR,
  SLOTS_ERROR_BUTTON,
  SLOTS_UNAVAILABLE,
  SLOTS_UNAVAILABLE_BUTTON,
  SLOTS_UNAVAILABLE_EXPLANATION,
  SLOT_TIME_FORMAT,
  ScheduleAppointment,
};
