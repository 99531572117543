import styled from "@emotion/styled/macro";
import { forwardRef } from "react";

type IFrameProps = {
  basePath: string;
  src: string;
  title: string;
};

const IFrameAtom = forwardRef<HTMLIFrameElement, Omit<IFrameProps, "ref">>(
  ({ basePath, src, title }, ref) => {
    return (
      <IFrameContainer
        key={basePath}
        ref={ref}
        src={src}
        title={title}
        allow="fullscreen;"
      />
    );
  }
);

const IFrameContainer = styled.iframe`
  display: block;
  width: 100vw;
  height: 100vh;
  border: 0px;
`;

export { IFrameAtom };
