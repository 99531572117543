import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

const HorizontalLine = styled.hr`
  width: 100%;
  margin-bottom: 20px;
  border-style: solid;
  border-top-width: 0;
  border-color: ${tokens.OFFWHITE_40};
`;

export { HorizontalLine };
