import styled from "@emotion/styled/macro";
import {
  tokens,
  Button,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { maxWidth } from "components/atoms/GlobalStyles";

type CheckoutFooterProps = {
  buttonText: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  testId?: string;
};

const CheckoutFooter = ({
  buttonText,
  onClick = () => {},
  isLoading = false,
  isDisabled = false,
  testId = "checkout-footer-button-continue",
}: CheckoutFooterProps) => {
  const buttonClicked = () => {
    onClick();
  };

  return (
    <CheckoutPageFooter>
      <CheckoutPageInnerFooter>
        <ButtonContainer>
          <Button
            data-testid={testId}
            style={{ width: 154 }}
            size="md"
            onClick={buttonClicked}
            disabled={isDisabled}
          >
            {isLoading ? (
              <LoadingIndicator color="white" showLoadingMessage={false} />
            ) : (
              buttonText
            )}
          </Button>
        </ButtonContainer>
      </CheckoutPageInnerFooter>
    </CheckoutPageFooter>
  );
};

const CheckoutPageFooter = styled.footer`
  height: 120px;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1024px) {
    background: ${tokens.BACKGROUND_LIGHT};
  }
`;
const ButtonContainer = styled.div`
  @media (max-width: ${maxWidth}px) {
    margin-right: 40px;
  }
`;
const CheckoutPageInnerFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export { CheckoutFooter };
