import axios from "axios";
import { getEnv } from "utils/env";

const sendProposalEmail = async (
  hybridToken: string,
  prospectId: string,
  optionalNote: string
) => {
  try {
    const postData = {
      optionalNote,
      prospectId,
      emailType: "ONE_PROPOSAL",
    };

    const url =
      getEnv() === "production"
        ? "https://gateway.sunrun.com/splat-proposal-service"
        : "https://gateway.sunrun.com/majstg-splat-proposal-service";
    await axios.post(`${url}/sendEmail`, postData, {
      headers: {
        Authorization: hybridToken,
      },
    });
  } catch (error) {}
};

export { sendProposalEmail };
