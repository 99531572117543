import startCase from "lodash/startCase";
import sumBy from "lodash/sumBy";
import camelCase from "lodash/camelCase";
import { CamelCase } from "type-fest";
import { FinancialProduct, PricePoint, RateType } from "../PricePoints";
import { isBaseRate } from "../../../services/pricingDataHelpers";
import type { PricingFieldsPayload } from "../getPricingFields.types";
import { formatCurrency } from "../../../utils/formatHelpers";
import {
  CHANNEL_PARTNER,
  DFS,
  DIRECT_TO_HOME,
  FUSION,
  NIS,
  SALES_PARTNER,
} from "../../../fixtures/salesDivision";
import {
  CASH,
  LOAN,
  MONTHLY,
  PREPAID,
  FLEX_MONTHLY,
} from "../../../constants/financialProducts";
import type { Permission } from "./Permission";
import { isFlex, isFreedom } from "./PricingFieldsUtilities";

type Commissions = PricePoint["calculations"]["commissions"]["commissions"];
type Commission = Commissions[number];

const EV_CHARGER_TYPE = "EV_CHARGER";
const SMART_PANEL_TYPE = "POWER_MANAGEMENT";
const EV_CHARGER_TITLE = "EV Charger";
const CHANGE_ORDER_TITLE = "Change Order";

type ColumnIds =
  | "Change Order"
  | "Service Package"
  | "Solar Rate Non Monthly"
  | "Solar Rate Flex Monthly"
  | "Solar Rate Monthly"
  | "Est. Monthly Solar"
  | "Est. Flex Monthly Solar"
  | "Solar Price"
  | "Monthly Battery"
  | "Battery Price"
  | "Monthly Smart Panel"
  | "Smart Panel Price"
  | "Est. Total Monthly Payment"
  | "Est. Total Flex Monthly Payment"
  | "Est. Monthly Payment"
  | "Total Price"
  | "Total Loan Amount"
  | "PPS"
  | "Est. Commission*"
  | "Est. Solar Commission"
  | "Est. Storage Commission"
  | "Est. Add-On Commission"
  | "EPC"
  | "EV Charger";

const isSolarCommission = (commission: Commission): boolean => {
  switch (commission?.productOffering) {
    case "SOLAR":
      return true;
    case "EV_CHARGER":
    case "POWER_MANAGEMENT":
    case "RAD":
    case "STORAGE":
    case "BACKUP":
      return false;
  }
};

const hasEvCharger = (payload: PricingFieldsPayload): boolean =>
  payload?.unifiedPricingData[
    camelCase(payload?.financialProduct) as CamelCase<FinancialProduct>
  ].some((pricePoint) => {
    return pricePoint?.lineItems.some((item) => {
      return item?.type === EV_CHARGER_TYPE;
    });
  });

const hasSmartPanel = (payload: PricingFieldsPayload): boolean =>
  payload?.unifiedPricingData[
    camelCase(payload?.financialProduct) as CamelCase<FinancialProduct>
  ].some((pricePoint) => {
    return pricePoint?.lineItems.some((item) => {
      return item.type === SMART_PANEL_TYPE;
    });
  });

const isBattery = (payload: PricingFieldsPayload): boolean =>
  payload?.unifiedPricingData[
    camelCase(payload?.financialProduct) as CamelCase<FinancialProduct>
  ].some((pricePoint) => {
    return Number(pricePoint?.calculations?.batteryPrice) > 0;
  });

const getLineItemByType = (
  pricePoint: PricePoint,
  type: PricePoint["lineItems"][0]["type"]
): PricePoint["lineItems"][0] | undefined => {
  return pricePoint?.lineItems?.find((item) => item.type === type);
};

const isStorageCommission = (commission: Commission): boolean => {
  switch (commission?.productOffering) {
    case "RAD":
    case "STORAGE":
    case "BACKUP":
      return true;
    case "EV_CHARGER":
    case "POWER_MANAGEMENT":
    case "SOLAR":
      return false;
  }
};

const hasStorageCommission = (payload: PricingFieldsPayload): boolean =>
  payload?.unifiedPricingData[
    camelCase(payload?.financialProduct) as CamelCase<FinancialProduct>
  ].some((pricePoint) => {
    return (
      sumCommissions(
        pricePoint?.calculations?.commissions?.commissions.filter(
          isStorageCommission
        )
      ) > 0
    );
  });

const isAddOnCommission = (commission: Commission): boolean => {
  switch (commission?.productOffering) {
    case "EV_CHARGER":
    case "POWER_MANAGEMENT":
      return true;
    case "RAD":
    case "SOLAR":
    case "STORAGE":
    case "BACKUP":
      return false;
  }
};

const hasAddOnCommission = (payload: PricingFieldsPayload): boolean =>
  payload?.unifiedPricingData[
    camelCase(payload?.financialProduct) as CamelCase<FinancialProduct>
  ].some((pricePoint) => {
    return (
      sumCommissions(
        pricePoint?.calculations?.commissions?.commissions.filter(
          isAddOnCommission
        )
      ) > 0
    );
  });

const getRateByKind = (rates: Array<RateType>, kind: RateType["kind"]) =>
  rates.find((rate) => rate.kind === kind);

const sumCommissions = (commissions: Commissions): number => {
  return sumBy(commissions, (commission) => Number(commission?.amount));
};

type DisplayFilterReturn = string | [value: string, subtitle?: string];

const createColumn = <V>(Column: {
  id: ColumnIds;
  title: string;
  subTitle?: string;
  permission?: Permission;
  value: (pricePoint: PricePoint) => V;
  displayFilter?: (value: V, pricePoint?: PricePoint) => DisplayFilterReturn;
}) => ({
  ...Column,
  value: Column.value as (pricePoint: PricePoint) => any,
  displayFilter: (Column.displayFilter || ((v: string) => v)) as (
    value: any,
    pricePoint?: PricePoint
  ) => DisplayFilterReturn,
});

const columns = [
  createColumn({
    id: "Change Order",
    title: CHANGE_ORDER_TITLE,
    value: (pricePoint) => pricePoint?.changeOrder?.changeOrderType,
    permission: {
      allow: (payload) => payload?.changeOrder,
    },
  }),
  createColumn({
    id: "Service Package",
    title: "Service Package",
    value: (pricePoint) => pricePoint?.servicePackage,
    permission: {
      allow: (payload) => isFlex(payload),
    },
    displayFilter: (value, pricePoint) => {
      return startCase(value);
    },
  }),
  createColumn({
    id: "Solar Rate Non Monthly",
    title: "Solar Rate",
    subTitle: "per W installed",
    permission: {
      disallow: (payload) =>
        [MONTHLY, FLEX_MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => pricePoint?.displayDetails?.finalRate?.value,
    displayFilter: (value, pricePoint) => {
      const formattedRate = formatCurrency(Number(value), 3);
      if (isBaseRate(pricePoint)) {
        return [`${formattedRate}`, "Base price"];
      } else {
        return formattedRate;
      }
    },
  }),
  createColumn({
    id: "Solar Rate Flex Monthly",
    title: "Flex Rate",
    permission: {
      allow: (payload) => [FLEX_MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => {
      const marginalRateValue = getRateByKind(
        pricePoint?.calculations.rates || [],
        "Marginal"
      )?.value;

      const marginalRate = marginalRateValue
        ? Number.parseFloat(marginalRateValue)
        : null;
      const consumptionRate = Number.parseFloat(
        getRateByKind(pricePoint?.calculations.rates || [], "Consumption")
          ?.value ?? "0"
      );
      const productionRate = Number.parseFloat(
        getRateByKind(pricePoint?.calculations.rates || [], "Production")
          ?.value ?? "0"
      );
      return (
        marginalRate ?? formatCurrency(consumptionRate - productionRate, 3)
      );
    },
    displayFilter: (_, pricePoint) => {
      const consumptionRate = Number.parseFloat(
        getRateByKind(pricePoint?.calculations.rates || [], "Consumption")
          ?.value ?? "0"
      );
      const productionRate = Number.parseFloat(
        getRateByKind(pricePoint?.calculations.rates || [], "Production")
          ?.value ?? "0"
      );
      return formatCurrency(consumptionRate - productionRate, 3);
    },
  }),
  createColumn({
    id: "Solar Rate Monthly",
    title: "Solar Rate",
    subTitle: "per kWh installed",
    permission: {
      allow: (payload) => [MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => pricePoint?.displayDetails?.finalRate?.value,
    displayFilter: (value, pricePoint) => {
      const formattedRate = formatCurrency(Number(value), 3);
      if (isBaseRate(pricePoint)) {
        return [`${formattedRate}`, "Base price"];
      } else {
        return formattedRate;
      }
    },
  }),
  createColumn({
    id: "Est. Monthly Solar",
    title: "Est. Monthly Solar",
    permission: {
      allow: (payload) =>
        isBattery(payload) && [MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => Number(pricePoint?.calculations?.solarPrice),
    displayFilter: (value) => {
      return formatCurrency(value, 2);
    },
  }),
  createColumn({
    id: "Est. Flex Monthly Solar",
    title: "Est. Monthly Solar",
    subTitle: "Min | Max",
    permission: {
      allow: (payload) => [FLEX_MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) =>
      getLineItemByType(pricePoint, "SOLAR")?.minimumAmount || NaN,
    displayFilter: (value, pricePoint) => {
      const totalAmount = pricePoint
        ? getLineItemByType(pricePoint, "SOLAR")?.totalAmount
        : NaN;
      return `${formatCurrency(value, 2)} | ${formatCurrency(totalAmount, 2)}`;
    },
  }),
  createColumn({
    id: "Solar Price",
    title: "Solar Price",
    permission: {
      disallow: (payload) =>
        [MONTHLY, FLEX_MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => Number(pricePoint?.calculations?.solarPrice),
    displayFilter: (value) => {
      return formatCurrency(value, 0);
    },
  }),
  createColumn({
    id: "Monthly Battery",
    title: "Monthly Battery",
    permission: {
      allow: (payload) =>
        isBattery(payload) &&
        [MONTHLY, FLEX_MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => Number(pricePoint?.calculations?.batteryPrice),
    displayFilter: (value) => {
      return formatCurrency(value, 2);
    },
  }),
  createColumn({
    id: "Battery Price",
    title: "Battery Price",
    permission: {
      allow: (payload) => isBattery(payload),
      disallow: (payload) =>
        [MONTHLY, FLEX_MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => Number(pricePoint?.calculations?.batteryPrice || 0),
    displayFilter: (value) => {
      return formatCurrency(value, 2);
    },
  }),
  createColumn({
    id: "Monthly Smart Panel",
    title: "Monthly Smart Panel",
    permission: {
      allow: (payload) =>
        payload &&
        hasSmartPanel(payload) &&
        [MONTHLY].includes(payload.financialProduct),
    },
    value: (pricePoint) =>
      getLineItemByType(pricePoint, SMART_PANEL_TYPE)?.totalAmount || 0,
    displayFilter: (value) => {
      return formatCurrency(value, 2);
    },
  }),
  createColumn({
    id: "Smart Panel Price",
    title: "Smart Panel Price",
    permission: {
      allow: (payload) => hasSmartPanel(payload),
      disallow: (payload) =>
        !payload || [MONTHLY].includes(payload.financialProduct),
    },
    value: (pricePoint) =>
      getLineItemByType(pricePoint, SMART_PANEL_TYPE)?.totalAmount || 0,
    displayFilter: (value) => {
      return formatCurrency(value, 2);
    },
  }),
  createColumn({
    id: "Est. Total Monthly Payment",
    title: "Est. Total Monthly Payment",
    subTitle: "before tax",
    permission: {
      allow: (payload) => [MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => Number(pricePoint?.calculations?.payment),
    displayFilter: (value) => formatCurrency(value, 2),
  }),

  createColumn({
    id: "Est. Total Flex Monthly Payment",
    title: "Est. Total Monthly Payment",
    subTitle: "Before Tax: Min | Max",
    permission: {
      allow: (payload) => [FLEX_MONTHLY].includes(payload?.financialProduct),
    },
    value: (pricePoint) => Number(pricePoint?.calculations?.payment),
    displayFilter: (value, pricePoint) =>
      `${formatCurrency(
        Number(pricePoint?.calculations?.minimumPayment),
        2
      )} | ${formatCurrency(value, 2)}
      `,
  }),

  createColumn({
    id: "Est. Monthly Payment",
    title: "Est. Monthly Payment",
    subTitle: "w/ or w/out tax credit pre-payment",
    permission: {
      allow: (payload) => [LOAN].includes(payload?.financialProduct),
    },
    value: (pricePoint) =>
      Number(
        pricePoint?.calculations?.paymentWithCredits ??
          pricePoint?.calculations?.payment
      ) ?? 0,
    displayFilter: (value, pricePoint) => {
      const payment = Number(pricePoint?.calculations?.payment ?? 0);
      const paymentWithCredits = Number(
        pricePoint?.calculations?.paymentWithCredits ?? 0
      );
      return `${formatCurrency(payment, 2)} | ${formatCurrency(
        paymentWithCredits,
        2
      )}`;
    },
  }),
  createColumn({
    id: "Total Price",
    title: "Total Price",
    permission: {
      allow: (payload) => [CASH, PREPAID].includes(payload?.financialProduct),
    },
    value: (pricePoint) => pricePoint?.calculations?.upfront,
    displayFilter: (value) => formatCurrency(Number(value), 2),
  }),
  createColumn({
    id: "Total Loan Amount",
    title: "Total Loan Amount",
    permission: {
      allow: (payload) => [LOAN].includes(payload?.financialProduct),
    },
    value: (pricePoint) => Number(pricePoint?.calculations?.upfront),
    displayFilter: (value) => formatCurrency(value, 2),
  }),
  createColumn({
    id: "PPS",
    title: "PPS",
    subTitle: "Profitability Score",
    permission: {
      allow: (payload) => isFlex(payload),
    },
    value: (pricePoint) => pricePoint?.pps,
  }),
  createColumn({
    id: "Est. Commission*",
    title: "Est. Commission*",
    permission: {
      allow: (payload) =>
        isFlex(payload) ||
        [DIRECT_TO_HOME, FUSION].includes(payload?.salesDivision),
      disallow: (payload) => payload.showCommission === false,
    },
    value: (pricePoint) => {
      const commissions =
        pricePoint?.calculations?.commissions?.commissions ?? [];
      const expertCommission = commissions.find(
        (commission: { description: string }) =>
          commission?.description === "Expert"
      );
      const standardCommission = commissions[0];
      return Number(
        expertCommission?.amount ?? standardCommission?.amount ?? 0
      );
    },
    displayFilter: (value) => formatCurrency(value, 0),
  }),
  createColumn({
    id: "Est. Solar Commission",
    title: "Est. Solar Commission",
    permission: {
      allow: (payload) =>
        isFlex(payload) || [DFS, NIS].includes(payload?.salesDivision),
      disallow: (payload) => payload.showCommission === false,
    },
    value: (pricePoint) => {
      const commissions =
        pricePoint?.calculations?.commissions?.commissions ?? [];
      const solarCommissions = commissions.filter(isSolarCommission);
      const standardCommission = commissions[0];
      return sumCommissions(solarCommissions ?? [standardCommission]);
    },
    displayFilter: (value) => formatCurrency(value, 0),
  }),
  createColumn({
    id: "Est. Storage Commission",
    title: "Est. Storage Commission",
    permission: {
      allow: (payload) => {
        const allowedUsers =
          isFlex(payload) || [DFS, NIS].includes(payload?.salesDivision);
        return allowedUsers && hasStorageCommission(payload);
      },
      disallow: (payload) => payload.showCommission === false,
    },
    value: (pricePoint) => {
      const commissions =
        pricePoint?.calculations?.commissions?.commissions ?? [];
      return sumCommissions(commissions.filter(isStorageCommission));
    },
    displayFilter: (value) => formatCurrency(value, 0),
  }),
  createColumn({
    id: "Est. Add-On Commission",
    title: "Est. Add-On Commission",
    permission: {
      allow: (payload) => {
        const allowedUsers =
          isFlex(payload) || [DFS, NIS].includes(payload?.salesDivision);
        return allowedUsers && hasAddOnCommission(payload);
      },
      disallow: (payload) => payload.showCommission === false,
    },
    value: (pricePoint) => {
      const commissions =
        pricePoint?.calculations?.commissions?.commissions ?? [];
      return sumCommissions(commissions.filter(isAddOnCommission));
    },
    displayFilter: (value) => formatCurrency(value, 0),
  }),
  createColumn({
    id: "EPC",
    title: "EPC",
    subTitle: "$/W",
    permission: {
      allow: (payload) =>
        isFreedom(payload) &&
        [CHANNEL_PARTNER, SALES_PARTNER].includes(payload?.salesDivision),
      disallow: (payload) => payload.showCommission === false,
    },
    value: (pricePoint) => Number(pricePoint?.displayDetails?.epcRate?.value),
    displayFilter: (value) => formatCurrency(value, 3),
  }),

  createColumn({
    id: "EV Charger",
    title: EV_CHARGER_TITLE,
    subTitle: "Total Cost",
    permission: {
      allow: (payload) => hasEvCharger(payload),
    },
    value: (pricePoint) => {
      const chargerLineItem = getLineItemByType(pricePoint, EV_CHARGER_TYPE);
      return chargerLineItem?.totalAmount ? chargerLineItem.totalAmount : 0;
    },

    displayFilter: (value) => {
      if (value > 0) {
        return formatCurrency(Number(value), 2);
      }
      return "";
    },
  }),
];

export {
  CHANGE_ORDER_TITLE,
  columns,
  createColumn,
  EV_CHARGER_TITLE,
  EV_CHARGER_TYPE,
};
export type { ColumnIds };
