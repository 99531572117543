import styled from "@emotion/styled/macro";
import {
  Button,
  Radio,
  SlidingDrawer,
  SvgIcon,
  SvgNames,
  tokens,
  Tooltip,
  Typography,
} from "@sunrun/experience-ui-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SplatContact } from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { ContactSelectionManagement } from "./ContactSelectionManagement";
import { PageHeaderOrganismContainer } from "./PageHeaderOrganism";
import { Card, CardHeaderDivider } from "components/atoms/Card";
import { contentPaddingMobile, maxWidth } from "components/atoms/GlobalStyles";
import { postCreditCheck } from "services/prospect";
import { useAppSelector } from "store";
import { useContactManagement } from "hooks/useContactManagement";
import { ROLES } from "utils/usageUtils";
import { getPrimaryContact } from "utils/contacts";

type QualifyCustomerModalProps = {
  open: boolean;
  onClose: () => void;
};

type FinancingPlanState = "monthly" | "loan";

const QualifyCustomerModal = ({ open, onClose }: QualifyCustomerModalProps) => {
  const { contactList, isEditing, isContactsLoading, ...restSmartProps } =
    useContactManagement();
  const { prospectId } = useParams();
  const [financingPlan, setFinancingPlan] =
    useState<FinancingPlanState>("monthly");

  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const [selectedApplicant, setSelectedApplicant] = useState<SplatContact>();
  const [isChecking, setIsChecking] = useState(false);
  const [creditErrorMsg, setCreditErrorMsg] = useState<Error>();

  useEffect(() => {
    if (!selectedApplicant && contactList?.length) {
      const initialPrimaryContact = getPrimaryContact(contactList);
      setSelectedApplicant(initialPrimaryContact);
    }
  }, [contactList, selectedApplicant]);

  const runCreditCheck = async () => {
    setIsChecking(true);
    setCreditErrorMsg(undefined);
    try {
      const result = await postCreditCheck(
        authKey,
        prospectId ?? "",
        selectedApplicant?.contactId ?? "",
        "INPERSON"
      );

      onClose();
      window.open(result.url);
    } catch (error) {
      setCreditErrorMsg(error as Error);
    }
    setIsChecking(false);
  };

  const isValid = selectedApplicant?.contactId && prospectId?.length;
  const submitButtonText = isChecking ? "Loading" : "Run Credit Check";

  const isCreditCheckDisabled =
    isChecking || !isValid || isEditing || isContactsLoading;

  return (
    <SlidingDrawer isOpen={open} onClose={() => onClose()} closeOnEscape>
      <ContainerStyled>
        <HeaderStyled>
          <Typography variant="h1" color={tokens.BRAND_HERO_BLUE}>
            Credit Check
          </Typography>
          <Button
            size="sm"
            iconMiddle={
              <SvgIcon height={22} color="white" name={SvgNames.Close} />
            }
            onClick={() => onClose()}
          />
        </HeaderStyled>
        <ContentContainer>
          <CardStyled>
            <header>
              <Typography variant="h4" color={tokens.BRAND_HERO_BLUE}>
                Financing Plan
              </Typography>
            </header>
            <CardHeaderDivider />
            <label id="financingPlanType" className="sr-text-label">
              Select Financing Plan
            </label>
            <RadioContainer aria-labelledby="financingPlanType">
              <Radio
                label="Monthly"
                value="monthly"
                checked={financingPlan === "monthly"}
                onChange={() => setFinancingPlan("monthly")}
                data-testid={"standard-radio"}
              />
              <StyledTooltip
                direction="bottom"
                content="This credit check is for monthly solar-as-a-service options. For loan qualification save a loan offer and get approved in checkout."
              >
                <SvgIcon width={18} name={SvgNames.InfoCircle} />
              </StyledTooltip>
            </RadioContainer>
          </CardStyled>
          <ContactSelectionManagement
            contact={selectedApplicant}
            title="Applicant"
            specifiedRole={ROLES.OTHER}
            buttonLabel="Change Applicant"
            ContactViewProps={{ itemsPerRow: 1 }}
            ContactSelectProps={{ itemsPerRow: 1 }}
            ContactFormProps={{ itemsPerRow: 1 }}
            onSelectContact={setSelectedApplicant}
            contactList={contactList}
            showCreditStatus
            isContactsLoading={isContactsLoading}
            {...restSmartProps}
          />
        </ContentContainer>
        <FooterContainer>
          {creditErrorMsg?.message && (
            <ErrorContainer>
              <span className="sr-text-helper-text">
                {creditErrorMsg?.message}
              </span>
            </ErrorContainer>
          )}
          <Button
            color="primary"
            size="md"
            onClick={runCreditCheck}
            disabled={isCreditCheckDisabled}
          >
            {submitButtonText}
          </Button>
        </FooterContainer>
      </ContainerStyled>
    </SlidingDrawer>
  );
};

const Flex = styled.div`
  display: flex;
`;

const FlexCol = styled(Flex)`
  flex-direction: column;
`;

const ContainerStyled = styled(FlexCol)`
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    ${tokens.CHARGEBLUE_50} 0,
    ${tokens.BACKGROUND_LIGHT} 16rem
  );
`;

const HeaderStyled = styled(PageHeaderOrganismContainer)``;

const ContentContainer = styled(FlexCol)`
  flex: 1;
  min-height: 0;

  padding-left: 24px;
  padding-right: 24px;
  overflow: auto;

  @media (max-width: ${maxWidth}px) {
    padding-left: ${contentPaddingMobile}px;
    padding-right: ${contentPaddingMobile}px;
  }
`;

const FooterContainer = styled.div`
  padding: 18px;
  @media (max-width: ${maxWidth}px) {
    border-top: 2px solid ${tokens.TINTS_OFF_WHITE_40};
  }
`;

const CardStyled = styled(Card)`
  margin-bottom: 36px;
  height: auto;
`;

const RadioContainer = styled(Flex)`
  padding-top: 12px;
  align-items: center;
  gap: 12px;
`;

const ErrorContainer = styled.div`
  padding-bottom: 8px;
  text-align: center;
  text-transform: capitalize;
`;

const StyledTooltip = styled(Tooltip)`
  .sr-tooltip-content {
    width: 205px;
  }
`;

export type { QualifyCustomerModalProps };
export { QualifyCustomerModal };
