import styled from "@emotion/styled/macro";
import { Typography } from "@sunrun/experience-ui-components";
import { ReviewOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { useDesignPreferencesUrl } from "../../hooks/useDesignPreferencesUrl";
import { Container } from "../atoms/GlobalStyles";
/* eslint-disable-next-line import/no-restricted-paths */
import { EnergyBundle } from "./EnergyBundle";
/* eslint-disable-next-line import/no-restricted-paths */
import { EnergyAccessories } from "./EnergyAccessories";

const ProductBreakdown = ({
  offer,
  prospectId,
}: {
  offer: ReviewOffer;
  prospectId: string;
}) => {
  const { data: designPreferencesUrl } = useDesignPreferencesUrl(prospectId);
  return (
    <>
      <EnergyBundle
        bundle={offer.bundle}
        total={offer.financial.includedTotal}
        paymentType={offer.financial.summary?.type}
        products={offer.includedProducts}
        hasAccessories={offer.accessoryProducts.length > 0}
      />
      {offer.accessoryProducts.length > 0 && (
        <EnergyAccessories
          total={offer.financial.accessoryTotal}
          paymentType={offer.financial.summary?.type}
          products={offer.accessoryProducts}
        />
      )}
      {designPreferencesUrl && (
        <ProductActionsContainer>
          <a target="_blank" rel="noreferrer" href={designPreferencesUrl.url}>
            <Typography fontWeight="regular" size="large">
              Edit Customer Preferences
            </Typography>
          </a>
        </ProductActionsContainer>
      )}
    </>
  );
};

const ProductActionsContainer = styled(Container)`
  margin-top: 1em;
`;

export { ProductBreakdown };
