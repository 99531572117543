import styled from "@emotion/styled/macro";
import {
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import { StatusMessage } from "../../util/constants";

type StatusPillProps = {
  status: string;
  label: string;
};

const StatusPill = ({ status, label }: StatusPillProps) => {
  return (
    <Chip $status={status}>
      <IconWrapper>
        {
          {
            success: (
              <SvgIcon
                name={SvgNames.CheckEnclosed}
                width="16"
                height="16"
                color={tokens.TINTS_SUCCESS_30}
                data-testid="status-pill-success-icon"
              />
            ),
            info: (
              <SvgIcon
                name={SvgNames.ClockCountdown}
                width="16"
                height="16"
                color={tokens.TINTS_MEDIUM_BLUE_50}
                data-testid="status-pill-info-icon"
              />
            ),
            failure: (
              <SvgIcon
                name={SvgNames.ErrorCircle}
                width="16"
                height="16"
                color={tokens.CHARGERED_20}
                data-testid="status-pill-failure-icon"
              />
            ),
            warning: (
              <SvgIcon
                name={SvgNames.WarningCircle}
                width="16"
                height="16"
                color={tokens.CHARGEYELLOW_20}
                data-testid="status-pill-warning-icon"
              />
            ),
          }[status]
        }
      </IconWrapper>
      <Typography size="small">{label}</Typography>
    </Chip>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
`;

const Chip = styled.div<{ $status: StatusPillProps["status"] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
  gap: 4px;
  border-radius: 6px;

  ${({ $status }) => {
    switch ($status) {
      case StatusMessage.SUCCESS:
        return `
            border: 1px solid ${tokens.SUCCESS_70};
            background: ${tokens.SUCCESS_80};

            & > svg {
                & > path {
                    fill: ${tokens.SUCCESS_30} !important;
                }
            }
            
            & > div {
                fill: ${tokens.SUCCESS_30} !important;
                color: ${tokens.SUCCESS_30};
            }
        `;
      case StatusMessage.INFO:
        return `
            border: 1px solid ${tokens.TINTS_CHARGE_BLUE_40};
            background: ${tokens.TINTS_CHARGE_BLUE_70};
            fill: ${tokens.TINTS_MEDIUM_BLUE_50};
            & > div {
                color: ${tokens.TINTS_MEDIUM_BLUE_50};
            }
        `;
      case StatusMessage.FAILURE:
        return `
              border: 1px solid ${tokens.TINTS_CHARGE_RED_60};
              background: ${tokens.TINTS_CHARGE_RED_80};
              fill: ${tokens.TINTS_CHARGE_RED_20};
              & > div {
                  color: ${tokens.TINTS_CHARGE_RED_20};
              }
          `;
      case StatusMessage.WARNING:
        return `
              border: 1px solid ${tokens.TINTS_CHARGE_YELLOW_60};
              background: ${tokens.TINTS_CHARGE_YELLOW_80};
              fill: ${tokens.TINTS_CHARGE_YELLOW_20};
              & > div {
                  color: ${tokens.TINTS_CHARGE_YELLOW_20};
              }
          `;
    }
  }}
`;

export { StatusPill };
export type { StatusPillProps };
