import styled from "@emotion/styled/macro";
import { Modal, Typography } from "@sunrun/experience-ui-components";
// eslint-disable-next-line import/no-restricted-paths
import { FileUploadError } from "./UploadAttachments";

type MaxSizeErrorModalProps = {
  errors: FileUploadError[];
  maxSizeMB: number;
  onClose: () => void;
};

const MaxSizeErrorModal = ({
  errors,
  maxSizeMB,
  onClose,
}: MaxSizeErrorModalProps) => {
  if (!errors.length) {
    return null;
  }
  const initialText =
    errors.length > 1
      ? "These files are too large."
      : "This file is too large.";
  const errorMessage = `${initialText} Please limit files to ${maxSizeMB} MB.`;

  return (
    <Modal
      type="error"
      title={errorMessage}
      onClose={onClose}
      primaryButton={{ text: "Retry", onClick: () => onClose() }}
    >
      <TextContainer>
        {errors.map((file) => (
          <Typography key={file.fileName}>{file.fileName}</Typography>
        ))}
      </TextContainer>
    </Modal>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export type { MaxSizeErrorModalProps };
export { MaxSizeErrorModal };
