import type { PricingFieldsPayload } from "../getPricingFields.types";

type Permission = {
  allow?: (payload: PricingFieldsPayload) => boolean;
  disallow?: (payload: PricingFieldsPayload) => boolean;
};

const applyPermissions = (
  payload: PricingFieldsPayload,
  permission?: Permission
) => {
  if (!permission) return true;

  const allowed = permission.allow ? permission.allow(payload) : true;
  const disallowed = permission.disallow ? permission.disallow(payload) : false;
  return allowed && !disallowed;
};

export type { Permission };
export { applyPermissions };
