type MessageCommandGenerateDesigns = {
  type: "COMMAND:GENERATE_DESIGNS";
  source: "IHD";
};
type MessageEventGenerateDesigns = {
  type: "EVENT:GENERATE_DESIGNS";
  source: "IHD";
};
type MessageEventDesignEditCancel = {
  type: "DESIGN_EDIT_CANCEL";
};
type MessageEventDesignEditComplete = {
  type: "DESIGN_EDIT_COMPLETE";
};
type MessageEventGoToDesign = {
  source: "Offer Experience";
  type: "IHD_HACK_GO_TO_DESIGN";
};
type MessageQueryAuthToken = {
  type: "QUERY:AUTH_TOKEN";
  source: "IHD";
  payload: {
    type: "IHD";
  };
};
type MessageEventAuthToken = {
  type: "EVENT:AUTH_TOKEN";
  source: "Offer Experience";
  payload: {
    token: string;
  };
};
export type IhdMessage =
  | MessageCommandGenerateDesigns
  | MessageEventAuthToken
  | MessageEventDesignEditCancel
  | MessageEventDesignEditComplete
  | MessageEventGenerateDesigns
  | MessageEventGoToDesign
  | MessageQueryAuthToken;
export const logMessage = (message: IhdMessage) => {
  switch (message.type) {
    case "COMMAND:GENERATE_DESIGNS":
    case "IHD_HACK_GO_TO_DESIGN":
    case "DESIGN_EDIT_CANCEL":
    case "DESIGN_EDIT_COMPLETE":
    case "EVENT:AUTH_TOKEN":
    case "EVENT:GENERATE_DESIGNS":
    case "QUERY:AUTH_TOKEN":
      // eslint-disable-next-line no-console
      console.log(
        "📬",
        "source" in message ? message.source : "IHD",
        message.type,
        "payload" in message ? message.payload : undefined
      );
      break;
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = message;
    }
  }
};
