/* eslint-disable import/no-restricted-paths */
/* eslint-disable import/group-exports */
import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";

export const maxWidth = 768;
export const contentPadding = 70;
export const contentPaddingMobile = 16;

/**
 * ActionButtons is a container for a group of buttons that will be horizontally side by side on wider screens and vertically stacked on smaller screens.
 */
export const ActionButtons = styled.div`
  display: flex;
  > div {
    padding-right: 10px;
  }
  @media (max-width: 1100px) {
    display: block;
    div + div {
      padding-top: 1rem;
    }
  }
`;

export const Container = styled.div`
  padding: 0px 60px 0px 100px;
  @media (max-width: ${maxWidth}px) {
    padding: 20px;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  padding: 20px 0px 20px 0px;
  font-size: 50px;
  flex-direction: column;
  border-bottom: 1px solid rgba(208, 204, 195, 0.8);
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${maxWidth}px) {
    align-items: baseline;
  }
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 300;
  @media (max-width: ${maxWidth}px) {
    font-size: 22px;
  }
`;

export const Collapse = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
`;

export const ImageText = styled.div`
  font-size: 20px;
  @media (max-width: ${maxWidth}px) {
    font-size: 16px;
  }
`;

export const RowProductImages = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${maxWidth}px) {
    grid-template-columns: 1fr;
  }
`;

export const Product = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductImageArea = styled.div`
  flex: auto 1 0;
  display: grid;
`;

export const ProductImage = styled.img`
  max-height: 287px;
  max-width: 100%;
  align-self: center;
  justify-self: center;
`;

export const BundleCost = styled.div`
  font-size: 32px;
  color: #62667b;
  font-weight: 300;
  padding-right: 10px;
  @media (max-width: ${maxWidth}px) {
    font-size: 20px;
  }
`;

export const OptionCost = styled.div`
  font-size: 14px;
  color: #a3a09a;
  font-weight: 500;
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100px;
  margin: 10px;
  align-items: flex-start;
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const DividedOne = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100px;
  margin: 10px;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100px;
  height: 100%;
  margin: 10px;
  align-items: flex-end;
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-top: 20px;
  }
`;

export const DividedTwo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100px;
  height: 100%;
  margin: 10px;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-top: 20px;
  }
`;

export const Header = styled.div`
  font-size: 36px;
  font-weight: 300;
  padding-bottom: 10px;
`;

export const StandardText = styled.div`
  font-size: 20px;
  font-weight: 400;
  opacity: 0.7;
  padding: 25px 0px 25px 0px;
`;

export const HeroText = styled.div`
  font-size: 68px;
  padding-bottom: 10px;
  @media (max-width: ${maxWidth}px) {
    font-size: 42px;
  }
`;

export const PromoText = styled.div`
  font-weight: 200;
  padding-top: 20px;
  @media (max-width: ${maxWidth}px) {
    width: 70%;
  }
`;

export const BreakdownBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(208, 204, 195, 0.8);
  background-color: ${tokens.OFFWHITE_50};
  @media (max-width: ${maxWidth}px) {
    width: 100%;
    border-radius: 0px;
  }
`;

export const BreakdownRows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 20px 30px 20px;
  @media (max-width: ${maxWidth}px) {
    height: auto;
    flex-direction: column;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 0px 30px 0px;
  @media (max-width: ${maxWidth}px) {
    height: 502px;
  }
`;

export const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 96px;
  margin: 0 auto;
  background: #f4f1ec;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
    width: 100%;
    height: 136px;
    padding: 0px;
    align-items: flex-start;
    justify-content: space-evenly;
  }
`;

export const Total = styled.div`
  font-size: 30px;
  font-weight: 400;
  padding: 0px 20px 0px 20px;
  @media (max-width: ${maxWidth}px) {
    font-size: 22px;
  }
`;

export const ModalButtonContainer = styled.div`
  padding-top: 4px;
`;

export const ModalButton = styled.button`
  background: transparent;
  border: none;
  padding: 4px 0px 0px 0px;
  cursor: pointer;
`;

export const Modal = styled.div`
  .sr-modal-container {
    @media (max-width: ${maxWidth}px) {
      width: 352px;
      left: calc(50% - 352px / 2 + 0.5px);
    }
  }
`;

export const Grid = styled.div`
  display: grid;
`;

export const StyledSecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100px;
  height: 100%;
  margin: 10px;
  align-items: flex-end;
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
    width: auto;
    align-items: flex-start;
    padding-top: 20px;
  }
`;

export const StyledCategory = styled.div`
  width: 100%;
  margin-top: 54px;
`;

export const StyledHeader = styled.div`
  margin-bottom: 21px;
`;

export const StyledFootnote = styled.div`
  text-align: right;
  font-style: italic;
  font-size: small;
  margin: 16px;
`;
