import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";
import {
  Button,
  Modal,
  Pill,
  Snackbar,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { PillProps } from "@sunrun/experience-ui-components/lib/components/Pill";
import { ButtonProps } from "@sunrun/experience-ui-components/lib/components/Button/Button";
import { CardContent, CardHeader } from "../molecules/Card";
import { findContact, getPrimaryContact } from "../../../utils/contacts";
import { ROLES } from "../../../utils/usageUtils";
import { SlimContactCard } from "../molecules/SlimContactCard";
import { ErrorModal } from "../../../components/molecules/ErrorModal";
import { CreditStatusPillLabels } from "../../util/constants";
import { postCreditCheck } from "../../../services/prospect";
import { rollbar } from "../../../providers/rollbar";
import { Card, CardHeaderDivider } from "components/atoms/Card";
import { useContactsData } from "utils/swrHooks";
import { useAppSelector } from "store";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { SplatContact } from "providers/Types";

type SignerType = "primary" | "secondary";

const CreditCheckTaskView: React.FC = () => {
  const { navigateToTask, refreshTasks } = useCheckoutContext();
  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { contacts, contactsError, isContactsValidating, refetch } =
    useContactsData(prospectId as string, authKey);

  const primaryContact = getPrimaryContact(contacts);
  const primaryContactId = primaryContact?.contactId;
  const primaryCreditCheckUrl = useRef("");
  const secondaryCreditCheckUrl = useRef("");
  const secondaryContact = findContact(contacts, ROLES.CONTRACTCOSIGNER);
  const secondaryContactId = secondaryContact?.contactId;

  const [urlLoading, setUrlLoading] = useState(false);
  const [primaryNotChecked, setPrimaryNotChecked] = useState(false);
  const [secondaryNotChecked, setSecondaryNotChecked] = useState(false);
  const [emailConfirmOpen, setEmailConfirmOpen] = useState<SignerType>();
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState<SignerType>();

  const successMessage =
    showSuccessSnackbar === "primary"
      ? `${primaryContact?.customerFirstName} ${primaryContact?.customerLastName} Credit Check successfully sent`
      : `${secondaryContact?.customerFirstName} ${secondaryContact?.customerLastName} Credit Check successfully sent`;

  const [showErrorSnackbar, setShowErrorSnackbar] = useState<SignerType>();
  const errorMessage =
    showErrorSnackbar === "primary"
      ? `Unable to email ${primaryContact?.customerFirstName} ${primaryContact?.customerLastName} Credit Check`
      : `Unable to email ${secondaryContact?.customerFirstName} ${secondaryContact?.customerLastName} Credit Check`;

  const auxMessage = useMemo(() => {
    if (primaryContact?.creditStatus.isFailed && !secondaryContactId) {
      return "We need one of the signers to pass the credit check to continue. Add a Secondary signer and run credit check.";
    }

    if (
      primaryContact?.creditStatus.isFailed &&
      secondaryContact?.creditStatus.isFailed
    ) {
      return "Sorry, both signers did not pass credit. You can try replacing the secondary signer or try another financing method.";
    }

    if (
      primaryContact?.creditStatus.isFailed &&
      !secondaryContact?.creditStatus.isPassed
    ) {
      return "We need one of the signers to pass the credit check to continue.";
    }
  }, [
    primaryContact?.creditStatus.isFailed,
    secondaryContact?.creditStatus.isFailed,
    secondaryContact?.creditStatus.isPassed,
    secondaryContactId,
  ]);

  const secondarySignerButtonProps: Partial<ButtonProps> = {
    onClick: () => navigateToTask("confirmNames", { secondaryEdit: true }),
    color: auxMessage ? "primary" : "secondary",
    size: "sm",
  };

  type SplatCreditStatus = {
    isInProgress: boolean;
    isPassed: boolean;
    isManual: boolean;
    isFailed: boolean;
    isPrescreenApproved: boolean;
  };

  useEffect(() => {
    setPrimaryNotChecked(
      !!primaryContact &&
        !primaryContact.creditStatus.isPassed &&
        !primaryContact.creditStatus.isFailed
    );
  }, [primaryContact]);

  useEffect(() => {
    setSecondaryNotChecked(
      !!secondaryContact &&
        !secondaryContact.creditStatus.isPassed &&
        !secondaryContact.creditStatus.isFailed
    );
  }, [secondaryContact]);

  const mapCreditStatusToPillProps = (status: SplatCreditStatus): PillProps => {
    if (status.isFailed) {
      return {
        type: "error",
        text: CreditStatusPillLabels.CREDIT_NOT_APPROVED,
      };
    }

    if (status.isPassed || status.isPrescreenApproved) {
      return {
        type: "success",
        text: CreditStatusPillLabels.CREDIT_APPROVED,
      };
    }

    return {
      type: "warning",
      text: CreditStatusPillLabels.CREDIT_NOT_CHECKED,
    };
  };

  const fetchUrl = useCallback(
    async (id: string, contactType: SignerType) => {
      let result;

      try {
        setUrlLoading(true);
        result = await postCreditCheck(authKey, prospectId!, id, "INPERSON");
      } catch (e: any) {
        rollbar.error(e);
      } finally {
        if (result?.url) {
          if (contactType === "primary") {
            primaryCreditCheckUrl.current = result?.url;
          } else {
            secondaryCreditCheckUrl.current = result?.url;
          }
        }
        setUrlLoading(false);
      }
    },
    [authKey, prospectId]
  );

  useEffect(() => {
    if (primaryContactId) {
      fetchUrl(primaryContactId, "primary");
    }
  }, [authKey, fetchUrl, primaryContactId]);

  useEffect(() => {
    if (secondaryContactId) {
      fetchUrl(secondaryContactId, "secondary");
    }
  }, [authKey, fetchUrl, secondaryContactId]);

  const handleEmail = useCallback(
    async (contactId: string, contactType: SignerType) => {
      setEmailConfirmOpen(undefined);
      let result;

      try {
        result = await postCreditCheck(
          authKey,
          prospectId!,
          contactId,
          "EMAIL"
        );
      } catch (e: any) {
        rollbar.error(e);
      } finally {
        if (result?.success && result.success === true) {
          setShowSuccessSnackbar(contactType);
        } else {
          setShowErrorSnackbar(contactType);
        }
      }
    },
    [authKey, prospectId]
  );

  const handleRunCredit = useCallback((contactType: SignerType) => {
    const ref = {
      primary: primaryCreditCheckUrl,
      secondary: secondaryCreditCheckUrl,
    }[contactType];

    if (ref.current) {
      const w = window.open(ref.current!, "_blank");
      if (w) {
        w.focus();
      }
    }
  }, []);

  const renderFooter = (contactType: SignerType) => {
    return (
      <StyledFooter>
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            handleRunCredit(contactType);
          }}
          style={{ minWidth: 125 }}
          data-testid={`checkout-credit-check-run-${contactType}`}
        >
          {urlLoading ? (
            <LoadingIndicator showLoadingMessage={false} />
          ) : (
            "Run Credit Check"
          )}
        </Button>
        <Button
          data-testid={`checkout-run-credit-check-task-email-${contactType}`}
          color="secondary"
          size="sm"
          onClick={() => setEmailConfirmOpen(contactType)}
        >
          Email
        </Button>
      </StyledFooter>
    );
  };

  const renderEmailModal = (contact: SplatContact, contactType: SignerType) => (
    <StyledModal
      hideIcon={true}
      title={"Email Credit Check"}
      onClose={() => setEmailConfirmOpen(undefined)}
      primaryButton={{
        testId: "credit-check-modal-send-email",
        text: "Send email",
        onClick: () => handleEmail(contact.contactId, contactType),
      }}
      secondaryButton={{
        testId: "credit-check-modal-cancel",
        text: "Cancel",
        onClick: () => setEmailConfirmOpen(undefined),
      }}
    >
      <Typography className={"email-confirm"}>
        The credit check email will be sent to the email address below.
      </Typography>
      <SlimContactCard contact={contact}>
        <span>{contact.customerEmail}</span>
      </SlimContactCard>
    </StyledModal>
  );

  if (contactsError) {
    return (
      <ErrorModal
        context={"Error fetching contacts credit status"}
        error={contactsError}
        onClose={refetch}
        onRetry={refetch}
      />
    );
  }

  if (isContactsValidating) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Typography>
        Once you’ve completed the credit check, it might take several minutes to
        update the status.
        <InlineLink
          onClick={() => {
            refetch();
            refreshTasks();
          }}
        >
          Please refresh this page
        </InlineLink>
      </Typography>
      <GridLayoutDiv>
        {primaryContact && (
          <Card>
            <CardHeader>
              <Typography size={20}>Primary Signer</Typography>
            </CardHeader>
            <CardHeaderDivider />
            <CardContent>
              <SlimContactCard contact={primaryContact}>
                <Pill
                  {...mapCreditStatusToPillProps(primaryContact.creditStatus)}
                />
              </SlimContactCard>
              {primaryNotChecked && renderFooter("primary")}
            </CardContent>
            {emailConfirmOpen === "primary" &&
              renderEmailModal(primaryContact, "primary")}
          </Card>
        )}
        <Snackbar
          open={!!showSuccessSnackbar}
          autoHideDuration={4000}
          colorScheme="light"
          message={successMessage}
          onClose={() => setShowSuccessSnackbar(undefined)}
          type="success"
        />
        <Snackbar
          open={!!showErrorSnackbar}
          autoHideDuration={4000}
          colorScheme="light"
          message={errorMessage}
          onClose={() => setShowErrorSnackbar(undefined)}
          type="error"
        />
        {secondaryContact && (
          <Card>
            <CardHeader>
              <Typography size={20}>Secondary Signer</Typography>
            </CardHeader>
            <CardHeaderDivider />
            <CardContent>
              <SlimContactCard contact={secondaryContact}>
                <Pill
                  {...mapCreditStatusToPillProps(secondaryContact.creditStatus)}
                />
              </SlimContactCard>
              {secondaryNotChecked && renderFooter("secondary")}
            </CardContent>
            {emailConfirmOpen === "secondary" &&
              renderEmailModal(secondaryContact, "secondary")}
          </Card>
        )}

        <SecondaryButtonWrapper $warning={!!auxMessage}>
          {auxMessage && (
            <AuxMessage data-testid="checkout-credit-check-task-need-one-to-pass">
              <SvgIcon
                color={tokens.ERROR_20}
                name={SvgNames.ErrorFilled}
                height="26"
                width="26"
              />
              {auxMessage}
            </AuxMessage>
          )}
          {!secondaryContact && (
            <Button
              {...secondarySignerButtonProps}
              data-testid="checkout-credit-check-task-add-secondary-signer"
            >
              Add Secondary Signer
            </Button>
          )}
          {primaryContact?.creditStatus?.isFailed &&
            secondaryContact?.creditStatus?.isFailed && (
              <Button
                {...secondarySignerButtonProps}
                data-testid="checkout-credit-check-task-change-secondary-signer"
              >
                Change secondary signer
              </Button>
            )}
        </SecondaryButtonWrapper>
      </GridLayoutDiv>
    </>
  );
};

const SecondaryButtonWrapper = styled.div<{ $warning: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  ${({ $warning }) =>
    $warning &&
    `
    background-color: ${tokens.ERROR_90};
    border-radius: 8px;
    padding: 16px;
  `}
  & > button {
    min-width: 212px;
  }
`;

const AuxMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${tokens.ERROR_20};

  & svg {
    min-height: 26px;
    min-width: 26px;
  }
`;

const GridLayoutDiv = styled.div`
  display: grid;
  margin-top: 32px;
  grid-row-gap: 32px;
  margin-bottom: 32px;
`;

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;

  & Button {
    padding: 8px 48px;
  }
`;

const StyledModal = styled(Modal)`
  .sr-modal-container {
    max-width: 100%;
  }

  .sr-button-tertiary {
    flex-direction: column;
  }

  & .email-confirm {
    margin-bottom: 24px;
  }
`;

const InlineLink = styled.span`
  text-decoration: underline;
  font-weight: 200;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 2px;
`;

export { CreditCheckTaskView };
