import { Typography, Tooltip } from "@sunrun/experience-ui-components";
import infoIconGray from "../assets/images/info-icon-gray.svg";
import {
  BreakdownBox,
  BreakdownRows,
  FirstColumn,
  Header,
  HeroText,
  StandardText,
  Total,
  TotalRow,
  StyledSecondColumn,
  StyledCategory,
  StyledHeader,
  StyledFootnote,
} from "../atoms/GlobalStyles";
import {
  RowContainer,
  RowContainerWithTooltip,
  RowContainerWithModal,
} from "../atoms/PriceGlobals";
import { ReviewCashSummary } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { formatCurrency } from "utils/formatHelpers";
import { Warranties } from "components/atoms/Warranties";
import { CardSectionDivider } from "components/atoms/Card";
import {
  COSTCO_CARD_ESTIMATE,
  ESTIMATED_NET_SYSTEM_COST,
  FEDERAL_SOLAR_TAX_CREDIT,
  LIFETIME_SAVINGS,
  POST_SOLAR_UTILITY_BILL,
  SRECII,
  STATE_LOCAL_REBATES,
  YEAR_1_ENERGY_RATE,
} from "constants/pricing-tooltips";

const PriceCash = ({ summary }: { summary: ReviewCashSummary }) => {
  return (
    <>
      <BreakdownBox>
        <BreakdownRows>
          <FirstColumn>
            <Header>Have peace of mind</Header>
            <StandardText>
              Know that you're covered with premium protection.
            </StandardText>
            <Warranties warranties={summary.warranties} />
          </FirstColumn>
          <StyledSecondColumn>
            <Tooltip content={ESTIMATED_NET_SYSTEM_COST}>
              <Header>Est. Net System Cost</Header>
              <HeroText>{formatCurrency(summary.netCost)}</HeroText>
              <img alt="info icon tooltip" src={infoIconGray}></img>
            </Tooltip>

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Terms</Typography>
              </StyledHeader>

              <RowContainerWithTooltip
                tooltip={YEAR_1_ENERGY_RATE}
                label="Year 1 Energy Rate"
                cost={summary.year1RateKWh}
                decimals={3}
                unit="/kWh"
              />

              {summary.taxCredit > 0 && (
                <RowContainerWithModal
                  modalDescription={FEDERAL_SOLAR_TAX_CREDIT}
                  label="Estimated Federal Solar Tax Credit"
                  ariaLabel="Open Estimated Federal Solar Tax Credit Modal"
                  cost={summary.taxCredit}
                />
              )}

              {/* TODO Conditional total when not purchased thu costco OR costco + CA */}
              <RowContainer label="Total System Cost" cost={summary.total} />

              {/* TODO Conditional total before tax when purchased thu costco and state != CA */}
              <RowContainer
                label="Total System Cost (before tax)"
                cost={summary.total}
              />
            </StyledCategory>

            {(summary.rebates > 0 || summary.srec > 0) && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Rebates & Incentives</Typography>
                </StyledHeader>

                {summary.rebates > 0 && (
                  <RowContainerWithTooltip
                    tooltip={STATE_LOCAL_REBATES}
                    label="Estimated State and Local Rebates"
                    cost={summary.rebates}
                  />
                )}

                {summary.srec > 0 && (
                  <RowContainerWithTooltip
                    tooltip={SRECII}
                    label="SRECII (SREC2)"
                    cost={summary.srec}
                  />
                )}
              </StyledCategory>
            )}

            <StyledCategory>
              <StyledHeader>
                <Typography variant="h3">Monthly Payments</Typography>
              </StyledHeader>

              {summary.market === "Y1_MONTHLY_PAYMENTS" && (
                <>
                  <RowContainer
                    label="Estimated Monthly Avg. Pre-Solar Utility Bill"
                    cost={summary.preSolarUtility}
                    unit="/mo"
                  />
                  <CardSectionDivider />
                </>
              )}

              <RowContainerWithModal
                modalDescription={POST_SOLAR_UTILITY_BILL}
                label="Avg. Est. Post-Solar Utility Bill"
                ariaLabel="Open Avg. Est. Post-Solar Utility Bill Modal"
                cost={
                  summary.market === "CONTROL" ? "*" : summary.postSolarUtility
                }
                unit={summary.market === "CONTROL" ? "" : "/mo"}
              />

              {summary.costcoCard > 0 && (
                <RowContainerWithTooltip
                  tooltip={COSTCO_CARD_ESTIMATE}
                  label="Est. Costco Shop Card"
                  cost={summary.costcoCard}
                />
              )}
            </StyledCategory>

            {summary.market === "SAVINGS" && (
              <StyledCategory>
                <StyledHeader>
                  <Typography variant="h3">Savings</Typography>
                </StyledHeader>

                <RowContainerWithModal
                  modalDescription={LIFETIME_SAVINGS}
                  label="Est. Lifetime Savings"
                  ariaLabel="Open Savings Modal"
                  cost={`${formatCurrency(
                    summary.savingsLow
                  )} - ${formatCurrency(summary.savingsHigh)}`}
                />
              </StyledCategory>
            )}
          </StyledSecondColumn>
        </BreakdownRows>
        <TotalRow>
          <Total>Due at Signing</Total>
          <Total>{formatCurrency(summary.dueAtSigning)} deposit</Total>
        </TotalRow>
        <TotalRow>
          <Total>Due at Installation</Total>
          <Total>{formatCurrency(summary.dueAtInstallation)}</Total>
        </TotalRow>
        {summary.evChargerValue && (
          <TotalRow>
            <Total>EV Charger: Due at Installation</Total>
            <Total>{formatCurrency(summary.evChargerValue)}</Total>
          </TotalRow>
        )}
      </BreakdownBox>
      <StyledFootnote>
        Valid until {new Date(summary.expires).toLocaleDateString()}
      </StyledFootnote>
    </>
  );
};

export { PriceCash };
