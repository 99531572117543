import styled from "@emotion/styled/macro";
import {
  Typography,
  SvgIcon,
  SvgNames,
  tokens,
} from "@sunrun/experience-ui-components";
import { ReactElement } from "react";

type StatusString = "Error" | "Warning" | "Success";

type ContactStatusNotificationProps = {
  action?: () => void;
  actionLabel?: string;
  message: string;
  status: StatusString;
  customIcon?: ReactElement;
  testId?: string;
  iconWidth?: number;
};

const ContactStatusNotification = ({
  status,
  testId,
  customIcon,
  action,
  actionLabel,
  message,
  iconWidth = 20,
}: ContactStatusNotificationProps) => {
  let iconColor = tokens.SUCCESS_20;
  let iconName = SvgNames.SuccessCircle;
  let backgroundColor = tokens.TINTS_SUCCESS_90;

  if (status === "Error") {
    iconColor = tokens.ERROR_20;
    iconName = SvgNames.WarningCircle;
    backgroundColor = tokens.TINTS_ERROR_90;
  } else if (status === "Warning") {
    iconColor = tokens.WARNING_20;
    iconName = SvgNames.WarningCircle;
    backgroundColor = tokens.TINTS_WARNING_90;
  }

  return (
    <BackgroundDiv backgroundColor={backgroundColor} color={iconColor}>
      <MessageDiv>
        {customIcon ? (
          customIcon
        ) : (
          <IconWithPadding
            name={iconName}
            width={iconWidth}
            color={iconColor}
            data-testid={testId ?? iconName}
          />
        )}
        <Typography variant="p" color={iconColor}>
          {message}
        </Typography>
      </MessageDiv>
      {action && (
        <UnderlinedText onClick={action} color={iconColor}>
          {actionLabel}
        </UnderlinedText>
      )}
    </BackgroundDiv>
  );
};

const UnderlinedText = styled(Typography)<{ color: string }>`
  cursor: pointer;
  color: ${({ color }) => color};
`;

const IconWithPadding = styled(SvgIcon)`
  padding-right: 10px;
`;

const MessageDiv = styled.div`
  display: flex;
  align-items: center;
`;

const BackgroundDiv = styled.div<{ backgroundColor: string; color: string }>`
  border-radius: 5px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;

export { ContactStatusNotification };
export type { StatusString, ContactStatusNotificationProps };
