import {
  Role,
  SplatContact,
} from "../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { ROLES } from "./usageUtils";

const getPrimaryContact = (contacts: SplatContact[] | undefined) => {
  if (!contacts?.length) {
    return;
  }

  return contacts?.find((contact: SplatContact) => contact.primary);
};

const findContact = (
  contacts: SplatContact[] | undefined,
  contactRole: Role
) => {
  if (contactRole === ROLES.HOMEOWNER) {
    return contacts?.find((contact: SplatContact) => contact.primary);
  }

  return contacts?.find((contact: SplatContact) =>
    contact.derivedRoles.includes(contactRole)
  );
};

const getContactsOnTitle = (contacts: SplatContact[] | undefined) => {
  return (
    contacts?.filter(
      (contact) => contact.relationToHomeowner === "Is titleholder"
    ) || []
  );
};

export { getContactsOnTitle, getPrimaryContact, findContact };
