import { SplatODI } from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { useCheckoutStepsData } from "checkout/api/checkoutSteps";
import { useAppSelector } from "store";

type ConfirmUtilityProps = {
  prospectId: string;
  proposalId: string;
};

type UtilityCheckListStep = {
  name: string;
  title: string;
  description: string;
  documentType: string;
  completed: boolean;
  isRequired: boolean;
  order: number;
};

const CONFIRM_UTILITY_ODI_SOURCE: Record<
  string,
  {
    name: string;
    title: string;
    documentType?: string;
    description?: string;
    order?: number;
  }
> = {
  accountNumber: {
    name: "accountNumber",
    title: "Account Number",
  },
  meterNumber: {
    name: "meterNumber",
    title: "Meter Number",
  },
  nameOnBill: {
    name: "nameOnBill",
    title: "Name on Utility Bill",
  },
  currentUtilityBill: {
    name: "currentUtilityBill",
    title: "Current Utility Bill",
  },
  usage: {
    name: "usage",
    title: "Usage History",
    description:
      "Supplemental documentation required in utilities where bills do not show 12 months of past energy usage.",
  },
  utilityServiceAddress: {
    name: "utilityServiceAddress",
    title: "Utility service address",
  },
  utilityBill: {
    name: "utilityBill",
    title: "Utility Bills",
    description: "Upload monthly utility bill(s) for review",
    documentType: "Utility Bill",
    order: 0,
  },
  reservationId: {
    name: "reservationId",
    title: "APS Reservation ID",
    description: "Confirmation of Reservation ID w/in APS portal.",
    documentType: "Reservation ID",
    order: 1,
  },
  bankApprovalDocument: {
    name: "bankApprovalDocument",
    title: "Bank Approval Document",
    description: "See your loan application for instructions",
    documentType: "Bank Approval Document",
    order: 2,
  },
  proofOfCpuc: {
    name: "proofOfCpuc",
    title: "CA Solar Consumer Protection Guide (physical signature)",
    description: `Image of completed "Customer" section of Page 23, signed in ink by a homeowner no later than the same day of Sunrun agreement signature.`,
    documentType: "CPUC wet sign",
    order: 3,
  },
  costcoProductAcknowledgementForm: {
    name: "costcoProductAcknowledgementForm",
    description: "Required for Costco-purchased systems.",
    title: "Costco Product Form",
    documentType: "Costco Product Acknowledgement Form",
    order: 4,
  },
  homeOwnerInsurance: {
    name: "homeOwnerInsurance",
    title: "Homeowner Insurance",
    description: "Declaration page from homeowners insurance policy.",
    documentType: "Home Owner Insurance",
    order: 5,
  },
  llcOfficerCertificate: {
    name: "llcOfficerCertificate",
    title: "LLC Certificate",
    description: "Officer/membership certificate for LLC.",
    documentType: "LLC Officer Certificate",
    order: 6,
  },
  permanentFoundationDocument: {
    name: "permanentFoundationDocument",
    title: "Mobile Home Signoff",
    description: "Stamp of approval from a structural engineer.",
    documentType: "Permanent Foundation Document",
    order: 7,
  },
  livingSpaceSquareFootage: {
    name: "livingSpaceSquareFootage",
    title: "Square Footage",
    description: "Confirmation of living space square footage.",
    documentType: "Living Space Square Footage",
    order: 8,
  },
  trustDocs: {
    name: "trustDocs",
    title: "Trust Documents",
    description: "Deed or other records for your home’s trust.",
    documentType: "Trust Docs",
    order: 9,
  },
  proofOfUsage: {
    name: "proofOfUsage",
    title: "Usage History",
    description:
      "Supplemental documentation required in utilities where bills do not show 12 months of past energy usage.",
    documentType: "Proof of Usage",
    order: 10,
  },
};

const mapUtilitySubSteps = (subOdi: SplatODI): UtilityCheckListStep | null => {
  const odiInfo = CONFIRM_UTILITY_ODI_SOURCE[subOdi.odiName];

  if (!odiInfo) {
    return null;
  }

  const subTask: UtilityCheckListStep = {
    name: odiInfo.name || "",
    title: odiInfo.title || "",
    description: odiInfo.description || "",
    documentType: odiInfo.documentType || "",
    completed: subOdi.completed || false,
    isRequired: subOdi.isRequired || false,
    order: odiInfo.order ?? 1000,
  };

  return subTask;
};

const uploadDocsOdis = [
  "bankApprovalDocument",
  "costcoProductAcknowledgementForm",
  "homeOwnerInsurance",
  "livingSpaceSquareFootage",
  "llcOfficerCertificate",
  "permanentFoundationDocument",
  "proofOfUsage",
  "reservationId",
  "trustDocs",
  "utilityBill",
  "proofOfCpuc",
];

const useConfirmUtility = ({ prospectId, proposalId }: ConfirmUtilityProps) => {
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const {
    steps = [],
    stepsError,
    isStepsLoading,
    refetch,
  } = useCheckoutStepsData(prospectId, proposalId, authKey);

  const confirmUtilitySubOdis = steps
    .filter((step) => ["linkUtilityAccount"].includes(step.name))[0]
    .odis.filter((odi) => !["nemAgreement"].includes(odi.odiName));

  const mappedUtilityChecklistSteps: (UtilityCheckListStep | null)[] =
    confirmUtilitySubOdis.map(mapUtilitySubSteps);

  const unknownOdis = confirmUtilitySubOdis
    .filter((step) => !CONFIRM_UTILITY_ODI_SOURCE[step.odiName])
    .map((odi) => odi.odiName);

  const utilityChecklistSteps = mappedUtilityChecklistSteps.filter(
    (step) => step !== null
  ) as UtilityCheckListStep[];

  return {
    utilityChecklistSteps,
    stepsError,
    isStepsLoading,
    refetch,
    unknownOdis,
  };
};

export { useConfirmUtility, uploadDocsOdis, CONFIRM_UTILITY_ODI_SOURCE };
export type { UtilityCheckListStep };
