/* eslint-disable import/no-restricted-paths */
import { useMemo } from "react";
import trashImg from "../assets/images/carousel/trash-img.png";
import treesImg from "../assets/images/carousel/trees-img.png";
import crossCountryRoadTripsImg from "../assets/images/carousel/cross-country-road-trips-img.png";
import { ImageInfoCarousel } from "./ImageInfoCarousel";
import { CardCarouselData, TitleSection } from "providers/Types";
import { formatNumber } from "utils/formatHelpers";

const environmentalImpactCarouselTitle: TitleSection = {
  title: "Environmental impact",
  subTitle: "See how you’re making a difference in the world. ",
};

const EnvironmentalImpactOrganism = ({
  kWhPerYear,
}: {
  kWhPerYear: number;
}) => {
  const twentyFiveYrMultiplier = 23.55595141;
  const numberOfRoadTrips = useMemo(
    () => Math.round(kWhPerYear * (6.02 * 10 ** -4) * twentyFiveYrMultiplier),
    [kWhPerYear]
  );
  const numberOfTrashBags = useMemo(
    () => Math.round(kWhPerYear * 0.03 * twentyFiveYrMultiplier),
    [kWhPerYear]
  );
  const numberOfTrees = useMemo(
    () => Math.round(kWhPerYear * (4.08 * 10 ** -4) * twentyFiveYrMultiplier),
    [kWhPerYear]
  );

  const formatted = useMemo(() => formatNumber(kWhPerYear), [kWhPerYear]);
  const environmentalImpactCarouselData: Array<CardCarouselData> = [
    {
      extendedDescription: `Your roof can generate ${formatted} kWh of clean solar energy
        per year. That’s enough over 25 years for ${numberOfRoadTrips} cross-country road trips!`,
      value: numberOfRoadTrips,
      footerTextSuffix: "Road Trips",
      headerText: "Cross-country road trips",
      iconMedia: crossCountryRoadTripsImg,
    },
    {
      extendedDescription: `Your roof can generate ${formatted} kWh of clean solar energy
        per year. That’s enough over 25 years for ${numberOfTrashBags} trash bags recycled instead of landfilled!`,
      value: numberOfTrashBags,
      footerTextSuffix: "Trash Bags",
      headerText: "Trash bags",
      iconMedia: trashImg,
    },
    {
      extendedDescription: `Your roof can generate ${formatted} kWh of clean solar energy
        per year. That’s enough over 25 years for ${numberOfTrees} city blocks of trees!`,
      value: numberOfTrees,
      footerTextSuffix: "Trees",
      headerText: "City blocks of trees",
      iconMedia: treesImg,
    },
  ];

  return (
    <ImageInfoCarousel
      cardCarouselData={environmentalImpactCarouselData}
      isLargeSize
      titleInfo={environmentalImpactCarouselTitle}
    />
  );
};

export { EnvironmentalImpactOrganism };
