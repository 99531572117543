import camelCase from "lodash/camelCase";
import { CamelCase } from "type-fest";
import { columns } from "./fields/Columns";
import { filters } from "./fields/Filters";
import { applyPermissions } from "./fields/Permission";
import { paymentOptions } from "./fields/PaymentOptions";
import type { FinancialProduct } from "./PricePoints";
import { PricingFieldsPayload } from "./getPricingFields.types";
import {
  CASH,
  FLEX_MONTHLY,
  LOAN,
  MONTHLY,
  PREPAID,
} from "constants/financialProducts";

const financialProductsOrderedList: FinancialProduct[] = [
  CASH,
  LOAN,
  MONTHLY,
  PREPAID,
  FLEX_MONTHLY,
];

const financialProductsCamelCaseOrderedList: CamelCase<FinancialProduct>[] =
  financialProductsOrderedList.map((fp) => {
    return camelCase(fp) as CamelCase<FinancialProduct>;
  });

// For context, in order to catch a mismatch in financial product and unified pricing data
// https://sunrun.jira.com/browse/SPLAT1-5673
const verifyFinancialProductAvailability = (payload: PricingFieldsPayload) => {
  if (
    !payload?.financialProduct ||
    !payload.unifiedPricingData[
      camelCase(payload.financialProduct) as CamelCase<FinancialProduct>
    ]
  ) {
    throw new Error(
      "Pricing could not be provided for this offer. Please change the included products or contact support"
    );
  }
};

const getPricingFields = (payload: PricingFieldsPayload) => {
  verifyFinancialProductAvailability(payload);
  const filteredColumns = columns.filter((c) =>
    applyPermissions(payload, c.permission)
  );
  const filteredPaymentOptions = paymentOptions.filter((po) =>
    applyPermissions(payload, po.permission)
  );
  const filteredFilters = filters.filter((f) =>
    applyPermissions(payload, f.permission)
  );

  return {
    columns: filteredColumns,
    paymentOptions: filteredPaymentOptions,
    filters: filteredFilters,
  };
};

export {
  getPricingFields,
  financialProductsOrderedList,
  financialProductsCamelCaseOrderedList,
};
export type { PricingFieldsPayload };
