import { useEffect, useMemo, useState } from "react";
import { Button, tokens, Typography } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { useParams } from "react-router-dom";
import { Card, CardHeaderDivider, CardSectionDivider } from "../atoms/Card";
import { SplatContact } from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { SplatProspectResponse } from "../../../amplify/backend/function/OfferExpUsage/ts/public/types";

import { QualifyCustomerModal } from "./QualifyCustomerModal";
import { useHqsUrl } from "hooks/useHqs";
import { useNavigateToUsage } from "utils/useNavigateHelper";
import { StatusCheckMark } from "components/atoms/StatusCheckMark";
import { Attachment, ROLES } from "utils/usageUtils";

type ConfirmInfoTaskListProps = {
  contacts?: SplatContact[];
  usage?: SplatProspectResponse;
  attachments: Attachment[];
  isContactsLoading: boolean;
  isUsageLoading: boolean;
  isHqsEligible: boolean;
};

const ConfirmInfoTaskList = ({
  contacts,
  usage,
  attachments,
  isContactsLoading,
  isUsageLoading,
  isHqsEligible,
}: ConfirmInfoTaskListProps) => {
  const [usageAndUtilityCompleted, setUsageAndUtilityCompleted] =
    useState(false);
  const goToUsage = useNavigateToUsage();

  const [isQualifyModalOpen, setOpenQualifyModal] = useState(false);
  const openToQualifyCustomer = () => {
    setOpenQualifyModal(true);
  };
  const { prospectId } = useParams();
  const hqsUrl = useHqsUrl({ prospectId });

  useEffect(() => {
    if (usage) {
      const {
        accountNumber,
        meterNumber,
        customerStreet,
        customerCity,
        customerState,
        customerZipCode,
        janUsage,
        febUsage,
        marUsage,
        aprUsage,
        mayUsage,
        junUsage,
        julUsage,
        augUsage,
        sepUsage,
        octUsage,
        novUsage,
        decUsage,
      } = usage;

      const utilityInfoPopulated = [
        janUsage,
        febUsage,
        marUsage,
        aprUsage,
        mayUsage,
        junUsage,
        julUsage,
        augUsage,
        sepUsage,
        octUsage,
        novUsage,
        decUsage,
      ].find((val) => val !== undefined);

      const utilityDataPresent =
        !!accountNumber?.length &&
        !!meterNumber?.length &&
        !!customerCity?.length &&
        !!customerState?.length &&
        !!customerStreet?.length &&
        !!customerZipCode?.length;

      setUsageAndUtilityCompleted(
        !!utilityInfoPopulated && utilityDataPresent && !!attachments.length
      );
    }
  }, [usage, attachments]);

  const qualifyCustomerCompleted = useMemo(() => {
    if (!contacts) return false;

    const homeOwnerContacts = contacts.filter((contact) =>
      contact?.roles?.includes(ROLES.HOMEOWNER)
    );
    return !!homeOwnerContacts.find(
      (contact) =>
        contact?.creditStatus?.isPassed ||
        contact?.creditStatus?.isPrescreenApproved
    );
  }, [contacts]);

  const tasksCompleted = [
    usageAndUtilityCompleted,
    qualifyCustomerCompleted,
    // TODO: add completed for HQS
  ].reduce((total, task) => total + Number(task), 0);

  const taskTypographyStyle = {
    marginLeft: 10,
  };

  return (
    <>
      <StyledCard>
        <header>
          <Typography variant="h4" color={tokens.BRAND_HERO_BLUE}>
            Tasks Completed:
            <span data-testid="confirm-info--tasks--count--completed">
              {tasksCompleted}
            </span>
            /
            <span data-testid="confirm-info--tasks--count--total">
              {isHqsEligible ? 3 : 2}
            </span>
          </Typography>
        </header>
        <StyledCardHeaderDivider />
        <section>
          <StyledTaskItem>
            <StyledFlex>
              <span data-testid="confirm-info--tasks--usage--status">
                <StatusCheckMark
                  success={usageAndUtilityCompleted}
                  isLoading={isUsageLoading}
                />
              </span>

              <Typography variant="h5" style={taskTypographyStyle}>
                <StyledFlex>Usage info & Utility Bill</StyledFlex>
              </Typography>
            </StyledFlex>
            <Button
              color="tertiary"
              size="sm"
              onClick={() => goToUsage()}
              data-testid="confirm-info--tasks--usage--edit"
            >
              {usageAndUtilityCompleted ? "Edit" : "Update"}
            </Button>
          </StyledTaskItem>
          <StyledCardSectionDivider />
          <StyledTaskItem>
            <StyledFlex>
              <span data-testid="confirm-info--home-qualification-survey--status">
                <StatusCheckMark
                  success={qualifyCustomerCompleted}
                  isLoading={isContactsLoading}
                />
              </span>

              <Typography variant="h5" style={taskTypographyStyle}>
                Qualify Customer
                {!qualifyCustomerCompleted && (
                  <OptionalDescriptionText>(Optional)</OptionalDescriptionText>
                )}
              </Typography>
            </StyledFlex>
            <Button
              color="tertiary"
              size="sm"
              onClick={openToQualifyCustomer}
              disabled={isContactsLoading || !contacts?.length}
              data-testid="confirm-info--home-qualification-survey--launch"
            >
              <span data-testid="qualify-customer-button">
                {qualifyCustomerCompleted ? "Edit" : "Launch"}
              </span>
            </Button>
          </StyledTaskItem>
          {isHqsEligible && (
            <>
              <StyledCardSectionDivider />
              <StyledTaskItem data-testid="confirmInfoTaskList-home-qualification">
                <StyledFlex>
                  <StatusCheckMark
                    success={
                      false /* TODO: add a flag for true when HQS is completed*/
                    }
                  />

                  <Typography variant="h5" style={taskTypographyStyle}>
                    Home Qualification Survey
                    <OptionalDescriptionText>Optional</OptionalDescriptionText>
                  </Typography>
                </StyledFlex>
                <Button
                  color="tertiary"
                  size="sm"
                  onClick={() => {
                    window.open(hqsUrl, "_blank");
                  }}
                >
                  <span data-testid="home-qualification-survey-button">
                    Launch
                  </span>
                </Button>
              </StyledTaskItem>
            </>
          )}
        </section>
      </StyledCard>
      <QualifyCustomerModal
        open={isQualifyModalOpen}
        onClose={() => setOpenQualifyModal(false)}
      />
    </>
  );
};

const OptionalDescriptionText = styled.span`
  color: #6f6d68;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding-left: 8px;
`;

const StyledCard = styled(Card)`
  height: auto;
  padding-bottom: 16px;
`;

const StyledCardSectionDivider = styled(CardSectionDivider)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledCardHeaderDivider = styled(CardHeaderDivider)`
  margin-bottom: 8px;
`;

const StyledTaskItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFlex = styled.div`
  display: flex;
`;

export { ConfirmInfoTaskList };
