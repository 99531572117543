import { useCallback, useState, useMemo, useEffect, useContext } from "react";
import styled from "@emotion/styled/macro";
import {
  Button,
  Typography,
  tokens,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import {
  SplatProspect,
  OpportunityStages,
  Role,
} from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import {
  ContactView,
  ContactViewProps,
} from "components/molecules/ContactView";
import { SplatContact, NewSplatContact } from "providers/Types";
import {
  ContactSelect,
  ContactSelectProps,
} from "components/molecules/ContactSelect";
import {
  ContactForm,
  ContactFormProps,
} from "components/molecules/ContactForm";
import { ErrorModal } from "components/molecules/ErrorModal";
import { usePrevious } from "hooks/usePrevious";
import { CreditStatusNotification } from "checkout/components/atoms/CreditStatusNotification";
import {
  Card,
  CardSectionDivider,
  CardHeader,
  CardContent,
} from "checkout/components/molecules/Card";
import { ContactStatusNotification } from "checkout/components/atoms/ContactStatusNotification";
import { isEmptyValue } from "utils/usageUtils";
import { UsageFormContext } from "providers/UsageFormContext";

type ContactSelectionManagementProps = {
  title: string;
  description?: string;
  buttonLabel: string;
  /**
   * Currently selected contact (can be undefined)
   * */
  contact?: SplatContact;
  /**
   * Role specified will be what new contacts are created or edited as
   * */
  specifiedRole: Role;
  /**
   * Callback to parent after new contact is selected
   * */
  onSelectContact: (contact: SplatContact) => void;
  /**
   * Props to customize ContactView
   * */
  ContactViewProps?: Partial<ContactViewProps>;
  /**
   * Props to customize ContactSelect
   * */
  ContactSelectProps?: Partial<ContactSelectProps>;
  /**
   * Props to customize ContactForm
   * */
  ContactFormProps?: Partial<ContactFormProps>;
  /**
   * showCreditStatus: Boolean to decide whether or not the user's credit status should be displayed
   * */
  showCreditStatus?: boolean;
  /**
   * contactList: Provided by useContactManagement hook, but can be overridden with a custom list
   * */
  contactList?: SplatContact[];
  /**
   * isContactsLoading: Contact List is loading
   * */
  isContactsLoading?: boolean;
  /**
   * isOptional: Marks field as optional
   * */
  isOptional?: boolean;
  /**
   * onEditContact: Provided by useContactManagement hook, but can be overridden with a custom function
   * */
  onEditContact: (contact: SplatContact) => Promise<any>;
  /**
   * onAddContact: Provided by useContactManagement hook, but can be overridden with a custom function
   * */
  onAddContact: (contact: NewSplatContact) => Promise<any>;
  /**
   * setIsEditing: Provided by useContactManagement hook, but can be overridden with a custom function.
   *
   * This sets `isEditing` to:
   * - **true** when ContactSelectionManagement is in `EDIT`, `CREATE`, `SELECT` mode
   * - **false** when ContactSelectionManagement is in `VIEW` mode
   * */
  setIsEditing: (isEditing: boolean) => void;
  /**
   * This is the functionality that allows the user to remove a contact
   */
  removeContact?: () => void;
  /**
   * This is the label for the button to remove a contact
   */
  removeButtonLabel?: string;

  prospect?: SplatProspect;
  /**
   * loading: Provided by useContactManagement hook, but can be overridden with a custom loading state
   * */
  loading?: boolean;
  /**
   * showContactInfo: Set to false if you want to hide contact info when selecting a contact.  Defaults to true.
   */
  showContactInfo?: boolean;
  /**
   * hideViewState: This allows you to hide the view state if you want to go strait to editing
   */
  hideViewState?: boolean;
};

type ContactConfiguration = "VIEW" | "EDIT" | "SELECT" | "CREATE";

const CONTACT_STATE: { [x: string]: ContactConfiguration } = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  SELECT: "SELECT",
  CREATE: "CREATE",
};

const ContactSelectionManagement = ({
  title,
  description,
  buttonLabel,
  contact,
  specifiedRole,
  onSelectContact,
  ContactViewProps,
  ContactSelectProps,
  ContactFormProps,
  showCreditStatus = false,
  showContactInfo = true,
  hideViewState = false,
  /* --- Provided by useContactManagement hook --- */
  contactList = [],
  isContactsLoading,
  isOptional,
  onEditContact,
  onAddContact,
  setIsEditing,
  removeContact,
  removeButtonLabel,
  prospect,
  loading,
}: ContactSelectionManagementProps) => {
  const prevLoading = usePrevious(isContactsLoading);
  const prevContactList = usePrevious(contactList);
  const prevContact = usePrevious(contact);

  const initialComponentView = useMemo(() => {
    if (contact) return CONTACT_STATE.VIEW;

    // If not in view mode, set edit mode to true
    setIsEditing(true);

    if (contactList.length > 0) return CONTACT_STATE.SELECT;

    return CONTACT_STATE.CREATE;
  }, [contact, contactList.length, setIsEditing]);

  const [error, setError] = useState<{ action?: string; error?: Error }>();
  const [contactConfiguration, setContactConfiguration] =
    useState<ContactConfiguration>(initialComponentView);
  const [temporaryContact, setTemporaryContact] = useState(contact);
  const { setIsDirty } = useContext(UsageFormContext);

  const hasPassedAgreementSigned = (prospect?: SplatProspect) => {
    const stages: Array<OpportunityStages> = [
      "4. Agreement Signed",
      "5. Ready for Submission",
      "6. Submitted To Operations",
      "7. Closed Won",
      "10. Installed PTO",
    ];
    return stages.includes(prospect?.leadStage as OpportunityStages);
  };

  const handleConfigUpdate = useCallback(
    (config: ContactConfiguration) => {
      if (
        contactConfiguration === CONTACT_STATE.VIEW &&
        config !== CONTACT_STATE.VIEW
      ) {
        // If we are leaving VIEW mode, we are entering any EDIT mode
        setIsEditing(true);
        setIsDirty(true);
      }

      if (
        contactConfiguration !== CONTACT_STATE.VIEW &&
        config === CONTACT_STATE.VIEW
      ) {
        // If we are leaving any EDIT mode, we are entering VIEW mode
        setIsEditing(false);
      }

      setContactConfiguration(config);
    },
    [contactConfiguration, setIsEditing, setIsDirty]
  );

  const handleEditContact = useCallback(
    async (editContact: SplatContact) => {
      try {
        await onEditContact(editContact);
        setTemporaryContact(editContact);
        onSelectContact(editContact);
        handleConfigUpdate(CONTACT_STATE.VIEW);
      } catch (err) {
        setError({ action: "Editing Contact", error: err as Error });
      }
    },
    [handleConfigUpdate, onEditContact, onSelectContact]
  );

  const handleAddContact = useCallback(
    async (addContact: NewSplatContact) => {
      try {
        const responseContact = await onAddContact(addContact);
        if (responseContact instanceof Error) {
          return; // error is already manually created, no need to throw
        }
        setTemporaryContact(responseContact);
        onSelectContact(responseContact);
        handleConfigUpdate(CONTACT_STATE.VIEW);
      } catch (err) {
        setError({ action: "Creating new Contact", error: err as Error });
      }
    },
    [handleConfigUpdate, onAddContact, onSelectContact]
  );

  useEffect(() => {
    if (
      (prevLoading && !isContactsLoading && prevContactList?.length === 0) ||
      (prevContact === undefined && !!contact)
    ) {
      setTemporaryContact(contact);
      handleConfigUpdate(initialComponentView);
    }
  }, [
    contact,
    handleConfigUpdate,
    initialComponentView,
    isContactsLoading,
    prevContact,
    prevContactList?.length,
    prevLoading,
  ]);

  const showContactInfoErrorNotification =
    isEmptyValue(contact?.customerEmail) ||
    isEmptyValue(contact?.customerPrimaryPhone);

  const ViewContact = () => {
    return (
      <ViewWrapper>
        {description && (
          <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HEROBLUE}>
            {description}
          </Typography>
        )}
        <ContactView {...ContactViewProps} contact={contact!} />
        {showCreditStatus && contact?.creditStatus && (
          <CreditStatusNotification contact={contact} />
        )}
        {showContactInfoErrorNotification && (
          <ContactStatusNotification
            status="Error"
            message="You need to fill in the contact information to continue."
          />
        )}
      </ViewWrapper>
    );
  };

  const SelectContact = ({
    onClickAddContact,
  }: {
    onClickAddContact: () => void;
  }) => {
    return (
      <>
        <ContactSelect
          {...ContactSelectProps}
          selectedContact={temporaryContact}
          contactList={contactList}
          onContactSelect={(newContact) => {
            setTemporaryContact(newContact);
          }}
          onEditContact={(newContact) => {
            setTemporaryContact(newContact);
            handleConfigUpdate(CONTACT_STATE.EDIT);
          }}
          showCreditStatus={showCreditStatus}
          onClickAddContact={onClickAddContact}
          showContactInfo={showContactInfo}
        />
        <ActionWrapper>
          {contact && !hideViewState && (
            <Button
              color="secondary"
              onClick={() => {
                handleConfigUpdate(CONTACT_STATE.VIEW);
              }}
              size="sm"
            >
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              onSelectContact(temporaryContact!);
              handleConfigUpdate(CONTACT_STATE.VIEW);
            }}
            size="sm"
            disabled={!temporaryContact}
          >
            Confirm
          </Button>
        </ActionWrapper>
      </>
    );
  };

  const shouldShowEdit = contactConfiguration === CONTACT_STATE.EDIT;
  const shouldShowCreate = contactConfiguration === CONTACT_STATE.CREATE;

  // VIEW state is overridden to show SELECT if hideViewState property is passed in
  const shouldShowView =
    contactConfiguration === CONTACT_STATE.VIEW && !hideViewState;
  const shouldShowSelect =
    (hideViewState && contactConfiguration === CONTACT_STATE.VIEW) ||
    contactConfiguration === CONTACT_STATE.SELECT;

  return (
    <>
      <Card>
        <CardHeader data-testid="contact-selection-header">
          <FlexContainer>
            <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HEROBLUE}>
              {title}
            </Typography>
            {isOptional && (
              <Typography
                size={tokens.FONT_SIZE_3}
                color={tokens.TINTS_OFF_WHITE_20}
              >
                {" (Optional)"}
              </Typography>
            )}
          </FlexContainer>
          <FlexContainer>
            {removeContact && (
              <Button onClick={removeContact} size="sm">
                {removeButtonLabel ?? "Remove Contact"}
              </Button>
            )}
            {shouldShowView && (
              <Button
                color="secondary"
                onClick={() => {
                  handleConfigUpdate(CONTACT_STATE.SELECT);
                }}
                size="sm"
              >
                {buttonLabel}
              </Button>
            )}
          </FlexContainer>
        </CardHeader>
        <CardSectionDivider />
        <CardContent>
          {isContactsLoading && (
            <LoadingIndicator width={15} height={15} color="black" />
          )}
          {!isContactsLoading && (
            <>
              {shouldShowView && <ViewContact />}
              {shouldShowSelect && (
                <EditViewWrapper>
                  <SelectContact
                    onClickAddContact={() => {
                      handleConfigUpdate(CONTACT_STATE.CREATE);
                    }}
                  />
                </EditViewWrapper>
              )}
              {shouldShowCreate && (
                <EditViewWrapper>
                  <ContactForm
                    {...ContactFormProps}
                    contact={undefined}
                    disabled={loading}
                    hideCancelButton={false}
                    onCancel={() => {
                      handleConfigUpdate(CONTACT_STATE.SELECT);
                    }}
                    onSubmit={(newContact: SplatContact) =>
                      handleAddContact({
                        ...newContact,
                        role: specifiedRole,
                      })
                    }
                  />
                </EditViewWrapper>
              )}
              {shouldShowEdit && (
                <EditViewWrapper>
                  {/* <ContactForm> component cannot be abstracted like the <SelectContact/> or <ViewContact/> because of a rerender issue.
              The others were done that way to make it cleaner to see */}
                  <ContactForm
                    {...ContactFormProps}
                    disabled={loading}
                    contact={temporaryContact}
                    disableFields={
                      ["customerFirstName", "customerLastName"].concat(
                        hasPassedAgreementSigned(prospect)
                          ? ["customerEmail"]
                          : []
                      ) as Array<keyof SplatContact>
                    }
                    onCancel={() => {
                      handleConfigUpdate(CONTACT_STATE.SELECT);
                    }}
                    onSubmit={handleEditContact}
                  />
                </EditViewWrapper>
              )}
            </>
          )}
        </CardContent>
      </Card>
      {!!error && (
        <ErrorModal
          context="ContactSelectionManagement"
          error={error.error}
          action={error.action}
          onClose={() => setError(undefined)}
        />
      )}
    </>
  );
};

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const EditViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
`;

export { ContactSelectionManagement };
export type { ContactSelectionManagementProps };
