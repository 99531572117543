import useSWR from "swr";
import { API } from "aws-amplify";
import { useAuthToken } from "./useAuthToken";

const useDesignPreferencesUrl = (prospectId: string | undefined) => {
  const hybridToken = useAuthToken("HYBRID");
  return useSWR(
    ["designPreferencesUrl", hybridToken, prospectId],
    async ([, authKey, prospectId]) => {
      if (!authKey) return null;
      if (!prospectId) return null;
      const resp: { url: string } = await API.get(
        "OfferExpApi",
        `/prospect/${prospectId}/design-preferences-url`,
        {
          headers: {
            Authorization: `Bearer ${authKey.token}`,
            "X-Auth-Type": authKey.type,
          },
        }
      );
      return resp;
    }
  );
};

export { useDesignPreferencesUrl };
