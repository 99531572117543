import styled from "@emotion/styled/macro";
import { Typography, Button, tokens } from "@sunrun/experience-ui-components";
import { TitledSection } from "components/molecules/TitledSection";
import { PricePoint } from "providers/pricing/PricePoints";
import { paymentOptions } from "providers/pricing/fields/PaymentOptions";
import { ActiveFilters, Filters } from "providers/pricing/fields/Filters";
import { PricingFilters } from "components/molecules/PricingFilters";
import {
  financialProductDisplayText,
  FinancialProduct,
} from "constants/financialProducts";

type SelectPurchaseTypeProps = {
  availableFinancialProducts: FinancialProduct[];
  onFinancialProductChange: (financialProduct: FinancialProduct) => void;
  selectedFinancialProduct: FinancialProduct;
};
const SelectPurchaseType = ({
  availableFinancialProducts,
  onFinancialProductChange,
  selectedFinancialProduct,
}: SelectPurchaseTypeProps) => {
  return (
    <div>
      <Title>Select Purchase Type</Title>
      <PurchaseTypeGrid data-testid="purchase-type-grid">
        {availableFinancialProducts.map((financialProduct, index) => (
          <PurchaseType
            key={index}
            onClick={() => onFinancialProductChange(financialProduct)}
          >
            <Radio
              type={"radio"}
              onChange={() => {}}
              title={financialProduct}
              checked={selectedFinancialProduct === financialProduct}
              data-testid={`financial-product-radio-${financialProduct}`}
            />
            <label>{financialProductDisplayText[financialProduct]}</label>
          </PurchaseType>
        ))}
      </PurchaseTypeGrid>
    </div>
  );
};

type PricingPaymentProps = {
  onFinancialProductChange: (financialProduct: FinancialProduct) => void;
  selectedFinancialProduct: FinancialProduct;
  availableFinancialProducts: FinancialProduct[];
  paymentOptions: typeof paymentOptions;
  filters: Filters;
  activeFilters: ActiveFilters;
  pricePoints: PricePoint[];
  resetCounter: number;
  onApplyFilter: (filter: Filters[0], payload: any) => void;
};
const PricingPayment = ({
  onFinancialProductChange,
  selectedFinancialProduct,
  availableFinancialProducts,
  paymentOptions,
  filters,
  activeFilters,
  pricePoints,
  resetCounter,
  onApplyFilter,
}: PricingPaymentProps) => {
  return (
    <Container>
      <Typography size={tokens.FONT_SIZE_3}>Payment</Typography>
      <Content>
        <SelectPurchaseType
          availableFinancialProducts={availableFinancialProducts}
          selectedFinancialProduct={selectedFinancialProduct}
          onFinancialProductChange={onFinancialProductChange}
        />
        {paymentOptions.map((paymentOption, index) => {
          switch (paymentOption.type) {
            case "Filters Group":
              return (
                <PricingFilters
                  resetCounter={resetCounter}
                  key={selectedFinancialProduct + index}
                  pricePoints={pricePoints}
                  filters={filters}
                  activeFilters={activeFilters}
                  onApplyFilter={onApplyFilter}
                />
              );
            case "Link":
              return (
                <Button
                  size="sm"
                  key={selectedFinancialProduct + index}
                  onClick={() => {
                    const w = window.open(paymentOption.url, "_blank");
                    if (w) {
                      w.focus();
                    }
                  }}
                >
                  {paymentOption.text}
                </Button>
              );
            default:
              return <div key={selectedFinancialProduct + index}></div>;
          }
        })}
      </Content>
    </Container>
  );
};

const Container = styled(TitledSection)``;
const Content = styled.div``;
const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const Radio = styled.input`
  width: 22px;
  height: 22px;
  accent-color: black;
  cursor: pointer;
`;
const PurchaseType = styled.div`
  display: flex;
  align-items: center;
  label {
    margin: 10px 5px;
    cursor: pointer;
  }
  cursor: pointer;
`;
const PurchaseTypeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export type { PricingPaymentProps };
export { PricingPayment };
