import type { PricePoint } from "../PricePoints";
import type { Permission } from "./Permission";

type PaymentOptionLink = {
  type: "Link";
  permission: Permission;
  text: string;
  url: string;
};

type PaymentOptionFiltersGroup = {
  type: "Filters Group";
  title: string;
  permission: Permission;
  text: (pricePoint: PricePoint) => string;
  value: (pricePoint: PricePoint) => any;
};

const paymentOptions: (PaymentOptionLink | PaymentOptionFiltersGroup)[] = [
  {
    type: "Filters Group",
    title: "Filters",
    permission: {},
    text: () => ``,
    value: () => ``,
  },
];

export { paymentOptions };
