import styled from "@emotion/styled/macro";
import { Contact } from "@sunrun/experience-ui-components/lib/components/ContactCard";
import { ModalType } from "@sunrun/experience-ui-components";
import { Typography } from "@sunrun/experience-ui-components";
import { tokens } from "@sunrun/experience-ui-components";

const modals: Record<string, ModalType> = {
  VOID_SUNRUN: {
    id: "VOID_SUNRUN",
    type: "destructive",
    condition: ({ isSameSigner }) => !isSameSigner,
    heading: "This action will void all offers",
    buttonLabel: "Update Primary Signer",
    createBody: () => (
      <>
        <TypographyStyled role="secondary">
          If you update the Primary Signer, this action will:
        </TypographyStyled>
        <StyledList>
          <li>Void all offers</li>
          <li>Take you to Offer Manager to create a new offer</li>
        </StyledList>
      </>
    ),
    confirm: "I agree to void all offers",
    createAction: ({ select }) => select,
  },
  VOID_NEM: {
    id: "VOID_NEM",
    type: "destructive",
    condition: ({ hasOffer }) => hasOffer,
    heading: "This action will void all offers",
    buttonLabel: "Update Primary Signer",
    createBody: () => (
      <TypographyStyled role="secondary">
        If you update the Primary Signer, this action will void all offers.
      </TypographyStyled>
    ),
    confirm: "I agree to void all offers",
    createAction: ({ select }) => select,
  },
  SAME_SIGNER: {
    id: "SAME_SIGNER",
    type: "warning",
    condition: ({ isSameSigner }) => isSameSigner,
    heading:
      "Are you sure you want to select the same person as the Primary Signer and Secondary Signer?",
    buttonLabel: "Update Secondary Signer",
    createAction: ({ select }) => select,
  },
  SAME_SIGNER_THEN_VOID_SUNRUN: {
    id: "SAME_SIGNER_THEN_VOID_SUNRUN",
    type: "warning",
    condition: ({ isSameSigner }) => isSameSigner,
    heading:
      "Are you sure you want to select the same person as the Primary Signer and Secondary Signer?",
    buttonLabel: "Update Primary Signer",
    createAction:
      ({ setModal }) =>
      () =>
        setModal(modals["VOID_SUNRUN"]),
  },
  SAME_SIGNER_THEN_RESEND_SUNRUN: {
    id: "SAME_SIGNER_FOLLOWED_BY_WARNING_CONTACT_SUPPORT",
    type: "warning",
    condition: ({ hasOffer, isSameSigner }) => hasOffer && isSameSigner,
    buttonLabel: "Update Secondary Signer",
    heading:
      "Are you sure you want to select the same person as the Primary Signer and Secondary Signer?",
    createAction:
      ({ setModal }) =>
      () =>
        setModal(modals["RESEND_SUNRUN_SECONDARY"]),
  },
  REMOVE_SECONDARY: {
    id: "REMOVE_SECONDARY",
    type: "warning",
    condition: ({ newestContactId, hasOffer }) => !newestContactId && !hasOffer,
    heading: "Are you sure you want to remove the secondary signer?",
    buttonLabel: "Update Secondary Signer",
    createBody: (contactList: Contact[]) => {
      const { customerFirstName, customerLastName } = contactList.find(
        (contact) => contact.derivedRoles.includes("Homeowner")
      )!;
      return (
        <Typography role="secondary">
          If you remove the secondary signer, only "{customerFirstName}{" "}
          {customerLastName}" will be able to sign.
        </Typography>
      );
    },
    createAction: ({ select }) => select,
  },
  RESEND_SUNRUN_SECONDARY: {
    id: "WARNING_CONTACT_SUPPORT",
    type: "warning",
    condition: ({ hasOffer, isSameSigner }) => hasOffer && !isSameSigner,
    heading: "Contact Sales Support Desk",
    buttonLabel: "Update Secondary Signer",
    createBody: () => (
      <>
        <Typography color={tokens.TINTS_OFF_WHITE_20}>
          Contact Sales Support Desk to re-send Sunrun Agreement for a new
          signature.
        </Typography>
        <Typography color={tokens.TINTS_OFF_WHITE_20}>
          Call Sales Support Desk at{" "}
          <TelLink href="tel:888-840-7180">888-840-7180</TelLink> or text at{" "}
          <TelLink href="tel:385-317-0193">385-317-0193</TelLink>.
        </Typography>
        <br />
        <Typography color={tokens.TINTS_OFF_WHITE_20}>
          If this is a monthly proposal, credit check may need to be re-run
          before re-sending the Sunrun Agreement for signature.
        </Typography>
      </>
    ),
    createAction: ({ select }) => select,
  },
  RESEND_SUNRUN_UTILITY: {
    id: "WARNING_CONTACT_SUPPORT",
    type: "warning",
    condition: ({ hasOffer }) => hasOffer,
    heading: "Contact Sales Support Desk",
    buttonLabel: "Update Utility Bill Contact",
    createBody: () => (
      <>
        <Typography color={tokens.TINTS_OFF_WHITE_20}>
          Contact Sales Support Desk to re-send Sunrun Agreement for a new
          signature.
        </Typography>
        <Typography color={tokens.TINTS_OFF_WHITE_20}>
          Call Sales Support Desk at{" "}
          <TelLink href="tel:888-840-7180">888-840-7180</TelLink> or text at{" "}
          <TelLink href="tel:385-317-0193">385-317-0193</TelLink>.
        </Typography>
      </>
    ),
    createAction: ({ select }) => select,
  },
  RESEND_NEM: {
    id: "WARNING_CONTACT_SUPPORT_NEM",
    type: "warning",
    condition: ({ hasOffer }) => hasOffer,
    heading: "Contact Sales Support Desk",
    buttonLabel: "Update Utility Bill Contact",
    createBody: () => (
      <>
        <Typography color={tokens.TINTS_OFF_WHITE_20}>
          Contact Sales Support Desk to re-send NEM Agreement for a new
          signature.
        </Typography>
        <Typography color={tokens.TINTS_OFF_WHITE_20}>
          Call Sales Support Desk at{" "}
          <TelLink href="tel:888-840-7180">888-840-7180</TelLink> or text at{" "}
          <TelLink href="tel:385-317-0193">385-317-0193</TelLink>.
        </Typography>
      </>
    ),
    createAction: ({ select }) => select,
  },
} as const;

const TelLink = styled.a`
  color: var(--brand-hero-blue);
  text-decoration: underline;
  font-size: var(--font-size-1);
  font-weight: 400;
`;

const TypographyStyled = styled(Typography)`
  color: var(--tints-off-white-20);
`;

const StyledList = styled.ul`
  line-height: "24px";
`;

export { modals };
