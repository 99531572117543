import styled from "@emotion/styled/macro";
import { zodResolver } from "@hookform/resolvers/zod";
import { Modal, Radio, Textarea } from "@sunrun/experience-ui-components";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { UpdateDesignResponse } from "../../../amplify/backend/function/OfferExpSiteModel/ts/public/types";

import { maxWidth } from "components/atoms/GlobalStyles";
import { UploadAttachments } from "components/molecules/UploadAttachments";
import { requestSiteModelFormSchema } from "providers/schema";
import {
  siteModelSendEmail,
  siteModelUpdateDesign,
  siteModelUploadImage,
} from "services/siteModel";
import { useAppSelector } from "store";
import { Attachment } from "utils/usageUtils";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { rollbar } from "providers/rollbar";

type FormValues = {
  isWithCustomer: string;
  notesToDesigner: string;
  image?: Attachment;
};

const SiteModelModal = ({ prospectId, open, onClose }: SiteModelModalProps) => {
  const { mutate } = useSWRConfig();
  const jwt = useAppSelector((state) => state?.auth?.hybridToken);
  const [error, setError] = useState<string | undefined>();
  const [tempUpdateDesignResponse, setTempUpdateDesignResponse] = useState<
    UpdateDesignResponse | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const submitLabel = useMemo(() => {
    if (tempUpdateDesignResponse?.designId && !loading) {
      return "Retry Image Upload";
    }
    return "Submit";
  }, [loading, tempUpdateDesignResponse?.designId]);

  const cancelLabel = useMemo(() => {
    if (tempUpdateDesignResponse?.designId && !loading) {
      return "Skip";
    }
    return "Cancel";
  }, [loading, tempUpdateDesignResponse?.designId]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(requestSiteModelFormSchema),
    defaultValues: {
      isWithCustomer: "",
      image: undefined,
      notesToDesigner: "",
    },
  });

  const isWithCustomer = watch("isWithCustomer");

  const onSubmit = async ({
    notesToDesigner,
    isWithCustomer,
    image,
  }: FormValues) => {
    setError(undefined);
    setLoading(true);

    if (!prospectId) {
      // Should never happen
      console.error(`ProspectId missing! prospectId: "${prospectId}"`);
      return (
        <ErrorComponent
          context="SiteModelModal"
          error={new Error(`prospectId, ${prospectId}, not found.`)}
        />
      );
    }

    let updateDesignResponse = tempUpdateDesignResponse;

    try {
      if (!updateDesignResponse?.designId) {
        updateDesignResponse = await siteModelUpdateDesign(
          jwt,
          prospectId,
          `${notesToDesigner}${
            image ? "\n\nCheck sitemodeling@sunrun.com for attached image." : ""
          }`,
          isWithCustomer === "yes" ? "High" : "Normal",
          "Redesign",
          "Open"
        );
        setTempUpdateDesignResponse(updateDesignResponse);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      rollbar.error(e as any);
      setError(
        "Sorry. We cannot complete your site model request at this time. Please try again then call sales support for assistance."
      );
      return;
    }

    try {
      if (image) {
        const imageUploadResponse = await siteModelUploadImage(
          jwt,
          prospectId,
          image.documentBlob,
          image.contentType
        );
        await siteModelSendEmail(
          jwt,
          prospectId,
          updateDesignResponse?.designId || "",
          imageUploadResponse?.key
        );
      }
      onClose();
      // Invalidate the site model request list and refetch
      mutate(`/prospect/${prospectId}/designRequests`);
    } catch (e) {
      console.error(e);
      rollbar.error(e as any);
      setError(
        "Sorry. There is an issue attaching your image at this time. Please try again or call sales support for assistance. You can try submitting the site model request without the image, then email the image to sitemodeling@sunrun.com with the customer’s address as the subject line."
      );
    } finally {
      setLoading(false);
    }
  };

  if (!open) return null;

  return (
    <Modal
      title="Request Site Model Update"
      primaryButton={{
        text: loading ? "Loading" : submitLabel,
        onClick: () => handleSubmit(onSubmit)(),
        disabled: loading,
      }}
      secondaryButton={{
        text: cancelLabel,
        onClick: () => onClose(),
        disabled: loading,
      }}
      onClose={onClose}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CustomerWrapper>
          <label id="isWithCustomer" className="sr-text-label">
            Are you with a customer?
          </label>
          <RadioWrapper aria-labelledby="isWithCustomer">
            <Radio
              {...register("isWithCustomer")}
              disabled={!!tempUpdateDesignResponse?.designId || loading}
              label="Yes"
              value="yes"
              checked={isWithCustomer === "yes"}
              data-testid={"standard-radio"}
            />
            <Radio
              {...register("isWithCustomer")}
              disabled={!!tempUpdateDesignResponse?.designId || loading}
              label="No"
              value="no"
              checked={isWithCustomer === "no"}
              data-testid={"standard-radio"}
            />
          </RadioWrapper>
          {errors?.isWithCustomer && (
            <span className="sr-text-helper-text">
              {errors?.isWithCustomer.message}
            </span>
          )}
        </CustomerWrapper>
        <FileInputWrapper>
          <label id="isWithCustomer" className="sr-text-label">
            Upload image (optional)
          </label>
          <Controller
            name="image"
            control={control}
            render={({ field: { onChange, value } }) => (
              <UploadAttachments
                disabled={loading}
                buttonSize="sm"
                acceptMultipleFiles={false}
                onUploadedNewFiles={(files: Attachment[]) => {
                  onChange(files[files.length - 1]);
                }}
                uploadedFiles={[]}
                uploadedNewFiles={value ? [value] : []}
                documentType="Site Model"
              />
            )}
          />
        </FileInputWrapper>
        <TextAreaWrapper>
          <Textarea
            disabled={!!tempUpdateDesignResponse?.designId || loading}
            label="Please explain the required changes.*"
            isError={!!errors?.notesToDesigner}
            helperText={errors?.notesToDesigner?.message}
            {...register("notesToDesigner")}
          />
        </TextAreaWrapper>
        {error && (
          <ErrorWrapper>
            <span className="sr-text-helper-text">{error}</span>
          </ErrorWrapper>
        )}
      </Form>
    </Modal>
  );
};

const Form = styled.form`
  margin-top: 40px;
  display: grid;
  gap: 32px 40px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${maxWidth}px) {
    grid-template-columns: 1fr;
  }
`;

const TextAreaWrapper = styled.div`
  grid-column: span 3;

  @media (max-width: ${maxWidth}px) {
    grid-column: span 1;
  }
`;

const ErrorWrapper = styled.div`
  grid-column: span 3;
  margin-top: -30px;
  margin-bottom: -10px;

  @media (max-width: ${maxWidth}px) {
    grid-column: span 1;
  }
`;

const FileInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-column: span 2;

  @media (max-width: ${maxWidth}px) {
    grid-column: span 1;
  }
`;

const CustomerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export type SiteModelModalProps = {
  prospectId?: string;
  open: boolean;
  onClose: () => void;
};

export { SiteModelModal };
