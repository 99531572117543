import { ChangeEvent } from "react";
import styled from "@emotion/styled/macro";
import { Radio, Typography, tokens } from "@sunrun/experience-ui-components";
import { NumberInput } from "components/atoms/NumberInput";
import { monthsAndDays } from "utils/usageUtils";

type EnergyUsageFormProps = {
  usageData: (number | undefined)[] | null;
  setUsageData: (data: (number | undefined)[]) => void;
  usageCadence: "monthly" | "daily";
  setUsageCadence: (type: "monthly" | "daily") => void;
  isReadOnly?: boolean;
};

const EnergyUsageForm = ({
  usageData,
  setUsageData,
  usageCadence,
  setUsageCadence,
  isReadOnly = false,
}: EnergyUsageFormProps) => {
  return (
    <StyledFlex5Container>
      <header>
        <Typography size={tokens.FONT_SIZE_3}>Energy Usage</Typography>
      </header>
      {!isReadOnly && (
        <StyledRadioContainer>
          <Radio
            label="Monthly (kWh)"
            value="monthly"
            onChange={() => setUsageCadence("monthly")}
            checked={usageCadence === "monthly"}
            data-testid={"standard-radio"}
          />
          <Radio
            label="Daily (kWh)"
            value="daily"
            onChange={() => setUsageCadence("daily")}
            checked={usageCadence === "daily"}
            data-testid={"standard-radio"}
          />
        </StyledRadioContainer>
      )}
      <StyledGrid>
        {[
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].map((monthName, index) => {
          const monthlyVal = usageData ? usageData[index] : undefined;
          return (
            <StyledNumberInputContainer key={monthsAndDays[index].month}>
              <>
                <NumberInput
                  type="number"
                  placeholder="--"
                  id={`${monthsAndDays[index].month}-input`}
                  value={monthlyVal}
                  aria-label={`${monthName} kWh`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (!usageData) {
                      return;
                    }

                    let newArr = [...usageData];
                    const value =
                      e.target.value === "" ? undefined : e.target.value;
                    newArr[index] =
                      value === undefined ? undefined : parseInt(value);
                    setUsageData(newArr);
                  }}
                  name={`${monthsAndDays[index].month}-input`}
                  disabled={isReadOnly}
                />
                <StyledOffWhiteLabel
                  htmlFor={`${monthsAndDays[index].month}-input`}
                >
                  {monthsAndDays[index].month}
                </StyledOffWhiteLabel>
              </>
            </StyledNumberInputContainer>
          );
        })}
      </StyledGrid>
    </StyledFlex5Container>
  );
};

const StyledRadioContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  margin-bottom: 40px;
  margin-top: 46px;
  min-width: 330px;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(4, auto);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(6, auto);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(4, auto);
  }
`;

const StyledNumberInputContainer = styled.div`
  text-align: center;
  width: 62px;
  overflow: hidden;
`;

const StyledOffWhiteLabel = styled.label`
  color: ${tokens.TINTS_OFF_WHITE_20};
  font-size: 14px;
`;

const StyledFlex5Container = styled.div`
  flex: 5;
  margin-bottom: 40px;
`;

export { EnergyUsageForm };
