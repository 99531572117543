import styled from "@emotion/styled/macro";
import {
  tokens,
  Typography,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import React from "react";
import { CardHeader } from "../molecules/Card";
import { Card } from "components/atoms/Card";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";

const VerificationCallTaskView: React.FC = () => {
  const { refreshTasks, isRefetchingTasks } = useCheckoutContext();

  if (isRefetchingTasks)
    return (
      <LoadingWrapper>
        <LoadingIndicator color="black" message="Loading" />
      </LoadingWrapper>
    );

  return (
    <>
      <InformationContainer data-testid="validation-info">
        <Typography size={tokens.FONT_SIZE_1} color={tokens.BRAND_HEROBLUE}>
          Now that you’ve signed your agreement, you’ll review the key points
          with another Sunrun representative. This is for quality assurance and
          your protection.
        </Typography>
        <Typography size={tokens.FONT_SIZE_1} color={tokens.BRAND_HEROBLUE}>
          Once you’ve completed the verification call, the status might take
          several minutes to update. Please{" "}
          <HyperlinkButton
            onClick={refreshTasks}
            data-testid="refresh-verification-call"
          >
            refresh this page
          </HyperlinkButton>
          .
        </Typography>
      </InformationContainer>
      <CardsContainer>
        <Card>
          <CardHeader>
            <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HEROBLUE}>
              Instructions
            </Typography>
          </CardHeader>
          <ul data-testid="verification-instructions">
            <li>
              <Typography
                size={tokens.FONT_SIZE_1}
                color={tokens.BRAND_HEROBLUE}
              >
                Outside of New York state dial 833.362.5137 (English) or
                833.372.5137 (Spanish)
              </Typography>
            </li>
            <li>
              <Typography
                size={tokens.FONT_SIZE_1}
                color={tokens.BRAND_HEROBLUE}
              >
                New York state dial 833.543.0929 (English) or 855.303.0929
                (Spanish) and the customer must call from their phone number
              </Typography>
            </li>
            <li>
              <Typography
                size={tokens.FONT_SIZE_1}
                color={tokens.BRAND_HEROBLUE}
              >
                Use speaker phone if homeowner and solar consultant are together
                in person.
              </Typography>
            </li>
            <li>
              <Typography
                size={tokens.FONT_SIZE_1}
                color={tokens.BRAND_HEROBLUE}
              >
                Please have the first two pages of your solar agreement easily
                accessible.
              </Typography>
            </li>
          </ul>
        </Card>
      </CardsContainer>
    </>
  );
};

const InformationContainer = styled.div`
  width: 80%;
  padding-top: 22px;

  > p {
    padding-bottom: 22px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  > div {
    padding: 0 0;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
`;

export { VerificationCallTaskView };
