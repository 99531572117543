import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { AxiosError } from "axios";
import { EditOfferProductAvailability } from "../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { NewOfferData } from "./Types";
import { emptyOffer, getProducts } from "services/storeFront";
import { useAppSelector } from "store";
import { getQueryStringParams } from "utils/queryString";

type NewOfferProviderProps = {
  prospectId: string;
} & PropsWithChildren;

const NewOfferProvider = ({ prospectId, children }: NewOfferProviderProps) => {
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const identity = useAppSelector((state) => state.auth.identity);
  const [offerId, setOfferId] = useState<string>();
  const [lockVersion, setLockVersion] = useState<number>();
  const [productAvailability, setProductAvailability] =
    useState<EditOfferProductAvailability>();
  const [error, setError] = useState<AxiosError>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const fetchNewOffer = useCallback(
    async (updatedFields?: string[]) => {
      if (!cognitoToken || !identity || !prospectId) {
        return;
      }
      if (updatedFields && updatedFields.length === 0) {
        // If this is an update, but no fields change - there is no need to update
        return;
      }
      try {
        setIsLoading(true);
        const offer = await emptyOffer(cognitoToken, prospectId, updatedFields);
        if (offer.id !== offerId) {
          const ePermitting =
            getQueryStringParams()?.ePermitting?.toString() === "true";
          const products = await getProducts(
            cognitoToken,
            offer.id,
            offer.opportunity.state,
            offer.opportunity.acpMarketType,
            ePermitting,
            identity
          );
          setOfferId(offer.id);
          setLockVersion(offer.lockVersion);
          setProductAvailability(products);
          setError(undefined);
        }
        setIsLoading(false);
      } catch (e: any) {
        setError(e);
        setIsLoading(false);
      }
    },
    [cognitoToken, identity, offerId, prospectId]
  );

  useEffect(() => {
    // Try to fetch the initial offer whenever the token or prospect change - as long as we haven't started loading yet
    if (isLoading === undefined) {
      fetchNewOffer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoToken, isLoading, prospectId, identity]);

  const completeValue =
    offerId && lockVersion !== undefined && productAvailability
      ? { offerId, lockVersion, productAvailability }
      : undefined;
  const isLoadingComplete = !isLoading && (error || completeValue);
  const value = isLoadingComplete ? (error ? error : completeValue) : undefined;

  return (
    <NewOfferContext.Provider value={value}>
      <SalesforceUpdatedContext.Provider value={fetchNewOffer}>
        <OfferConsumedContext.Provider value={fetchNewOffer}>
          {children}
        </OfferConsumedContext.Provider>
      </SalesforceUpdatedContext.Provider>
    </NewOfferContext.Provider>
  );
};

const NewOfferContext = createContext<NewOfferData | AxiosError | undefined>(
  undefined
);
const SalesforceUpdatedContext = createContext(
  (updatedFields?: string[]) => {}
);
const OfferConsumedContext = createContext(() => {});

export {
  NewOfferContext,
  SalesforceUpdatedContext,
  NewOfferProvider,
  OfferConsumedContext,
};
