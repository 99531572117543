import styled from "@emotion/styled/macro";
import { useMemo, useState } from "react";
import { Button, SvgIcon, SvgNames } from "@sunrun/experience-ui-components";
import dashDot from "../assets/images/dash-dot.svg";
import { ReviewOfferProduct } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import {
  BundleCost,
  Collapse,
  CollapseContainer,
  Container,
  ImageText,
  OptionCost,
  Product,
  ProductImage,
  ProductImageArea,
  ProductsContainer,
  RowProductImages,
  Title,
} from "components/atoms/GlobalStyles";
import {
  formatProductCost,
  formatProductMinMaxCost,
} from "utils/formatHelpers";
import { FinancialProduct } from "providers/pricing/PricePoints";
import { FLEX_MONTHLY } from "constants/financialProducts";

const EnergyBundle = ({
  bundle,
  total,
  paymentType,
  products,
  hasAccessories,
}: {
  bundle: string;
  total: number;
  paymentType: FinancialProduct;
  products: ReviewOfferProduct[];
  hasAccessories: boolean;
}) => {
  const [visible, setVisible] = useState(true);

  const isMonthly = useMemo(() => paymentType === "Monthly", [paymentType]);
  const isFlexMonthly = useMemo(
    () => paymentType === FLEX_MONTHLY,
    [paymentType]
  );
  const hasMultipleProductsWithPrice = useMemo(
    () => products.filter((e) => e.cost > 0).length > 1,
    [products]
  );
  return (
    <Container>
      <Header>Your products</Header>
      <ProductsContainer>
        {!visible ? (
          <CollapseContainer>
            <Title>{bundle} Bundle</Title>
            <Collapse>
              {(isMonthly || isFlexMonthly) && hasAccessories && (
                <BundleCost>{formatProductCost(total, paymentType)}</BundleCost>
              )}
              <Button
                size="sm"
                iconMiddle={
                  <SvgIcon
                    height={22}
                    color="white"
                    name={SvgNames.Plus}
                  ></SvgIcon>
                }
                onClick={() => setVisible(!visible)}
              ></Button>
            </Collapse>
          </CollapseContainer>
        ) : (
          <>
            <CollapseContainer>
              <Title>{bundle} Bundle</Title>
              <Collapse onClick={() => setVisible(!visible)}>
                {(isMonthly || isFlexMonthly) && hasAccessories && (
                  <BundleCost>
                    {formatProductCost(total, paymentType)}
                  </BundleCost>
                )}
                <img alt="expand" src={dashDot} height="44px"></img>
              </Collapse>
            </CollapseContainer>
            <RowProductImages>
              {products.map((product, index) => {
                const productCost = formatProductCost(
                  product.cost,
                  product.paymentType
                );
                const productCostRange = formatProductMinMaxCost(
                  product.minimumCost,
                  product.cost,
                  product.paymentType
                );
                return (
                  <Product key={product.productId ?? index}>
                    <ProductImageArea>
                      <ProductImage
                        alt={product.name}
                        src={product.imageUrl}
                      ></ProductImage>
                    </ProductImageArea>
                    <ImageText>
                      {product.name +
                        (product.count > 0 ? " (" + product.count + ")" : "")}
                    </ImageText>
                    {productCost &&
                      isMonthly &&
                      hasMultipleProductsWithPrice && (
                        <OptionCost>{productCost}</OptionCost>
                      )}
                    {productCostRange && isFlexMonthly && (
                      <OptionCost>{productCostRange}</OptionCost>
                    )}
                  </Product>
                );
              })}
            </RowProductImages>
          </>
        )}
      </ProductsContainer>
    </Container>
  );
};

const Header = styled.div`
  font-size: 44px;
  font-weight: 300;
  padding: 20px 20px 40px 0px;
  border-bottom: 1px solid rgba(208, 204, 195, 0.8);
`;

export { EnergyBundle };
