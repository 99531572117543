import { useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const useScrollToElementWithAnchor = ({
  anchorKey,
  containerIsWindow = true,
}: {
  anchorKey: string;
  containerIsWindow: boolean;
}) => {
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [offsetTop, setOffsetTop] = useState<number>();

  const { hash } = useLocation();

  const scrollToRefSetter = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null && !containerIsWindow) {
        setOffsetTop(node.offsetTop);
      }

      if (node !== null && containerIsWindow && hash?.includes(anchorKey)) {
        window.scrollTo({
          top: node.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }

      scrollToRef.current = node;
    },
    [containerIsWindow, anchorKey, hash]
  );

  const containerRefSetter = useCallback(
    (node: HTMLDivElement | null) => {
      if (
        node !== null &&
        offsetTop &&
        !containerIsWindow &&
        hash?.includes(anchorKey)
      ) {
        node.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }
      containerRef.current = node;
    },
    [offsetTop, containerIsWindow, anchorKey, hash]
  );

  return [scrollToRefSetter, containerRefSetter];
};

export { useScrollToElementWithAnchor };
