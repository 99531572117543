const ESTIMATED_NET_SYSTEM_COST =
  "Estimated net system cost after subtracting all estimated rebates and incentives. Not all rebates & incentives are guaranteed. See your agreement for payment schedule.";
const YEAR_1_ENERGY_RATE =
  "We calculate the Year 1 Energy Rate by dividing your annual Sunrun bill by your solar systems annual production.";
const FEDERAL_SOLAR_TAX_CREDIT =
  "You must file for the Federal Solar Tax Credit in your next federal income tax return. Amount is estimated. The purchaser of a solar system may qualify for certain federal, state, local or other rebates, tax credits or incentives. No representation or warranty is made as to whether such credits will be available to the purchaser. Please consult a qualified tax professional for assistance and details.";
const STATE_LOCAL_REBATES =
  "You are solely responsible for claiming these tax credits and rebates.";
const SRECII =
  "Solar Renewable Energy Certificate-II. Estimate only. You are solely responsible for registering your PV system with your applicable SREC-II Program Administrator. Sunrun does not guarantee the number of SREC-II credits produced. SREC-II values are subject to actual system production in future dates; changes in the SREC-II program, SREC-II laws, regulations and policies.";
const POST_SOLAR_UTILITY_BILL =
  "You will still have a bill with your utility company. The amount you owe your utility depends on utility rate schedules, fixed charges, seasonal changes in solar output, and if you use more power than you did in the past.";
const COSTCO_CARD_ESTIMATE =
  "Costco Shop Card amount is an estimate only and will be verified by Sunrun prior to system installation. Amount is based on pre-tax purchase amount of materials only, including solar panels, inverters, racking systems, and battery storage systems. Installation cost is not included.";
const LIFETIME_SAVINGS =
  "This is an estimate and does not guarantee actual savings. This savings estimate assumes: customers consistent energy usage year-over-year, a range of possible estimated annual utility rate increases , and that the customer is on the appropriate post-solar utility rate tariff. Actual savings will vary based on actual energy usage, actual utility rate increases, actual utility rate tariff structures, and actual production based on the final solar system design.";
const LOAN_APR =
  "This APR includes a 0.50% Autopay discount. You must sign up for Autopay in order to receive this discount. Autopay is not required to obtain your loan.";

export {
  COSTCO_CARD_ESTIMATE,
  ESTIMATED_NET_SYSTEM_COST,
  FEDERAL_SOLAR_TAX_CREDIT,
  LIFETIME_SAVINGS,
  LOAN_APR,
  POST_SOLAR_UTILITY_BILL,
  SRECII,
  STATE_LOCAL_REBATES,
  YEAR_1_ENERGY_RATE,
};
