import { useMemo } from "react";
import { useAppSelector } from "store";
import { type AuthType, type AuthToken } from "utils/authUtils";

const useAuthToken = (tokenType: AuthType): AuthToken | undefined => {
  const authSlice = useAppSelector((state) => state.auth);
  return useMemo(() => {
    const { cognitoToken, hybridToken, proposalToken } = authSlice;
    switch (tokenType) {
      case "IHD":
        return cognitoToken?.token
          ? { type: "IHD", token: cognitoToken.token }
          : undefined;
      case "HYBRID":
        return hybridToken ? { type: "HYBRID", token: hybridToken } : undefined;
      case "PROPOSAL":
        return proposalToken
          ? { type: "PROPOSAL", token: proposalToken }
          : undefined;
    }
  }, [authSlice, tokenType]);
};

export { useAuthToken };
