import styled from "@emotion/styled/macro";
import { tokens } from "@sunrun/experience-ui-components";
import { Typography } from "@sunrun/experience-ui-components";
import { PackageHeader } from "../atoms/PackageHeader";
import { ReactComponent as Yes } from "../../assets/images/included.svg";
import { ReactComponent as No } from "../../assets/images/notIncluded.svg";
import { EditOfferBundle } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";

type PackageCompareCardProps = React.ComponentPropsWithoutRef<"table"> & {
  className?: string;
  bundle: EditOfferBundle;
  uniqueProdIds: string[];
  productTitles: string[];
};

const PackageCompareCard = ({
  className = "",
  bundle,
  uniqueProdIds,
  productTitles,
  ...otherProps
}: PackageCompareCardProps) => {
  return (
    <StyledTable
      className={className}
      cellSpacing="0"
      cellPadding="0"
      {...otherProps}
    >
      <thead>
        <tr>
          <StyledColTh colSpan={4} scope="colgroup">
            <StyledPackageHeader>{bundle.title}</StyledPackageHeader>
          </StyledColTh>
        </tr>
      </thead>

      <tbody>
        {uniqueProdIds.map((prodId: string, j: number) => (
          <StyledTr key={`${bundle.title}-${j}`}>
            <StyledGutter>&nbsp;</StyledGutter>

            <StyledTh scope="row">
              <Typography>{productTitles[j]}</Typography>
            </StyledTh>

            <StyledTd>
              {bundle.productIds.includes(prodId) ? <Yes /> : <No />}
            </StyledTd>

            <StyledGutter>&nbsp;</StyledGutter>
          </StyledTr>
        ))}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={4}>&nbsp;</td>
        </tr>
      </tfoot>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  outline: 1px solid ${tokens.TINTS_OFF_WHITE_40};
`;

const StyledColTh = styled.th`
  text-align: left;
`;

const StyledPackageHeader = styled(PackageHeader)`
  margin-bottom: 16px;
  border-radius: 8px 8px 0 0;

  & > div {
    margin: 84px 0 16px 16px;
  }
`;

const StyledTr = styled.tr`
  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const StyledTh = styled.th`
  width: 45%;
  letter-spacing: -0.32px;
  text-align: left;
  padding: 10px;
  border-right: 1px solid rgba(208, 204, 195, 0.5);
  border-bottom: 1px solid rgba(208, 204, 195, 0.5);
  color: ${tokens.BRAND_HERO_BLUE};
`;

const StyledTd = styled.td`
  text-align: center;
  border-bottom: 1px solid rgba(208, 204, 195, 0.5);
`;

const StyledGutter = styled.td`
  width: 16px;
`;

export { PackageCompareCard };
